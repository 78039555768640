import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Form, Row } from "antd";
import DisplayInfo from "../../../components/Base/DisplayInfo";
import {
  BuildFieldErrorsDict,
  formatCPFCNPJ,
  maskIntNumber,
} from "../../../util/Utils";
import api from "../../../services/api";
import Loading from "../../../components/Base/Loading";
import PageTitle from "../../../components/Base/PageTitle";
import DividerPage from "../../../components/Base/DividerPage";
import FingerScroll from "../../../components/Base/FingerScroll";
import { WhatsAppOutlined } from "@ant-design/icons";
import PriorityOpportunityCard from "../../../components/Base/PriorityOpportunityCard";
import Empty from "../../../components/Base/Empty";
import { content_bg } from "../../../Cores";
import Collapse from "../../../components/Collapse";
import TextArea from "../../../components/Base/Forms/TextArea";
import Upload from "../../../components/Base/Forms/Upload";
import Comment from "../../../components/Comment";
import { Notification } from "../../../notification";

const TabContainer = styled.div`
  padding-top: 24px;
`;

const Container = styled.div`
  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({ noHorizontalPadding }) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`;
    }
  }}
`;

export default function TabClientDetail({
  clientId,
  priority_opportunities = [],
}) {
  const [form] = Form.useForm();
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [client, setClient] = useState({});
  const [fileList, setFileList] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    if (clientId) {
      fetchClient();
      fetchComments();
    }
  }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchClient = async () => {
    try {
      setIsLoadingClient(true);
      const response = await api.get(`sap/client/${clientId}`);
      setClient(response.data);
    } catch (error) {
      // BuildFieldErrorsDict(error, null, false)
    } finally {
      setIsLoadingClient(false);
    }
  };

  function generateLink(phone) {
    const phoneNumbers = phone.replaceAll(/\D/g, "");
    if (phoneNumbers.length === 10) {
      return phone;
    }
    return (
      <a
        target={"_blank"}
        href={`https://wa.me/55${phoneNumbers}/`}
        rel="noreferrer"
      >
        <WhatsAppOutlined /> {phone}
      </a>
    );
  }

  const addNewQuestion = async () => {
    let formData = new FormData();
    setIsLoadingComments(true);
    let values;
    try {
      values = await form.validateFields();

      delete values["file_list"];
      fileList.forEach((file) => {
        formData.append("files[]", file);
      });
      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (value) {
          if (value.constructor.name === "Array") {
            value.forEach((value) => {
              formData.append(key, value);
            });
          } else {
            formData.append(key, value);
          }
        }
      });
      handleCommentAdded();
    } catch (error) {
      Notification("error", "Erros encontrados");
      setIsLoadingComments(false);
      return;
    }

    try {
      await api.post(`client/${clientId}/add_question`, formData);
      Notification("success", "Comentário criado com sucesso.");
      form.resetFields();
      setFileList([]);
      fetchComments();
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, false);
    } finally {
      setIsLoadingComments(false);
    }
  };

  const fetchComments = async () => {
    try {
      setIsLoadingComments(true);
      const response = await api.get(`client/${clientId}/questions`);
      setComments(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingComments(false);
    }
  };

  const handleCommentAdded = () => {
    const allCommentsCollapsable = document.querySelectorAll(
      ".ant-collapse-header"
    )[1];

    if (allCommentsCollapsable.ariaExpanded === "false") {
      allCommentsCollapsable.click();
    }
  };

  return (
    <TabContainer>
      {isLoadingClient ? (
        <Col xs={24}>
          <Loading />
        </Col>
      ) : (
        <>
          <PageTitle value={"Informações básicas"} />
          <Row gutter={[16, 16]}>
            <Col xs={12} md={8}>
              <DisplayInfo label={"Código (SAP)"}>{client?.id}</DisplayInfo>
            </Col>

            <Col xs={12} md={8}>
              <DisplayInfo label={"Cliente (SAP)"}>{client?.name}</DisplayInfo>
            </Col>

            {/*<Col xs={12} md={8}>*/}
            {/*    <DisplayInfo label={'Cliente (CARETRACK)'}>*/}
            {/*        {equipment?.name}*/}
            {/*    </DisplayInfo>*/}
            {/*</Col>*/}
            <Col xs={12} md={8}>
              <DisplayInfo label={"cpf/cnpj"}>
                {formatCPFCNPJ(client?.cnpj_cpf)}
              </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
              <DisplayInfo label={"cidade/estado"}>
                {client?.city} / {client?.state}
              </DisplayInfo>
            </Col>
            {/*<Col xs={12} md={8}>*/}
            {/*    <DisplayInfo label={'Consultor de peças'}>*/}
            {/*        {client?.parts_consultant}*/}
            {/*    </DisplayInfo>*/}
            {/*</Col>*/}
            <Col xs={12} md={8}>
              <DisplayInfo label={"Saldo do Limite"} showZero>
                R$ {maskIntNumber(client?.limit_value)}
              </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
              <DisplayInfo label={"Bloqueio FINANCEIRO"}>
                {client?.financial_block ? "Sim" : "Não"}
              </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
              <DisplayInfo label={"centro"}>{client?.center}</DisplayInfo>
            </Col>
          </Row>
          <PageTitle
            style={{ marginTop: 24 }}
            value={"Contato(s) do cliente"}
          />
          <Row gutter={[16, 16]}>
            {client?.contact_list?.map(({ nome, telefone, email }) => (
              <>
                <Col xs={12} md={8}>
                  <DisplayInfo label={"Contato de Uso"}>{nome}</DisplayInfo>
                </Col>

                <Col xs={12} md={8}>
                  <DisplayInfo label={"TELEFONE (USO)"}>
                    {generateLink(telefone)}
                  </DisplayInfo>
                </Col>

                <Col xs={12} md={8}>
                  <DisplayInfo label={"EMAIL (USO)"}>{email}</DisplayInfo>
                </Col>
              </>
            ))}
          </Row>
          <PageTitle
            style={{ marginTop: 24 }}
            value={"Responsáveis da Tracbel"}
          />
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col xs={12} md={8}>
              <DisplayInfo label={"Consultor de serviços"}>
                {client?.parts_consultant}
              </DisplayInfo>
            </Col>

            <Col xs={12} md={8}>
              <DisplayInfo label={"Consultor de peças"}>
                {client?.name}
              </DisplayInfo>
            </Col>
          </Row>
          <DividerPage />
          <PageTitle
            style={{ marginTop: 24 }}
            value={"Novas notificações prioritárias"}
          />
          {priority_opportunities.length === 0 ? (
            <Empty />
          ) : (
            <FingerScroll gap={8} breakLeft={false}>
              {priority_opportunities.map((item) => {
                return <PriorityOpportunityCard opportunity={item} />;
              })}
            </FingerScroll>
          )}
          <DividerPage />
          <Row>
            <Col xs={24}>
              <Container noHorizontalPadding>
                <Collapse title={<PageTitle value={"Adicionar Comentários"} />}>
                  <Col xs={24} md={24}>
                    <Form layout={"vertical"} form={form}>
                      <Form.Item
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <TextArea
                          showCount
                          maxLength={2000}
                          placeholder={"Adicione um comentário..."}
                        />
                      </Form.Item>

                      <Form.Item name={"file_list"} label={"Adicionar Anexos"}>
                        <Upload setFileList={setFileList} />
                      </Form.Item>
                    </Form>
                    {/*)}*/}
                  </Col>
                  <Col xs={24} md={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 12,
                      }}
                    >
                      <Button
                        onClick={addNewQuestion}
                        type="primary"
                        loading={isLoadingComments}
                      >
                        Adicionar Comentário
                      </Button>
                    </div>
                  </Col>
                </Collapse>
              </Container>
            </Col>
          </Row>
          <Row>
            <Container noHorizontalPadding>
              <Collapse
                title={<PageTitle value={"Todos os comentários"} />}
                defaultActiveKey={comments.length > 0 && "1"}
              >
                {isLoadingComments ? (
                  <Loading />
                ) : (
                  <>
                    {comments.length === 0 ? (
                      <Empty style={{ height: 216 }} />
                    ) : (
                      comments.map((comment) => <Comment comment={comment} />)
                    )}
                  </>
                )}
              </Collapse>
            </Container>
          </Row>
        </>
      )}
    </TabContainer>
  );
}
