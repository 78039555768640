import React, {useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../util/Utils";

export default function useDefaultFetch() {
    const [isLoading, setIsLoading] = useState(false)
    const fetchData = async (url) => {
        try {
            setIsLoading(true)
            const response = await api.get(url);

            return response.data;
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    const postData = async (url, data = null) => {
        try {
            setIsLoading(true)
            const response = await api.post(url, data);

            return response.data;
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

   const patchData = async (url, data = null) => {
        try {
            setIsLoading(true)
            const response = await api.patch(url, data);

            return response.data;
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    return {fetchData, isLoading, postData, patchData}
}