import React, {useEffect, useState} from 'react';
import {useActionBar} from "../../context/ActionBarContext";
import useTab from "../../hooks/useTab";
import {useParams} from "react-router-dom";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";
import Loading from "../../components/Base/Loading";
import {Tabs} from "../../components/Base/Tabs";
import TabRuleDetail from "../../components/Rules/tabs/TabRuleDetail";
import TabRuleEquipments from "../../components/Rules/tabs/TabRuleEquipments";
import useActionPermission from "../../hooks/useActionPermission";

export default function RuleDetail() {
    const {setQueryTab, defaultTabId} = useTab({defaultTab: '1'})
    const [rule, setRule] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const ruleID = params.id
    const {hasPermission} = useActionPermission({permissions: [1, 2, 3] })

    const {changeTitle, setHasBack} = useActionBar()
    useEffect(() => {
        changeTitle('Detalhe da regra')
        setHasBack(true)
    }, [changeTitle, setHasBack])



    useEffect(() => {
        const fetchRule = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`rule/${ruleID}`);
                setRule(response.data)
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setIsLoading(false)
            }
        }
        fetchRule()

    }, [ruleID]);


    const _getTabs = () => {

        let tabs = [{
            label: 'Visão geral',
            key: '1',
            children: <TabRuleDetail rule={rule} setRule={setRule} hasPermission={hasPermission}/>
        },]

        if (hasPermission) {
            tabs.push({
                label: 'Equipamentos',
                key: '2',
                children: <TabRuleEquipments rule={rule} />
            })
        }

        return tabs

    }
    return (
        <>
            {isLoading ? (<Loading/>) :
                (
                    <>
                        <Tabs style={{'position': 'sticky !important', marginTop: -16}}
                              destroyInactiveTabPane={true}
                              activeKey={defaultTabId}
                              onChange={setQueryTab} items={_getTabs()}/>
                    </>
                )
            }
        </>)
}
