import styled from "styled-components";
import { content_bg } from "../../Cores";
import OsEquipmentTable from "./OsEquipmentTable";
import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
// import CreateCotacaoOsModal from "./modals/CreateCotacaoOsModal";
import api from "../../services/api";
import Loading from "../../components/Base/Loading";
import PageTitle from "../../components/Base/PageTitle";
import DisplayInfo from "../../components/Base/DisplayInfo";
import { dateFormat } from "../../util/dates";
import CreatePartCotacaoModal from "../../components/PartQuotation/CreatePartCotacaoModal";
import PartsBudgetTable from "../../components/PartBudgetsTable";
import CreateOsQuotationModal from "../../components/OsCreation/CreateOsModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({ noHorizontalPadding }) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`;
    }
  }}
`;

export const TabOsEquipment = ({ equipment }) => {
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [openCreateCotacaoOs, setOpenCreateCotacaoOs] = useState(false);
  const [client, setClient] = useState({});
  const [currentBudget, setCurrentBudget] = useState(equipment.budget);
  const [partsQuotations, setPartsQuotations] = useState(
    equipment.parts_quotation
  );
  const [openCreatePartCotacao, setOpenCreatePartCotacao] = useState(false);

  useEffect(() => {
    if (equipment.client.id) {
      fetchClient();
    }
  }, [equipment.client.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchClient = async () => {
    try {
      setIsLoadingClient(true);
      const response = await api.get(`sap/client/${equipment.client.id}`);
      setClient(response.data);
    } catch (error) {
      // BuildFieldErrorsDict(error, null, false)
    } finally {
      setIsLoadingClient(false);
    }
  };

  return (
    <>
      {isLoadingClient ? (
        <Loading />
      ) : (
        <>
          <Container>
            <OsEquipmentTable equipment={equipment} />
            <Row style={{ marginTop: "1rem", marginLeft: "auto" }}>
              <Button
                type="primary"
                onClick={() => setOpenCreatePartCotacao(true)}
              >
                <PlusOutlined />
                Criar cotação de peças
              </Button>
              <Button
                onClick={() => setOpenCreateCotacaoOs(true)}
                type="primary"
                style={{ marginLeft: "16px" }}
              >
                <PlusOutlined />
                Criar nova cotação
              </Button>
            </Row>
          </Container>
          <CreateOsQuotationModal
            isOpen={openCreateCotacaoOs}
            setIsOpen={setOpenCreateCotacaoOs}
            startingCenter={equipment.center.id}
            startingBrand={equipment.brand_equipment}
            client={equipment.client}
            equipment_id={equipment.id}
            source={"equipment"}
            setCurrentBudget={setCurrentBudget}
          />
          <CreatePartCotacaoModal
            isOpen={openCreatePartCotacao}
            setIsOpen={setOpenCreatePartCotacao}
            startingCenter={equipment.center.id}
            startingBrand={equipment.brand_equipment.id_sap}
            client_id={equipment.client.id}
            equipment_id={equipment.id}
            setPartsQuotations={setPartsQuotations}
          />
          {currentBudget && (
            <Row gutter={[24, 0]} align={"stretch"}>
              <Col xs={24}>
                <Container noHorizontalPadding>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <PageTitle value={"Cotação atual"} />
                    </Col>

                    <Col xs={12} lg={8}>
                      <DisplayInfo label={"CÓDIGO (SAP)"}>
                        {currentBudget.id_sap}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12} lg={8}>
                      <DisplayInfo label={"TIPO DE COTAÇÃO"}>
                        {currentBudget.type}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12} lg={8}>
                      <DisplayInfo label={"DATA DE ABERTURA"}>
                        {dateFormat(currentBudget.created_at)}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12} lg={8}>
                      <DisplayInfo label={"DESCRIÇÃO DA FALHA/ATIVIDADES"}>
                        {currentBudget.description}
                      </DisplayInfo>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          )}

          {!!partsQuotations.length && (
            <Row
              gutter={[24, 0]}
              align={"stretch"}
              style={{ paddingTop: "16px" }}
            >
              <Col xs={24}>
                <Container noHorizontalPadding>
                  <PageTitle value={"Cotações de peças"} />
                  <PartsBudgetTable parts_quotation={partsQuotations} />
                </Container>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
