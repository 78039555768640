import React from "react";
import styled from "styled-components";
import PageTitle from "../../components/Base/PageTitle";
import LastDate from "../../components/Base/LastDate";
import { Col, Row } from "antd";
import DisplayInfo from "../../components/Base/DisplayInfo";
import Maps from "../../components/Base/Maps/Maps";
import ShareLocation from "../../components/Base/ShareLocation";
import NoLocation from "../../components/Base/NoLocation";

const TabContainer = styled.div`
  padding-top: 24px;
`;

const StyledLastDate = styled(LastDate)`
  margin-top: 16px;
`;
export default function TabLocation({ equipment, ...props }) {
  const latitude = equipment.last_equipment_position?.latitude;
  const longitude = equipment.last_equipment_position?.longitude;

  function noHasLocation() {
    return !latitude || !longitude;
  }

  return (
    <TabContainer>
      {noHasLocation() ? (
        <Col xs={24}>
          <NoLocation
            message={
              "O equipamento não reportou sua localização recentemente, então não conseguimos criar um mapa com sua localização."
            }
          />
        </Col>
      ) : (
        <>
          <PageTitle value={"Localização atual"} />
          <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
            <Col xs={12} lg={8}>
              <DisplayInfo label={"LATITUDE"}>{latitude}</DisplayInfo>
            </Col>
            <Col xs={12} lg={8}>
              <DisplayInfo label={"LONGITUDE"} noWrap>
                {longitude}
              </DisplayInfo>
            </Col>
            <Col xs={12} lg={8}>
              <DisplayInfo
                label={"Última localização"}
                extraIcon={
                  equipment.last_equipment_position && (
                    <ShareLocation
                      lat={equipment.last_equipment_position?.latitude}
                      long={equipment.last_equipment_position?.longitude}
                    />
                  )
                }
              >
                {equipment.last_equipment_position?.reverse_geocoding}
              </DisplayInfo>
            </Col>
          </Row>
          <Maps
            height={"491px"}
            defaultZoom={15}
            center={{
              lat: Number(latitude),
              lng: Number(longitude),
            }}
            coords={[
              {
                lat: Number(latitude),
                lng: Number(longitude),
                id: 1,
              },
            ]}
          />
          <StyledLastDate
            label={"Última atualização da localização"}
            datetime={equipment.last_equipment_position.updated_at}
          />
        </>
      )}
    </TabContainer>
  );
}
