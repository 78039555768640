import React from "react";
import { Col, Form, Row } from "antd";
import PageTitle from "../../Base/PageTitle";
import Select from "../../Base/Select";
import InputNumber from "../../Base/Forms/InputNumber";

export default function OilAnalysisFormContainer({}) {
  return (
    <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
      <Col lg={24} md={24} sm={24} xs={24}>
        <PageTitle value={<> Variáveis da regra </>} />
      </Col>
      {/* <Col lg={12} md={12} sm={24} xs={24}>*/}
      {/*    <Form.Item label={'Sistema'} name={'telemetry_system'}*/}
      {/*               rules={[{*/}
      {/*                   required: true,*/}
      {/*                   message: 'Sistema é obrigatório'*/}
      {/*               }*/}
      {/*               ]}>*/}
      {/*        <Select*/}
      {/*            placeholder="Selecione o sistema"*/}
      {/*            url={'telemetry_sistems?page_size=1000'}*/}
      {/*        />*/}
      {/*    </Form.Item>*/}
      {/*</Col>*/}
      <Col lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label={"Origem da coleta"}
          name={"laboratory"}
          rules={[
            {
              required: true,
              message: "Origem da coleta é obrigatório",
            },
          ]}
        >
          <Select
            placeholder="Selecione"
            url={"oil_laboratory?page_size=1000"}
          />
        </Form.Item>
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label="Condição da amostra"
          name="condition"
          rules={[
            {
              required: true,
              message: "Condição da amostra é obrigatório",
            },
          ]}
        >
          <Select
            placeholder="Selecione"
            url={"oil_condition?page_size=1000"}
          />
        </Form.Item>
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label="Horímetro mínimo"
          name="hour"
          rules={[
            {
              required: true,
              message: "Horímetro mínimo é obrigatório",
            },
          ]}
        >
          <InputNumber placeholder="Digite o valor" />
        </Form.Item>
      </Col>
      {/*<Col lg={12} md={12} sm={24} xs={24}>*/}
      {/*    <Form.Item label="Quantidade de amostras"*/}
      {/*               name="recurrence"*/}
      {/*               rules={[{*/}
      {/*                   required: true,*/}
      {/*                   message: 'Quantidade da amostras é obrigatório'*/}
      {/*               },*/}
      {/*               ]}>*/}
      {/*            <InputNumber*/}
      {/*                     placeholder="Digite o valor"/>*/}
      {/*    </Form.Item>*/}
      {/*</Col>*/}
    </Row>
  );
}
