import { useEffect } from "react";
import useTable from "../hooks/useTable";
import { dateFormat } from "../util/dates";
import UpTable from "./Base/UPTable";

export default function PartsBudgetTable({ parts_quotation }) {
  const { isLoading, tableParams, handleTableChange, tableData, setTableData } =
    useTable({
      useHandleRequestTable: false,
    });

  const columns = [
    {
      title: "Código (SAP)",
      dataIndex: "id_sap",
      sorter: "id_sap",
      key: "id_sap",
      width: "100px",
    },
    {
      title: "Tipo de Cotação",
      width: "100px",
      render: () => "ZPPE",
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      sorter: "seller",
      key: "seller",
      width: "100px",
    },
    {
      title: "Data de Abertura",
      dataIndex: "created_at",
      sorter: "created_at",
      key: "created_at",
      width: "100px",
      render: (value) => dateFormat(value, "DD/MM/YYYY"),
    },
  ];

  useEffect(() => {
    if (parts_quotation) {
      setTableData(parts_quotation);
    }
  });

  return (
    <UpTable
      onChange={handleTableChange}
      loading={isLoading}
      noPagination={true}
      tableParams={tableParams}
      columns={columns}
      dataSource={tableData}
      scroll={{
        y: "350px",
      }}
    />
  );
}
