import React from 'react'
import styled from 'styled-components'
import {red_10} from "../../Cores";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  gap: 10px;
  width: calc(100% + 48px);
  min-height: 38px;
  background-color: ${red_10};
  padding-left: 24px;
  margin-left: -24px;
  //margin-bottom: 16px;
`

export default function Pagealert({icon, message,...props}) {
    return (
        <Container {...props}>
            {icon}
            <span>
                {message}
            </span>
        </Container>
    )
}