import moment from "moment-timezone";

export const dateFormat = (datetime, format = "DD/MM/YYYY") => {
    if (datetime) {
        return moment(datetime).tz('America/Sao_Paulo').format(format);

    }
    return null

}

export const getNameDay = (day) => {
    let days = {
        0: 'Domingo',
        1: 'Segunda-feira',
        2: 'Terça-feira',
        3: 'Quarta-feira',
        4: 'Quinta-feira',
        5: 'Sexta-feira',
        6: 'Sábado',
    }
    return days[day];
}


export function listSeparetedByDate(datas, lookup='created_at') {
    /***
     * pega a lista de items e cria uma matriz com o primeiro index de cada lista sendo a data no formato DD/MM/YYYY
     * ***/

    const datasByDate = []

    let indexList = -1
    let currentDate;
    datas.forEach((item) => {
        const itemDate = dateFormat(item[lookup])
        if (!currentDate || itemDate !== currentDate) {
            currentDate = dateFormat(item[lookup])
            datasByDate.push([`${currentDate}`])
            ++indexList
        }
        datasByDate[indexList].push(item)

    })
    return datasByDate
}

