import React, {useState} from "react";
import {dateFormat} from "../../util/dates";
import styled from "styled-components";
import {
    gray_2,
    menu_bg,
    primary_color,
    red_5,
    success_color
} from "../../Cores";
import Avatar from "../Base/Avatar";

const Container = styled.div`
  padding: 16px 16px 16px 24px;
  width: 100%;
  min-height: 110px;
  max-height: 130px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: ${({isRead}) => isRead ? 'normal': 'bold'};
  //cursor: pointer;
  cursor: ${({onClick}) => !!onClick? 'pointer': 'default'};
  border-left: ${({isRead}) => isRead ? 'none' : `2px solid ${primary_color}`};
  background: ${({isRead}) => isRead ? 'transpa#FDBA12rent' : menu_bg};
`

const Title = styled.span`
  color: white;
  //font-weight: bold;
  font-size: 14px;
`

const HeaderContainer = styled.div`
  display: flex;
  column-gap: 4px;
  flex-wrap: nowrap;
  font-size: 12px;

  & > * {
    flex-shrink: 0;
    position: relative;
    display: inline-block;

  }

  & > :not(:first-child) {
    margin-left: 8px;
  }

  & > :not(:first-child):before {
    content: '';
    background: white;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
  }
`

const PMP = styled.span`
  color: ${({hasPMP}) => hasPMP ? success_color : red_5};
`
const Garanty = styled.span`
  color: ${({hasWarranty}) => hasWarranty ? success_color : red_5};
`
const Time = styled.span`
  color: ${gray_2};
  font-size: 12px;
`
const Content = styled.div`
  display: flex;
  gap: 8px;
  & > *:first-child {
    flex-shrink: 0;
  }
`
const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default function CardNotificationOpportunity({notification, onClick, ...props}) {


    return (
        <Container isRead={notification.read} onClick={onClick} >
            <HeaderContainer>
                <span>N° {`${notification.id}`}</span>
                <span>{notification.content_object.priority_name}</span>
                <PMP hasPMP={notification.PMP}>PMP</PMP>
                <Garanty
                    hasWarranty={notification.warranty_date}>Garantia</Garanty><
                /HeaderContainer>
            <Content>
           {notification.owner &&
                <Avatar size={24} src={notification.owner.picture}/>}
                  <Title level={5}> <Text>{notification.message}</Text></Title>
                </Content>
            <Time>{dateFormat(notification.created_at, 'DD/MM/YYYY - HH[h]mm')}</Time>
        </Container>
    )

}