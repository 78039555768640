import React from "react";
import styled from "styled-components";
import { PageHeader, Typography } from "antd";
import DropdownUser from "./DropdownUser";
import { border_dark_color2 } from "../../Cores";
import NotificationDropdown from "../notification/NotificationDropdown";
import { useActionBar } from "../../context/ActionBarContext";
import { useUserContext } from "../../context/UserContext";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import MenuButton from "../MenuButton";
import FilterCenterActionBar from "./FilterCenterActionBar";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 99;
  transition: opacity 0.5s, visibility 0s 0.4s;
  ${({ hiddenActionBar }) => {
    if (!hiddenActionBar) {
      return `
             transition: opacity .4s, visibility .5s;
         `;
    }
  }}

  opacity: ${({ hiddenActionBar }) => (hiddenActionBar ? "0" : "1")};
  visibility: ${({ hiddenActionBar }) =>
    hiddenActionBar ? "hidden" : "visible"};
`;

const StyledPageHeader = styled(PageHeader)`
  transition: 0.3s;
  height: ${({ hiddenActionBar }) => (hiddenActionBar ? "0px" : "72px")};
  padding-top: ${({ hiddenActionBar }) => (hiddenActionBar ? "0px" : "16px")};
  padding-bottom: ${({ hiddenActionBar }) =>
    hiddenActionBar ? "0px" : "16px"};
  overflow: hidden;

  .ant-page-header-heading {
    flex-wrap: nowrap !important;
  }

  border-bottom: 1px solid ${border_dark_color2};

  ${({ isMenuFloat, isPhone }) => {
    if (isMenuFloat && !isPhone) {
      return `
            margin-left: 80px;
          `;
    }
  }}

  ${({ isPhone }) => {
    if (isPhone) {
      return `
            padding: 16px 4px;
              
          `;
    }
  }}
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 70px;
  max-width: 800px;
`;

export default function ActionBar({ ...props }) {
  const { titleActionBar, hasBack, hiddenActionBar } = useActionBar();
  const { user } = useUserContext();
  const { isPhone } = useSystemBreakpoint();
  const navigate = useNavigate();

  const handleBack = () => {
    if (typeof hasBack === "string") {
      navigate(hasBack);
    } else {
      navigate(-1);
    }
  };
  return (
    <Container hiddenActionBar={hiddenActionBar}>
      <StyledPageHeader
        hiddenActionBar={hiddenActionBar}
        isPhone={isPhone}
        // onBack={!isPhone && hasBack ? () => window.history.go(-1) : null}
        onBack={!isPhone && hasBack ? () => handleBack() : null}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            {isPhone && <MenuButton />}
            <StyledTitle isPhone={isPhone} level={4}>
              {titleActionBar}
            </StyledTitle>
          </div>
        }
        extra={[
          <FilterCenterActionBar key={"filter_center"} />,
          <NotificationDropdown key={"notification-dropdown"} />,
          <DropdownUser user={user} key={"user-dropdown"} />,
        ]}
        {...props}
      />
    </Container>
  );
}
