import {Badge} from "antd";
import {BellOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import NotificationOffCanvas from "./NotificationOffCanvas";
import useDefaultFetch from "../../hooks/useDefaultFetch";

export default function NotificationDropdown() {
    const [open, setOpen] = useState(false)
    const [amoutNotification, setAmoutNotification] = useState(0)
    const {fetchData} = useDefaultFetch()

    // useEffect(() => {
    //     async function fetch() {
    //         const data = await fetchData(`notification?page_size=1&page=1&read=false`)
    //
    //         setAmoutNotification(data.count)
    //     }
    //
    //     fetch()
    //     setInterval(fetch, 65000)
    //
    // }, [])

    return (
        <div style={{cursor: 'pointer'}}>
            <Badge size="small" count={amoutNotification}
                   onClick={() => {
                       setOpen(true);
                   }}
                   style={{marginRight: 24, borderColor: 'transparent'}}>
                <BellOutlined
                    style={{color: "white", fontSize: 24, marginRight: 24}}/>
            </Badge>
            {/*<NotificationOffCanvas setAmoutNotification={setAmoutNotification} open={open} setOpen={setOpen}/>*/}
        </div>
    )

}