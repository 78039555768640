import React from 'react';
import OffCanvas from "../Base/OffCanvas";
import TitleOffCanvas from "../TitleOffCanvas";
import {Tabs} from "../Base/Tabs";
import TabTelemetryDataDetailOffCanvas from "./TabTelemetryDataDetailOffCanvas";
import {Button, Empty} from "antd";
import {useNavigate} from "react-router-dom";
import {
    SYSTEM_CARETRACK_ID,
    SYSTEM_REMOTE_LOG_ID
} from "../../util/OpportunityUtils";
import Loading from "../Base/Loading";
import TabCautionCodeDataDetailOffCanvas
    from "./TabCautionCodeDataDetailOffCanvas";

export default function GenericTelemetryOffCanvas({
                                                   record,
                                                   open,
                                                   setOpen
                                               }) {



    function onClose() {
        setOpen(false)
    }
    let navigate = useNavigate();

    function getTelemetryDataBySystem(){

        const datas = {
            [SYSTEM_CARETRACK_ID]: <TabTelemetryDataDetailOffCanvas id={record?.id}/>,
            [SYSTEM_REMOTE_LOG_ID]: <TabCautionCodeDataDetailOffCanvas id={record?.id}/>,
            'default': <Empty/>
        }

        return datas[record?.telemetry_system?.id || 'default']
    }

    return (
        <OffCanvas
            destroyOnClose
            showBorderHeader={false}
            title={
                <TitleOffCanvas>Detalhes do registro</TitleOffCanvas>
            }
            onClose={onClose}
            open={open} width={'520px'}
            footer={<Button onClick={() => {
                navigate(`/rule/create`)
            }} type="primary">Criar regra derivada</Button>}>
            <Tabs
                destroyInactiveTabPane={true}
                isOffCanvasParent
                activeKey={'1'} items={[
                {
                    label: 'Detalhes',
                    key: '1',
                    children: getTelemetryDataBySystem()
                }]}/>

        </OffCanvas>
    )

}

