import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { menu_bg, primary_color } from "../../Cores";
import Percent from "./Percent";
import { maskIntNumber } from "../../util/Utils";

const Container = styled.div`
  min-width: ${({ minWidth }) => minWidth}px;
  width: 100%;

  height: 115px;
  background: ${menu_bg};
  padding: 16px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;
const StyledNumber = styled(Typography.Title)`
  color: ${primary_color} !important;
  margin: 0 !important;
`;

const Name = styled.span`
  //white-space: pre-wrap;
  //overflow: hidden;
  font-size: 12px;
  //text-overflow: ellipsis;
  //min-width: 200px;
  //max-width: 369px;
  width: 100%;
  display: inline-block;
`;

export default function StatisticCard({
  name,
  percent,
  minWidth = 220,
  number = 0,
}) {
  return (
    <Container minWidth={minWidth}>
      <Header>
        <StyledNumber level={3}>{maskIntNumber(number)}</StyledNumber>
        {percent && (
          <Percent
            value={percent}
            type={
              percent.includes("-")
                ? "negative"
                : percent === "0.00"
                ? "neutral"
                : "positive"
            }
          />
        )}
      </Header>
      <Name>{name}</Name>
    </Container>
  );
}
