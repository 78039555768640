import React from 'react';
import {dateFormat} from "../../../util/dates";
import {GenericTelemetricTable} from "./GenericTelemetricTable";


export default function TelemetricCaretrackTable({search,}) {

    const columns = [{
        title: 'N° Série/PID17',
        dataIndex: 'equipment_pin_17',
        // sorter: 'equipment__pin_17',
        key: 'equipment_pin_17',
        width: '160px',
    },

        {
        title: 'Sistema',
        dataIndex: 'telemetry_system',
        // sorter: 'equipment__pin_17',
        key: 'telemetry_system',
        width: '160px',
              render: (value, record) => (value.name),
    },
        {
            title: 'Cliente',
            dataIndex: 'client_name',
            // sorter: 'equipment__client__name',
            key: 'client_name',
            width: '185px',
            ellipsis: true,
        }, {
            title: 'Criação',
            dataIndex: 'created_at',
            // sorter: 'created_at',
            key: 'created_at',
            width: '91px',
            ellipsis: true,
            render: (value, record) => (dateFormat(value, "DD/MM/YYYY")),
        }, {
            title: 'PID',
            dataIndex: 'pid',
            // sorter: 'pid',
            key: 'pid',
            width: '50px',
            ellipsis: true,
        }, {
            title: 'Tipo PID',
            dataIndex: 'pid_type',
            // sorter: 'pid_type',
            key: 'pid_type',
            width: '75px',
            ellipsis: true,
        },
        {
            title: 'MID',
            dataIndex: 'mid',
            // sorter: 'mid',
            key: 'mid',
            width: '50px',
            ellipsis: true,
        }, {
            title: 'FMI',
            dataIndex: 'fmi',
            // sorter: 'fmi',
            key: 'fmi',
            width: '50px',
            ellipsis: true,
        },
        // {
        //     title: 'Occ.',
        //     dataIndex: 'occurrences',
        //     // sorter: 'occurrences',
        //     key: 'occurrences',
        //     width: '50px',
        //     ellipsis: true,
        // },
        {
            title: 'Descrição',
            dataIndex: 'description',
            // sorter: 'description',
            key: 'description',
            width: '485px',
            ellipsis: true,
        }
    ];


    return (
        <GenericTelemetricTable search={search} url={'telemetric_data'}
                                columns={columns}/>

    )
}