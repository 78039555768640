import React, {useEffect} from 'react';
import UpTable from "../../Base/UPTable";
import useTable from "../../../hooks/useTable";
import api from "../../../services/api";
import {
    BuildFieldErrorsDict,
    maskIntNumber, moneyMask
} from "../../../util/Utils";
import BadgeOportunityStatus from "../../Base/BadgeOportunityStatus";
import AvatarProfile from "../../AvatarProfile";
import WarrantyDateAlert from "../WarrantyDateAlert";
import AlertPMP from "../AlertPMP";
import DateAlert from "../../DateAlert";
import AlertOS from "../AlertOS";
import {Tooltip} from "antd";
import AlertPriority from "../AlertPriority";
import BadgeCondition from "../../Base/display/BadgeCondition";
import {
    SYSTEM_CARETRACK_ID,
    SYSTEM_REMOTE_LOG_ID
} from "../../../util/OpportunityUtils";
import {dateFormat} from "../../../util/dates";


export default function OpportunityServiceLeedsTable({
                                                         opportunity,
                                                         onFirstRequest,
                                                     }) {

    const fetchServiceLeeds = async ({
                                         setIsLoading,
                                         pagination,
                                         order,
                                         setTableParams,
                                         setTableData,
                                         firstRequest
                                     }) => {
        try {
            setIsLoading(true)
            const response = await api.post(`opportunity/${opportunity.id}/service_leeds`);
            setTableData(response.data)
            setTableParams((tableParams) => {
                return {
                    ...tableParams,
                    pagination: {
                        ...pagination,
                        total: response.data.count,
                    }
                }
            })
            if (firstRequest) {
                onFirstRequest && onFirstRequest(response.data)
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }


    const {
        tableData,
        isLoading,
        tableParams,
        resetTable,
        handleTableChange,
        handleRequestTable
    } = useTable({
        fetchData: fetchServiceLeeds,
        useHandleRequestTable: false
    })

    const columns = [
        {
            title: 'Service ID',
            dataIndex: 'service_leed',
            sorter: 'service_leed',
            key: 'service_leed',
            width: '150px',
            render: (value, record) => (

                value.service_id

            ),
            // fixed: 'left',
        },
        {
            title: 'Descrição Service ID',
            dataIndex: 'service_leed',
            sorter: 'service_leed__service_description',
            key: 'service_leed__service_description',
            width: '200px',
            ellipse: true,
            render: (value, record) => (

                value.service_description

            ),
        },
        {
            title: 'Número da peça',
            dataIndex: 'service_leed',
            sorter: 'service_leed__part_number',
            key: 'service_leed__part_number',
            width: '151px',
            render: (value, record) => (

                value.part_number

            ),
        },
        {
            title: 'Descrição Peça',
            dataIndex: 'service_leed',
            sorter: 'service_leed__part_description',
            key: 'service_leed__part_description',
            width: '150px',
            ellipse: true,
            render: (value, record) => (

                value.part_description

            ),
        },
        {
            title: 'Hor. p/ Manutenção',
            dataIndex: 'service_leed',
            sorter: 'service_leed__hourmeter',
            key: 'service_leed__hourmeter',
            width: '200px',
            render: (value, record) => (

                Number(value.hourmeter).toFixed()

            ),
        },
        {
            title: 'Preço u. sugerido',
            dataIndex: 'unitary_value',
            sorter: 'unitary_value',
            key: 'unitary_value',

            width: '200px',
            render: (value, record) => (

                <>R$ {moneyMask(value)}</>

            ),
        },
        {
            title: 'Qtd. de Peças',
            dataIndex: 'amount',
            sorter: 'amount',
            key: 'amount',

            width: '200px',
            render: (value, record) => (

                Number(value).toFixed()

            ),
        }, {
            title: 'Preço sugerido',
            dataIndex: 'total',
            sorter: 'total',
            key: 'total',

            width: '200px',
            render: (value, record) => (

                <>R$ {moneyMask(value)}</>

            ),
        },

    ];

    useEffect(() => {

        handleRequestTable()

        // eslint-disable-next-line
    }, [])


    return (
        <UpTable onChange={handleTableChange}
                 loading={isLoading}
                 showQuickJumper
                 noPagination
                 tableHeightDiff={56}
                 tableParams={tableParams}
                 columns={columns} dataSource={tableData}/>
    )
}