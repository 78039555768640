import React, { useEffect, useState } from "react";
import UpTable from "../../Base/UPTable";
import useTable from "../../../hooks/useTable";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import OilDetailOffCanvas from "./OilDetailOffCanvas";
import { dateFormat } from "../../../util/dates";
import OilAlertCondition from "../OilAlertCondition";
import { Tooltip } from "antd";

export default function OpportunityOilTable({
  search = "",
  noPagination = false,
  oppotunity,
  onFirstRequest,
  setSelectedAnalysis,
}) {
  const [open, setOpen] = useState();
  const [oilId, setOilId] = useState();

  const fetchOilHistory = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
    firstRequest,
  }) => {
    try {
      setIsLoading(true);
      let response;
      response = await api.post(
        `opportunity/${oppotunity.id}/oil_analisys_history`
      );

      const results = response.data.map((item) => ({
        ...item,
        key: item.id,
      }));

      setTableData(results);
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
      if (firstRequest) {
        onFirstRequest && onFirstRequest(response.data);
      }
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const { tableData, isLoading, tableParams, resetTable, handleTableChange } =
    useTable({ fetchData: fetchOilHistory, search });

  const columns = [
    {
      title: "Id. da amostra.",
      dataIndex: "id_integration",
      sorter: "id_integration",
      key: "id_integration",
      width: "198px",

      // fixed: 'left',
    },
    {
      title: "Data da amostra",
      dataIndex: "collection_date",
      // sorter: 'type_opportunity',
      key: "collection_date",
      width: "198px",
      render: (value, record) => dateFormat(value, "DD/MM/YYYY"),
    },
    {
      title: "Parte analisada",
      dataIndex: "analized_part",
      // sorter: 'type_opportunity',
      key: "analized_part",
      width: "350px",
    },
    {
      title: "Horímetro",
      dataIndex: "hourmeter_analysis",
      // sorter: 'client__name',
      key: "hourmeter_analysis",
      width: "130px",
      ellipsis: true,
    },
    {
      title: "Tipo do Óleo",
      dataIndex: "oil_type_name",
      // sorter: 'client__name',
      key: "oil_type_name",
      width: "150px",
      ellipsis: true,
    },

    {
      title: "Condição", //falta
      dataIndex: "condition_name",
      // sorter: 'equipment__pin_17',
      key: "condition_name",
      width: "130px",
      ellipsis: true,
      render: (value, record) => <OilAlertCondition value={value} />,
    },
    {
      title: "Notificação", //falta
      dataIndex: "id_opportunity",
      // sorter: 'current_hour_meter',
      key: "id_opportunity",
      width: "100px",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    handleTableChange({
      current: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
    });
    // eslint-disable-next-line
  }, [oppotunity, search]);

  const handleOpportunityDetail = (record) => {
    setOpen(true);
    setOilId(record.id);
  };

  const rowSelection = {
    renderCell: (checked, record, index, originNode) => {
      if (!record.pdf_url) {
        return (
          <Tooltip title={"Análise não possuí PDF registrado no sistema."}>
            {originNode}
          </Tooltip>
        );
      }
      return originNode;
    },
    onChange: (selectedRowKeys, selectedRows) => {
      console.log({ selectedRows, selectedRowKeys });
      setSelectedAnalysis(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.pdf_url,
    }),
  };

  return (
    <>
      <UpTable
        onChange={handleTableChange}
        loading={isLoading}
        showQuickJumper
        noPagination={noPagination}
        onRowClick={handleOpportunityDetail}
        tableParams={tableParams}
        columns={columns}
        dataSource={tableData}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />

      <OilDetailOffCanvas open={open} setOpen={setOpen} oilId={oilId} />
    </>
  );
}
