import { border_dark_color2, content_bg, text_color } from "../../Cores";
import React, { useEffect, useState } from "react";
import { Tabs as TabsAnt } from "antd";
import styled from "styled-components";
import { useActionBar } from "../../context/ActionBarContext";

const StyledTabsAnt = styled(TabsAnt)`
  position: sticky !important;

  & > div:first-child::after {
    content: "";
    position: absolute;
    height: 46px;
    top: 0;
    z-index: -1;
    left: 0;
    width: calc(100vw);
    max-width: 1440px;
    margin-left: -24px;
    display: block;
    background: ${content_bg};
  }

  color: ${text_color};

  .ant-tabs-nav {
    margin-bottom: 0;

    :before {
      border-bottom: 1px solid ${border_dark_color2};
    }
  }
`;

const tabBarStyle = {
  background: `${content_bg}`,
  position: "sticky",
  top: 0,
  zIndex: 98,
  transition: "top .25s",
};
const tabBarStyleOffCanvas = {
  background: `${content_bg}`,
  marginTop: "-32px",
  zIndex: 999,
};

export function Tabs({
  children,
  isOffCanvasParent = false,
  destroyInactiveTabPane = false,
  ...props
}) {
  const { hiddenActionBar } = useActionBar();
  const [styleTabBar, setStyleTabBar] = useState();

  useEffect(() => {
    if (!isOffCanvasParent) {
      if (hiddenActionBar) {
        setStyleTabBar(tabBarStyle);
      } else {
        setStyleTabBar({ ...tabBarStyle, top: 72 });
      }
    } else {
      setStyleTabBar(tabBarStyleOffCanvas);
    }
  }, [hiddenActionBar]);
  return (
    <StyledTabsAnt
      defaultActiveKey="1"
      destroyInactiveTabPane={destroyInactiveTabPane}
      tabBarStyle={styleTabBar}
      {...props}
    >
      {children}
    </StyledTabsAnt>
  );
}

export function TabPane({ children, ...props }) {
  return <TabsAnt.TabPane {...props}>{children}</TabsAnt.TabPane>;
}
