import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import { PermissionRoute, PrivateRoute } from "./PrivateRoute";
import LayoutDefault from "../layout/layoutDefault";
import Home from "../pages/Home/Home";
import ListUser from "../pages/User/ListUser";
import DashboardManager from "../pages/Dashboard/DashboardManager";
import ListEquipment from "../pages/Equipment/ListEquipment";
import EquipmentDetail from "../pages/Equipment/EquipmentDetail";
import ListOpportunity from "../pages/Opportunity/ListOpportunity";
import OpportunityDetail from "../pages/Opportunity/OpportunityDetail";
import ListRules from "../pages/Rules/ListRules";
import RuleCreate from "../pages/Rules/RuleCreate";
import RuleDetail from "../pages/Rules/RuleDetail";
import ListTelemetricData from "../pages/TelemetricData/ListTelemetricData";
import RuleUpdate from "../pages/Rules/RuleUpdate";
import ListClient from "../pages/Client/ListClient";
import ClientDetail from "../pages/Client/ClientDetail";
import SystemSettings from "../pages/SystemSettings/SystemSettings";

const all_permissions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const RoutesMap = () => {
  return (
    <Routes>
      <Route path="login" exact element={<Login />} />
      {/*<Route path="/404" exact element={NotFound}/>*/}
      {/*<Route path="/403" exact element={NotPermission}/>*/}
      {/*<Route path="/maintenance" exact element={Maintenance}/>*/}
      {/*<ManagerRoute  path="/" element={<>LOGADO</>}/>*/}
      <Route element={<PrivateRoute />}>
        {/*<Route index element={<Home/>}/>*/}
        <Route element={<LayoutDefault />}>
          <Route index element={<Navigate to="/dashboard/equipment?tab=1" />} />
          <Route
            path="dashboard"
            element={<PermissionRoute permissions={[1, 2, 4]} />}
          >
            <Route path="equipment" element={<DashboardManager />} />
            <Route path="client" element={<>CLIENT</>} />
            <Route path="consultant" element={<>CONSULTOR</>} />
          </Route>

          <Route path="machine" element={<>machine</>} />
          <Route
            path="client"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<ListClient />} />
            <Route path=":id/detail" element={<ClientDetail />} />
          </Route>
          <Route
            path="equipment"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<ListEquipment />} />
            <Route path=":id/detail" element={<EquipmentDetail />} />
          </Route>
          <Route
            path="opportunity"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<ListOpportunity />} />
            <Route path=":id/detail" element={<OpportunityDetail />} />
          </Route>
          <Route
            path="rule"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<ListRules />} />
            <Route
              path="create"
              element={<PermissionRoute permissions={[1, 2, 3]} />}
            >
              <Route index element={<RuleCreate />} />
            </Route>
            <Route
              path=":id/update"
              element={<PermissionRoute permissions={[1, 2, 3]} />}
            >
              <Route index element={<RuleUpdate />} />
            </Route>
            <Route path=":id/detail" element={<RuleDetail />} />
          </Route>
          <Route
            path="telemetric_data"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<ListTelemetricData />} />
            {/*<Route path=":id/detail" element={<OpportunityDetail/>}/>*/}
          </Route>
          <Route path="user" element={<PermissionRoute permissions={[1]} />}>
            <Route index element={<ListUser />} />
          </Route>
          <Route
            path="settings"
            element={<PermissionRoute permissions={all_permissions} />}
          >
            <Route index element={<SystemSettings />} />
          </Route>
          <Route path="notificacoes" element={<>notificacoes</>} />
        </Route>
      </Route>
    </Routes>
  );
};
export default RoutesMap;
