import { useEffect, useState } from "react";
import { BuildFieldErrorsDict, buildFormDataToFilter } from "../../util/Utils";
import OffCanvas from "../Base/OffCanvas";
import { Button, Form } from "antd";
import styled from "styled-components";
import Collapse from "../Collapse";
import RadioButton from "../Base/Forms/RadioButton";
import Title from "../TitleOffCanvas";
import { set_filter_params } from "../../util/TableStorageService";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;

export default function FilterClientOffCanvas({
  isOpen,
  onClose,
  initialFormFilter,
  setFormFilter,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  function initFormValues() {
    return initialFormFilter;
  }

  useEffect(() => {
    form.setFieldsValue(initFormValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onReset() {
    form.resetFields();
    form.setFieldsValue(initFormValues());
  }

  async function onSubmit() {
    setIsLoading(true);

    let values;
    try {
      values = await form.validateFields();
      let formData = buildFormDataToFilter(values);
      set_filter_params("formFlter", values);
      setFormFilter(formData);
      onClose();
    } catch (error) {
      BuildFieldErrorsDict(error, form);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <OffCanvas
      destroyOnClose
      title={<Title level={4}>Filtrar clientes</Title>}
      open={isOpen}
      onClose={onClose}
      width={"394px"}
      footer={
        <>
          <Button onClick={onReset}>Redefinir filtros</Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Filtrar
          </Button>
        </>
      }
    >
      <Form form={form} onChange={() => {}}>
        <Collapse title="CONTÉM EQUIPAMENTOS" defaultActiveKey={[0]}>
          <Container>
            <Form.Item noStyle name="has_equipments">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title="GRANDES CONTAS" defaultActiveKey={[0]}>
          <Container>
            <Form.Item noStyle name="big_accounts">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
      </Form>
    </OffCanvas>
  );
}
