import React from 'react'
import {Radio} from "antd";
import {gray_1, input_border, neutral_5, primary_color} from "../../Cores";
import styled from 'styled-components';

const StyledGroup = styled(Radio.Group)`
  label {
    border: 1px solid ${input_border} !important;
   
  } 
  label.ant-radio-button-wrapper-checked {
    border: 1px solid ${primary_color} !important;
     border-right-color: ${primary_color} !important;
  }
  .ant-radio-button-wrapper-disabled{
  //background: #1B1D1D;
    color: ${gray_1};
  }  
  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled{
  background: ${neutral_5};
    border-color: ${neutral_5}!important;
    color: ${gray_1};
  }  
  .ant-radio-button-wrapper-disabled:hover{

    color: ${gray_1};
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
   background: transparent ; 
  }
`
export default function GroupButtons({...props}) {
    return (
        <StyledGroup
            optionType="button"
            {...props}
        />
    )
}