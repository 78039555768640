import React from 'react';
import styled from "styled-components";
import {actionbar_bg} from "../../Cores";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";


const Divider = styled.div`
  background: ${actionbar_bg};
  ${({orientation}) => {
    if (orientation === 'vertical') {
      return ' height: 100%; ' +
              'width: 24px;'
    }
    return ` width: calc(100% + 48px);
              height: 24px;
                margin-left: -24px;`
  }}


  ${({isPhone}) => {
    if (isPhone) {
      return `
                 width: calc(100% + 32px);
                margin-left: -24px;
                margin-left: -16px;
          `
    }
  }}
`


export default function DividerPage({orientation}) {
    const {isPhone} = useSystemBreakpoint()
    return (
        <Divider orientation={orientation} isPhone={isPhone}/>
    )
}