import React from "react";
import styled from "styled-components";
import Loading from "../../components/Base/Loading";
import CodeErrorOpportunity from "./Specifications/CodeErrorOpportunity";
import ScheduledReviewOpportunity from "./Specifications/ScheduledReviewOpportunity";
import OilAnalysisOpportunity from "./Specifications/OilAnalysisOpportunity";
import KalmarAlertOpportunity from "./Specifications/KalmarAlertOpportunity";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabOpportunityDetail({
  summary,

  isLoadingEquipment,
  opportunity,
}) {
  const getInfoByType = () => {
    let type = opportunity.type_opportunity;

    switch (type) {
      case "Revisão programada":
        return (
          <ScheduledReviewOpportunity
            opportunity={opportunity}
            summary={summary}
          />
        );

      case "Código de erro":
        return <CodeErrorOpportunity opportunity={opportunity} />;
      case "Análise de óleo":
        return <OilAnalysisOpportunity opportunity={opportunity} />;
      case "Alerta":
        if (opportunity.telemetry_system.id === 5) {
          return <KalmarAlertOpportunity opportunity={opportunity} />;
        }
        return <>Tipo de ocorrência não implementado</>;
      default:
        return <>Tipo de ocorrência não implementado</>;
    }
  };

  return (
    <TabContainer>
      {isLoadingEquipment ? <Loading /> : getInfoByType()}
    </TabContainer>
  );
}
