import React from 'react';
import {Col, Form, Row} from "antd";
import PageTitle from "../../Base/PageTitle";
import {InfoCircleOutlined} from "@ant-design/icons";
import Select from "../../Base/Select";
import InputNumber from "../../Base/Forms/InputNumber";
import {formaterThousandSeparator} from "../../../util/Utils";
import styled from "styled-components";
import {collapse_header_text_color} from "../../../Cores";

const ContainerInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${collapse_header_text_color};
  padding-bottom: 16px;
`
export default function FormInfo({label}) {
    return (
        <ContainerInfo>
            <InfoCircleOutlined/>
            <span>{label}</span>
        </ContainerInfo>
    )
}