import React from 'react'
import {ArrowRightOutlined} from "@ant-design/icons";
import {NavLink, useNavigate} from "react-router-dom";
import styled from 'styled-components'
import {primary_color} from "../../Cores";

const Container = styled.div`
      display: flex;
  align-items: center;
  gap: 8px;
  & > *{
    flex-shrink: 0;
    flex-wrap: nowrap;
  }
  &:before{
    content: '';
    display: inline-block;
    width: 19px;
    height: 19px;
    background: ${({color}) => color};

  }
 
`
export default function Legend({value, color, ...props}) {

    return (
     <Container color={color}>
         {value}
     </Container>
    )
}