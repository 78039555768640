import React from 'react';
import styled from "styled-components";
import {
    actionbar_bg,
    border_dark_color, danger_color_bg, danger_color_icon,
    success_bg,
    success_color,
    success_color2
} from "../../Cores";
import {ArrowUpOutlined, ArrowDownOutlined} from "@ant-design/icons";

const Container = styled.span`
  padding: 6px 8px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: 22px;
  color: ${({color}) => color}; 
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 1px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;

  
 
`

const colors = [
    {
        type: 'positive',
        borderColor: success_color2,
        color: success_color,
        backgroundColor: success_bg
    },
    {
        type: 'negative',
            borderColor: 'red' ,
        color: danger_color_icon,
        backgroundColor: danger_color_bg
    },
    {
        type: 'neutral',
        borderColor: border_dark_color ,
        color: 'white',
        backgroundColor: actionbar_bg
    },
]
const StyledArrowUpOutlined = styled(ArrowUpOutlined)`
  font-size: 10px;
`
const StyledArrowDownOutlined = styled(ArrowDownOutlined)`
  font-size: 10px;
`
const Value = styled.span`
  font-size: 12px;
`
export default function Percent({value, type='positive'}){
    return (
        <Container {...colors.find((color) => type === color.type)}>
            {type==='positive' && <StyledArrowUpOutlined/>}
            {type==='negative' && <StyledArrowDownOutlined/>}
            <Value>
                   {value}%
            </Value>

        </Container>
    )
}