import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Button,
} from "antd";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import OffCanvas from "../Base/OffCanvas";
import {Notification} from "../../notification";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";


import ProfileDisplay from "./ProfileDisplay";
import ProfileForm from "./ProfileForm";
import ProfileImage from "./ProfileImage";
import {useUserContext} from "../../context/UserContext";


export default function MyProfileOffCanvas({user, open, setOpen, noEditable}) {

    const [form] = Form.useForm();
    const {setUser} = useUserContext();
    const [editMode, setEditMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasChange, setHasChange] = useState(false)
    const [fileList, setFileList] = useState([])


    useEffect(() => {
        if (editMode) {
            form.setFieldsValue(user)
        }
        // eslint-disable-next-line
    }, [user, editMode])

    function onClose() {
        if (hasChange) {
            Modal.confirm({

                title: 'Deseja realmente sair?',
                content: 'Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.',
                icon: <ExclamationCircleOutlined/>,
                // content: 'Deseja realmente fechar?',
                okText: 'Sim',
                cancelText: 'Não',
                onOk: () => {
                    closeDrawer()
                    form.resetFields()
                }
            });
        } else {
            closeDrawer()
        }
    }

    function closeDrawer() {

        setOpen(false)
        setHasChange(false)
        setEditMode(false)
    }

    const onSubmit = async () => {
        setIsLoading(true)
        let formData = new FormData()
        let values;
        try {
            values = await form.validateFields()
            delete values['profile_img']
            fileList.forEach((file) => {
                formData.append('files[]', file)
            });
            const indexFirstSpace = values.get_full_name.indexOf(' ')//para nomes acima de 2 palavras
            formData.append('first_name', values.get_full_name.split(' ')[0])
            formData.append('last_name', values.get_full_name.slice(indexFirstSpace))

            delete values['get_full_name']
            Object.keys(values).forEach((key) => {
                const value = values[key]
                if (value) {

                    if (value.constructor.name === 'Array') {

                        value.forEach((value) => {
                            formData.append(key, value)
                        })
                    } else {

                        formData.append(key, value)
                    }
                }
            });
        } catch (error) {
            setIsLoading(false)
            return;
        }
        try {

            const response = await api.patch(`user/my_user`, formData);
            setUser(response.data)
            Notification('success', 'Perfil atualizado com sucesso.')
            closeDrawer()
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }

    }


    return (
        <OffCanvas
            destroyOnClose
            title={
                <ProfileImage
                    id={user.id}
                    editMode={editMode}
                    user={user}
                    setUser={setUser}
                    name={user.get_full_name}/>

            }
            onClose={onClose}
            open={open} width={'520px'} footer={
            <>
                {!noEditable && (
                    editMode ? (
                        <>
                            <Button
                                onClick={() => setEditMode(false)}>Voltar</Button>
                            <Button onClick={onSubmit} type="primary"
                                    loading={isLoading}>Salvar</Button>
                        </>
                    ) : (<Button onClick={() => setEditMode(true)}
                                 type="primary">Editar perfil</Button>))
                }
            </>}>
            {editMode ? (
                <ProfileForm user={user} form={form}
                             setFileList={setFileList}
                             onValuesChange={() => setHasChange(true)}/>
            ) : (
                <ProfileDisplay user={user}/>
            )}

        </OffCanvas>
    )

}