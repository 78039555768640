import React, { useState } from "react";
import { Col, Row } from "antd";
import { TableSearch } from "../../components/Base/UPTable";
import { get_filter_params } from "../../util/TableStorageService";
import FilterButtons from "../../components/Base/FilterButtons";
import TelemetricTigercatTable from "./tables/TelemetricTigercatTable";
import TelemetricCaretrackTable from "./tables/TelemetricCaretrackTable";
import { GenericTelemetricTable } from "./tables/GenericTelemetricTable";
import TelemetricKalmarTable from "./tables/TelemetricKalmarTable";

export const CARETRACK_TYPE = "Caretrack";
export const TIGERCAT_TYPE = "Remotelog";
export const KALMAR_TYPE = "Insight";

const filterOptions = [
  // {
  //     label: 'Todos',
  //     value: 'all'
  // },
  {
    label: "Caretrack",
    value: CARETRACK_TYPE,
  },
  {
    label: "Remotelog",
    value: TIGERCAT_TYPE,
  },
  {
    label: "Insight",
    value: KALMAR_TYPE,
  },
];

export default function ListTelemetricData() {
  const [search, setSearch] = useState(get_filter_params("search"));

  const [typeTable, setTypeTable] = useState(
    get_filter_params("typeTable", filterOptions[0].value)
  );

  const tables = {
    [CARETRACK_TYPE]: (
      <TelemetricCaretrackTable search={search} typeTable={typeTable} />
    ),
    [TIGERCAT_TYPE]: (
      <TelemetricTigercatTable search={search} typeTable={typeTable} />
    ),
    [KALMAR_TYPE]: (
      <TelemetricKalmarTable search={search} typeTable={typeTable} />
    ),
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <TableSearch
            size={"large"}
            allowClear
            defaultValue={search}
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquise por n° de série ou cliente"}
          />
        </Col>
      </Row>
      <FilterButtons
        onChange={({ target }) => setTypeTable(target.value)}
        style={{ marginTop: 16 }}
        value={typeTable}
        options={filterOptions}
      />

      {tables[typeTable]}
    </>
  );
}
