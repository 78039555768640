import {useState} from "react";
export default  function useForm(){
    const [validateFields, setValidateFields] = useState({})

    function onChange({currentTarget:{name, value}}, setData){
        setData && setData((data) => ({
                ...data,
                [name]: value
            })
        )
    }
    function onChangeDate(name, value, setData){
        setData && setData((data) => ({
                ...data,
                [name]: value
            })
        )
    }

    function onChangeRadio({target:{name, value}}, setData){
        setData && setData((data) => ({
                ...data,
                [name]: value
            })
        )
    }

    function onChangeCheckedSingle({target:{name, value}}, setData){
        setData && setData((data) => ({
                ...data,
                [name]: value
            })
        )
    }

    function onChangeCheckedMult(name, values, setData){

        setData && setData((data) => ({
                ...data,
                [name]: values
            })
        )
    }

    function onValidate(name, value, setError){

        setError((data) => ({
                ...data,
                [name]: value
            })
        )
    }



    return {onChange, onValidate,  onChangeDate, onChangeCheckedMult,onChangeCheckedSingle,onChangeRadio,validateFields, setValidateFields}
}