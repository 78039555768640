import React from "react";
import { primary_color } from "../Cores";
import styled from "styled-components";

const PMPObservationContainer = styled.div`
  color: ${primary_color};
`;

const PMPObservation = ({ text = "Sim" }) => {
  return <PMPObservationContainer>{text}</PMPObservationContainer>;
};

export default PMPObservation;
