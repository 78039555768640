import React, {useCallback, useContext, useEffect} from 'react';
import {UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";

function useConfirmExit(confirmExit, when = true) {
    const {navigator} = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;
        const go = navigator.go;

        navigator.push = async (...args) => {
            const result = await confirmExit()
            if (result !== false) {
                push(...args);
            }
        };

        navigator.go = async (...args) => {
            const result = await confirmExit()
            if (result !== false) {
                go(...args);
            }
        };

        return () => {
            navigator.push = push;
            navigator.go = go;
        };
    }, [navigator, confirmExit, when]);


}

export function usePrompt(message, when = true) {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);

    async function confirDialog() {
        let myPromise = new Promise(function (resolve, reject) {
            Modal.confirm({
                title: message,
                content: '',
                icon: <ExclamationCircleOutlined/>,
                okText: 'Sim',
                cancelText: 'Não',
                onOk: () => {
                    resolve(true);
                }, onCancel: () => {
                    resolve(false);
                }
            });

        });
        return await myPromise
    }

    const confirmExit = useCallback(async () => {
        return await confirDialog()
    }, [message]);

    useConfirmExit(confirmExit, when);
}
