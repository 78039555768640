import React from 'react';
import {Col, Row} from "antd";
import PageTitle from "../../../components/Base/PageTitle";
import DisplayInfo from "../../../components/Base/DisplayInfo";


export default function RuleContainerInfo({opportunity}) {

    return (
        <Col xs={24} md={24}>
            <PageTitle value={'Especificação da regra'}
                       style={{marginTop: 15}}/>
            <Row gutter={[16, 16]}>
                <Col xs={8} md={4}>
                    <DisplayInfo label={'TIPO DA REGRA'}>
                        {opportunity.type_opportunity}
                    </DisplayInfo>
                </Col>
                <Col xs={12} md={10}>
                    <DisplayInfo label={'CÓD / DESCRIÇÃO'}>
                        {`${opportunity.rule}. ${opportunity.rule_name}`}
                    </DisplayInfo>
                </Col>
                <Col xs={12} md={10}>
                    <DisplayInfo label={'COMENTÁRIO TÉCNICO'}>
                        {opportunity.rule_tech_comment}
                    </DisplayInfo>
                </Col>
            </Row>
        </Col>)
}
