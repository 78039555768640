import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row, Form, Button, Checkbox } from "antd";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import useActionPermission from "../../../hooks/useActionPermission";
import { Notification } from "../../../notification";
import Select from "../../../components/Base/Select";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabClientSettings({ client, setClient, ...props }) {
  const { patchData, isLoading } = useDefaultFetch();
  const { hasPermission } = useActionPermission({ permissions: [1, 2, 4] });
  const [ruleType, setRuleType] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const [isBigAccount, setIsBigAccount] = useState(client.is_big_account);

  async function handleSubmit() {
    const data = await patchData(`client/${client.id}`, {
      generates_opportunity_types: ruleType,
      is_big_account: isBigAccount,
    });
    if (data) {
      setClient((client) => ({
        ...client,
        generates_opportunity_types: data.generates_opportunity_types,
        is_big_account: data.is_big_account,
      }));
      setHasChange(false);
      Notification("success", "Cliente atualizado com sucesso.");
    }
  }

  function handleDisabledButton() {
    return !hasPermission || !hasChange;
  }

  useEffect(() => {
    console.log({ client });
    setRuleType(client.generates_opportunity_types);
    setIsBigAccount(client.is_big_account);
  }, [client]);

  if (!client.id) return null;

  console.log({
    ruleType,
    isBigAccount,
  });

  return (
    <TabContainer>
      <Row>
        <Col xs={24} md={12}>
          <Form layout={"vertical"}>
            <Form.Item
              name="generates_opportunity_types"
              label={"Habilitar geração de notificações do tipo"}
              initialValue={client.generates_opportunity_types}
            >
              <Select
                url={"rule_type?page_size=10000"}
                mode="multiple"
                disabled={!hasPermission}
                onChange={(value) => {
                  setRuleType(value);
                  setHasChange(true);
                }}
                placeholder="Selecione os tipos das regras"
              />
            </Form.Item>
            <Form.Item
              name="is_big_account"
              initialValue={client.is_big_account}
            >
              <Checkbox
                onChange={() => {
                  setIsBigAccount(!isBigAccount);
                  setHasChange(true);
                }}
                checked={isBigAccount}
              >
                É Grandes Contas?
              </Checkbox>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24}>
          <Button
            disabled={handleDisabledButton()}
            loading={isLoading}
            onClick={handleSubmit}
            type="primary"
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </TabContainer>
  );
}
