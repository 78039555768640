import React from 'react'
import Avatar from "./Base/Avatar";
import styled from "styled-components";
import {border_gray, collapse_header_text_color} from "../Cores";
import {dateFormat} from "../util/dates";

const Container = styled.div`
  display: flex;
  gap: 8px;
`
const Content = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`
const Name = styled.div`
  font-size: 14px;
`
const Dates = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
  &::after{
    content: '';
    display: block;
    background: ${collapse_header_text_color};
    width: 2px;
    height: 2px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    
  }
`
const Date = styled.div`
  font-size: 12px;
  color: ${collapse_header_text_color};
`

export const ListResponsibleCard = styled.div`
  display: flex;
  flex-direction: column;
  
  
  & > *{
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${border_gray};
  }
  & > *:first-child{
    padding-top: 0;
  }
  & > *:last-child{
    padding-top: 16px;
    //padding-bottom: 16px;
    border-bottom: unset;
  }
`

export default function ResponsibleCard({responsible, dateStart, dateEnd}) {
    return (
        <Container>
            <Avatar size={24}
                    id={responsible.id}
                    name={responsible.get_full_name}
                    src={responsible.picture}/>
            <Content>
                <Name>{responsible.get_full_name}</Name>
                <Dates>
                    <Date>{dateFormat(dateStart)}</Date>
                    <Date>{dateEnd ? dateFormat(dateEnd) : 'Atualmente'}</Date>
                </Dates>
            </Content>
        </Container>
    )
}