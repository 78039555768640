import {Navigate, Outlet} from "react-router-dom";
import {isAuthenticated} from "../util/Auth";
import {useUserContext} from "../context/UserContext";

export const PrivateRoute = (props) => {
    return isAuthenticated() ? <Outlet/> : <Navigate to="/login"/>
}

export const PermissionRoute = ({permissions}) => {
    //1 - Admin
    //2 - Gestor
    const {user} = useUserContext()

    if (user.profiles.some(
        (profile) => permissions.includes(profile)
    )) {
        return <Outlet/>
    }
    return <Navigate to="/"/>
}