import React, {useEffect, useState} from 'react';
import {useActionBar} from "../../context/ActionBarContext";

import CreateUserOffCanvas from "../../components/User/CreateUserOffCanvas";
import styled from 'styled-components';
import {Button, Col, Row} from "antd";
import {

    UserAddOutlined
} from '@ant-design/icons';
import Badge from "../../components/Base/Badge";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";
import UpTable, {
    TableLabel,
    TableSearch,
} from "../../components/Base/UPTable";
import useTable from "../../hooks/useTable";
import Avatar from "../../components/Base/Avatar";
import UserProfileOffCanvas from "../../components/User/UserProfileOffCanvas";
import UserEditOffCanvas from "../../components/User/UserEditOffCanvas";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import Switch from "../../components/Base/Switch";
import {
    get_filter_params,
    set_filter_params
} from "../../util/TableStorageService";


const Container = styled.div`

`

const StyleProfile = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;


  & > * {
    flex-shrink: 0;
  }


`
const Fullname = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 290px;
`


export default function ListUser() {
    const {changeTitle} = useActionBar()
    const {isDesktop} = useSystemBreakpoint()
    const [openEditUser, setOpenEditUser] = useState(false)
    const [openCreateUser, setOpenCreateUser] = useState(false)
    const [openUserProfile, setOpenUserProfile] = useState(false)
    const [user, setUser] = useState()
    const [userEdit, setUserEdit] = useState()

    const [search, setSearch] = useState(get_filter_params('search'))
    const [isActive, setIsActive] = useState(get_filter_params('isActive',true))


    const columns = [
        {
            title: 'Nome completo',
            // dataIndex: 'first_name',
            sorter: 'first_name',
            key: 'first_name',
            width: '300px',

            render: (text, record) => <StyleProfile>
                <Avatar
                    id={record.id}
                    name={`${record.first_name} ${record.last_name}`}
                    src={record.picture}
                /><Fullname>{record.first_name} {record.last_name}</Fullname></StyleProfile>,
        },
        {
            title: 'E-mail',
            dataIndex: 'username',
            key: 'username',
            sorter: 'username',
            width: '250px',
            ellipsis: true,
        },

        {
            title: 'Status',
            key: 'is_active',
            dataIndex: 'is_active',
            width: '150px',
            sorter: 'is_active',
            render: (_, {is_active}) => (

                <Badge type={is_active ? 'success' : 'inactive'}>
                    {is_active ? 'Ativo' : 'Inativo'}
                </Badge>

            ),
        },
        // {
        //     title: 'Ação',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //
        //             <TableEditIcon onClick={(event) => {
        //                 event.stopPropagation()
        //                 handleEditUser(record)
        //             }}/>
        //         </Space>
        //     ),
        //     fixed: 'right',
        //     width: '100px'
        // },
    ];

    function handleEditUser(record) {

        setUserEdit(record)
        setOpenEditUser(true)
    }

    const fetchUsers = async ({
                                  setIsLoading,
                                  pagination,
                                  order,
                                  setTableParams,
                                  setTableData
                              }) => {
        try {
            setIsLoading(true)

            const response = await api.get(`user?page_size=${pagination.pageSize}&page=${pagination.current}&search=${search}&is_active=${isActive}&ordering=${order ? order : ''}`);
            setTableData(response.data.results)

            setTableParams((tableParams) => {

                return {
                    ...tableParams,
                    pagination: {
                        ...pagination,
                        total: response.data.count,

                    }
                }
            })
            set_filter_params('isActive', isActive)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    const {
        tableData,
        isLoading,
        tableParams,
        resetTable,
        handleTableChange,
        handleRequestTable
    } = useTable({fetchData: fetchUsers, search, useHandleRequestTable: false})

    useEffect(() => {
        changeTitle('Consultar usuários')
    }, [changeTitle])


    useEffect(() => {

        handleRequestTable()
// eslint-disable-next-line
    }, [search, isActive])

    //
    // const handleOpenUserProfile = async (record) => {
    //     setUser(record)
    //     setOpenUserProfile(true)
    // }


    return (
        <Container>
            <Row gutter={[24, 16]} align={'middle'}>
                <Col xs={{span: 24, order: 2}} md={{span: 24, order: 1}}
                     lg={{span: 10, order: 1}}>
                    <TableSearch size={'large'}
                                 allowClear
                                 defaultValue={search}
                                 onSearch={(value, e) => setSearch(value)}
                                 onPressEnter={(e) => setSearch(e.target.value)}
                                 placeholder={'Pesquise por nome, e-mail, perfil de acesso ou centro'}/>

                </Col>
                <Col xs={{span: 24, order: 3}} md={{span: 12, order: 2}}
                     lg={{span: 8, order: 2}}>
                    <Row gutter={4}
                         wrap={false}
                    >

                        <Col><Switch checked={isActive}
                                     onChange={(checked) => setIsActive(checked)}/></Col>
                        <Col> <TableLabel>Exibir apenas usuários
                            ativos</TableLabel></Col>
                    </Row>
                </Col>
                <Col style={{textAlign: 'end'}}
                     xs={{span: 24, order: 1}} md={{span: 12, order: 3}}
                     lg={{span: 6, order: 3}}>
                    <Button onClick={() => setOpenCreateUser(true)}
                            type="primary" block={!isDesktop}>
                        <UserAddOutlined/>
                        Cadastrar usuário
                    </Button>
                </Col>
            </Row>


            <UpTable onChange={handleTableChange}
                // onRowClick={handleOpenUserProfile}
                     onRowClick={handleEditUser}

                     loading={isLoading}
                     tableParams={tableParams}
                     columns={columns} dataSource={tableData}/>

            <UserProfileOffCanvas user={user} open={openUserProfile}
                                  setOpen={setOpenUserProfile}/>
            <UserEditOffCanvas user={userEdit} open={openEditUser}
                               resetTable={resetTable}
                               setOpen={setOpenEditUser}/>
            <CreateUserOffCanvas open={openCreateUser}
                                 resetTable={resetTable}
                                 setOpen={setOpenCreateUser}/>

        </Container>
    )
}