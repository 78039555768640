import React from 'react'
import styled from "styled-components";
import {collapse_header_text_color, primary_color} from "../../Cores";
import {dateFormat} from "../../util/dates";
import {InfoCircleOutlined} from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > .ant-avatar.ant-avatar-circle {
    flex-shrink: 0;
  }
`

const Name = styled.div`
  font-size: 14px;
`

const Date = styled.div`
  font-size: 12px;
  color: ${collapse_header_text_color};
`

export default function OcurrenceCard({event}) {

    return (
        <Container>
            <InfoCircleOutlined size={14} style={{color: primary_color}}/>
            <Name>{event.description} <Date>{dateFormat(event.date,"DD/MM/YYYY HH:mm")}</Date></Name>
        </Container>
    )
}