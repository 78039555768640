import React, { createContext, useContext, useEffect, useState } from "react";
import useDefaultFetch from "../hooks/useDefaultFetch";
import { useUserContext } from "./UserContext";

const ParametersContext = createContext();

export function ParametersProvider({ children }) {
  const [params, setParams] = useState({});
  const { fetchData } = useDefaultFetch();
  const { user } = useUserContext();

  const updateParameters = async () => {
    try {
      const systemParametersData = await fetchData(`parameters`);
      if (systemParametersData) {
        const systemParamsObject = systemParametersData.reduce(
          (acc, item) => ({ ...acc, [item.slug]: item.value }),
          {}
        );
        setParams(systemParamsObject);
      }
    } catch (error) {
      console.error("Error fetching parameters", error);
    }
  };

  useEffect(() => {
    if (user) {
      updateParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <ParametersContext.Provider value={{ params, updateParameters }}>
      {children}
    </ParametersContext.Provider>
  );
}

export const useParameters = () => useContext(ParametersContext);
