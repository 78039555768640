import React, { useState } from "react";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import DisplayInfo from "../../components/Base/DisplayInfo";
import PageTitle from "../../components/Base/PageTitle";
import DividerPage from "../../components/Base/DividerPage";
import { content_bg } from "../../Cores";
import Pagealert from "../../components/Base/Pagealert";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Collapse from "../../components/Collapse";
import AttendanceListOffCanvas from "./AttendanceListOffCanvas";
import OsOpportunityTable from "./OsOpportunityTable";
import CreateOsModal from "./modals/CreateOsModal";
import FormInfo from "../../components/Rules/RuleForm/FormInfo";
import { dateFormat } from "../../util/dates";
import CreatePartCotacaoModal from "../../components/PartQuotation/CreatePartCotacaoModal";
import PartsBudgetTable from "../../components/PartBudgetsTable";
import CreateOsQuotationModal from "../../components/OsCreation/CreateOsModal";

const Container = styled.div`
  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({ noHorizontalPadding }) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`;
    }
  }}
`;

export default function TabDocumentOpportunity({
  opportunity,
  setOpportunity,
  equipment,
  client,
  isLoadingEquipment,
}) {
  const [openAttendanceList, setOpenAttendanceList] = useState(false);
  const [openCreateOs, setOpenCreateOs] = useState(false);
  const [openCreateCotacaoOs, setOpenCreateCotacaoOs] = useState(false);
  const [openCreatePartCotacao, setOpenCreatePartCotacao] = useState(false);
  const [partsQuotations, setPartsQuotations] = useState(
    opportunity.parts_quotation
  );

  function getCurrentButton() {
    return opportunity.PMP ? (
      <Button
        onClick={() => setOpenCreateOs(true)}
        type="primary"
        style={{ marginLeft: "16px" }}
      >
        <PlusOutlined />
        Criar nova OS
      </Button>
    ) : (
      <Button
        onClick={() => setOpenCreateCotacaoOs(true)}
        type="primary"
        style={{ marginLeft: "16px" }}
      >
        <PlusOutlined />
        Criar nova cotação
      </Button>
    );
  }

  const setCurrentBudget = (budget) => {
    setOpportunity((opportunity) => ({
      ...opportunity,
      budget,
    }));
  };

  return (
    <>
      <Row gutter={[16, 0]} style={{ padding: "16px 0" }}>
        <Col xs={24}>
          {!opportunity.service_order && (
            <Pagealert
              icon={<InfoCircleOutlined />}
              message={`Notificações sem OS associada. Por favor, associe uma OS para essa notificação.`}
            />
          )}
        </Col>
        <Col xs={24}>
          <Collapse
            title={"Lista de ordens de serviço do equipamento"}
            defaultActiveKey={["1"]}
          >
            <Container>
              {opportunity.id && (
                <OsOpportunityTable
                  opportunity={opportunity}
                  setOpportunity={setOpportunity}
                  equipment={equipment}
                />
              )}
            </Container>
          </Collapse>
        </Col>
        <Col xs={10}>
          {!opportunity.service_order && (
            <FormInfo label={"Selecione uma OS para associa-la."} />
          )}
        </Col>
        <Col xs={14} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => setOpenCreatePartCotacao(true)}>
            <PlusOutlined />
            Criar cotação de peças
          </Button>
          {!opportunity.budget &&
            !opportunity.service_order &&
            getCurrentButton()}
        </Col>
      </Row>
      <DividerPage />
      {opportunity.service_order ? (
        <Row gutter={[24, 0]} align={"stretch"}>
          <Col xs={24}>
            <Container noHorizontalPadding>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <PageTitle value={"OS associada"} />
                </Col>
                <Col xs={24}>
                  <PageTitle
                    style={{ marginBottom: 0 }}
                    value={"Visão geral"}
                  />
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"Código (SAP)"}>
                    {opportunity.service_order}
                  </DisplayInfo>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      ) : (
        opportunity.budget && (
          <Row gutter={[24, 0]} align={"stretch"}>
            <Col xs={24}>
              <Container noHorizontalPadding>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <PageTitle value={"Cotação atual"} />
                  </Col>

                  <Col xs={12} lg={8}>
                    <DisplayInfo label={"CÓDIGO (SAP)"}>
                      {opportunity.budget.id_sap}
                    </DisplayInfo>
                  </Col>
                  <Col xs={12} lg={8}>
                    <DisplayInfo label={"TIPO DE COTAÇÃO"}>
                      {opportunity.budget.type}
                    </DisplayInfo>
                  </Col>
                  <Col xs={12} lg={8}>
                    <DisplayInfo label={"DATA DE ABERTURA"}>
                      {dateFormat(opportunity.budget.created_at)}
                    </DisplayInfo>
                  </Col>
                  <Col xs={12} lg={8}>
                    <DisplayInfo label={"DESCRIÇÃO DA FALHA/ATIVIDADES"}>
                      {opportunity.budget.description}
                    </DisplayInfo>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        )
      )}
      {!!partsQuotations.length && (
        <Row gutter={[24, 0]} align={"stretch"}>
          <Col xs={24}>
            <Container noHorizontalPadding>
              <PageTitle value={"Cotações de peças"} />
              <PartsBudgetTable parts_quotation={partsQuotations} />
            </Container>
          </Col>
        </Row>
      )}

      <CreateOsModal
        client={client}
        setOpportunity={setOpportunity}
        setClose={setOpenCreateOs}
        isModalOpen={openCreateOs}
        opportunity={opportunity}
        equipment={equipment}
      />
      <CreateOsQuotationModal
        isOpen={openCreateCotacaoOs}
        setIsOpen={setOpenCreateCotacaoOs}
        startingCenter={opportunity.center}
        startingBrand={equipment.brand_equipment}
        client={equipment.client}
        equipment_id={equipment.id}
        opportunity_id={opportunity.id}
        source={"opportunity"}
        setCurrentBudget={setCurrentBudget}
      />
      <AttendanceListOffCanvas
        open={openAttendanceList}
        setOpen={setOpenAttendanceList}
      />
      <CreatePartCotacaoModal
        isOpen={openCreatePartCotacao}
        setIsOpen={setOpenCreatePartCotacao}
        startingCenter={opportunity.center}
        startingBrand={equipment.brand_equipment.id_sap}
        client_id={client.id}
        equipment_id={equipment.id}
        opportunity_id={opportunity.id}
        setPartsQuotations={setPartsQuotations}
      />
    </>
  );
}
