import React, { useRef, useState } from "react";
import Avatar from "./Base/Avatar";
import { Button, Col, Comment as CommentAnt, Modal, Row, Tooltip } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { collapse_header_text_color, gray_dark } from "../Cores";
import api from "../services/api";
import { BuildFieldErrorsDict } from "../util/Utils";
import File from "./File";
import { Notification } from "../notification";
import { useUserContext } from "../context/UserContext";

const Time = styled.span`
  color: ${gray_dark};
`;
const Container = styled.div``;
const Body = styled.span`
  font-size: 14px;
`;
const StyledCommentAnt = styled(CommentAnt)`
  & .ant-comment-actions {
    margin-top: 0;
  }
`;
const Name = styled.span`
  color: ${collapse_header_text_color};

  &:hover {
    color: ${collapse_header_text_color};
  }
`;
export default function Comment({ comment }) {
  const [isLoading, setIsLoading] = useState();
  const questionRef = useRef();
  const { user } = useUserContext();
  const isCommentOwner = user.id === comment.owner.id;

  function handlerDeletion() {
    Modal.confirm({
      title: "Deseja deletar o comentário e todos os arquivos anexados a ele?",
      // content: 'Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.',
      icon: <ExclamationCircleOutlined />,
      // content: 'Deseja realmente fechar?',
      okText: "Sim",
      cancelText: "Não",
      onOk: async () => {
        try {
          setIsLoading(true);
          await api.delete(`question/${comment.id}`);
          Notification("success", "Comentário excluído com sucesso.");
          questionRef.current.remove();
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        } finally {
          setIsLoading(false);
        }
      },
    });
  }

  return (
    <Container ref={questionRef}>
      <StyledCommentAnt
        author={<Name>{comment.owner.get_full_name}</Name>}
        avatar={
          <Avatar
            src={comment.url}
            name={comment.owner.get_full_name}
            id={comment.owner.id}
            alt={comment.owner.get_full_name}
          />
        }
        content={<Body>{comment.title}</Body>}
        datetime={
          <Tooltip title={comment.get_time}>
            <Time>{comment.get_time}</Time>
          </Tooltip>
        }
        actions={[
          isCommentOwner && (
            <Button
              danger
              type={"link"}
              loading={isLoading}
              onClick={handlerDeletion}
              style={{ padding: 0 }}
            >
              Deletar
            </Button>
          ),
        ]}
      />
      <Row style={{ marginLeft: 37 }} gutter={[16, 8]}>
        {comment.files.map((file) => (
          <Col lg={8} xs={24}>
            <File file={file} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
