import React, {useEffect, useState} from 'react';

import styled from "styled-components";
import {Chart} from "react-google-charts";
import {content_bg, input_border} from "../../../Cores";
import PageTitle from "../PageTitle";
import {BuildFieldErrorsDict, formdataToJSON} from "../../../util/Utils";
import api from "../../../services/api";
import Loading from "../Loading";
import Select from "../Select";
import Empty from "../Empty";
import {NumberFormatInteger} from "./utils";
import {Col, Form, Row} from "antd";
import HeaderCharts from "./HeaderCharts";

const StyledEmpty = styled(Empty)`

  padding: 25px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  //align-items: center;
`
const Container = styled.div`

  height: 100%;
  width: 100%;
  background: ${content_bg};
`
const StyledChart = styled(Chart)`
  color: white;

  //
  // rect:first-child {
  //
    //   //fill: ${content_bg};
  //  
  // }
  text {
    fill: white
  }

  .google-visualization-tooltip text {
    fill: black;
  }

  //background: 
  // red !important;
`

const options = {
    // title: "My Daily Activities",
    backgroundColor: content_bg,
    legend: {
        textStyle: {
            color: input_border,
            fontSize: 14
        }
    },
    sliceVisibilityThreshold: 0,
    // color:'red',
    colors: ['#BAE637', '#9254DE', '#FDBA12', '#FF7A45', '#36CFC9', '#EB2F96', '#F5222D'],
    pieHole: 0.4,
    is3D: false,
};

export default function Donut({
                                  title,
                                  hasSelect,
                                  optionsSelect,
                                  defaultValue,
                                  selectName, body,
    information='',
      filter,
                                  url,
                                  filterRender,
                                  ...props
                              }) {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData()
    }, [body, filter])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const response = await api.post(`${url}`,
                {
                    ...body,
                    ...formdataToJSON(filter),
                    ...form.getFieldsValue()
                }
            );
            const amount_equipments_type = response.data.map((item) => {

                return [item.label, item.amount]
            })
            setIsEmpty(amount_equipments_type.length === 0)

            setData([['', ''], ...amount_equipments_type])

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <Row>
            <Col xs={24}>
                <HeaderCharts title={title} form={form}
                              optionsSelect={optionsSelect}
                              selectName={selectName}
                               information={information}
                              defaultValue={defaultValue}
                              filterRender={filterRender}
                              onValuesChange={fetchData}/>

            </Col>
            <Col xs={24}>
                {isLoading ? <Loading noHeight/> :
                    isEmpty ? <StyledEmpty/> :
                        <StyledChart
                            formatters={[NumberFormatInteger]}
                            {...props}

                            options={options}
                            width="100%"
                            chartType="PieChart"
                            data={data}
                        />
                }
            </Col>
        </Row>

    )
}