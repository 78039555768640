import React from 'react'
import styled from 'styled-components';
import {Button} from "antd";
import {content_bg} from "../Cores";

const ButtonContainer = styled(Button)`
  background-color: ${content_bg};
  border: 1px solid #565D62;
  padding: 6px 8px;
  border-radius: 4px;
  
`

export default function IconButton({icon, ...props}){
    return (
        <ButtonContainer {...props}>{icon}</ButtonContainer>
    )
}