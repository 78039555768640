import React, {useState} from "react";
import styled from "styled-components";
import {Button, Form, Modal, Typography} from "antd";
import Avatar from "../Base/Avatar";
import {neutral_1, neutral_5} from "../../Cores";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import ProfileUpload from "../Base/Forms/ProfileUpload";
import api from "../../services/api";
import {Notification} from "../../notification";
import {BuildFieldErrorsDict} from "../../util/Utils";
import PageTitle from "../Base/PageTitle";

const StyledTitle = styled(Typography.Title)`
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`
const ProfileContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`


const HeaderContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${neutral_1};
  width: 100%;
  //height: 148px;
  position: relative;



`

const StyledCloseOutlined = styled(CloseOutlined)`
  //position: absolute;
  //top: 13px;
  //right: 16px;
  //z-index: 3;
  cursor: pointer;
`

const Container = styled.div`
  position: relative;

`

const StyledAvatar = styled(Avatar)`
  ${({editMode}) => {
    if (editMode) {
      return `
  &:before {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${neutral_5};
    opacity: 0.5;
  }

  cursor: pointer;
 `

    }
  }}
`


const StyledEditIcon = styled(EditOutlined)`
  svg {
    width: 22px;
    height: 22px;
  }

  ${({editMode}) => {
    if (editMode) {
      return `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: white;
   cursor: pointer;
   `

    }
  }}
`


const ModalContent = styled.div`
  padding: 24px 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledModal = styled(Modal)`
  & .ant-modal-footer {
    border: none;
    background: ${neutral_1};
  }
`

export default function ProfileImage({
                                         src,
                                         setUser,
                                         id,
                                         user,
                                         url = 'user/my_user',
                                         name,
                                         editMode = false
                                     }) {

    const [open, setOpen] = useState(false)
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [hasChange, setHasChange] = useState(false)
    const [fileList, setFileList] = useState([])


    const onSubmit = async () => {
        if(!hasChange) {
            handleModalClose()
            return
        }
        setIsLoading(true)
        let data = {'picture': ''};
        if (fileList.length) {
            data = new FormData()
            let values;
            try {
                values = await form.validateFields()
                delete values['profile_img']
                fileList.forEach((file) => {
                    data.append('files[]', file)
                });

                Object.keys(values).forEach((key) => {
                    const value = values[key]
                    if (value) {

                        if (value.constructor.name === 'Array') {

                            value.forEach((value) => {
                                data.append(key, value)
                            })
                        } else {

                            data.append(key, value)
                        }
                    }
                });
            } catch (error) {
                setIsLoading(false)
                return;
            }

        }
        try {

                const response = await api.patch(url, data);
                setUser(response.data)
                Notification('success', 'Foto atualizado com sucesso.')
                setOpen(false)
            } catch (error) {

                BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
            } finally {
                setIsLoading(false)
            }
    }


    function handleModalClose() {
        setOpen(false)
        setHasChange(false)
        form.resetFields()

    }

    return (
        <>
            <ProfileContainer>
                <Container onClick={() => editMode && setOpen(true)}>
                    <StyledAvatar
                        id={id}
                        editMode={editMode}

                        name={name}
                        size={{
                            xs: 40,
                            sm: 40,
                            md: 40,
                            lg: 60,
                            xl: 60,
                            xxl: 60
                        }}
                        src={user.picture}/>
                    {editMode && <StyledEditIcon editMode={editMode}/>}
                </Container>
                <StyledTitle level={4}>{name}</StyledTitle>
            </ProfileContainer>
            <StyledModal open={open} width={'360px'}
                         bodyStyle={{padding: 0}}

                         closable={false}

                         footer={[<Button
                             onClick={handleModalClose}>Cancelar</Button>,
                             <Button loading={isLoading} type="primary"
                                     onClick={onSubmit}>Salvar
                                 alterações</Button>]}
                         onCancel={handleModalClose}>
                <HeaderContainer>

                    <PageTitle style={{marginBottom: 0}}
                               value={'Editar foto'}/>
                    <StyledCloseOutlined onClick={handleModalClose}/>
                </HeaderContainer>
                <ModalContent destroyOnClose>

                        <Form
                            id={'updateImage'}
                            layout={'vertical'}
                            form={form}>
                            <Form.Item name={"profile_img"} >
                                <ProfileUpload
                                    onChange={() => setHasChange(true)}
                                    defaultPreviewImage={user.picture}
                                    setFileList={setFileList}
                                />
                            </Form.Item>
                        </Form>

                </ModalContent>
            </StyledModal>
        </>
    )
}