import {useEffect, useState} from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function useSystemBreakpoint() {
    const screens = useBreakpoint();
    const [isPhone, setIsPhone] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    //todo mudar a largura do breakpoint

    useEffect(() => {
        setIsPhone(!screens.sm)
        setIsTablet(screens.md)
        setIsDesktop(screens.lg)

    }, [screens])

    return {isPhone, isTablet, isDesktop}
}