import React, {useEffect, useState} from 'react';
import {Form, Modal, Button} from "antd";
import styled from "styled-components";
import {text_color} from "../../Cores";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import OffCanvas from "../Base/OffCanvas";
import {Notification} from "../../notification";
import {BuildFieldErrorsDict} from "../../util/Utils";

import PageTitle from "../Base/PageTitle";
import ResponsibleCard, {ListResponsibleCard} from "../ResponsibleCard";
import api from "../../services/api";
import Loading from "../Base/Loading";
import Empty from "../Base/Empty";
import SelectUser from "../User/SelectUser";


const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`


export default function ResponsableOffCanvas({
                                                 opportunity,
                                                 open,
                                                 responsible,
                                                 setOpen,
                                                 setResponsible
                                             }) {

    const [form] = Form.useForm();
    const [hasChange, setHasChange] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isHistoryLoading, setIsHistoryLoading] = useState(false)
    const [resposiblesHistory, setResponsiblesHistory] = useState([])


    async function fetchHistoryResponsibles() {
        try {
            setIsHistoryLoading(true)
            const response = await api.get(`opportunity/${opportunity.id}/responsibles`,);
            setResponsiblesHistory(response.data)

        } catch (error) {
            // setLoading(false)
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsHistoryLoading(false)
        }
    }

    useEffect(() => {

        if (open) {

            form.setFieldsValue({'new_responsible': responsible?.responsible?.id})
        }
    }, [open])

    useEffect(() => {
        if (opportunity.id) {
            fetchHistoryResponsibles()
        }
    }, [opportunity.id])

    function onClose() {
        if (hasChange) {
            Modal.confirm({
                title: 'Deseja realmente sair?',
                content: 'Ao escolher voltar, você abandonará a edição e os dados serão perdidos.',
                icon: <ExclamationCircleOutlined/>,
                // content: 'Deseja realmente fechar?',
                okText: 'Sim',
                cancelText: 'Não',
                onOk: closeDrawer
            });
        } else {
            closeDrawer()
        }
    }

    function closeDrawer() {
        setOpen(false)
        form.resetFields()
    }


    const onSubmit = async () => {
        setIsLoading(true)
        let values;
        try {
            values = await form.validateFields()

        } catch (error) {
            setIsLoading(false)
            return;
        }

        try {
            const data = {
                ...values
            }
            const response = await api.post(`opportunity/${opportunity.id}/new_responsible`, data);
            setResponsible(response.data)
            setHasChange(false)
            fetchHistoryResponsibles()
            Notification('success', 'Responsável alterado com sucesso.')
            // closeDrawer()
            // resetTable()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <OffCanvas
            title={<Title>Alterar responsável</Title>}
            onClose={onClose}
            open={open} width={'378px'}
            footer={<Button disabled={!hasChange} loading={isLoading}
                            onClick={onSubmit}
                            type="primary">Alterar</Button>}>

            <Form
                id={'responsableForm'}
                layout={'vertical'}
                initialValues={{'is_active': true}}
                form={form}
                onValuesChange={() => setHasChange(true)}
            >
                <Form.Item label={'Novo Responsavel'} name={'new_responsible'}
                           rules={[{
                               required: true,
                               message: 'Selecione um responsável'
                           }
                           ]}>
                    <SelectUser showSearch  label_key={'get_full_name'}/>
                </Form.Item>
            </Form>
            <PageTitle value={' Histórico de responsáveis'}/>
            <ListResponsibleCard>
                {isHistoryLoading ? <Loading noHeight/> : (
                    resposiblesHistory.length === 0 ? (<Empty/>) : (
                        resposiblesHistory.map(({
                                                    responsible,
                                                    end_date,
                                                    start_date
                                                }) =>
                            <ResponsibleCard responsible={responsible}
                                             dateStart={start_date}
                                             dateEnd={end_date}/>)
                    )
                )}

            </ListResponsibleCard>
        </OffCanvas>
    )

}