import React from 'react'
import styled from 'styled-components'
import {
    gray_2,
    menu_bg,
    red_5,
    success_color
} from "../../Cores";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {dateFormat} from "../../util/dates";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  padding: 16px;
  width: ${({width}) => width}px;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${menu_bg};
  cursor: pointer
`

const Title = styled.span`
  color: white;
  font-weight: bold;
  font-size: 14px;
`

const HeaderContainer = styled.div`
  display: flex;
  column-gap: 4px;
  flex-wrap: nowrap;
  font-size: 12px;

  & > * {
    flex-shrink: 0;
    position: relative;
    display: inline-block;

  }

  & > :not(:first-child) {
    margin-left: 8px;
  }

  & > :not(:first-child):before {
    content: '';
    background: white;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
  }
`

const PMP = styled.span`
  color: ${({hasPMP}) => hasPMP ? success_color : red_5};
`
const Garanty = styled.span`
  color: ${({hasWarranty}) => hasWarranty ? success_color : red_5};
`
const Time = styled.span`
  color: ${gray_2};
  font-size: 12px;
`

export default function PriorityOpportunityCard({opportunity, width = 287}) {
    const navigate = useNavigate()

    function handleCardClick() {
        navigate(`/opportunity/${opportunity.id}/detail`)
    }

    return (
        <Container width={width} onClick={handleCardClick}>
            <HeaderContainer>
                <span>N° {`${opportunity.id}`}</span>
                <span>{opportunity.type_opportunity}</span>
                <PMP hasPMP={opportunity.PMP}>PMP</PMP>
                <Garanty
                    hasWarranty={opportunity.warranty_date}>Garantia</Garanty><
                /HeaderContainer>
            <Title level={5}>{opportunity.pin_17}</Title>
            <Time>{dateFormat(opportunity.created_at, 'DD/MM/YYYY - HH[h]mm')}</Time>
        </Container>
    )
}