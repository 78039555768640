import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import DisplayInfo from "../Base/DisplayInfo";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import Loading from "../Base/Loading";
import {dateFormat} from "../../util/dates";

export default function TabCautionCodeDataDetailOffCanvas({
                                                               id
                                                           }) {

    // const [form] = Form.useForm();
    //
    const [record, setRecord] = useState({})
    const {fetchData, isLoading} = useDefaultFetch()

    useEffect(() => {
        async function fetch() {
            setRecord(await fetchData(`caution_code_data/${id}`))
        }
        id && fetch()
    }, [id])

    return (
        <Row gutter={[4, 24]} style={{marginTop: 24}}>
            {isLoading ? (<Col xs={24}><Loading/></Col>) : (<>
                <Col xs={24} sm={24}>
                    <DisplayInfo label={'N° SÉRIE'}>
                        {record.equipment_pin_17}
                    </DisplayInfo>
                </Col>
                <Col xs={24} sm={24}>
                    <DisplayInfo label={'CLIENTE'}>
                        {record.client_name}
                    </DisplayInfo>
                </Col>
                <Col xs={12} sm={12}>
                    <DisplayInfo label={'SPN'}>
                        {record.spn}
                    </DisplayInfo>

                </Col>
                <Col xs={12} sm={12}>
                    <DisplayInfo label={'FMI'}>
                        {record.fmi}
                    </DisplayInfo>

                </Col>
                <Col xs={12}>
                    <DisplayInfo label={'CRIADO EM'} >
                        {dateFormat(record.created_at,'DD/MM/YYYY HH:mm')}
                    </DisplayInfo>
                </Col>
                <Col xs={24} sm={12}>
                    <DisplayInfo label={'OCORRÊNCIA'}>
                        {record.num_occurrences}
                    </DisplayInfo>
                </Col>
                <Col xs={24} sm={12}>
                    <DisplayInfo label={'DESCRIÇÃO'} >
                        {record.caution_description}
                    </DisplayInfo>
                </Col>
            </>)}
        </Row>

    )

}

