import React from 'react'
import styled from "styled-components";
import {border_dark_color2} from "../../Cores";
import {Drawer, Modal} from "antd";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";


const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid ${border_dark_color2};
`
const ExtraIcon = styled.div`
  margin-left: auto;
`

export function ModalMap({open, onClose, children}) {

    const {isDesktop} = useSystemBreakpoint();
    return (
        <>
            {isDesktop ? (
                <Modal open={open} width={'360px'}
                       bodyStyle={{padding: 0}}
                       footer={null} closable={false} onCancel={onClose}>
                    {children}

                </Modal>
            ) : (
                <Drawer closable={false}
                        bodyStyle={{padding: 0}}
                        onClose={onClose}
                        placement={'bottom'}
                        open={open}>
                    {children}
                </Drawer>

            )
            }
        </>
    )
}