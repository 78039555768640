import React, { useEffect, useState } from "react";
import { Badge as BadgeAnt } from "antd";
import { dateFormat } from "../../util/dates";

export default function WarrantyDateAlert({
  date,
  brandEquipmentName,
  hourmeter,
}) {
  const [showDate, setShowDate] = useState();
  const [color, setColor] = useState();

  function calculateAlert() {
    if (
      brandEquipmentName &&
      brandEquipmentName.toLowerCase() === "kalmar" &&
      hourmeter < 2000
    ) {
      setShowDate(false);
      setColor("green");
      return;
    }
    if (date) {
      const now = new Date();
      const alertDate = new Date(date);
      const diffDate = now.getTime() - alertDate.getTime();
      const mili_in_one_day = 86400000;
      const days = diffDate / mili_in_one_day;

      if (days < 1) {
        setShowDate(true);
        setColor("green");
        return;
      }
    }
    setShowDate(false);
    setColor("red");
  }

  useEffect(() => {
    calculateAlert();
  }, [date]);

  function generateText() {
    const dateFormated = dateFormat(date);
    if (date) {
      return dateFormated;
    } else {
      return "-";
    }
  }

  return <BadgeAnt status={color} text={generateText()} />;
}
