import React from 'react'
import Avatar from "./Base/Avatar";
import styled from "styled-components";
import {collapse_header_text_color} from "../Cores";
import {dateFormat} from "../util/dates";

const Container = styled.div`
  display: flex;
  gap: 8px;

  & > .ant-avatar.ant-avatar-circle {
    flex-shrink: 0;
  }
`

const Name = styled.div`
  font-size: 14px;
`

const Date = styled.div`
  font-size: 12px;
  color: ${collapse_header_text_color};
`

export default function AuditCard({event}) {

    return (
        <div>
            <Container>
                {event.owner && <Avatar size={24}
                                        id={event.owner.id}
                                           name={event.owner.get_full_name}
                                           src={event.owner.picture}
                                           style={{marginRight: 3}}/>}

                <Name>{event.owner?.get_full_name} <Date>{dateFormat(event.created_at)} - {event.description}</Date></Name>
            </Container>


        </div>
    )
}