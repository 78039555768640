import React, { useRef } from "react";

import styled from "styled-components";
import { Chart } from "react-google-charts";
import {
  blue_6,
  blue_8,
  day_break_blue_2,
  day_break_blue_9,
  purple_5,
  purple_7,
  chart_color_gray,
  content_bg,
  primary_color,
} from "../../../Cores";
import { NumberFormatInteger } from "./utils";
import PageTitle from "../PageTitle";

const StyledChart = styled(Chart)`
  color: white;

  svg text:first-child {
    fill: white;
  }

  text[text-anchor="middle"] {
    fill: white;
    font-weight: bold;
  }

  g.google-visualization-tooltip text {
    font-weight: bold;
    fill: ${content_bg};
  }
`;
const defaultOptions = {
  colors: [
    day_break_blue_9,
    purple_5,
    blue_6,
    purple_7,
    blue_8,
    day_break_blue_2,
    primary_color,
    chart_color_gray,
  ],
  fill: "red",
  backgroundColor: content_bg,
  chartArea: { left: 50, top: 10, width: "100%", height: "85%" },
  legend: { position: "bottom", maxLines: 1 },
};

export default function StaticBarGraphic({
  title,
  options,
  data,
  isLoading,
  setSearch,
  isStacked = false,
  ...props
}) {
  const lastSelected = useRef(null);
  if (isLoading) return <PageTitle value={title} />;

  const getTicks = () => {
    const filteredData = data[1].filter((item) => typeof item === "number");

    const max = Math.max(...filteredData);

    const ticks = [];
    for (let i = 0; i < 5; i++) {
      const tick = (max * (i + 1)) / 5;

      ticks.push(Math.round(tick));
    }
    return ticks;
  };

  const ticks = getTicks();

  return (
    <>
      <PageTitle value={title} />
      <StyledChart
        formatters={[NumberFormatInteger]}
        width="100%"
        options={{
          ...defaultOptions,
          ...options,
          isStacked,
          vAxis: {
            minValue: 0,
            ticks,
          },
        }}
        chartType="ColumnChart"
        data={data}
        chartEvents={[
          {
            eventName: "select",
            callback({ chartWrapper }) {
              if (!setSearch) return;

              const selected = chartWrapper.getChart()?.getSelection()?.[0];

              if (!selected) {
                setSearch("");
                return;
              }

              const { column } = selected;

              if (lastSelected.current === column) {
                setSearch("");
                lastSelected.current = null;
                return;
              }

              lastSelected.current = column;
              setSearch(data[0][column]);
            },
          },
        ]}
        {...props}
      />
    </>
  );
}
