import OsCreationInitialInformationForm from "./InitialInformationForm";
import LaborSelection from "./LaborSelection";
import PartDetail from "./PartDetail";
import PartSelection from "./PartSelection";
import QuotationSummary from "./QuotationSummary";

export default function StepManager({
  currentStep,
  startingCenter,
  startingBrand,
  form,
  quotationData,
  setQuotationData,
  partDetails,
  partData,
  setPartData,
  setSelectedParts,
  client,
  laborData,
  setLaborData
}) {
  switch (currentStep) {
    case 0:
      return (
        <OsCreationInitialInformationForm
          startingCenter={startingCenter}
          form={form}
          setQuotationData={setQuotationData}
          client={client}
        />
      );
    case 1:
      return (
        <LaborSelection
          laborData={laborData}
          setLaborData={setLaborData}
          startingBrand={startingBrand}
          startingCenter={startingCenter}
        />
      );
    case 2:
      return (
        <PartSelection
          partData={partData}
          setPartData={setPartData}
          startingBrand={startingBrand}
          startingCenter={startingCenter}
        />
      );
    case 3:
      return (
        <PartDetail
          partData={partDetails}
          setSelectedParts={setSelectedParts}
        />
      );
    case 4:
      return (
        <QuotationSummary
          quotationData={quotationData}
          setQuotationData={setQuotationData}
        />
      );
    default:
      return <OsCreationInitialInformationForm />;
  }
}
