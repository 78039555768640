import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Button, Col, Row,
} from "antd";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import OffCanvas from "../Base/OffCanvas";
import {Notification} from "../../notification";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";
import UserForm from "./UserForm";
import styled from "styled-components";
import {text_color} from "../../Cores";
import LastLogin from "../Base/LastLogin";


const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`
export default function UserEditOffCanvas({user, resetTable, open, setOpen}) {

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false)
    const [hasChange, setHasChange] = useState(false)

    useEffect(() => {
        form.setFieldsValue(user)
        // eslint-disable-next-line
    }, [user])

    function onClose() {
        if (hasChange) {
            Modal.confirm({

                title: 'Deseja realmente sair?',
                content: 'Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.',
                icon: <ExclamationCircleOutlined/>,
                // content: 'Deseja realmente fechar?',
                okText: 'Sim',
                cancelText: 'Não',
                onOk: () => {
                    closeDrawer()
                    form.setFieldsValue(user)
                }
            });
        } else {
            closeDrawer()
        }
    }

    function closeDrawer() {

        setOpen(false)
        setHasChange(false)

    }

    const onSubmit = async () => {
        setIsLoading(true)
        let values;
        try {
            values = await form.validateFields()

        } catch (error) {
            setIsLoading(false)
            return;
        }
        try {
            const indexFirstSpace = values.get_full_name.indexOf(' ')

            const data = {
                ...values,
                first_name: values.get_full_name.split(' ')[0],
                last_name: values.get_full_name.slice(indexFirstSpace)
            }
            await api.patch(`user/${user.id}`, data);
            resetTable()
            Notification('success', 'Usuário atualizado com sucesso.')
            closeDrawer()
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }

    }


    return (
        <OffCanvas
            // destroyOnClose
            title={

                <Title>Editar usuário</Title>

            }
            onClose={onClose}
            open={open} width={'378px'}
            footer={
                <>

                    <Button disabled={!hasChange}  onClick={onSubmit} type="primary"
                            loading={isLoading}>Salvar</Button>

                </>}
        >
            {/*{editMode ? (*/}
            <UserForm user={user} form={form}

                      onValuesChange={() => {
                          setHasChange(true)
                      }}/>
            <Row gutter={4}>
                <Col xs={24}>
                    <LastLogin datetime={user?.last_login}/>
                </Col>

            </Row>


        </OffCanvas>
    )

}

