import React from "react";
import { Col, Form, Row } from "antd";
import PageTitle from "../../Base/PageTitle";
import Select from "../../Base/Select";
import InputNumber from "../../Base/Forms/InputNumber";
import DividerPage from "../../Base/DividerPage";
import FormInfo from "./FormInfo";
import {
  SYSTEM_CARETRACK_ID,
  SYSTEM_REMOTE_LOG_ID,
} from "../../../util/OpportunityUtils";

const TELEMETRY_SYSTEM_NAME = "telemetry_system";

export default function ErrorCodeFormContainer({ form, updateMode }) {
  const system_field = Form.useWatch(TELEMETRY_SYSTEM_NAME, form);

  return (
    <>
      <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <PageTitle value={"Variáveis da regra"} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={"Sistema"}
            name={TELEMETRY_SYSTEM_NAME}
            rules={[
              {
                required: true,
                message: "Sistema é obrigatório",
              },
            ]}
          >
            <Select
              disabled={updateMode}
              placeholder="Selecione o sistema"
              url={"telemetry_sistems?page_size=1000"}
            />
          </Form.Item>
        </Col>
        {SYSTEM_CARETRACK_ID === system_field && (
          <>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="PID"
                name="pid"
                rules={[
                  {
                    required: true,
                    message: "PID é obrigatório",
                  },
                ]}
              >
                <InputNumber placeholder="Digite o PID" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="MID"
                name="mid"
                rules={[
                  {
                    required: true,
                    message: "MID é obrigatório",
                  },
                ]}
              >
                <InputNumber placeholder="Digite o MID" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Número do ACD" name="acd_number">
                <InputNumber disabled={true} placeholder="Digite o ACD" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="FMI"
                name="fmi"
                rules={[
                  {
                    required: true,
                    message: "FMI é obrigatório",
                  },
                ]}
              >
                <InputNumber placeholder="Digite o FMI" />
              </Form.Item>
            </Col>
          </>
        )}
        {SYSTEM_REMOTE_LOG_ID === system_field && (
          <>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="SPN"
                name="spn_remote_log"
                rules={[
                  {
                    required: true,
                    message: "SPN é obrigatório",
                  },
                ]}
              >
                <InputNumber placeholder="Digite o SPN" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="FMI"
                name="fmi_remote_log"
                rules={[
                  {
                    required: true,
                    message: "FMI é obrigatório",
                  },
                ]}
              >
                <InputNumber placeholder="Digite o FMI" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <DividerPage />
      <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <PageTitle value={<> Frequência</>} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <FormInfo
            label={
              "Selecione qual o tipo de variável de tempo (dias, horas) e o intervalo de tempo (qtd) em que a frequência deverá atuar. Por exemplo: caso a variável seja dias, a quantidade 3, e a frequência 5, isso significa que só será criada uma notificação se, dentro de 3 dias, aquela regra tenha uma ocorrência de 5 vezes."
            }
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={"Tipo da frequência"}
            name={"frequency_type"}
            rules={[
              {
                required: true,
                message: "Selecione o tipo da frequência",
              },
            ]}
          >
            <Select
              url={"frequency_type?page_size=1000"}
              placeholder="Selecione o tipo da frequência"
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label="Quantidade"
            name="interval"
            rules={[
              {
                required: true,
                message: "Quantidade é obrigatório",
              },
              {
                pattern: /^[1-9]+[0-9]*$/,
                message: "Informe um número maior que zero",
              },
            ]}
          >
            <InputNumber placeholder="Digite a quantidade em números" />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label="Ocorrência"
            name="recurrence"
            rules={[
              {
                required: true,
                message: "Ocorrência é obrigatório",
              },

              {
                pattern: /^[1-9]+[0-9]*$/,
                message: "Informe um número maior que zero",
              },
            ]}
          >
            <InputNumber placeholder="Digite a quantidade de ocorrências mínimas" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
