import React from 'react';
import styled from "styled-components";
import {Tabs} from "../../components/Base/Tabs";

import useTab from "../../hooks/useTab";
import Settings from "./tabs/Settings";
import TabStatus from "./tabs/TabStatus";
import TabAboutUs from "./tabs/TabAboutUs";


const Container = styled.div`
  position: relative;
`


export default function SystemSettings() {
    const {setQueryTab, defaultTabId} = useTab({defaultTab: '1'})


    return (
        <Container>

            <Tabs
                destroyInactiveTabPane={true}
                activeKey={defaultTabId}
                tabBarStyle={{marginBottom: 16}}
                onChange={setQueryTab}
                items={[
                    {
                        label: 'Configurações',
                        key: '1',
                        children: <Settings/>
                    },
                    {
                        label: 'Status',
                        key: '2',
                        children: <TabStatus/>
                    },
                     {
                        label: 'Informações do sistema',
                        key: '3',
                        children: <TabAboutUs/>
                    },


                ]}/>


        </Container>
    )
}