import React from 'react'
import {
    Badge as BadgeAnt,
} from "antd";

const getColor = (value) => {
    let result = ''
    switch (value) {
        case 'Alta':
            result = 'red'
            break
        case 'Média':
            result = 'yellow'
            break
        case 'Baixa':
            result = 'blue'
            break
        default:
            result = 'green'
            break
    }
    return result
}

export default function AlertPriority({value}) {

    return (
        <BadgeAnt status={getColor(value)} text={value}/>
    )
}