import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Button} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import OffCanvas from "../Base/OffCanvas";
import {Notification} from "../../notification";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";
import TitleOffCanvas from "../TitleOffCanvas";
import UserForm from "./UserForm";


export default function CreateUserOffCanvas({open, setOpen, resetTable}) {

    const [form] = Form.useForm();
    const [hasChange, setHasChange] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    function onClose() {
        if (hasChange) {
            Modal.confirm({

                title: 'Deseja realmente sair?',
                content: 'Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.',
                icon: <ExclamationCircleOutlined/>,
                // content: 'Deseja realmente fechar?',
                okText: 'Sim',
                cancelText: 'Não',
                onOk: closeDrawer
            });
        } else {
            closeDrawer()
        }
    }

    function closeDrawer() {
        setOpen(false)
        form.resetFields()
    }

    const onSubmit = async () => {
        setIsLoading(true)
        let values;
        try {
            values = await form.validateFields()

        } catch (error) {
            setIsLoading(false)
            return;
        }

        try {
            const indexFirstSpace = values.get_full_name.indexOf(' ')


            const data = {
                ...values,
                first_name: values.get_full_name.split(' ')[0],
                last_name: values.get_full_name.slice(indexFirstSpace)
            }
            await api.post(`user`, data);
            Notification('success', 'Usuário cadastrado com sucesso.')
            closeDrawer()
            resetTable()
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <OffCanvas
            title={<TitleOffCanvas>Cadastrar usuário</TitleOffCanvas>}
            onClose={onClose}
            open={open} width={'378px'}
            footer={<Button disabled={!hasChange} loading={isLoading}
                            onClick={onSubmit}
                            type="primary">Cadastrar</Button>}>
            <UserForm form={form}

                      onValuesChange={() => {
                          setHasChange(true)
                      }}/>

        </OffCanvas>
    )

}