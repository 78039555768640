import styled from "styled-components";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Notification } from "../../notification";
import StepComponet from "./StepComponent";
import StepManager from "./StepManager";
import api from "../../services/api";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  overflow-y: auto;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`;

export default function CreateOsQuotationModal({
  isOpen,
  setIsOpen,
  startingCenter,
  startingBrand,
  client,
  equipment_id,
  opportunity_id = null,
  source,
  setCurrentBudget,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [quotationData, setQuotationData] = useState({});
  const [laborData, setLaborData] = useState([
    {
      laborId: undefined,
      laborDescription: undefined,
      quantity: 1,
      manufacturer: startingBrand.id,
      center: startingCenter,
      price: 0,
    },
  ]);
  const [partData, setPartData] = useState([]);
  const [partDetails, setPartDetails] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setQuotationData({});
    setLaborData([
      {
        laborId: undefined,
        laborDescription: undefined,
        quantity: 1,
        manufacturer: startingBrand.id_sap,
        center: startingCenter,
        price: 0,
      },
    ]);
    setPartData([]);
    setPartDetails([]);
    setSelectedParts([]);
    setCurrentStep(0);
    setIsOpen(false);
  };

  useEffect(() => {
    if (currentStep === 1) {
      const buttonDisabled =
        laborData.some(
          (labor) =>
            !labor.laborId ||
            labor.quantity === "" ||
            labor.manufacturer === "" ||
            labor.center === ""
        ) || !laborData.length;
      setIsButtonDisabled(buttonDisabled);
    } else if (currentStep === 2) {
      const buttonDisabled = partData.some(
        (part) =>
          part.partNumber === "" ||
          part.quantity === "" ||
          part.manufacturer === "" ||
          part.center === ""
      );
      setIsButtonDisabled(buttonDisabled);
    } else if (currentStep === 3) {
      const buttonDisabled = selectedParts.some((part) => !part.material_id);
      setIsButtonDisabled(buttonDisabled);
    }
  }, [partData, currentStep, selectedParts, laborData]);

  const onSubmit = async () => {
    try {
      setIsButtonLoading(true);
      const response = await api.post(
        `/${source}/${
          source === "equipment" ? equipment_id : opportunity_id
        }/create_os`,
        {
          ...quotationData,
        }
      );

      if (response.data.error) {
        Notification(
          "error",
          "Erro ao criar ordem de serviço",
          response.data.message
        );
        return;
      }

      Notification("success", "Ordem de serviço criada com sucesso");
      setCurrentBudget(response.data);
      onClose();
    } catch (error) {
      Notification(
        "error",
        "Erro ao criar ordem de serviço",
        error.response.data.message
      );
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleButtonClick = async () => {
    switch (currentStep) {
      case 0:
        let values;
        try {
          values = await form.validateFields();
          values.client_id = client.id;
          values.equipment_id = equipment_id;
          values.opportunity_id = opportunity_id;
          setQuotationData((prev) => ({
            ...prev,
            ...values,
          }));
          setCurrentStep((prev) => prev + 1);
        } catch (error) {
          return;
        }
        break;
      case 1:
        setQuotationData((prev) => ({
          ...prev,
          labor: laborData,
        }));
        setCurrentStep((prev) => prev + 1);
        break;
      case 2:
        if (!partData.length) {
          setQuotationData((prev) => ({
            ...prev,
            parts: [],
          }));
          setCurrentStep((prev) => prev + 2);
          break;
        }
        try {
          setIsButtonLoading(true);
          const response = await api.post(
            "/parts_quotation/get_parts_materials",
            {
              client_id: client.id,
              parts: partData,
            }
          );
          setPartDetails(response.data);
          setSelectedParts(
            response.data.map((part) => ({ center: part.center }))
          );
          setCurrentStep((prev) => prev + 1);
        } catch (e) {
          Notification("error", "Erro ao consultar peças no SAP");
        } finally {
          setIsButtonLoading(false);
        }
        break;
      case 3:
        setQuotationData((prev) => ({
          ...prev,
          parts: selectedParts,
        }));
        setCurrentStep((prev) => prev + 1);
        break;
      case 4:
        onSubmit();
        break;
      default:
        setCurrentStep(0);
    }
  };

  const getButtonText = () => {
    if (isButtonLoading) {
      return "Processando...";
    }
    return currentStep < 4 ? (
      "Avançar"
    ) : (
      <>
        <PlusOutlined style={{ marginRight: "4px" }} />
        Criar OS
      </>
    );
  };

  return (
    <Modal
      open={isOpen}
      width={"900px"}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button onClick={onClose}>Cancelar</Button>,
        <Button
          type={"primary"}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
          loading={isButtonLoading}
        >
          {getButtonText()}
        </Button>,
      ]}
      closable={false}
      title="Criar nova cotação de ordem de serviços"
    >
      <StyledCloseOutlined onClick={onClose} />
      <StepComponet currentStep={currentStep} />
      <ContentContainer>
        <StepManager
          currentStep={currentStep}
          startingCenter={startingCenter}
          startingBrand={startingBrand}
          form={form}
          quotationData={quotationData}
          setQuotationData={setQuotationData}
          partDetails={partDetails}
          partData={partData}
          setPartData={setPartData}
          setSelectedParts={setSelectedParts}
          client={client}
          laborData={laborData}
          setLaborData={setLaborData}
        />
      </ContentContainer>
    </Modal>
  );
}
