import styled from 'styled-components'
import useForm from "../../../hooks/useForm";

import Label from "./Label";

const ErrorMensage = styled.span`
  color: red;
`


export default ({setData, label, error, children, ...props}) => {
    const {onChange} = useForm()

    return (
        <div {...props}>
            {label && <Label>{label}</Label>}
            {children}
            {error && <ErrorMensage>error</ErrorMensage>}
        </div>

    )
}