import { Dropdown, Menu, Popconfirm } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteTokens } from "../../util/Auth";
import styled from "styled-components";
import { text_color } from "../../Cores";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import MyProfileOffCanvas from "../User/MyProfileOffCanvas";
import AvatarProfile from "../AvatarProfile";

const StyledDropdown = styled(Dropdown)`
  cursor: pointer;
`;

const StyledDownOutlined = styled(DownOutlined)`
  & > svg {
    width: 10px;
  }
`;


export default function DropdownUser({ user }) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { isPhone } = useSystemBreakpoint();
  const [openPerfilUser, setOpenPerfilUser] = useState(false);

  const handleDropdownClick = (e) => {
    if (e.key !== "logout") {
      setOpen(false);
    }
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  const handleLogout = () => {
    deleteTokens();
    navigate("/login");
  };

  const LogoutButton = () => {
    return (
      <Popconfirm
        title="Deseja realmente sair?"
        placement="rightTop"
        onConfirm={handleLogout}
        okText="Sim"
        cancelText="Não"
      >
        <div style={{ color: text_color }}> Sair</div>
      </Popconfirm>
    );
  };

  const menu = (
    <Menu
      onClick={handleDropdownClick}
      items={[
        {
          label: "Meu perfil",
          key: "profile",
          // disabled: true,
          onClick: () => setOpenPerfilUser(true),
        },

        {
          label: <LogoutButton />,
          key: "logout",
        },
      ]}
    />
  );

  return (
    <>
      <StyledDropdown
        overlay={menu}
        open={open}
        onOpenChange={handleOpenChange}
        trigger={["click"]}
      >
        <AvatarProfile
          src={user.picture}
          id={user.id}
          name={`${user.first_name} ${user.last_name}`}
          extra={!isPhone && <StyledDownOutlined />}
        />
      </StyledDropdown>
      <MyProfileOffCanvas
        user={user}
        setOpen={setOpenPerfilUser}
        open={openPerfilUser}
      />
    </>
  );
}
