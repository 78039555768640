import { Col, Form, Input, Row, Upload as UploadAnt } from "antd";
import Select from "../Base/Select";
import React from "react";
import LastLogin from "../Base/LastLogin";
import { phoneMask } from "../../util/Utils";
import GroupButtons from "../Base/GroupButtons";
import { useStaticDataContext } from "../../context/StaticDataContext";

const optionSituacion = [
  {
    label: "Ativo",
    value: true,
  },
  {
    label: "Inativo",
    value: false,
  },
];

export default function ProfileForm({ user, form, setFileList, ...props }) {
  const { brands } = useStaticDataContext();

  function maskPhone(phone) {
    const value = phoneMask(phone);
    form.setFieldValue("phone", value);
    form.validateFields(["phone"]);
  }

  return (
    <Form id={"createForm"} layout={"vertical"} form={form} {...props}>
      <Form.Item
        label="E-mail Tracbel (Microsoft)"
        name="username"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled placeholder="email@tracbel.com.br" />
      </Form.Item>

      <Form.Item
        label="Nome completo"
        name="get_full_name"
        rules={[
          {
            required: true,
            message: "Nome é obrigatório",
          },
          {
            pattern: /^((\b[A-zÀ-ú']{2,40}\b)\s*){2,}$/,
            message: "Informe um nome completo válido",
          },
        ]}
      >
        <Input placeholder="Nome completo do colaborador" />
      </Form.Item>

      <Form.Item
        label="Celular profissional (Tracbel)"
        name="phone"
        rules={[
          {
            required: true,
            message: "Celular é obrigatório",
          },
          {
            pattern: /^\([1-9]{2}\) [0-9]{5}-[0-9]{4}$/,
            message: "Informe um número de celular válido",
          },
        ]}
      >
        <Input
          onChange={(e) => maskPhone(e.target.value)}
          placeholder="(99) 99999-9999"
        />
      </Form.Item>

      <Form.Item
        label={"Situação"}
        name={"is_active"}
        rules={[
          {
            required: true,
            message: "Situação é obrigatório",
          },
        ]}
      >
        <GroupButtons options={optionSituacion} disabled />
      </Form.Item>

      {/*<Form.Item name={"profile_img"}*/}
      {/*                   label={'Foto'}>*/}
      {/*            <ImageUpload*/}

      {/*                // defaultFileList={defaultFileList}*/}
      {/*                setFileList={setFileList}*/}
      {/*            />*/}
      {/*        </Form.Item>*/}
      <Form.Item
        label={"Perfis de acesso"}
        name={"profiles_display"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos um perfil de acesso",
          },
        ]}
      >
        <Select
          mode="multiple"
          disabled
          placeholder="Selecione"

          // options={optionsProfiles}
        />
      </Form.Item>
      <Form.Item
        label={"Centros"}
        name={"centers_display"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos um centro",
          },
        ]}
      >
        <Select
          mode="multiple"
          disabled
          // options={optionsCenters}
        />
      </Form.Item>
      <Form.Item
        label={"Marcas"}
        name={"brands"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos um centro",
          },
        ]}
      >
        <Select
          mode="multiple"
          options={brands?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Form.Item>
      <Row gutter={4}>
        <Col xs={24}>
          <LastLogin datetime={user.last_login} />
        </Col>
      </Row>
    </Form>
  );
}
