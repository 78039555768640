import React from 'react';
import RenewPMP from "./RenewPMP";
import AlertPMP from "./Opportunity/AlertPMP";

export function InfoPMP({renew_PMP, PMP}) {
    return (
        <>
            {renew_PMP ? <RenewPMP/> : <AlertPMP value={PMP}/>}
        </>
    )
}
