import { Autocomplete } from "@react-google-maps/api";
import { Input } from "antd";
import { useState } from "react";

export default function PlaceAutocomplete({ setCoords, coordRef }) {
  const [searchResult, setSearchResults] = useState(null);
  const [inputValue, setInputValue] = useState();

  const onLoad = (autocomplete) => {
    setSearchResults(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult !== null) {
      const place = searchResult.getPlace();
      const address = place.formatted_address;
      const { lat, lng } = place.geometry.location.toJSON();

      setCoords({ lat, lng });
      setInputValue(address);
      coordRef.current = address;
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      className="auto-complete"
    >
      <Input value={inputValue} onChange={handleInputChange} />
    </Autocomplete>
  );
}
