import { Collapse, Row, Table } from "antd";
import styled from "styled-components";
import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import {
  content_bg,
  icon_color,
  primary_color,
  success_color,
  sunset_orange_6,
  text_color,
} from "../../Cores";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { useEffect, useMemo, useState } from "react";

const StyledCollapse = styled(Collapse)`
  border: unset;
  margin-bottom: 10px;
  border-radius: 5px;
  color: ${text_color};

  & .ant-collapse-content {
    border: unset;
    border-radius: 0 0 5px 5px !important;
  }

  & > .ant-collapse-item {
    border: unset;
  }

  & .ant-collapse-expand-icon {
    color: ${text_color};
  }

  & .ant-collapse-header-text {
    color: ${text_color};
  }
`;

const StyledTable = styled(Table)`
  margin-top: 16px;
  width: 100%;

  .ant-select-arrow {
    color: white;
  }

  .ant-select-open .ant-select-arrow {
    color: #656c70;
  }

  td {
    background: ${content_bg};
  }

  tr:hover td {
    cursor: ${({ hasClick }) => (hasClick ? "pointer" : "default")};
    background: #323535 !important;
  }

  .ant-pagination-item-link {
    background: transparent;
    border: none;
    color: white;
  }

  .ant-pagination-item-link:disabled {
    color: ${icon_color};
  }

  .ant-pagination-item-link:hover:not(:disabled) {
    color: ${primary_color};
  }

  .ant-table-row-selected td.ant-table-cell {
    background: #323535 !important;
  }
`;

const tableColumns = [
  {
    title: "ID Material",
    dataIndex: "material_id",
    key: "material_id",
  },
  {
    title: "Descrição",
    dataIndex: "material_description",
    key: "material_description",
  },
  {
    title: "QTD",
    dataIndex: "qtd",
    key: "qtd",
  },
  {
    title: "ATP",
    dataIndex: "atp",
    key: "atp",
  },
  {
    title: "Preço",
    dataIndex: "price",
    key: "price",
    render: (text) => (text ? `R$ ${text.replace(".", ",")}` : "-"),
  },
  {
    title: "Inat.",
    dataIndex: "inactive",
    key: "inactive",
  },
  {
    title: "Subst.",
    dataIndex: "substitution",
    key: "substitution",
  },
];

export default function PartDetail({ partData, setSelectedParts }) {
  const [panels, setPanels] = useState([]);

  const handleRowSelectionChange = (selectedRows, index, panel) => {
    const [part] = selectedRows;

    setSelectedParts((prev) => {
      const newSelectedParts = [...prev];
      newSelectedParts[index] = { ...newSelectedParts[index], ...part };
      return newSelectedParts;
    });

    panel.extra = getPartStatus(false);
  };

  const getPartStatus = (partNeedsAction) => {
    if (partNeedsAction) {
      return (
        <WarningOutlined
          style={{
            color: sunset_orange_6,
            fontSize: "20px",
          }}
        />
      );
    }
    return <CheckOutlined style={{ color: success_color, fontSize: "20px" }} />;
  };

  useEffect(() => {
    const getPanels = () => {
      return partData.map((part, index) => {
        const hasOptions = part?.options.length > 1;

        if (!hasOptions) {
          const singleOption = part.options[0];

          setSelectedParts((prev) => {
            const newSelectedParts = [...prev];
            newSelectedParts[index] = {
              ...newSelectedParts[index],
              key: singleOption.id,
              material_description: singleOption.material_description,
              material_id: singleOption.material_id,
              qtd: part.qtd,
              atp: singleOption.atp,
              price: singleOption.price,
            };
            return newSelectedParts;
          });
        }

        return {
          key: part.part_number,
          material_description: part.material_description,
          center: part.center,
          options: part?.options
            .map((option) => ({
              ...option,
              qtd: part.qtd,
            }))
            .sort((a, b) => a.key - b.key),
          extra: getPartStatus(hasOptions),
          hasOptions,
          defaultSelectedRowKey: !hasOptions
            ? part.options[0].material_id
            : null,
        };
      });
    };

    const panels = getPanels();

    setPanels(panels);
  }, [partData, setSelectedParts]);

  return (
    <div>
      {panels.map((panel, index) => (
        <StyledCollapse>
          <CollapsePanel
            key={index}
            header={panel.material_description}
            extra={panel.extra}
          >
            <Row style={{ marginBottom: "1rem" }}>
              <StyledTable
                rowSelection={{
                  type: "radio",
                  defaultSelectedRowKeys: [panel.defaultSelectedRowKey],
                  onChange: (_, selectedRows) =>
                    handleRowSelectionChange(selectedRows, index, panel),
                }}
                columns={tableColumns}
                dataSource={panel.options}
              />
            </Row>
          </CollapsePanel>
        </StyledCollapse>
      ))}
    </div>
  );
}
