import React, { useState } from "react";
import { Form, Modal, Button } from "antd";
import styled from "styled-components";
import { gray_2, text_color } from "../../../Cores";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import OffCanvas from "../../Base/OffCanvas";
import { Notification } from "../../../notification";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import DragAndDropUpload from "../../Base/Forms/DragAndDropUpload";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;
export default function ImportEquipmentOffCanvas({
  open,
  setOpen,
  resetTable,
}) {
  const [hasChange, setHasChange] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  const [form] = Form.useForm();
  const sendFiles = async () => {
    let formData = new FormData();
    setIsLoadingFiles(true);
    let values;
    try {
      values = await form.validateFields();
      delete values["file_list"];
      fileList.forEach((file) => {
        formData.append("files[]", file);
      });
    } catch (error) {
      Notification("error", "Erros encontrados");
      setIsLoadingFiles(false);
      return;
    }

    try {
      await api.post(`import_table_equipments`, formData);
      Notification("success", "Importação iniciada");
      closeDrawer();
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, false);
    } finally {
      setIsLoadingFiles(false);
    }
  };

  function onClose() {
    if (hasChange) {
      Modal.confirm({
        title: "Deseja realmente sair?",
        content:
          "Ao escolher voltar, você abandonará a importação e os dados serão perdidos.",
        icon: <ExclamationCircleOutlined />,
        // content: 'Deseja realmente fechar?',
        okText: "Sim",
        cancelText: "Não",
        onOk: closeDrawer,
      });
    } else {
      closeDrawer();
    }
  }

  function closeDrawer() {
    setHasChange(false);
    setOpen(false);
    form.resetFields();
    setFileList([]);
  }

  return (
    <OffCanvas
      title={<Title>Importar chassi</Title>}
      onClose={onClose}
      open={open}
      width={"378px"}
      footer={
        <Button
          disabled={!hasChange}
          loading={isLoadingFiles}
          onClick={sendFiles}
          type="primary"
        >
          Importar
        </Button>
      }
    >
      <Form layout={"vertical"} form={form}>
        <Form.Item name={"file_list"} label={"Arquivo"}>
          <DragAndDropUpload
            maxCount={1}
            typesAcceptFile={["xlsx"]}
            noLimitFileSize
            onRemove={(file) => {
              setFileList([...fileList.filter((f) => f.uid !== file.uid)]);
            }}
            beforeUpload={(file) => {
              setHasChange(true);
              setFileList([...fileList, file]);
              return false;
            }}
          />
        </Form.Item>
        <Info>
          Importe uma tabela do excel na formatação adequada, que contenha uma
          coluna com o nome “Chassis Id*” e outra com “PIN17*”. Não envie
          arquivos que não contenham essas duas colunas ou que as colunas
          estejam escritas de outra maneira.
        </Info>
      </Form>
    </OffCanvas>
  );
}
