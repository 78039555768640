import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import {text_color} from "../../Cores";
import OffCanvas from "../../components/Base/OffCanvas";


const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`


export default function AttendanceListOffCanvas({open, setOpen, oilId}) {


    const [oil, setOil] = useState({})
    const downlaodButton = useRef()
    // const {fetchData, isLoading} = useDefaultFetch()

    // useEffect(() => {
    //     if (open && oilId) {
    //         async function fetch() {
    //             const oilData = await fetchData(`oil_analysis/${oilId}`)
    //             setOil(oilData)
    //
    //         }
    //
    //         fetch()
    //     }
    // }, [open, oilId])


    function onClose() {

        closeDrawer()

    }

    function closeDrawer() {
        // setHasChange(false)
        setOpen(false)
    }


    return (
        <>
            <OffCanvas
                destroyOnClose
                title={
                    <Title level={4}>Lista de atendimentos</Title>

                }
                onClose={onClose}
                open={open}>

            </OffCanvas>

        </>
    )

}

