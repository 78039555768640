import React from 'react';
import {useMenu} from "../context/MenuContext";
import styled from "styled-components";
import {primary_color} from "../Cores";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;

  & > * {

    color: white;
  }

  & i, & svg {
    transition: .3s;
  }

  &:hover i {

    color: ${primary_color};

  }

  &:hover svg {
    fill: ${primary_color};
  }

  
`

export default function MenuButton() {
    const {hiddenMenu, handleMenu} = useMenu()


    return (
        <IconButton
            onClick={handleMenu}>
            {hiddenMenu ? <MenuUnfoldOutlined/> :
                <MenuFoldOutlined/>}
        </IconButton>
    )
}