import {useEffect, useState} from "react";
import {useUserContext} from "../context/UserContext";

export default function useActionPermission({permissions}) {
    const [hasPermission, setHasPermission] = useState(false)
    const {user} = useUserContext()

    useEffect(() => {

        if (user.profiles.some(
            (profile) => permissions.includes(profile)
        )) {
            setHasPermission(true)
        }
    }, [user])

    return {
        hasPermission
    }
}