import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Empty, Select as SelectAnt } from "antd";

import { BuildFieldErrorsDict } from "../../util/Utils";
import api from "../../services/api";
import { gray_2 } from "../../Cores";
import Avatar from "../Base/Avatar";

const StyledCloseOutline = styled(CloseOutlined)`
  color: white;
`;
const StyledDownOutlined = styled(DownOutlined)`
  color: white;
  transition: 0.1s;
  /* transform: translateY(-50%)
    rotate(${({ isActive }) => (isActive ? "0deg" : "-90deg")}) !important; */
`;
const ContainerOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //gap: 0px;/
`;

const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  //gap: 0px;/
`;
const CenterContainer = styled.span`
  font-size: 12px;
  color: ${gray_2};
  text-transform: uppercase;
  //margin-left: 24px;
`;

const { Option } = SelectAnt;

export default function SelectUser({
  label_key = "name",
  noPagination,
  ...props
}) {
  const [options, setOptions] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await api.get("user?is_active=true&page_size=10000");
      setOptions(
        response.data.results.map((item) => {
          return {
            value: item.id,
            label: item[`${label_key}`],
            picture: item.picture,
            centers_str: item.centers_str,
          };
        })
      );
      //
      // setOptions([
      //     {
      //         value: 2,
      //         label: 'user',
      //         picture: null
      //     },
      //     {
      //         value: 1,
      //         label: 'Macaco',
      //         picture: 'https://924234.smushcdn.com/2329743/wp-content/uploads/2021/12/erro-leva-usuario-a-vender-famoso-nft-10-do-preco-original.jpg?lossy=1&strip=1&webp=1'
      //     }
      // ])
    } catch (e) {
      BuildFieldErrorsDict(e, null);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <SelectAnt
      style={{ width: "100%" }}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Selecione"
      notFoundContent={<Empty />}
      suffixIcon={<StyledDownOutlined />}
      removeIcon={<StyledCloseOutline />}
      clearIcon={<StyledCloseOutline />}
      {...props}
    >
      {options.map((option) => {
        return (
          <Option
            key={"option" + option.value}
            value={option.value}
            label={option.label}
          >
            <ContainerOption>
              <ContainerLabel>
                <ContainerOption>
                  {/*{option.picture !== undefined &&*/}
                  {/*    <Avatar size={20} name={option.label} src={option.picture}/>}*/}
                  <span>{option.label}</span>
                </ContainerOption>
                <CenterContainer>{option.centers_str}</CenterContainer>
              </ContainerLabel>
            </ContainerOption>
          </Option>
        );
      })}
    </SelectAnt>
  );
}
