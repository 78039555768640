import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Row} from "antd";
import {TableSearch} from "../../components/Base/UPTable";
import File from "../../components/File";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../util/Utils";
import Loading from "../../components/Base/Loading";
import Empty from "../../components/Base/Empty";
import {useLocation} from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import {get_filter_params} from "../../util/TableStorageService";


const TabContainer = styled.div`
  padding-top: 24px;

`

export default function TabAttachment({opportunity, tabName, tabKey,...props}) {
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState(get_filter_params('search'))
    const querys = useQuery()
    const location = useLocation()

    useEffect(() => {
        if (opportunity.id && tabKey === querys.get(tabName)) {
            fetchFiles()
        }
    }, [opportunity, search,location])



    const fetchFiles = async () => {
        try {
            setIsLoading(true)
            //usar search
            // const response = await api.get(`opportunity/${opportunity.id}/files?search=${search}`);
            const response = await api.get(`opportunity/${opportunity.id}/files`);
            setFiles(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <TabContainer>
            <Row gutter={[24, 16]} align={'middle'}>
                <Col xs={24}>
                    <TableSearch size={'large'}
                                 allowClear
                                 onSearch={(value, e) => setSearch(value)}
                                 onPressEnter={(e) => setSearch(e.target.value)}
                                 placeholder={'Pesquise pelo nome do arquivo'}/>

                </Col>
            </Row>
            {isLoading ? <Loading noHeight/> :
                files.length === 0 ? <Empty style={{marginTop: 16,
                    height: 216
                }}/> : (
                    <Row gutter={[16, 8]} style={{marginTop: 16}}>
                        {files.map((file) =>

                            <Col lg={8} xs={24}>
                                <File file={file}/>
                            </Col>
                        )}
                    </Row>

                )}


        </TabContainer>

    )
}

