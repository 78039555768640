import React from 'react';
import PageTitle from "../../../components/Base/PageTitle";
import FingerScroll from "../../../components/Base/FingerScroll";
import InfoCard from "../../../components/Base/display/InfoCard";
import {maskIntNumber} from "../../../util/Utils";
import {Col, Divider} from "antd";
import {ListTimelineCard} from "../../../components/TimelineCard";
import OcurrenceCard from "../../../components/Opportunity/OccurrencesCard";
import RuleContainerInfo from "./RuleContainerInfo";
import PageText from "../../../components/Base/display/PageText";
import DividerPage from "../../../components/Base/DividerPage";
import {
    SYSTEM_CARETRACK_ID,
    SYSTEM_REMOTE_LOG_ID
} from "../../../util/OpportunityUtils";


export default function CodeErrorOpportunity({opportunity}) {
    const telemetric_data = opportunity.telemetric_data
    const telemetric_system= opportunity.telemetry_system


    return (<>
        <PageTitle value={'Dados da notificação'}/>
        <FingerScroll>
            {SYSTEM_CARETRACK_ID === telemetric_system.id && <>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.mid)}
                    title={'MID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.pid)}
                    title={'PID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.pid_type)}
                    title={'TIPO PID'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.fmi)}
                    title={'FMI'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(telemetric_data?.acd_number)}
                    title={'Número do ACD'}/>
            </>}
            {SYSTEM_REMOTE_LOG_ID === telemetric_system.id  && <>
                <InfoCard
                    width={212}
                    data={maskIntNumber(opportunity.content_object.spn)}
                    title={'SPN'}/>
                <InfoCard
                    width={212}
                    data={maskIntNumber(opportunity.content_object.fmi)}
                    title={'FMI'}/>

            </>}
        </FingerScroll>
        <Divider style={{marginTop: 16}}/>
        <Col xs={24} md={24}>
            <PageTitle value={'Descrição'}
                       style={{marginTop: 15}}/>
            {SYSTEM_CARETRACK_ID === telemetric_system.id && <PageText value={telemetric_data.description}/>}
            {SYSTEM_REMOTE_LOG_ID === telemetric_system.id && <PageText value={opportunity.content_object?.caution_description}/>}
            <PageTitle value={'Ocorrências'}
                       style={{marginTop: 24}}/>
            <ListTimelineCard>
                <OcurrenceCard event={opportunity.ocurrences}/>
            </ListTimelineCard>
        </Col>
        <DividerPage/>
        <RuleContainerInfo opportunity={opportunity}/>
    </>)
}