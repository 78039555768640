import { Steps } from "antd";
import styled from "styled-components";
import { text_color } from "../../Cores";

const StyledStep = styled(Steps)`
  margin-top: 16px;

  .ant-steps-item-title {
    color: ${text_color} !important;
  }

  .ant-steps-item-active {
    font-weight: bold;
  }
`;

const STEP_ITEMS = [
  {
    title: "Informações Iniciais",
  },
  {
    title: "Seleção de Peças",
  },
  {
    title: "Detalhes das Peças",
  },
  { title: "Resumo" },
];

export default function StepComponet({ currentStep }) {
  return (
    <StyledStep
      items={STEP_ITEMS}
      current={currentStep}
      labelPlacement="vertical"
    />
  );
}
