import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ShareLocation from "../ShareLocation";
import ArrowLink from "../ArrowLink";
import Loading from "../Loading";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import {dateFormat} from "../../../util/dates";
import HeaderModalMap from "../../ModalMap/HeaderModalMap";
import {ItemBodyModalMap} from "../../ModalMap/ItemBodyModalMap";
import {ModalMap} from "../../ModalMap/ModalMap";
import AlertPMP from "../../Opportunity/AlertPMP";
import {Tooltip} from "antd";
import {validateOpportunityType} from "../../../util/OpportunityUtils";


const Container = styled.div`
  height: 374px;
  overflow: hidden;

`
const ContentContainer = styled.div`
  height: 226px;

  overflow-y: scroll;

`


export default function NearOpportunityModalMaps({
                                                     isModalOpen,
                                                     opportunity,
                                                     setClose,
                                                     id
                                                 }) {
    const {postData, isLoading} = useDefaultFetch()
    const [nearOportunity, setNearOpportunity] = useState();

    const onClose = () => {
        setClose(false);
    }

    useEffect(() => {
        async function post() {
            setNearOpportunity(await postData(`opportunity/${id}/modal_information`, {
                latitude: opportunity.position.latitude,
                longitude: opportunity.position.longitude
            }))
        }

        id && post()
    }, [id])


    if (!nearOportunity) return null
    return (
        <ModalMap open={isModalOpen} onClose={onClose}>
            <MapCard onClose={onClose} isLoading={isLoading}
                     nearOportunity={nearOportunity}/>
        </ModalMap>
    );
}


function MapCard({onClose, isLoading, nearOportunity}) {


    return (
        <Container>
            {isLoading ? (<Loading/>) : (
                <>
                    <HeaderModalMap subtitle1={nearOportunity.client_name}
                                    subtitle2={`${nearOportunity.distance}Km`}
                                    title={nearOportunity.rule_name}
                                    onClose={onClose}
                                    image={nearOportunity.equipment_picture}/>

                    <ContentContainer>

                        <ItemBodyModalMap
                            label={'N° SÉRIE'}
                            icon={<i className="ri-barcode-box-line"/>}>
                            {nearOportunity.pin_17}
                        </ItemBodyModalMap>


                        <ItemBodyModalMap label={'LOCALIZAÇÃO'} icon={<i
                            className="ri-map-pin-2-line"/>} extraIcon={
                            <ShareLocation
                                lat={nearOportunity.position?.latitude}
                                long={nearOportunity.position?.longitude}/>}>
                            {nearOportunity.position.reverse_geocoding}
                        </ItemBodyModalMap>
                        {/*<ItemBodyModalMap label={'CLIENTE'}*/}
                        {/*                  icon={<i*/}
                        {/*                      className="ri-user-line"/>}>*/}
                        {/*    {nearOportunity.client_name}*/}
                        {/*</ItemBodyModalMap>*/}
                        <ItemBodyModalMap label={'CENTRO'}
                                          icon={<i
                                              className="ri-map-2-line"/>}>
                            {nearOportunity.center_name}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'PMP'}
                            // showTooltip={false}
                                          icon={<i
                                              className="ri-timer-line"/>}>
                            {nearOportunity.PMP ? `${dateFormat(nearOportunity.PMP.start_dt)} - ${dateFormat(nearOportunity.PMP.end_dt)} (${nearOportunity.PMP.contract_number})` : '-'}
                        </ItemBodyModalMap>
                        {validateOpportunityType(nearOportunity, 'Revisão programada') &&
                            <ItemBodyModalMap
                                label={'DATA ESTIMADA DA REVISÃO'}
                                icon={<i className="ri-community-line"/>}>
                                {dateFormat(nearOportunity.next_review_date)}
                            </ItemBodyModalMap>}
                        {validateOpportunityType(nearOportunity, 'Código de erro') &&
                            <ItemBodyModalMap label={'MID / PID / FMI'}
                                              showTooltip={false}
                                              icon={<i
                                                  className="ri-community-line"/>}>
                                <Tooltip
                                    title={nearOportunity.telemetric_data?.description}>{`${nearOportunity.telemetric_data?.mid} / ${nearOportunity.telemetric_data?.pid} / ${nearOportunity.telemetric_data?.fmi}`}</Tooltip>
                            </ItemBodyModalMap>}
                        {validateOpportunityType(nearOportunity, "Análise de óleo") && (<>
                            <ItemBodyModalMap
                                label={'DATA DO RESULTADO DA AMOSTRA'}
                                icon={<i
                                    className="ri-calendar-check-line"/>}>
                                {dateFormat(nearOportunity.result_date)}
                            </ItemBodyModalMap>
                            <ItemBodyModalMap
                                label={'CONDIÇÃO DA AMOSTRA'}
                                icon={<i className="ri-flask-line"/>}>
                                {nearOportunity.condition}
                            </ItemBodyModalMap>
                        </>)}
                        <ItemBodyModalMap>
                            <ArrowLink
                                href={`/opportunity/${nearOportunity.id}/detail?tab=1`}
                                label={'Visualizar notificação'}/></ItemBodyModalMap>
                    </ContentContainer>
                </>
            )}
        </Container>
    )
}

