import useForm from "../../../hooks/useForm";
import {Input} from 'antd';
import InputGroup from "./InputGroup";

const { TextArea } = Input;

export default ({setData, label, error, ...props}) => {
    const {onChange} = useForm()

    return (

        <InputGroup label={label} error={error}>
            <TextArea
                status={error && 'error'}
                onChange={(e) => onChange(e, setData)} {...props}/>
        </InputGroup>

    )
}