import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button, Checkbox, Input} from "antd";
import {content_bg, icon_color, primary_color} from "../../Cores";
import {BuildFieldErrorsDict} from "../../util/Utils";
import api from "../../services/api";
import Loading from "../Base/Loading";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {get_filter_params} from "../../util/TableStorageService";

const Container = styled.div`

  display: flex;
  flex-direction: column;
  background: #1B1D1D;
  border: 1px solid #565D62;
  border-radius: 2px;
  width: ${props => props.isPhone ? '100%' : '90%'};
  padding: 11px 12px 0px 12px;
`

const TransferContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.isPhone ? 'column' : 'row'};
  align-items: center;
`

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  width: 24px;
  height: 24px;
  background: #FDBA12;
  border: 1px solid #FDBA12;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: black;
  transform: rotate(${props => props.isMobile ? '+90deg' : '0'});
  margin: ${props => props.isMobile ? '10px 8px 10px 0' : '0 0 0 8px'};
    //visibility: ${props => props.onClick ? 'visible' : 'hidden'};
`

const TransferSearch = styled(Input.Search)`
  margin: 12px 0;
  width: auto;

  button {
    background: ${content_bg};
    border-color: ${icon_color};

    :hover {
      background: ${content_bg};

    }

    :hover span {
      color: ${primary_color};
    }

    svg {
      transition: .5s;
    }

    span {

      color: ${icon_color};
    }
  }
`

const ListItems = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: scroll;
  //padding: 0;
  margin: 0px -12px 0px;

  li {
    width: 100%;

  }

`

const StyledCheckbox = styled(Checkbox)`

  width: calc(100% + 24px);
  background: ${props => props.checked ? '#222425' : 'inherit'};
  padding: 6px 12px;

  :hover {
    background: #222425;
  }

  & > span:last-child {
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`

export function TransferButton({onClick, ...props}) {
    const {isPhone} = useSystemBreakpoint();
    return (
        <StyledButton onClick={onClick}> > </StyledButton>
    )
}


export function Transfer({
                             title,
                             onClick,
                             url,
                             setSelecteds,
                             initalSelecteds = [],
                             params = {},
                             needParams = true,
                             placeHolder,
                             ...props
                         }) {
    const [search, setSearch] = useState(get_filter_params('search'))
    const [selectedItens, setSelectedItens] = useState(initalSelecteds);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [markAll, setMarkAll] = useState(false);
    const {isPhone} = useSystemBreakpoint();
    const transferRef = useRef();
    useEffect(() => {
        options.length > 0 && setMarkAll(selectedItens.length === options.length)
    }, [selectedItens])

    function handleCheckAll(event) {
        let checked = event.target.checked
        setMarkAll(checked)
        let checkeds = []
        if (checked) {
            checkeds = options.map((op) => op.value)
        }
        setSelectedItens(checkeds)
    }

    const fechdata = async () => {
        try {
            setIsLoading(true)
            const response = await api.post(`${url}/list_post?page_size=1000`, params)
            let aux_selected = []
            const aux = response.data.map((item) => {
                if (selectedItens.includes(item.id)) {
                    aux_selected.push(item.id)
                }
                return {
                    value: item.id,
                    label: item.name,
                }
            })
            setOptions(aux)
            setSelectedItens(aux_selected)
        } catch (error) {
            BuildFieldErrorsDict(error, null, 'Erro ao consultar dados')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!needParams && url) {
            fechdata()
        }
    }, [])

    useEffect(() => {
        if (needParams) {
            let aux = Object.keys(params)
            if (aux.length !== 0) {
                if (params[`${aux[0]}`].length !== 0) {
                    fechdata()
                } else {
                    setOptions([])
                    setSelectedItens([])
                }
            }
        }
    }, [params])

    useEffect(() => {
        setSelecteds && setSelecteds(selectedItens)
    }, [selectedItens])

    function onChangeSelectionItem(e) {
        let aux = selectedItens
        if (e.target.checked) {
            aux.push(e.target.value)
        } else {
            const index = selectedItens.indexOf(e.target.value);
            aux.splice(index, 1);
        }
        setSelectedItens([...aux]);
    }

    const getTransferHeigth = () => {
        let distance_table_2_botton = 100
        if (isPhone) {
            return 400
        }
        let distance_table_2_top = transferRef?.current?.getBoundingClientRect().top
        let height = window.innerHeight - distance_table_2_top - distance_table_2_botton
        if (height < 400) {
            return 400
        }
        return height
    }

    return (
        <TransferContainer isPhone={isPhone}>
            <Container isPhone={isPhone} ref={transferRef}
                       style={{height: getTransferHeigth()}} {...props}>
                <Checkbox checked={markAll}
                          onChange={handleCheckAll}>
                    {title}
                </Checkbox>
                {placeHolder && <TransferSearch size={'small'}
                                                allowClear
                                                defaultValue={search}
                                                onSearch={(value, e) => setSearch(value)}
                                                placeholder={placeHolder}/>}
                {isLoading ? <Loading/> : <ListItems>
                    {options.filter((item) => {
                        return item.label.toLowerCase().includes(search.toLowerCase()) || selectedItens.includes(item.value)
                    }).map((item) => {
                        return <li title={item.label}>

                            <StyledCheckbox checked={selectedItens.includes(item.value)}
                                            value={item.value}
                                            onChange={onChangeSelectionItem}>{item.label}</StyledCheckbox>

                        </li>
                    })}
                </ListItems>}
            </Container>
            {onClick && <StyledButton isMobile={isPhone}
                                      onClick={onClick}> > </StyledButton>}
        </TransferContainer>

    )
}