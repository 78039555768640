import React from "react";
import styled from "styled-components";
import useSystemBreakpoint from "../hooks/useSystemBreakpoint";


const Container = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;

  ${({isPhone}) => {
    if (isPhone) {
      return `
                 width: calc(100% + 32px);
                margin-left: -16px;
          `
    }
  }}
`

export default function ContainerWithoutLimit({children, ...props}) {
      const {isPhone} = useSystemBreakpoint()
    return (<Container isPhone={isPhone} {...props}>
        {children}
    </Container>)
}