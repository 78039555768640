import React from 'react'
import Collapse from "./Collapse";
import styled from 'styled-components';
import {
    neutral_4, red_10, red_5,
    success_bg, success_color, table_header
} from "../Cores";

const ContainerList = styled.div`
  background: ${table_header};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const TitleContainer = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  text-transform: capitalize;
`

const StyledCollapse = styled(Collapse)`

  & .ant-collapse-item-active .ant-collapse-header {
    border-bottom: 1px solid ${neutral_4};
  }

  & .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-item-disabled .ant-collapse-header{ 
    padding-right: 16px;
    cursor: default;
  }
  & .ant-collapse-item-disabled svg{
    display: none;
  }
`

const AmountInfoContainer = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;
`


const colors = [
    {
        type: 'success',
        borderColor: success_color,
        color: success_color,
        backgroundColor: success_bg
    },
    {
        type: 'alert',
        borderColor: red_5,
        color: red_5,
        backgroundColor: red_10
    },
]

export default function StatusInfo({item}) {

    const totalAmount = item.items.length
    const activeds = item.items.filter(({is_serving}) => is_serving).length

    return (
        <StyledCollapse collapsible="disabled"
            title={<TitleContainer><span>{item.name}</span> <AmountInfo
                type={activeds < totalAmount ? 'alert' : 'success'}>{activeds}/{totalAmount}</AmountInfo></TitleContainer>}
            defaultActiveKey={['1']}>
            <ContainerList>
                {item.items.map(({description, is_serving}) => (
                    <TitleContainer><span>{description}</span>
                        <AmountInfo type={is_serving ?  'success':'alert' }>{is_serving? 'ok': 'INDISPONÍVEL'}</AmountInfo></TitleContainer>
                ))}


            </ContainerList>
        </StyledCollapse>
    )
}

function AmountInfo({type = 'success', children}) {
    return (
        <AmountInfoContainer {...colors.find((color) => type === color.type)}>
            {children}
        </AmountInfoContainer>
    )
}