import React from "react";
import { Spin } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ noHeight }) => {
    if (!noHeight) {
      return `
            height: calc(100vh - 130px);
          `;
    }
    return `
        padding: 24px 0;
      `;
  }}
`;

export default function Loading({ noHeight = false, ...props }) {
  return (
    <Container noHeight={noHeight}>
      <Spin size={"large"} {...props} />
    </Container>
  );
}
