import { useEffect, useState } from "react";
import StaticUpTable, {
  TableSearch,
} from "../../../components/Base/StaticUPTable";

export default function StoppedEquipmentsTable({
  options,
  data,
  isLoading,
  stoppedEquipmentSearch,
  setStoppedEquipmentSearch,
  ...props
}) {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.columnKey && sorter.columnKey !== "hourmeter") {
      setTableData(
        data.sort((a, b) =>
          sorter.order === "ascend"
            ? a[sorter.columnKey].toLowerCase() >
              b[sorter.columnKey].toLowerCase()
            : a[sorter.columnKey].toLowerCase() <
              b[sorter.columnKey].toLowerCase()
        )
      );
    } else if (sorter.columnKey && sorter.columnKey === "hourmeter") {
      setTableData(
        data.sort((a, b) =>
          sorter.order === "ascend"
            ? a[sorter.columnKey] - b[sorter.columnKey]
            : b[sorter.columnKey] - a[sorter.columnKey]
        )
      );
    }
  };

  useEffect(() => {
    if (stoppedEquipmentSearch) {
      setTableData(
        data.filter(
          (item) =>
            item.center.toLowerCase().includes(stoppedEquipmentSearch.toLowerCase()) ||
            item.client.toLowerCase().includes(stoppedEquipmentSearch.toLowerCase()) ||
            item.serial_number.toLowerCase().includes(stoppedEquipmentSearch.toLowerCase())
        )
      );
    } else {
      setTableData(data);
    }
  }, [stoppedEquipmentSearch]);

  if (isLoading) return;

  const columns = [
    {
      title: "Centro",
      dataIndex: "center",
      key: "center",

      sorter: () => handleChange,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",

      sorter: () => handleChange,
    },
    {
      title: "Equipamento",
      dataIndex: "serial_number",
      key: "serial_number",

      sorter: () => handleChange,
    },
    {
      title: "OS",
      dataIndex: "os",
      key: "os",

      sorter: () => handleChange,
    },
    {
      title: "Horímetro",
      dataIndex: "hourmeter",
      key: "hourmeter",

      sorter: () => handleChange,
    },
  ];

  return (
    <>
      <TableSearch
        size={"large"}
        allowClear
        onSearch={(value) => setStoppedEquipmentSearch(value)}
        onPressEnter={(e) => setStoppedEquipmentSearch(e.target.value)}
        placeholder={"Pesquise por Centro, Cliente ou Equipamento"}
      />
      <StaticUpTable
        onChange={handleChange}
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        scroll={{
          y: 350,
        }}
        {...props}
      />
    </>
  );
}
