import styled from "styled-components";
import {primary_color, text_color} from "../Cores";

const Ellipse = `
 white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 800px;
  width: 100%;
`
export const EquipementInfo = styled.div`
  margin-top: 96px;
  margin-left: auto;


  @media screen and (max-width: 992px) {
    margin-top: 0px;
    margin-left: 16px;
  }

`

export const Chassi = styled.span`
height: 18px;
  font-size: 12px;
  color: ${primary_color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${Ellipse};
  text-transform: uppercase;
`

export const Name = styled.span`
  font-weight: 700;
  font-size: 20px;
  ${Ellipse};
  color: ${primary_color};
  text-transform: uppercase;
`
export const Brand = styled.span`
  ${Ellipse};
  color: ${text_color};
  font-size: 12px;
`