import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

import styled from "styled-components";
import { gray_2, text_color } from "../../../Cores";
import OffCanvas from "../../Base/OffCanvas";
import Collapse from "../../Collapse";
import api from "../../../services/api";
import {
  BuildFieldErrorsDict,
  buildFormDataToFilter,
} from "../../../util/Utils";

import RangeDate from "../../RangeDate";
import RadioButton from "../../Base/Forms/RadioButton";
import { set_filter_params } from "../../../util/TableStorageService";
import moment from "moment-timezone";
import CheckboxOffCanvas from "../../FiltersOffCanvas/CheckboxOffCanvas";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;
const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  //gap: 0px;/
`;

const ContainerOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //gap: 0px;/
`;
const CenterContainer = styled.span`
  font-size: 12px;
  color: ${gray_2};
  text-transform: uppercase;
  //margin-left: 24px;
`;

const ItemLabel = styled.span`
  font-size: 12px;
  color: ${gray_2};
  text-transform: uppercase;
  //margin-left: 24px;
`;

const RESPOSIBLE_FORM_NAME = "opportunities_resposibles__responsible_id__in";
const NEXT_REVIEW_FORM_NAME = "next_review_date__range";
const RULE_FORM_NAME = "rule__priority__id__in";
const BRAND_FORM_NAME = "brand_equipment_id__in";
const CREATE_DATE_FORM_NAME = "created_at__range";
const UPDATE_DATE_FORM_NAME = "updated_at__range";

export default function FilterOpportunityOffCanvas({
  open,
  typeTable,
  setOpen,
  initialFormFilter,
  setFormFilter,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [priority, setPriority] = useState([]);
  const [status, setStatus] = useState([]);
  const [brands, setBrands] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [form] = Form.useForm();

  function onReset() {
    form.resetFields();
    form.setFieldsValue(initformvalues());
  }

  function initformvalues() {
    let range_date = initialFormFilter[NEXT_REVIEW_FORM_NAME];
    let create_range_date = initialFormFilter[CREATE_DATE_FORM_NAME];
    let update_range_date = initialFormFilter[UPDATE_DATE_FORM_NAME];

    let aux = initialFormFilter;
    // aux[BRAND_FORM_NAME] = brands.map((item) => item.id)

    if (range_date) {
      aux[NEXT_REVIEW_FORM_NAME] = [
        moment(initialFormFilter[NEXT_REVIEW_FORM_NAME][0]),
        moment(initialFormFilter[NEXT_REVIEW_FORM_NAME][1]),
      ];
    }
    if (create_range_date) {
      aux[CREATE_DATE_FORM_NAME] = [
        moment(initialFormFilter[CREATE_DATE_FORM_NAME][0]),
        moment(initialFormFilter[CREATE_DATE_FORM_NAME][1]),
      ];
    }
    if (update_range_date) {
      aux[UPDATE_DATE_FORM_NAME] = [
        moment(initialFormFilter[UPDATE_DATE_FORM_NAME][0]),
        moment(initialFormFilter[UPDATE_DATE_FORM_NAME][1]),
      ];
    }
    return aux;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const responseOpportunity = await api.get(
          "opportunity_status?type=all"
        );
        setStatus(responseOpportunity.data);
        const responseBrand = await api.get("brand_equipment?page_size=1000");
        setBrands(responseBrand.data.results);
        const responseUser = await api.get("user?page_size=10000");
        setResponsibles(responseUser.data.results);

        const responseRule = await api.get("rule_priority?page_size=1000");
        setPriority(responseRule.data.results);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchData();
    form.setFieldsValue(initformvalues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responsibles.length) {
      // onReset()
      let values = form.getFieldsValue(true);
      // REMOVER TODOS OS FILTROS ESPECIFICOS DE UM TIPO DE TABELA
      delete values[NEXT_REVIEW_FORM_NAME];
      delete values[RULE_FORM_NAME];
      let responsibleFilter = values[RESPOSIBLE_FORM_NAME];
      if (responsibleFilter === undefined || responsibleFilter.length === 0) {
        delete values[RESPOSIBLE_FORM_NAME];
      }

      setFormFilter(buildFormDataToFilter(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeTable]);

  async function onSubmit() {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
      console.log({ values });

      let responsibleFilter = values[RESPOSIBLE_FORM_NAME];
      if (responsibleFilter === undefined || responsibleFilter.length === 0) {
        delete values[RESPOSIBLE_FORM_NAME];
      }

      if (typeTable === "Renovação de PMP") {
        values["equipment__pmps__isnull"] = true;
      }
      if (typeTable === "Novos contratos de PMP") {
        values["equipment__pmps__isnull"] = false;
      }
      let formData = buildFormDataToFilter(values);
      set_filter_params("formFilter", values);
      setFormFilter(formData);
      setOpen(false);
      // Notification('success', 'Filtro atualizado com sucesso.')
    } catch (error) {
      console.log(error);
      // return;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <OffCanvas
      destroyOnClose
      title={<Title level={4}>Filtrar notificações</Title>}
      // onClose={onClose}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      width={"394px"}
      footer={
        <>
          <Button onClick={onReset}>Redefinir filtros</Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Filtrar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onValuesChange={() => {
          // onFormChange()
        }}
      >
        {/*{isLoading ? <Loading/> :*/}
        {typeTable === "Revisão programada" && (
          <Collapse title={"DATA REVISÃO (EST.)"} defaultActiveKey={["0"]}>
            <Form.Item noStyle name="next_review_date__range">
              <RangeDate />
            </Form.Item>
          </Collapse>
        )}

        {typeTable === "Código de erro" && (
          <CheckboxOffCanvas
            defaultActiveKey={["0"]}
            collapseTitle={"SEVERIDADE"}
            form={form}
            items={priority}
            initialValue={[1, 2, 3]}
            itemName={RULE_FORM_NAME}
          />
        )}

        <CheckboxOffCanvas
          defaultActiveKey={["0"]}
          collapseTitle={"marca"}
          form={form}
          items={brands}
          initialValue={brands.map((item) => item.id)}
          hasSearch
          itemName={BRAND_FORM_NAME}
          placeholder={"Pesquise pela marca"}
        />

        <CheckboxOffCanvas
          defaultActiveKey={["0"]}
          collapseTitle={"STATUS"}
          form={form}
          items={status}
          itemName={"status_id__in"}
        />
        <CheckboxOffCanvas
          defaultActiveKey={["0"]}
          collapseTitle={"RESPONSÁVEL"}
          form={form}
          hasSearch
          align={"center"}
          items={responsibles}
          labelLookup={"get_full_name"}
          itemName={RESPOSIBLE_FORM_NAME}
          placeholder={"Pesquise pelo responsável"}
          renderContent={({ label, object, ...options }, index) => (
            <ContainerLabel>
              <ContainerOption>
                <span>{label}</span>
              </ContainerOption>
              <CenterContainer>{object.centers_str}</CenterContainer>
            </ContainerLabel>
          )}
        />
        <Collapse title={"PMP"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="equipment__pmps__isnull">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Possui PMP" },
                  { value: false, label: "Não possui PMP" },
                ]}
                disabled={
                  typeTable === "Renovação de PMP" ||
                  typeTable === "Novos contratos de PMP"
                }
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"GARANTIA"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="equipment__warranty_date__isnull">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: false, label: "Possui Garantia" },
                  { value: true, label: "Não possui Garantia" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"DATA"} defaultActiveKey={["0"]}>
          <Container>
            <ItemLabel>Criado em</ItemLabel>
            <Form.Item noStyle name="created_at__range">
              <RangeDate />
            </Form.Item>
            <ItemLabel>Atualizado em</ItemLabel>
            <Form.Item noStyle name="updated_at__range">
              <RangeDate />
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"GRANDES CONTAS"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="big_accounts">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
      </Form>
    </OffCanvas>
  );
}
