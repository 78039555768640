import React, {useEffect, useState} from 'react'
import {dateFormat, getNameDay, listSeparetedByDate} from "../util/dates";
import PageTitle from "./Base/PageTitle";

import TimelineCard, {ListTimelineCard} from "./TimelineCard";

export default function Timeline({data}) {

    const [formatedDate, setFormatedDate] = useState([]);
    useEffect(() => {

        setFormatedDate(listSeparetedByDate(data))
    }, [])
    return (
        <>
            {formatedDate.map((itens) => (<TimeList values={itens}/>))}
        </>
    )
}

function TimeList({values}) {
    const title = values[0]
    return <div>
        <PageTitle value={title}/>
        <ListTimelineCard>
            {values.filter((item, index) => index !== 0).map((item) => (
                <TimelineCard timeline={item}/>))}
        </ListTimelineCard>
    </div>
}