import React, {useEffect, useState} from 'react';

import PageTitle from "../Base/PageTitle";
import styled from "styled-components";
import {border_gray} from "../../Cores";
import CardNotificationOpportunity from "./CardNotificationOpportunity";
import Loading from "../Base/Loading";
import ContainerWithoutLimit from "../ContainerWithoutLimit";
import Empty from "../Base/Empty";
import {dateFormat, listSeparetedByDate} from "../../util/dates";
import {useNavigate} from "react-router-dom";
import useDefaultFetch from "../../hooks/useDefaultFetch";


export const ListNotificationCards = styled.div`
  display: flex;
  flex-direction: column;


  & > * {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${border_gray};
    border-top: 1px solid ${border_gray};
  }


  & > *:first-child {
    padding-top: 16px;
  }

  & > *:last-child {
    padding-top: 16px;
    //padding-bottom: 16px;
    border-bottom: unset;
  }
`

export default function ListNotificationOffCanvas({
                                                      decrementAmoutNotification,
                                                      decrementAmountTab,
                                                      datas,
                                                      isLoading
                                                  }) {

    const [formatedData, setFormatedData] = useState([])
    useEffect(() => {

        if (datas.length) {

            setFormatedData(listSeparetedByDate(datas))
        }
    }, [datas])
    return (
        <div style={{paddingTop: 24}}>
            {isLoading ? (<Loading/>) : (
                datas.length === 0 ? (
                    <Empty text={'Não há notificações disponíves'}/>) : (
                    formatedData.map((itens) => (
                        <FomatedList
                            decrementAmoutNotification={decrementAmoutNotification}
                            decrementAmountTab={decrementAmountTab}
                            values={itens}/>))))}
        </div>
    )
}


function FomatedList({
                         values,
                         decrementAmoutNotification,
                         decrementAmountTab
                     }) {
    const [_values, _setValues] = useState(values)
    const title = _values[0]
    const {patchData} = useDefaultFetch()
    const navigate = useNavigate()

    async function handleClick(item) {
        navigate(`opportunity/${item.content_object.id}/detail?tab=1`)
        if (!item.read) {
            const newValues = _values.map((value) => {
                if (value.id && value.id === item.id) {
                    return {...value, read: true}
                }
                return value
            })
            _setValues(newValues)
            decrementAmoutNotification()
            decrementAmountTab()
            await patchData(`notification/${item.id}`, {'read': true})
        }
    }

    return <div style={{marginBottom: 16}}>
        <PageTitle style={{marginBottom: 16}} value={title}/>
        <ContainerWithoutLimit>
            <ListNotificationCards>
                {_values.filter((item, index) => index !== 0).map((item) => (
                    <CardNotificationOpportunity
                        onClick={() => handleClick(item)}
                        notification={item}/>))}
            </ListNotificationCards>
        </ContainerWithoutLimit>
    </div>
}
