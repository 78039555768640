import React from 'react';
import OffCanvas from "../Base/OffCanvas";
import ProfileDisplay from "./ProfileDisplay";
import ProfileImage from "./ProfileImage";
export default function UserProfileOffCanvas({user, open, setOpen}) {

    // const [form] = Form.useForm();
    //
    // const [editMode, setEditMode] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)
    // const [hasChange, setHasChange] = useState(false)
    if(!user) return null
    function onClose() {
        // if (hasChange) {
        //     Modal.confirm({
        //
        //         title: 'Deseja realmente sair?',
        //         content: 'Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.',
        //         icon: <ExclamationCircleOutlined/>,
        //         // content: 'Deseja realmente fechar?',
        //         okText: 'Sim',
        //         cancelText: 'Cancelar',
        //         onOk: () => {
        //             closeDrawer()
        //             form.resetFields()
        //         }
        //     });
        // } else {
        closeDrawer()
        // }
    }

    function closeDrawer() {

        // setEditMode(false)
        setOpen(false)


    }

    // const onSubmit = async () => {
    //     setIsLoading(true)
    //     let values;
    //     try {
    //         values = await form.validateFields()
    //
    //     } catch (error) {
    //         setIsLoading(false)
    //         return;
    //     }
    //     try {
    //         const indexFirstSpace = values.get_full_name.indexOf(' ')
    //
    //         const data = {
    //             phone: values.phone,
    //             first_name: values.get_full_name.split(' ')[0],
    //             last_name: values.get_full_name.slice(indexFirstSpace)
    //         }
    //         const response = await api.patch(`user/my_user`, data);
    //         setUser(response.data)
    //         Notification('success', 'Perfil atualizado com sucesso.')
    //         closeDrawer()
    //     } catch (error) {
    //
    //         BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
    //     } finally {
    //         setIsLoading(false)
    //     }
    //
    // }
    //

    return (
        <OffCanvas
            destroyOnClose
            title={
                <ProfileImage
                    id={user.id}
                    src={'#'}
                    name={user.get_full_name}
                />

            }
            onClose={onClose}
            open={open} width={'520px'}
            // footer={
            //     <>
            //         {editMode ? (
            //             <Button onClick={onSubmit} type="primary"
            //                     loading={isLoading}>Salvar</Button>
            //         ) : (<Button onClick={() => setEditMode(true)}
            //                      type="primary">Editar perfil</Button>)}
            //     </>}
        >
            {/*{editMode ? (*/}
            {/*    <ProfileForm initialValues={user} user={user} form={form}*/}
            {/*                 onChange={() => setHasChange(true)}/>*/}
            {/*) : (*/}
            <ProfileDisplay user={user}/>
            {/*)}*/}

        </OffCanvas>
    )

}

