import useForm from "../../../hooks/useForm";
import { Input, Radio, Space } from "antd";
import InputGroup from "./InputGroup";
import styled from "styled-components";
import { neutral_5 } from "../../../Cores";

const { Group } = Radio;

const StyledGroup = styled(Group)`
  display: block;
`;
const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper-disabled span {
    color: ${neutral_5};
  }
`;

export default ({
  setData,
  label,
  error,
  name,
  options,
  direction = "vertical",
  ...props
}) => {
  const { onChangeRadio } = useForm();

  return (
    <InputGroup label={label} error={error}>
      <StyledGroup
        name={name}
        onChange={(e) => {
          console.log({ e });
          onChangeRadio(e, setData);
        }}
        {...props}
      >
        <Space direction={direction}>
          {options.map(({ label, ...options }, index) => (
            <StyledRadio key={`${name}-${label}-${index}`} {...options}>
              {label}
            </StyledRadio>
          ))}
        </Space>
      </StyledGroup>
    </InputGroup>
  );
};
