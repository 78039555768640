import React from "react";
import { GenericTelemetricTable } from "./GenericTelemetricTable";
import { dateFormat } from "../../../util/dates";

export default function TelemetricKalmarTable({ search }) {
  const columns = [
    {
      title: "N° Série/PID17",
      dataIndex: "equipment_serial_number",
      key: "equipment_serial_number",
      width: "140px",
    },
    {
      title: "Cliente",
      dataIndex: "client_name",
      key: "client_name",
      width: "185px",
      ellipsis: true,
    },
    {
      title: "SPN",
      dataIndex: "alert_metadata__spn",
      key: "alert_metadata__spn",
      width: "75px",
      ellipsis: true,
      render: (_, record) => <span>{record.alert_metadata.spn}</span>,
    },
    {
      title: "FMI",
      dataIndex: "alert_metadata__fmi",
      key: "alert_metadata__fmi",
      width: "50px",
      ellipsis: true,
      render: (_, record) => <span>{record.alert_metadata.fmi}</span>,
    },
    {
      title: "Criação",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "140px",
      render: (value) => dateFormat(value, "DD/MM/YYYY"),
    },
    {
      title: "Descrição",
      dataIndex: "alert_metadata__descriptionCustomer",
      key: "alert_metadata__descriptionCustomer",
      width: "485px",
      ellipsis: true,
      render: (_, record) => (
        <span>{record.alert_metadata.descriptionCustomer}</span>
      ),
    },
  ];

  return (
    <GenericTelemetricTable
      search={search}
      url={"kalmar/alerts"}
      columns={columns}
    />
  );
}
