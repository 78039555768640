import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, InputNumber } from "antd";
import {
  Autocomplete,
  DistanceMatrixService,
  useLoadScript,
} from "@react-google-maps/api";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import DisplayInfo from "../../components/Base/DisplayInfo";
import PlaceAutocomplete from "../../components/Base/Maps/PlaceAutocomplete";
import PMPTabMap from "../../components/Base/Maps/PMPTabMap";
import Select from "../../components/Base/Select";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import DateInput from "../../components/Base/Forms/DateInput";
import Collapse from "../../components/Collapse";
import PageTitle from "../../components/Base/PageTitle";
import UpTable from "../../components/Base/UPTable";
import useTable from "../../hooks/useTable";
import ChooseRevisionTypeModal from "./modals/ChooseRevisionModal";
import api from "../../services/api";
import { Notification } from "../../notification";
import { GOOGLE_MAPS_KEY } from "../../settings";
import {
  actionbar_bg,
  content_bg,
  gray,
  input_border,
  primary_1,
  red_5,
} from "../../Cores";

const TabContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 20%;
  background-color: ${actionbar_bg};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: ${content_bg};
  flex-grow: 0.8;

  .distance-options,
  .quotation-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const StyledTitle = styled.h1`
  color: rgba(255, 255, 255, 0.85);
  font-size: 18px;
  font-weight: 500;
`;

const StyledLabel = styled.h2`
  color: ${gray};
  font-size: 12px;
  white-space: nowrap;
  text-transform: ${({ isUpper }) => (isUpper ? "uppercase" : "none")};
  margin: 0;
`;

const StyledLine = styled.li`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 10%;

  span,
  div:first-child {
    flex: 1;
  }
`;

const StyledPosition = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;

  gap: 0.5rem;

  h4 {
    margin-bottom: 0;
    height: fit-content;
  }

  .auto-complete {
    width: 20rem;
  }
`;

const StyledError = styled.span`
  display: inline-block;
  width: 100%;

  font-size: 0.75rem;

  color: ${red_5};
`;

const StyledDateInput = styled(DateInput)`
  border-color: ${input_border};
  background-color: ${primary_1};
`;

const StyledRequired = styled.span`
  color: ${red_5};
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  margin-right: 0.25rem;
`;

const freightOptions = [
  {
    label: "Frete CIF",
    value: "CIF",
  },
  {
    label: "Frete FOB",
    value: "FOB",
  },
];

export default function TabPMP({ equipment, ...props }) {
  const columns = [
    {
      title: "Número da Cotação PMP",
      dataIndex: "pmp_number",
      sorter: "pmp_number",
      key: "pmp_number",
      width: "75px",
      ellipsis: true,
    },
    {
      title: "Arquivo da Cotação PMP",
      dataIndex: "pdf_url",
      sorter: "pdf_url",
      key: "pdf_url",
      width: "75px",
      ellipsis: true,
      render: (pdf_url) => (
        <a href={pdf_url} download target="_blank">
          <Button type="primary">Download</Button>
        </a>
      ),
    },
  ];

  const { isLoading, tableParams, handleTableChange, tableData, setTableData } =
    useTable({
      useHandleRequestTable: false,
    });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ["places", "geometry", "directions"],
  });

  const [revisions, setRevisions] = useState([]);
  const [revisionInput, setRevisionInput] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [originCoords, setOriginCoords] = useState({
    lat: 0,
    lng: 0,
  });
  const [destinationCoords, setDestinationCoords] = useState({
    lat: 0,
    lng: 0,
  });
  const [distance, setDistance] = useState(0);
  const [openHistory, setOpenHistory] = useState();
  const [form, setForm] = useState({
    freight_option: "",
    payment_option: "",
    start_date: "",
    end_date: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentConditions, setPaymentConditions] = useState([]);
  const [materialGroups, setMaterialGroups] = useState([]);
  const [revisionIds, setRevisionIds] = useState([]);
  const [isQuotationBeingCreated, setIsQuotationBeingCreated] = useState(false);
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const materialGroupsResponse = await api.get("/pmp_material_group");
        const paymentConditionsResponse = await api.get(
          "/pmp_payment_condition"
        );
        const pmpHistoryResponse = await api.get(
          `/equipment/${equipment.id}/pmp_quotation_history`
        );
        setPaymentConditions(paymentConditionsResponse.data);
        setMaterialGroups(materialGroupsResponse.data);
        setTableData(pmpHistoryResponse.data);
      } catch (error) {
        console.log(error.response.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const autocomplete = new Autocomplete({
      inputField: inputRef.current,
      fields: ["place_id", "name"],
    });
    autocompleteRef.current = autocomplete;
  }, []);

  const sendData = async () => {
    try {
      setIsQuotationBeingCreated(true);
      await api.post(`/equipment/${equipment.id}/create_pmp`, {
        Deslocamento: Number(distance.toFixed(0)),
        Incoterms_1: form.freight_option,
        Cond_Pagamento: form.payment_option,
        Inicio_Contrato: form.start_date,
        Fim_Contrato: form.end_date,
        Grupo_Materiais: form.material_group,
        revision_ids: revisionIds,
      });
      Notification("success", "Solicitação de cotação enviada com sucesso.");
    } catch (error) {
      Notification("error", "Erro ao criar cotação.");
    } finally {
      setIsQuotationBeingCreated(false);
      const pmpHistoryResponse = await api.get(
        `/equipment/${equipment.id}/pmp_quotation_history`
      );
      setTableData(pmpHistoryResponse.data);
    }
  };

  const handleRevisionInput = (e) => {
    setRevisionInput(e);
  };

  const handleRevisionAdd = () => {
    if (Number(revisionInput) % 250 !== 0) {
      setError(true);
      setErrorMessage("A revisão deve ser múltipla de 250");
      return;
    } else if (Number(revisionInput) === 0) {
      setError(true);
      setErrorMessage("A revisão não pode ser 0");
      return;
    } else if (revisions.includes(revisionInput)) {
      setError(true);
      setErrorMessage("A revisão já foi adicionada");
      return;
    }

    setIsModalOpen(true);

    setError(false);
    setErrorMessage("");
  };

  const handleRevisionRemove = (index) => {
    setRevisions(revisions.filter((_, i) => i !== index));
  };

  const handleDistance = (distanceResponse) => {
    const distanceInMeters =
      distanceResponse?.rows?.[0]?.elements?.[0]?.distance?.value;

    if (!distanceInMeters) {
      setDistance(0);
      return;
    }

    const distanceInKms = (distanceInMeters * 2) / 1000;

    if (distanceInKms) {
      setDistance(distanceInKms);
    } else {
      setDistance(0);
    }
  };

  const formatValue = (value) => {
    if (value === "") {
      return value;
    }
    return value.replace(/[^0-9]/g, "");
  };

  const parseValue = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  const getCoords = () => {
    const coords = [originCoords, destinationCoords];
    return coords.filter(({ lat, lng }) => lat !== 0 && lng !== 0);
  };

  const isButtonDisabled = () => {
    return (
      !form.freight_option ||
      !form.payment_option ||
      !form.start_date ||
      !form.end_date ||
      !originCoords ||
      !destinationCoords ||
      !distance ||
      revisions.length === 0 ||
      isQuotationBeingCreated
    );
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <ContainerWithoutLimit style={{ backgroundColor: `${actionbar_bg}` }}>
      <TabContainer>
        <Row>
          <StyledTitle>Selecione a revisão</StyledTitle>
          <StyledLabel isUpper>
            <StyledRequired>*</StyledRequired>Revisões
          </StyledLabel>
          <ul>
            {revisions.length > 0 &&
              revisions.map((item, index) => {
                return (
                  <StyledLine>
                    <DisplayInfo>
                      {item.hours} - {item.value}
                    </DisplayInfo>
                    <CloseOutlined
                      onClick={() => handleRevisionRemove(index)}
                    />
                    <div />
                  </StyledLine>
                );
              })}
          </ul>
          <div style={{ display: "inline" }}>
            <InputNumber
              controls={false}
              formatter={(value) => formatValue(value)}
              onChange={handleRevisionInput}
              parser={(value) => parseValue(value)}
              status={error && "error"}
              style={{
                marginRight: "0.25rem",
              }}
              value={revisionInput}
            />
            <Button onClick={handleRevisionAdd} style={{ width: "2.5rem" }}>
              +
            </Button>
            {errorMessage && <StyledError>{errorMessage}</StyledError>}
          </div>
        </Row>
        <Row style={{ gap: "2rem" }}>
          <StyledTitle>Dados para cotação</StyledTitle>
          <div
            className="distance-options"
            style={{ justifyContent: "space-between" }}
          >
            <DistanceMatrixService
              options={{
                destinations: [destinationCoords],
                origins: [originCoords],
                travelMode: "DRIVING",
                language: "en",
              }}
              callback={(response) => handleDistance(response)}
            />
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Distância
              </StyledLabel>
              <p style={{ marginTop: "0.25rem" }} key={distance}>
                {new Intl.NumberFormat("pt-BR", {
                  maximumFractionDigits: 2,
                }).format(distance)}{" "}
                km (ida e volta)
              </p>
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Origem
              </StyledLabel>
              <PlaceAutocomplete
                setCoords={setOriginCoords}
                coordRef={originRef}
              />
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Destino
              </StyledLabel>
              <PlaceAutocomplete
                setCoords={setDestinationCoords}
                coordRef={destinationRef}
              />
            </StyledPosition>
          </div>
          <div
            className="quotation-options"
            style={{ justifyContent: "space-between" }}
          >
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Tipo de Frete
              </StyledLabel>
              <Select
                style={{ width: "12rem" }}
                options={freightOptions}
                onChange={(value) =>
                  setForm((prev) => ({ ...prev, freight_option: value }))
                }
              />
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Condições de Pagamento
              </StyledLabel>
              <Select
                style={{ width: "12rem" }}
                options={paymentConditions}
                label_key="description"
                onChange={(value) =>
                  setForm((prev) => ({ ...prev, payment_option: value }))
                }
              />
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Grupo de Materiais
              </StyledLabel>
              <Select
                style={{ width: "12rem" }}
                options={materialGroups}
                label_key="name"
                onChange={(value) =>
                  setForm((prev) => ({ ...prev, material_group: value }))
                }
              />
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Início
              </StyledLabel>
              <StyledDateInput
                name="start_date"
                setData={setForm}
                placeholder="Selecione a data"
                style={{ width: "12rem" }}
              />
            </StyledPosition>
            <StyledPosition>
              <StyledLabel isUpper>
                <StyledRequired>*</StyledRequired>Fim
              </StyledLabel>
              <StyledDateInput
                name="end_date"
                setData={setForm}
                placeholder="Selecione a data"
                style={{ width: "12rem", textColor: "#656c70" }}
                addonBg={"#656c70"}
              />
            </StyledPosition>
          </div>
        </Row>
        <PMPTabMap
          height={"240px"}
          coords={getCoords()}
          defaultZoom={4}
          agroupMarkers={false}
          originRef={originRef}
          destinationRef={destinationRef}
        />
        <Button
          onClick={sendData}
          type="primary"
          style={{
            width: "20rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            marginLeft: "auto",
          }}
          disabled={isButtonDisabled()}
          loading={isQuotationBeingCreated}
        >
          {isQuotationBeingCreated ? (
            "Criando cotação..."
          ) : (
            <>
              <PlusOutlined />
              {" Criar nova cotação"}
            </>
          )}
        </Button>
        <Collapse
          title={
            <PageTitle
              style={{ marginTop: 16 }}
              value={"Histórico de cotações"}
            />
          }
          activeKey={openHistory}
          onChange={(value) => setOpenHistory(value[0])}
        >
          <UpTable
            onChange={handleTableChange}
            loading={isLoading}
            tableParams={tableParams}
            columns={columns}
            dataSource={tableData}
          />
        </Collapse>
      </TabContainer>
      <ChooseRevisionTypeModal
        setOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setRevisions={setRevisions}
        setRevisionsIds={setRevisionIds}
        revisionInput={revisionInput}
        setRevisionInput={setRevisionInput}
        model={equipment.model_equipment.name}
        brand={equipment.brand_equipment.name}
      />
    </ContainerWithoutLimit>
  );
}
