import React from 'react';
import styled from "styled-components";
import TableTelemetryRecords from "../../components/equipment/TableTelemetryRecords";

const TabContainer = styled.div`
  padding-top: 24px;
`

export default function TabTelemetryRecords({equipment, ...props}) {
    return (
        <TabContainer>
          <TableTelemetryRecords equipment={equipment}/>
        </TabContainer>
    )
}