import {Button, Modal, Upload} from 'antd';
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import {gray_2} from "../../../Cores";
import useFile from "../../../hooks/useFile";
import api from "../../../services/api";
import {Notification} from "../../../notification";
import {buildFormDataToFilter} from "../../../util/Utils";


const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`
const StyledUploader = styled(Upload)`
  display: block;
  padding: 8px;

  & .ant-upload-text-icon svg {
    color: white;
  }

  .anticon-delete svg {
    width: 50px;
    height: 50px;
    color: white;
    transform: translateY(-15px);
  }
`


export default ({
                    setFileList,
                    noLimitFileSize,
                    beforeUpload,
                    onRemove,
                    maxCount = 2,
                    typesAcceptFile = [],
                    ...props
                }) => {


    const {
        max_size,
        accept,
        ...useFileProps
    } = useFile({
        typesAcceptFile: typesAcceptFile,
        beforeUpload: beforeUpload,
        onRemove: onRemove,
        noLimitFileSize: noLimitFileSize,
        setFileList: setFileList,
        maxCount: maxCount
    })




    return (
        <>
            <StyledUploader
                accept={accept}

                maxCount={maxCount}
                {...useFileProps}// Props do hook useFile
                {...props} >
                <Button icon={<UploadOutlined/>}>Selecione os arquivos</Button>

            </StyledUploader>

            <Info>Tamanho máximo do arquivo: {max_size} MB. Máximo
                de {maxCount} arquivos. Tipos de arquivos aceitos: {
                    accept}.</Info>
        </>

    )
}