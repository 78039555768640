import React, { useEffect, useState } from "react";
import Switch from "../Base/Switch";
import { Form, Input } from "antd";
import CheckBoxMult from "../Base/Forms/CheckBoxMult";
import Collapse from "../Collapse";
import styled from "styled-components";
import { content_bg, icon_color, primary_color } from "../../Cores";

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 11px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;

const FilterSearch = styled(Input.Search)`
  margin: 0;
  width: 100%;

  button {
    background: ${content_bg};
    border-color: ${icon_color};

    :hover {
      background: ${content_bg};
    }

    :hover span {
      color: ${primary_color};
    }

    svg {
      transition: 0.5s;
    }

    span {
      color: ${icon_color};
    }
  }
`;

export default function CheckboxOffCanvas({
  collapseTitle,
  placeholder,
  form,
  initialValue,
  labelLookup = "name",
  hasSearch = false,
  renderContent,
  defaultActiveKey = ["1"],
  itemName,
  items,
  align,
}) {
  const [search, setSearch] = useState("");
  const [markAll, setMarkAll] = useState(false);
  const field = Form.useWatch(itemName, form);

  const itemsFilter = items.filter((item) => {
    return (
      item[labelLookup]?.toLowerCase().includes(search.toLowerCase()) ||
      form.getFieldValue(itemName)?.includes(item.id)
    );
  });

  function handleCheckAll(checked) {
    // setHasChange(true)
    setMarkAll(checked);
    let checkeds = [];
    if (checked) {
      checkeds = itemsFilter.map((item) => item.id);
    }
    form.setFieldValue(itemName, checkeds);
  }

  async function checkMarkAll() {
    const value = await form.getFieldValue(itemName);
    setMarkAll(itemsFilter.length === value?.length);
  }

  useEffect(() => {
    checkMarkAll();
  }, [search, items, field]);
  return (
    <Collapse
      title={collapseTitle.toUpperCase()}
      defaultActiveKey={defaultActiveKey}
    >
      {hasSearch && (
        <FilterSearch
          allowClear
          defaultValue={search}
          onSearch={(value, e) => setSearch(value)}
          placeholder={placeholder}
        />
      )}
      <SwitchContainer>
        <Switch
          checked={markAll}
          // defaultChecked={centers.length === userCentersFilter.length}
          onChange={handleCheckAll}
        />
        <span>Marcar/desmarcar todos</span>
      </SwitchContainer>
      <Container>
        <Form.Item noStyle name={itemName} initialValue={initialValue}>
          <CheckBoxMult
            align={align}
            options={itemsFilter.map((item) => {
              return {
                value: item.id,
                label: item[labelLookup],
                onChange: async (e) => {
                  const fields = await form.validateFields();

                  setMarkAll(itemsFilter.length === fields[itemName].length);
                },
                object: item,
              };
            })}
            renderContent={renderContent}
          />
        </Form.Item>
      </Container>
    </Collapse>
  );
}
