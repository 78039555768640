import React from 'react'
import {primary_color} from "../../Cores";
import {ShareAltOutlined} from "@ant-design/icons";


export default function ShareLocation({lat, long}) {
    return (
        <a target={'_blank'} href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`} rel="noreferrer">


            <ShareAltOutlined style={{color: primary_color}} />
        </a>
    )
}