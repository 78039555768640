import { Col, InputNumber, Row, Table } from "antd";
import styled from "styled-components";
import DisplayInfo from "../Base/DisplayInfo";
import PageTitle from "../Base/PageTitle";
import { useEffect, useState } from "react";
import {
  calendula_gold_2,
  content_bg,
  icon_color,
  primary_color,
} from "../../Cores";
import Empty from "../Base/Empty";
import { moneyMask } from "../../util/Utils";

const Container = styled.div``;

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  display: flex;
`;

const StyledEmpty = styled(Empty)`
  padding: 73px;
`;

const StyledTable = styled(Table)`
  margin-top: 16px;

  .ant-select-arrow {
    color: white;
  }

  .ant-select-open .ant-select-arrow {
    color: #656c70;
  }

  td {
    background: ${content_bg};
  }

  tr:hover td {
    cursor: ${({ hasClick }) => (hasClick ? "pointer" : "default")};
    background: #323535 !important;
  }

  .ant-pagination-item-link {
    background: transparent;
    border: none;
    color: white;
  }

  .ant-pagination-item-link:disabled {
    color: ${icon_color};
  }

  .ant-pagination-item-link:hover:not(:disabled) {
    color: ${primary_color};
  }

  .ant-table-row-selected td.ant-table-cell {
    background: ${calendula_gold_2};
  }
`;

export default function QuotationSummary({ quotationData, setQuotationData }) {
  const tableColumns = [
    {
      title: "",
      render: (_, __, index) => (
        <span style={{ color: primary_color }}>{index + 1}</span>
      ),
    },
    {
      title: "ID do Material",
      dataIndex: "material_id",
      key: "material_id",
    },
    {
      title: "Descrição",
      dataIndex: "material_description",
      key: "material_description",
    },
    {
      title: "QTD",
      dataIndex: "qtd",
      key: "qtd",
      render: (text, _, index) => (
        <InputNumber
          defaultValue={parseInt(text)}
          onChange={(e) => handleQuantityChange(index, e)}
        />
      ),
    },
    {
      title: "ATP",
      dataIndex: "atp",
      key: "atp",
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      render: (text) => (text ? `R$ ${text.replace(".", ",")}` : "-"),
    },
  ];
  const [totalCost, setTotalCost] = useState("-");

  const handleQuantityChange = (index, value) => {
    setQuotationData((prev) => {
      const updatedParts = [...prev.parts];
      updatedParts[index] = { ...updatedParts[index], qtd: value };
      return { ...prev, parts: updatedParts };
    });
  };

  useEffect(() => {
    const newTotal = quotationData.parts.reduce((acc, part) => {
      const { price, qtd } = part;

      const priceAsInt = parseInt(price) || 0;
      const qtdAsInt = parseInt(qtd) || 0;

      return acc + priceAsInt * qtdAsInt;
    }, 0);

    setTotalCost(newTotal.toFixed(2));
  }, [quotationData.parts]);

  return (
    <Container>
      <PageTitle value="INFORMAÇÕES BÁSICAS" />
      <StyledRow>
        <Col span={12}>
          <DisplayInfo label="N° pedido do cliente">
            {quotationData.order_number}
          </DisplayInfo>
        </Col>
        <Col span={12}>
          <DisplayInfo
            label="Vendedor responsável"
            style={{ marginBotton: "16px", textAlign: "center" }}
          >
            {quotationData.seller}
          </DisplayInfo>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={12}>
          <DisplayInfo label="Filial">{quotationData.center}</DisplayInfo>
        </Col>
        <Col span={12}>
          <DisplayInfo label="Tipo de frete">
            {quotationData.incoterm}
          </DisplayInfo>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={12}>
          <DisplayInfo label="Condição de pagamento">
            {quotationData.paymentConditionLabel}
          </DisplayInfo>
        </Col>
        <Col span={12}>
          <DisplayInfo label="Valor total calculado">
            R$ {moneyMask(totalCost)}
          </DisplayInfo>
        </Col>
      </StyledRow>
      <Row>
        <PageTitle value="MATERIAIS" />
      </Row>
      <StyledTable
        columns={tableColumns}
        dataSource={quotationData.parts}
        locale={{
          sortTitle: "",
          emptyText: <StyledEmpty />,
        }}
        pagination={false}
      />
    </Container>
  );
}
