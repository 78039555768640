import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Input } from "antd";
import styled from "styled-components";
import { text_color } from "../../Cores";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import OffCanvas from "../Base/OffCanvas";
import Select from "../Base/Select";
import { Notification } from "../../notification";
import api from "../../services/api";
import { BuildFieldErrorsDict, moneyMask } from "../../util/Utils";
import { FINISHED_ID } from "../../util/OpportunityUtils";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

export default function StatusOffCanvas({
  open,
  setOpen,
  opportunity,
  setOpportunity,
}) {
  const [form] = Form.useForm();
  const [hasChange, setHasChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    showExclusiveFinishedStatusFields,
    setShowExclusiveFinishedStatusFields,
  ] = useState(false);

  const [optionsStatus, setOptionsStatus] = useState([]);
  const [backupStatus, setBackupStatus] = useState([]);
  const [optionsStatusChangeReason, setOptionsStatusChangeReason] = useState(
    []
  );
  const [backupStatusChangeReason, setBackupStatusChangeReason] = useState([]);
  const [optionsContactChannel, setOptionsContactChannel] = useState([]);
  const [backupContactChannel, setBackupContactChannel] = useState([]);

  useEffect(() => {
    async function fetchOptions() {
      try {
        const response = await api.get("opportunity_status");
        const statusChangeReasonResponse = await api.get(
          "opportunity_status_change_reason"
        );
        const contactChannelResponse = await api.get(
          "opportunity_contact_channel"
        );

        setBackupStatus(response.data);
        setBackupStatusChangeReason(statusChangeReasonResponse.data);
        setBackupContactChannel(contactChannelResponse.data);
      } catch (error) {
        // setLoading(false)
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchOptions();

    // form.setFieldsValue({status: opportunity.status.id})
  }, []);

  useEffect(() => {
    setOptionsStatus(
      backupStatus
        .filter((item) => item.id !== opportunity?.status?.id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    );
    setOptionsStatusChangeReason(
      backupStatusChangeReason
        .filter((item) => item.id !== opportunity?.status_change_reason?.id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    );
    setOptionsContactChannel(
      backupContactChannel
        .filter((item) => item.id !== opportunity?.contact_channel?.id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    );
  }, [
    opportunity.status,
    backupStatus,
    backupStatusChangeReason,
    opportunity.status_change_reason,
    backupContactChannel,
    opportunity.contact_channel,
  ]);

  function onClose() {
    if (hasChange) {
      Modal.confirm({
        title: "Deseja realmente sair?",
        content:
          "Ao escolher voltar, você abandonará a edição e os dados serão perdidos.",
        icon: <ExclamationCircleOutlined />,
        // content: 'Deseja realmente fechar?',
        okText: "Sim",
        cancelText: "Não",
        onOk: closeDrawer,
      });
    } else {
      closeDrawer();
    }
  }

  function closeDrawer() {
    setOpen(false);
    form.resetFields();
  }

  const onSubmit = async () => {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
    } catch (error) {
      setIsLoading(false);
      return;
    }

    try {
      const data = {
        ...values,
      };

      const response = await api.post(
        `opportunity/${opportunity.id}/change_status`,
        data
      );

      Notification("success", "Status da notificação alterada com sucesso.");
      closeDrawer();
      setOpportunity(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, "Erros encontrados");
    } finally {
      setIsLoading(false);
    }
  };

  function maskMoney(money) {
    const value = moneyMask(money);
    form.setFieldValue("gross_value", value);
    form.validateFields(["gross_value"]);
  }

  return (
    <OffCanvas
      title={<Title>Alterar o status</Title>}
      onClose={onClose}
      open={open}
      width={"378px"}
      footer={
        <Button
          disabled={!hasChange}
          loading={isLoading}
          onClick={onSubmit}
          type="primary"
        >
          Alterar
        </Button>
      }
    >
      <Form
        id={"statusForm"}
        layout={"vertical"}
        initialValues={{ is_active: true }}
        form={form}
        onValuesChange={() => setHasChange(true)}
      >
        <Form.Item
          label={"Selecione o status"}
          name={"status"}
          rules={[
            {
              required: true,
              message: "Selecione um status",
            },
          ]}
        >
          <Select
            onChange={(value) =>
              setShowExclusiveFinishedStatusFields(value === FINISHED_ID)
            }
            placeholder="Busque pelo status"
            options={optionsStatus}
          />
        </Form.Item>
        <Form.Item
          label={"Usuários a serem notificados"}
          name={"notified_users"}
        >
          <Select
            url={"user?is_active=true&page_size=10000"}
            label_key={"get_full_name"}
            mode="multiple"
            placeholder="Busque pelo nome do usuário"
          />
        </Form.Item>
        {showExclusiveFinishedStatusFields && (
          <>
            <Form.Item
              label="Valor Bruto"
              name="gross_value"
              rules={[
                {
                  required: true,
                  message: "Informe o valor bruto",
                },
                // {
                //     pattern: /^(R|U)$(\s)?\d{1,3}(,|.)\d{3}*(,|.)\d{2}$/,
                //     message: 'Informe um valor válido.'
                // },
              ]}
            >
              <Input
                onChange={(e) => maskMoney(e.target.value)}
                placeholder="Digite o valor bruto dessa notificação"
              />
            </Form.Item>

            <Form.Item label={"Motivo do Status"} name={"status_change_reason"}>
              <Select
                mode="multiple"
                placeholder="Busque pelo motivo"
                options={optionsStatusChangeReason}
              />
            </Form.Item>
            <Form.Item label={"Canal de comunicação"} name={"contact_channel"}>
              <Select
                placeholder="Busque pelo canal de comunicação"
                options={optionsContactChannel}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </OffCanvas>
  );
}
