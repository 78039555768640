import React, { useState } from "react";
import { Notification } from "../notification";
import { Modal, Upload } from "antd";
import api from "../services/api";
import { buildFormDataToFilter } from "../util/Utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const typesAccept = [
  "jpg",
  "jpeg",
  "png",
  "svg",
  "tiff",
  "pdf",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "avi",
  "mpeg",
  "mp4",
  "webp",
  "mov",
];

const max_size = 20; //20 é 20mb

export function format_accept_types(typesAcceptFile) {
  return typesAcceptFile.reduce((acc, currentValue, currentIndex) => {
    if (acc === "") return `.${currentValue}`;
    return `${acc}, .${currentValue}`;
  }, "");
}

export default function useFile({
  noLimitFileSize,
  typesAcceptFile,
  beforeUpload,
  onRemove,
  setFileList,
  maxCount,
}) {
  const [_typesAcceptFile, _setTypesAcceptFile] = useState(
    typesAcceptFile.length > 0 ? typesAcceptFile : typesAccept
  );

  const deleteFile = async (id) => {
    try {
      await api.delete(`file_upload/${id}`);
      Notification("success", "Arquivo deletado com sucesso");
    } catch (error) {
      buildFormDataToFilter(error, null, "Erro ao deletar arquivo");
    }
  };
  const _deleteFileConfirmation = (id) => {
    return new Promise(function (resolve, reject) {
      Modal.confirm({
        title: "Deseja realmente remover esse arquivo?",
        content: "",
        icon: <ExclamationCircleOutlined />,
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          deleteFile(id);
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };

  const __beforeUpload = (file, fileList) => {
    const limitSizeIsValid = file.size / 1024 / 1024 <= max_size;
    if (noLimitFileSize || limitSizeIsValid) {
      if (beforeUpload) {
        beforeUpload(file);
      } else {
        setFileList((_fileList) => {
          if (_fileList.length < maxCount) {
            return [..._fileList, file];
          }
          return _fileList;
        });
        return false;
      }
    } else {
      Notification("error", `O limite máximo por arquivo é ${max_size}MB!`);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const __onRemove = (file) => {
    if (onRemove) {
      onRemove(file);
    } else {
      setFileList((fileList) => [
        ...fileList.filter((f) => f.uid !== file.uid),
      ]);
      if (file.id) {
        return _deleteFileConfirmation(file.id);
      }
    }
  };

  const formated_types = format_accept_types(_typesAcceptFile);
  return {
    accept: formated_types,
    max_size,
    beforeUpload: __beforeUpload,
    onRemove: __onRemove,
  };
}
