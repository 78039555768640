import React from 'react';
import {primary_color} from "../../Cores";
import BadgeCount from "./BadgeCount";


export default function FilterIcon({children, ...props}) {
    return (
        <div style={{cursor: 'pointer'}}>
            <BadgeCount
                // style={{cursor: 'pointer'}}
                offset={[26, 8]}
                {...props}>
                <i className="ri-filter-2-line"
                   style={{
                       fontSize: 24,
                       color: primary_color,
                       cursor: 'pointer'
                   }}
                >
                </i>
            </BadgeCount>
        </div>
    )
}