import React from 'react'
import styled from "styled-components";
import {
    Badge as BadgeAnt,
    Tooltip,
} from "antd";

const getColor = (value) => {
    let result = ''
    switch (value.toLowerCase()) {
        case 'amarelo':
            result = 'yellow'
            break
        case 'verde':
            result = 'green'
            break
        case 'vermelho':
            result = 'red'
            break
        case 'cinza':
            result = '#858D93'
            break
        default:
            result = '#858D93'
            break
    }
    return result
}

const TooltipContentWrapper = styled.div`
    display: grid;
    gap: 4px;

    font-size: 12px;

    & div {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    & span {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid black;
        border-radius: 100%;
        margin-top: 2px;
        margin-right: 4px;
    }
`

const tooltipColorsMap = [
    { key: 'verde', color: 'green', description: 'A posição ou status foram reportados nas últimas 96 horas com o motor ligado.' },
    { key: 'amarelo', color: 'yellow', description: 'A posição ou status foi reportada a mais de 96 horas.' },
    { key: 'vermelho', color: 'red', description: 'A posição ou status foi reportada nas últimas 96 horas com o motor desligado.' },
    { key: 'cinza', color: '#858D93', description: 'A máquina nunca comunicou com o portal, não possui o network map, ou o hardware apresenta algum código de erro.' },
    { key: 'branco', color: 'white', description: 'Ao ser configurada o portal não recebeu os dados da W-ECU (EX: Chip, IMSI etc.).' },
]

export default function SystemConfiabilityAlert({value}) {

    const currentTooltipData = tooltipColorsMap.find(tooltip => tooltip.key === value.toLowerCase())

    const TooltipContent = ({ children }) => {

        if (!currentTooltipData) return children

        return (
            <Tooltip title={
                <TooltipContentWrapper>
                    <div>
                        <span style={{backgroundColor: currentTooltipData.color}} />
                        <p>{currentTooltipData.description}</p>
                    </div>
                </TooltipContentWrapper>
            }>
                {children}
            </Tooltip>
        ) 
    }

    return (
        <TooltipContent>
            <BadgeAnt color={getColor(value)} text={value}/>
        </TooltipContent>
    )
}