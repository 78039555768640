import styled from 'styled-components';
import {
    ReactComponent as TracbelLargeIcon
} from '../../assets/icons/Logo_Principal.svg'
import {
    ReactComponent as TracbelMiniIcon
} from '../../assets/icons/Logo_Principal_mini.svg'
import {Input, Typography} from 'antd';
import {
    ReactComponent as MicrosoftIcon
} from "../../assets/icons/LogoMicrosoft.svg"
import {icon_color, text_color} from "../../Cores";

export const MainContainer = styled.div`
  //height: 100vh;
  width: 100%;
  overflow: hidden;

`


export const StyledImage = styled.img`
  height: 100vh;
  object-fit: cover;
`

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
 overflow-y: scroll;
`

export const FormContainer = styled.div`
  width: 328px;

  ${({isPhone}) => {
    if (isPhone) {
      return `
            margin: 64px auto 64px;
          `
    }
    return `
            margin: 128px auto 90px;
          `
  }}

`

export const StyledTracbelLargeIcon = styled(TracbelLargeIcon)`
  width: 100%;
`

export const StyledTracbelMiniIcon = styled(TracbelMiniIcon)`
  width: 100%;
  //position: absolute;
  bottom: 16px;
`
export const StyledLogoMicrosoft = styled(MicrosoftIcon)`
  width: 16px;
  height: 16px;
`

export const StyledTitle = styled(Typography.Title)`
  color: rgba(255, 255, 255, 0.85) !important; //todo mudar no less
  width: 100%;
  text-align: center;

`
export const StyledText = styled(Typography.Text)`
  text-align: center;
  display: block;
  font-weight: 500;
  color: ${text_color};

`


export const StyleButtonMicrosoft = styled.button`
  width: 100%;
  margin-top: 32px;
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: baseline;
  justify-content: center;
  background: #242525;
  border: 1px solid #656C70;
  color: ${text_color};

  & > svg {
    transform: translateY(2px);
  }

  & > span {
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    cursor: pointer;
  }

`

export const StyledPasswordInput = styled(Input.Password)`
  & .anticon {
    color: ${icon_color};
  }
`