import React, { useState, createContext, useEffect, useContext } from "react";
import { BuildFieldErrorsDict } from "../util/Utils";
import api from "../services/api";
import { useUserContext } from "./UserContext";

const StaticDataContext = createContext();

export function StaticDataProvider({ children }) {
  const [centers, setCenters] = useState([]);
  const [brands, setBrands] = useState([]);
  const { user } = useUserContext();
  const [isLoadingCenter, setIsLoadingCenter] = useState(false);

  useEffect(() => {
    if (user) {
      fetchAllCenters();
      fetchAllBrands();
    }
  }, [user]);

  const fetchAllCenters = async () => {
    try {
      setIsLoadingCenter(true);
      const response = await api.get(`/center?page_size=10000`);
      setCenters(response.data.results);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingCenter(false);
    }
  };

  const fetchAllBrands = async () => {
    try {
      // setIsLoadingCenter(true)
      const response = await api.get(`/brand_equipment?page_size=10000`);
      setBrands(response.data.results);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      // setIsLoadingCenter(false)
    }
  };

  return (
    <StaticDataContext.Provider
      value={{
        isLoadingCenter,
        centers,
        brands,
      }}
    >
      {children}
    </StaticDataContext.Provider>
  );
}

export function useStaticDataContext() {
  return useContext(StaticDataContext);
}
