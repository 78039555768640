import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import OffCanvas from "./Base/OffCanvas";
import styled from "styled-components";
import { collapse_header_text_color, gray_2, text_color } from "../Cores";
import { useUserContext } from "../context/UserContext";
import CheckBoxMult from "./Base/Forms/CheckBoxMult";
import { Notification } from "../notification";
import Switch from "./Base/Switch";
import { useStaticDataContext } from "../context/StaticDataContext";
import FormInfo from "./Rules/RuleForm/FormInfo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;
const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 11px 0;
`;

const ContainerInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${collapse_header_text_color};
`;

export default function FilterCentersOffCanvas({ open, setOpen }) {
  const [hasChange, setHasChange] = useState(false);
  const { setUserCentersFilter, userCentersFilter, user } = useUserContext();
  const { centers } = useStaticDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [markAll, setMarkAll] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setMarkAll(centers.length === userCentersFilter.length);
  }, [centers]);

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ centers: userCentersFilter });
    }
  }, [open]);

  function onClose() {
    if (hasChange) {
      Modal.confirm({
        title: "Deseja realmente sair?",
        content:
          "Ao escolher voltar, você abandonará o cadastro e os dados serão perdidos.",
        icon: <ExclamationCircleOutlined />,
        // content: 'Deseja realmente fechar?',
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          closeDrawer();
        },
      });
    } else {
      closeDrawer();
    }
  }

  function closeDrawer() {
    setHasChange(false);
    setOpen(false);
  }

  async function onSubmit() {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
      setUserCentersFilter(values.centers);
      Notification("success", "Filtro atualizado com sucesso.");
      closeDrawer();
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  }

  function onReset() {
    setHasChange(true);
    form.setFieldsValue({ centers: user.centers });
  }

  function handleCheckAll(checked) {
    setHasChange(true);
    setMarkAll(checked);
    let checkeds = [];
    if (checked) {
      checkeds = centers.map((center) => center.id);
    }
    form.setFieldsValue({ centers: checkeds });
  }

  return (
    <OffCanvas
      destroyOnClose
      title={<Title level={4}>Selecionar centros</Title>}
      onClose={onClose}
      open={open}
      width={"394px"}
      footer={
        <>
          <Button onClick={onReset}>Redefinir filtros</Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Salvar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        initialValues={{ centers: userCentersFilter }}
        onValuesChange={() => {
          setHasChange(true);
        }}
      >
        {/*{isLoading ? <Loading/> :*/}
        {/*<Collapse title={'CENTROS'} defaultActiveKey={['1']}>*/}
        <SwitchContainer>
          <Switch
            checked={markAll}
            // defaultChecked={centers.length === userCentersFilter.length}
            onChange={handleCheckAll}
          />
          <span>Marcar/desmarcar todos</span>
        </SwitchContainer>
        <Container>
          <Form.Item noStyle name="centers">
            <CheckBoxMult
              options={centers.map((center) => {
                return {
                  value: center.id,
                  label: center.name,
                  onChange: async (e) => {
                    const centersForm = await form.validateFields();
                    setMarkAll(centers.length === centersForm.centers.length);
                  },
                };
              })}
            />
          </Form.Item>
          {/*</Row>*/}
          <FormInfo
            label={
              " O filtro 'CENTROS' é global e será aplicado em todas as demais funcionalidades que utilizarem centros como filtro."
            }
          />
        </Container>

        {/*</Collapse>*/}
        {/*}*/}
      </Form>
    </OffCanvas>
  );
}
