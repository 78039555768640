import React from 'react';
import {Empty as EmptyAnt} from "antd";
import styled from "styled-components";
import {ReactComponent as BadFaceIcon} from "../../assets/icons/bad_face.svg";
import {gray} from "../../Cores";


const StyledEmptyDescription = styled.span`
  color: ${gray};

`


const StyledEmpty = styled(EmptyAnt)`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`


export default function NoLocation({message, ...props}) {
    return (
        <StyledEmpty imageStyle={{
            height: 40,
        }} image={
        <BadFaceIcon/>}
                  description={<StyledEmptyDescription>{message? message: 'O equipamento não reportou sua localização recentemente, então não conseguimos criar um mapa de notificações próximas para essa notificação.'}</StyledEmptyDescription>}

                  {...props}/>
    )
}