import React from 'react';
import RoutesMap from './routes/Routes.js'
import {BrowserRouter} from "react-router-dom";
import AppProvider from "./context/compose";
import './App.less'

export default function App() {
    return (
        <BrowserRouter>
            <AppProvider>
                <RoutesMap/>
            </AppProvider>
        </BrowserRouter>
    );
}