import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { gray } from "../../Cores";
import { dateFormat } from "../../util/dates";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${gray};
`;
const StyledInfo = styled.span`
  color: ${gray};
  line-height: 22px;
`;

export default function LastHourmeterUpdate({ dataOrigin, updateDate }) {
  return (
    <Container>
      <StyledInfoCircleOutlined />
      <StyledInfo>
        Origem do horímetro: {dataOrigin}. Dia atualizado:{" "}
        {dateFormat(updateDate)}
      </StyledInfo>
    </Container>
  );
}
