import React, { useEffect, useState } from "react";
import useTable from "../../hooks/useTable";
import { dateFormat } from "../../util/dates";
import UpTable from "../../components/Base/UPTable";
import api from "../../services/api";
import { BuildFieldErrorsDict, moneyMask } from "../../util/Utils";
import { primary_color } from "../../Cores";
import styled from "styled-components";

import {
  ExclamationCircleOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { Notification } from "../../notification";
import FilterOsModal from "./FilterOsModal";
import moment from "moment-timezone";
import { Modal } from "antd";
import BadgeCondition from "../../components/Base/display/BadgeCondition";

const FilterButton = styled.div`
  color: ${primary_color};
  margin-left: auto;
  width: max-content;
  cursor: pointer;
`;

export default function OsOpportunityTable({
  search = "",
  equipment,
  setOpportunity,
  opportunity,
}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [openAssociation, setOpenAssociation] = useState(false);
  const [currentOs, setCurrentOs] = useState({});
  const [values, setValues] = useState();
  const [valuesFilter, setValuesFilter] = useState({
    order: false,
    filter: 730,
  });

  const fetchOsList = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      if (!equipment.id) return;
      setIsLoading(true);

      let currentValues = values;
      if (!currentValues) {
        const response = await api.get(`equipment/${equipment.id}/get_os_list`);
        setValues(response.data);
        currentValues = response.data;
      }

      currentValues = currentValues.sort((first, second) => {
        const value = valuesFilter.order ? -1 : 1;
        if (moment(first.date).isAfter(second.date)) {
          return -1 * value;
        } else {
          return 1 * value;
        }
      });

      const days = valuesFilter.filter;
      currentValues = currentValues.filter((value) => {
        if (value.os_number === opportunity.service_order || days === -1)
          return true;
        return moment(value.date).isAfter(
          moment().subtract(days, "days").format("YYYY-MM-DD")
        );
      });

      setTableData(
        currentValues.map((item) => ({
          ...item,
          key: item.os_number,
        }))
      );
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            // total: response.data.count,
          },
        };
      });
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const { tableData, isLoading, tableParams, resetTable, handleTableChange } =
    useTable({ fetchData: fetchOsList, search });

  const columns = [
    {
      title: "Número da OS",
      dataIndex: "os_number",
      // sorter: 'os_number',
      key: "os_number",
      width: "100px",

      // fixed: 'left',
    },
    {
      title: "Data da OS",
      dataIndex: "date",
      // sorter: 'type_opportunity',
      key: "date",
      width: "100px",
      render: (value, record) => dateFormat(value, "DD/MM/YYYY"),
    },
    {
      title: "Valor",
      dataIndex: "value",
      // sorter: 'client__name',
      key: "value",
      width: "100px",
      ellipsis: true,
      render: (value, record) => moneyMask(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      // sorter: 'client__name',
      key: "status",
      width: "100px",
      ellipsis: true,
      render: (value) =>
        value && (
          <BadgeCondition
            type={value.toLowerCase() === "concluída" && "Aprovado"}
          >
            {value}
          </BadgeCondition>
        ),
    },

    {
      title: "Descrição", //falta
      dataIndex: "description",
      // sorter: 'current_hour_meter',
      key: "description",
      width: "200px",
      ellipsis: true,
    },
    {
      title: "Contato do cliente",
      dataIndex: "consultant",
      key: "consultant",
      width: "200px",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    handleTableChange({
      current: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
    });
    // eslint-disable-next-line
  }, [equipment, valuesFilter]);

  const onRowClick = (record) => {
    Modal.confirm({
      title: "Deseja associar?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      async onOk() {
        try {
          const response = await api.patch(`opportunity/${opportunity.id}`, {
            service_order: record.os_number,
          });
          setOpportunity(response.data);
          Notification("success", "OS associado com sucesso");
          // return response.data;
        } catch (error) {
          BuildFieldErrorsDict(error, null, false);
        } finally {
          // setIsLoading(false)
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      {/*<Col xs={6} offset={20}>*/}
      <FilterButton onClick={() => setOpenFilter(true)}>
        <OrderedListOutlined /> Filtrar e ordenar
      </FilterButton>

      <UpTable
        onChange={handleTableChange}
        loading={isLoading}
        showQuickJumper
        rowSelection={{
          selectedRowKeys: [opportunity?.service_order],
          hideSelectAll: true,
          selections: true,
          renderCell: () => <></>,
        }}
        noPagination={true}
        onRowClick={onRowClick}
        tableParams={tableParams}
        columns={columns}
        dataSource={tableData}
      />

      <FilterOsModal
        isModalOpen={openFilter}
        valuesFilter={valuesFilter}
        setClose={setOpenFilter}
        id={currentOs}
        setValues={setValuesFilter}
      />
    </>
  );
}
