import React from "react";
import { Badge as BadgeAnt } from "antd";
import { dateFormat } from "../../util/dates";
import styled from "styled-components";

const StyledBadge = styled(BadgeAnt)`
  display: flex;
  align-items: center;
`;

export default function AlertPMP({ value }) {
  const _getText = () => {
    if (value?.contract_number) {
      let revision_quantity_remaining = `${value.revision_quantity_remaining} revisão restante`;
      if (value.revision_quantity_remaining > 1) {
        revision_quantity_remaining = `${value.revision_quantity_remaining} revisões restantes`;
      }

      if (value) {
        return (
          <>
            <div>
              {" "}
              {value.contract_type} ({value.contract_number})
            </div>
            <div>
              Venc: {dateFormat(value.end_dt, "DD/MM/YY")} ou{" "}
              {revision_quantity_remaining}
            </div>
          </>
        );
      } else {
        return "Não";
      }
    } else {
      return value ? "Sim" : "Não";
    }
  };
  return (
    <>
      {/*{value?.contract_number ? ('fdfdf') :*/}
      <StyledBadge status={value ? "green" : "red"} text={_getText()} />
      {/*}*/}
    </>
  );
}
