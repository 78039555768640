import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {grey_border, primary_color} from "../Cores";
import {ReactComponent as FileIcon} from "../assets/icons/file.svg";
import {DownloadOutlined} from "@ant-design/icons";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../util/Utils";


const FileContainer = styled.a`
  cursor: pointer;
  color: white;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid ${grey_border};
  //margin-left: 45px;
  margin-bottom: 8px;

  &:hover {
    border-color: ${primary_color};
  }
`

const FileName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  //max-width: 150px;
  min-width: 50px;
  overflow: hidden;
  display: inline-block;
  flex-shrink: 1;
`
const FileIconStyled = styled(FileIcon)`
  flex-shrink: 0;

`
const DownloadOutlinedStyled = styled(DownloadOutlined)`
  //justify-self: end;
  margin-left: auto;

`

export default function File({file}) {
    const [loading, setIsLoading] = useState(false)
    const link_ref = useRef(null);

    async function get_protected_link(id) {
        try {
            setIsLoading(true)
            const response = await api.get(`file_upload/${id}`);
            // setProtectedUrl(response.data.protected_url)
            link_ref.current.href = response.data.protected_url
            link_ref.current.click()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <FileContainer target={'_blank'} onClick={() => {
                !loading && get_protected_link(file.id)
            }}>
                {/*<FileIconStyled/>*/}
                <FileName>{file.name} </FileName>
                <DownloadOutlinedStyled/>
            </FileContainer>
            <a ref={link_ref} href={'#'} style={{display: "none"}}/>
        </>
    )
}