import React from "react";
import PageTitle from "../../../components/Base/PageTitle";
import FingerScroll from "../../../components/Base/FingerScroll";
import HourMeterCard from "../../../components/Base/HourMeterCard";
import { maskIntNumber } from "../../../util/Utils";
import RuleContainerInfo from "./RuleContainerInfo";
import LastDate from "../../../components/Base/LastDate";
import { Col } from "antd";
import { dateFormat } from "../../../util/dates";
import Line from "../../../components/Base/Charts/Line";

export default function ScheduledReviewOpportunity({ opportunity, summary }) {
  return (
    <>
      <PageTitle value={"Dados da notificação"} />
      <FingerScroll>
        <HourMeterCard
          width={370}
          date={maskIntNumber(opportunity.next_review_hour_meter)}
          title={"Horímetro da revisão dessa notificação"}
          label={""}
        />
        <HourMeterCard
          width={370}
          date={
            summary.current_hour_meter &&
            summary.current_hour_meter > opportunity.next_review_hour_meter
              ? "-"
              : `${dateFormat(opportunity?.next_review_date)}`
          }
          title={"Data estimada em que o horímetro da revisão será alcançado"}
          label={"Estímativa"}
        />
      </FingerScroll>

      <PageTitle
        value={"Estatísticas do horímetro"}
        style={{ marginTop: 15 }}
      />
      <FingerScroll>
        <HourMeterCard
          date={
            summary?.current_hour_meter
              ? `${maskIntNumber(summary?.current_hour_meter)}`
              : "-"
          }
          title={"Horímetro atual"}
          label={"Total"}
        />
        <HourMeterCard
          date={
            summary?.average_seven_days
              ? `${summary?.average_seven_days}h`
              : "-"
          }
          title={"Últimos 7 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={
            summary?.average_fifteen_days
              ? `${summary?.average_fifteen_days}h`
              : "-"
          }
          title={"Últimos 15 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={summary?.average_overall ? `${summary?.average_overall}h` : "-"}
          title={`Geral (${
            summary?.tracked_since ? summary?.tracked_since : "-"
          })`}
          label={"Média diária"}
        />
      </FingerScroll>
      <LastDate
        style={{ marginTop: 8 }}
        datetime={summary?.last_communication_hourmeter}
        label={"Última atualização do horímetro"}
      />

      <Col xs={24} md={24} style={{ marginTop: 8 }}>
        <Line
          url={`/equipment/${opportunity.equipment}/get_hourmeter_historys`}
          title={"Histórico do horimetro"}
          width="100%"
          height="404px"
          hasSelect
          hasPadding={false}
          defaultValue={"7"}
          optionsSelect={[
            { value: "7", label: "Últimos 7 dias" },
            { value: "15", label: "Últimos 15 dias" },
            { value: "30", label: " Último mês" },
            { value: "365", label: "Último ano" },
          ]}
        />
      </Col>
      <RuleContainerInfo opportunity={opportunity} />
    </>
  );
}
