import { Col, Form, Input, Row } from "antd";
import Select from "../Base/Select";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { BuildFieldErrorsDict, phoneMask } from "../../util/Utils";
import GroupButtons from "../Base/GroupButtons";
import { useStaticDataContext } from "../../context/StaticDataContext";

const optionSituacion = [
  {
    label: "Ativo",
    value: true,
  },
  {
    label: "Inativo",
    value: false,
  },
];

export default function UserForm({ user = null, form, ...props }) {
  const [optionsProfiles, setOptionsProfiles] = useState();
  const { centers, brands } = useStaticDataContext();

  useEffect(() => {
    async function fetchOptions() {
      try {
        const promiseProfile = await api.get("profile");

        setOptionsProfiles(
          promiseProfile.data.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      } catch (error) {
        // setLoading(false)
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchOptions();
  }, []);

  function maskPhone(phone) {
    const value = phoneMask(phone);
    form.setFieldValue("phone", value);
    form.validateFields(["phone"]);
  }

  return (
    <Form id={"createForm"} layout={"vertical"} form={form} {...props}>
      <Form.Item
        label="E-mail Tracbel (Microsoft)"
        name="username"
        rules={[
          {
            required: true,
            message: "E-mail é obrigatório",
          },
          {
            type: "email",
            message: "Informe um e-mail válido. Ex: email@tracbel.com.br",
          },
          //     {
          //     pattern: /^\S+@tracbel\.com\.br$/,
          //     message: 'Este não é um e-mail corporativo da Tracbel. Tente outro.'
          // },
        ]}
      >
        <Input
          disabled={user && !!user.last_login}
          placeholder="email@tracbel.com.br"
        />
      </Form.Item>
      <Form.Item label="Usuário do SAP" name="user_sap">
        <Input placeholder="Usuário do SAP" />
      </Form.Item>
      <Form.Item label="ID do SAP" name="id_sap">
        <Input placeholder="ID do SAP" />
      </Form.Item>
      <Form.Item
        label="Nome completo"
        name="get_full_name"
        rules={[
          {
            required: true,
            message: "Nome é obrigatório",
          },
          {
            pattern: /^((\b[A-zÀ-ú']{2,40}\b)\s*){2,}$/,
            message: "Informe um nome completo válido",
          },
        ]}
      >
        <Input placeholder="Nome completo do colaborador" />
      </Form.Item>

      <Form.Item
        label="Celular profissional (Tracbel)"
        name="phone"
        rules={[
          {
            required: true,
            message: "Celular é obrigatório",
          },
          {
            pattern: /^\([1-9]{2}\) [0-9]{5}-[0-9]{4}$/,
            message: "Informe um número de celular válido",
          },
        ]}
      >
        <Input
          onChange={(e) => maskPhone(e.target.value)}
          placeholder="(99) 99999-9999"
        />
      </Form.Item>

      <Form.Item
        label={"Situação"}
        name={"is_active"}
        rules={[
          {
            required: true,
            message: "Situação é obrigatório",
          },
        ]}
      >
        <GroupButtons options={optionSituacion} />
      </Form.Item>
      <Form.Item
        label={"Perfis de acesso"}
        name={"profiles"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos um perfil de acesso",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Busque pelo nome do centro"
          options={optionsProfiles}
        />
      </Form.Item>
      <Form.Item
        label={"Centros"}
        name={"centers"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos um centro",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Busque pelo nome do perfil"
          options={centers?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Form.Item>
      <Form.Item
        label={"Marcas"}
        name={"brands"}
        rules={[
          {
            required: true,
            message: "Selecione pelo menos uma marca",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Busque pelo da marca"
          options={brands?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Form.Item>
    </Form>
  );
}
