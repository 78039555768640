import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Row} from "antd";
import Loading from "../../components/Base/Loading";
import Empty from "../../components/Base/Empty";
import DividerPage from "../../components/Base/DividerPage";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import Timeline from "../../components/Timeline";

const TabContainer = styled.div`

`


export default function TabTimeline({opportunity, ...props}) {

    const {fetchData, isLoading} = useDefaultFetch()
    const [timeline, setTimeline] = useState([])

    useEffect(() => {
        async function fetch() {
            const data = await fetchData(`opportunity/${opportunity.id}/timeline`)
            setTimeline(data)
        }
        fetch()

    }, [])

    return (
        <TabContainer>
            <DividerPage/>
            <Row gutter={[24, 24]} style={{marginTop: 16}}>
                {isLoading ? (
                    <Col xs={24}><Loading noHeight/></Col>
                ) : timeline.length === 0 ? (
                    <Col xs={24}><Empty/></Col>
                ) : (
                    <Col xs={24}> <Timeline data={timeline}/></Col>

                )}
            </Row>
        </TabContainer>

    )
}
