import styled from "styled-components";
import {ClockCircleOutlined} from "@ant-design/icons";
import React from "react";
import {dateFormat} from "../../util/dates";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export default function LastLogin({datetime}) {
    if(!datetime) return null
    return (
        <Container>
            <ClockCircleOutlined/>
            <span>Último login: <span>{dateFormat(datetime, "DD/MM/YYYY [às] HH:mm ")}</span></span>

        </Container>
    )
}