import React from 'react';
import styled from 'styled-components';
import {
    calendula_gold_1,
    calendula_gold_3,
    calendula_gold_6,
    cyan_1,
    cyan_3,
    cyan_6, red_10, dust_red_3, dust_red_6,
    success_bg,
    success_color
} from "../../../Cores";

const Container = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;



`

function getColor(type) {
    let result = null
    switch (type) {
        case 'Atenção':
            result = {
                borderColor: calendula_gold_3,
                color: calendula_gold_6,
                backgroundColor: calendula_gold_1
            }
            break;
        case 'Aprovado':
            result = {
                borderColor: success_color,
                color: success_color,
                backgroundColor: success_bg
            }
            break;
        case 'Reprovado':
            result = {
                borderColor: dust_red_3,
                color: dust_red_6,
                backgroundColor: red_10
            }
            break;
        default:
            result = {
                borderColor: cyan_3,
                color: cyan_6,
                backgroundColor: cyan_1
            }
            break;
    }
    return result
}

export default function BadgeCondition({children, type, ...props}) {
    return (
        // <Container {...props} {...colors.find((color) => type === color.type)}>
        <Container {...props} {...getColor(type)}>
            {children}
        </Container>)
}