import useForm from "../../../hooks/useForm";
import { Checkbox as CheckboxAnt } from "antd";
import InputGroup from "./InputGroup";
import styled from "styled-components";
import { input_border } from "../../../Cores";

const StyledCheckbox = styled(CheckboxAnt)`
  & span.ant-checkbox-inner {
    border-color: ${input_border};
  }
`;

export default function Checkbox({
  setData,
  label,
  error,
  labelCheck,
  ...props
}) {
  const { onChangeCheckedSingle } = useForm();

  return (
    <InputGroup label={label} error={error}>
      <StyledCheckbox
        onChange={onChangeCheckedSingle}
        hasError={!!error}
        {...props}
      >
        {labelCheck}
      </StyledCheckbox>
    </InputGroup>
  );
}
