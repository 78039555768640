import React, {useState} from 'react';
import styled from "styled-components";
import {Button, Col, Modal, Row} from "antd";
import PageTitle from "../../Base/PageTitle";
import DisplayInfo from "../../Base/DisplayInfo";
import Pagealert from "../../Base/Pagealert";
import {
    ExclamationCircleOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import DividerPage from "../../Base/DividerPage";
import {actionbar_bg, content_bg} from "../../../Cores";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import File from "../../File";
import {dateFormat} from "../../../util/dates";
import {
    ERROR_CODE_ID,
    OIL_ANALYSIS,
    SCHEDULED_REVIEW_ID
} from "../rule_consts";
import {useNavigate} from "react-router-dom";
import api from "../../../services/api";
import {Notification} from "../../../notification";
import {BuildFieldErrorsDict, maskIntNumber} from "../../../util/Utils";
import Loading from "../../Base/Loading";
import AuditCard from "../../AuditCard";
import BadgeCondition from "../../Base/display/BadgeCondition";
import {
    SYSTEM_CARETRACK_ID,
    SYSTEM_REMOTE_LOG_ID
} from "../../../util/OpportunityUtils";


const InfoContainer = styled.div`
  background: ${actionbar_bg};
`

const StyledButton = styled(Button)`
  color: green;
`
const Container = styled.div`
  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({noHorizontalPadding}) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`
    }
  }}
`

export default function TabRuleDetail({rule, setRule, hasPermission}) {
    const navigate = useNavigate()
    const {isDesktop} = useSystemBreakpoint();
    const [isLoading, setIsLoading] = useState(false);
    const _handleConfirmChangeStatus = () => {
        Modal.confirm({
            title: 'Deseja realmente desativar essa regra?',
            content: '',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => {
                handleChangeStatus()
            }
        });
    }
    const handleChangeStatus = async () => {
        try {
            setIsLoading(true)
            const response = await api.post(`rule/${rule.id}/change_status`);
            let message = `Regra ${response.data.is_active ? 'A' : 'De'}tivada com sucesso. `
            Notification('success', message)
            setRule(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }
    }


    function showRuleTypeInfo() {
        return (
            <>
                {rule.rule_type === SCHEDULED_REVIEW_ID &&
                    <>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Sistema'}>{rule.telemetry_system?.name}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Horas'}>{maskIntNumber(rule.hour)}</DisplayInfo>
                        </Col>
                    </>
                }

                {rule.rule_type === ERROR_CODE_ID && rule.telemetry_system.id === SYSTEM_CARETRACK_ID &&

                    <>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Sistema'}>{rule.telemetry_system?.name}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'PID'}>{`${rule.pid}`}</DisplayInfo>
                        </Col>

                        <Col xs={12}>
                            <DisplayInfo
                                label={'MID'}>{`${rule.mid}`}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'FMI'}>{`${rule.fmi}`}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'CÓDIGO ACD'}></DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'FREQUÊNCIA'}>{`${rule.recurrence} vezes dentro de ${rule.interval} ${rule.frequency_type_name}`.toLowerCase()}</DisplayInfo>
                        </Col>
                    </>}
                {rule.rule_type === ERROR_CODE_ID && rule.telemetry_system.id === SYSTEM_REMOTE_LOG_ID &&

                    <>
                        <Col xs={24}>
                            <DisplayInfo
                                label={'Sistema'}>{rule.telemetry_system?.name}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'SPN'}>{`${rule.spn_remote_log}`}</DisplayInfo>
                        </Col>


                        <Col xs={12}>
                            <DisplayInfo
                                label={'FMI'}>{`${rule.fmi_remote_log}`}</DisplayInfo>
                        </Col>

                        <Col xs={24}>
                            <DisplayInfo
                                label={'FREQUÊNCIA'}>{`${rule.recurrence} vezes dentro de ${rule.interval} ${rule.frequency_type_name}`.toLowerCase()}</DisplayInfo>
                        </Col>
                    </>}
                {rule.rule_type === OIL_ANALYSIS &&

                    <>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Origem da coleta'}>{`${rule.laboratory_name}`}</DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Condição da amostra'}><BadgeCondition type={`${rule.condition_name}`}>
                                {`${rule.condition_name}`}
                            </BadgeCondition></DisplayInfo>
                        </Col>
                        <Col xs={12}>
                            <DisplayInfo
                                label={'Horímetro mínimo'}>{`${rule.hour}`}</DisplayInfo>
                        </Col>

                    </>}
            </>


        )
    }

    return (
        <>
            {isLoading ? <Loading/> : <>
                {rule.num_equipments === 0 &&
                    <Pagealert icon={<InfoCircleOutlined/>}
                               message={'Essa regra não possui equipamentos. Por favor, adicione pelo menos um equipamento na aba ‘Equipamentos’.'}/>}
                <DividerPage/>
                <InfoContainer>
                    <Row gutter={[24, 0]} align={'stretch'}>
                        <Col xs={24} lg={12}>
                            <Container noHorizontalPadding>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}><PageTitle
                                        value={'Informações da regra'}/></Col>
                                    <Col xs={12}>
                                        <DisplayInfo
                                            label={'NOME'}>{rule.name}</DisplayInfo>
                                    </Col>
                                    <Col xs={12}>
                                        <DisplayInfo
                                            label={'TIPO'}>{rule.type}</DisplayInfo>
                                    </Col> <Col xs={12}>
                                    <DisplayInfo
                                        label={'SEVERIDADE'}>{rule.priority_name}</DisplayInfo>
                                </Col>
                                    <Col xs={12}>
                                        <DisplayInfo
                                            label={'CRIADA EM'}>{dateFormat(rule.created_at)}</DisplayInfo>
                                    </Col> <Col xs={12}>
                                    <DisplayInfo
                                        label={'CÓDIGO'}>{rule.id}</DisplayInfo>
                                </Col>
                                </Row>
                            </Container>
                        </Col>
                        {!isDesktop && <DividerPage/>}
                        <Col xs={24} lg={12}>
                            <Container>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}><PageTitle
                                        value={'Variáveis'}/>
                                    </Col>


                                    {showRuleTypeInfo()}

                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <DividerPage/>
                    <Row>
                        <Container noHorizontalPadding>
                            <Col xs={24} md={24}>
                                <PageTitle value={'Comentário técnico'}/>
                            </Col>
                            <DisplayInfo
                                label={''}>{rule.tech_comment || 'O especialista não adicionou nenhum comentário técnico para este tipo de regra.'}</DisplayInfo>
                            <Row gutter={[16, 8]}>
                                {rule.files.map((file) =>
                                    <Col lg={8} xs={24}>
                                        <File file={file}/>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </Row>
                    <DividerPage/>
                    {/*<Row>*/}
                    {/*    <Container noHorizontalPadding>*/}
                    {/*        <Col xs={24} md={24}>*/}
                    {/*            <PageTitle value={'Usuários notificados'}/>*/}
                    {/*        </Col>*/}
                    {/*    </Container>*/}
                    {/*</Row>*/}
                    <DividerPage/>
                    <Row>
                        <Container noHorizontalPadding>
                            <Col xs={24} md={24}>
                                <PageTitle value={'Auditoria'}/>
                                {rule.events?.map((event) => {
                                    return <AuditCard event={event}/>
                                })}
                            </Col>
                        </Container>
                    </Row>
                </InfoContainer>

                {hasPermission && <>
                    <DividerPage/>
                    <Row style={{marginTop: 24}} gutter={16}>
                        <Col>
                            {rule.is_active ?
                                <Button danger type={'link'}
                                        onClick={() => _handleConfirmChangeStatus()}
                                        style={{padding: 0}}>Desativar</Button> :
                                <StyledButton type={'link'} color={"green"}
                                              onClick={() => _handleConfirmChangeStatus()}
                                              style={{padding: 0}}>Ativar</StyledButton>}
                        </Col>
                        <Col>
                            <Button type={'link'}
                                    onClick={() => navigate(`/rule/${rule.id}/update`)}
                                    style={{padding: 0}}>Alterar
                                informações</Button>
                        </Col>
                    </Row>
                </>}
            </>}

        </>

    )
}
