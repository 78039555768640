import React from 'react'
import {
    Badge as BadgeAnt,
} from "antd";
import styled from 'styled-components';

const StyledBadge = styled(BadgeAnt)`
    display: flex;
    align-items: center;
`

export default function AlertTelemetryStatus({value}) {
    return (
        <>
            <StyledBadge status={value === 'Online' ? 'green' : 'red'} text={value}/>
        </>
    )
}