import React, { useState } from "react";
import UpTable from "../../../components/Base/UPTable";
import GenericTelemetryOffCanvas from "../../../components/equipment/GenericTelemetryOffCanvas";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import useTable from "../../../hooks/useTable";

export function GenericTelemetricTable({ columns, url, search }) {
  const fetchCautionCodeData = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `${url}?page_size=${pagination.pageSize}&page=${
          pagination.current
        }&search=${search}&ordering=${order ? order : ""}`
      );

      setTableData(response.data.results);
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const { tableData, isLoading, tableParams, resetTable, handleTableChange } =
    useTable({ fetchData: fetchCautionCodeData, search });

  const [open, setOpen] = useState(false);
  const [telemetricRecord, setTelemetricRecord] = useState();

  return (
    <>
      <UpTable
        onChange={handleTableChange}
        loading={isLoading}
        showQuickJumper
        onRowClick={(record) => {
          setTelemetricRecord(record);
          setOpen(true);
        }}
        tableParams={tableParams}
        columns={columns}
        dataSource={tableData}
      />
      <GenericTelemetryOffCanvas
        record={telemetricRecord}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
