import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { Tabs } from "../../components/Base/Tabs";
import api from "../../services/api";
import { BuildFieldErrorsDict } from "../../util/Utils";
import { useParams } from "react-router-dom";

import useTab from "../../hooks/useTab";
import Loading from "../../components/Base/Loading";
import TabOverview from "./TabOverview";
import TabOpportunityDetail from "./TabOpportunityDetail";
import TabAttachment from "./TabAttachment";
import TabNearTechnician from "./TabNearTechnician";
import TabTimeline from "./TabTimeline";
import TabNearOpportunities from "./TabNearOpportunities";
import TabDocumentOpportunity from "./TabDocumentOpportunity";
import { Modal } from "antd";
import { useUserContext } from "../../context/UserContext";

export default function OpportunityDetail() {
  const { changeTitle, setHasBack } = useActionBar();

  const { setQueryTab, defaultTabId, tabName } = useTab({ defaultTab: "1" });
  const [opportunity, setOpportunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [isLoadingEquipment, setIsLoadingEquipment] = useState(true);
  const { user } = useUserContext();
  const opportunityID = params.id;
  const [equipment, setEquipment] = useState({});

  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [client, setClient] = useState({});

  useEffect(() => {
    changeTitle("Visualizar notificação");
    setHasBack("opportunity?load=1");
  }, [changeTitle, setHasBack]);

  useEffect(() => {
    if (opportunity.id) {
      fetchEquipment();
    }
  }, [opportunity]);  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (opportunity?.responsible?.responsible.id === user?.id) {
      if (
        opportunity.rule_name === "Revisão programada" &&
        user?.profiles.includes(4)
      ) {
        let message = "";
        if (!opportunity.PMP) {
          message =
            "Esse equipamento possui uma oportunidade de contratação de PMP.";
        } else if (opportunity.renew_PMP) {
          message =
            "Esse equipamento possui uma oportunidade de renovação de PMP.";
        }
        if (message !== "") {
          Modal.info({
            // title: 'This is a notification message',
            content: message,
            icon: null,
            okText: "Entendi",
            onOk() {},
          });
        }
      }
    }
  }, [user, opportunity]);

  const fetchEquipment = async () => {
    try {
      setIsLoadingEquipment(true);
      const response = await api.get(`equipment/${opportunity.equipment}`);
      setEquipment(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingEquipment(false);
    }
  };

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`opportunity/${opportunityID}`);
        setOpportunity(response.data);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOpportunity();
  }, [opportunityID]);

  const fetchClient = async () => {
    try {
      setIsLoadingClient(true);
      const response = await api.get(`sap/client/${opportunity.client_id}`);
      setClient(response.data);
    } catch (error) {
      // BuildFieldErrorsDict(error, null, false)
    } finally {
      setIsLoadingClient(false);
    }
  };
  useEffect(() => {
    if (opportunity.id) {
      fetchClient();
    }
  }, [opportunity]);  // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/*<DividerPage/>*/}
          <Tabs
            style={{
              position: "sticky !important",
              marginTop: -16,
            }}
            destroyInactiveTabPane={true}
            activeKey={defaultTabId}
            onChange={setQueryTab}
            items={[
              {
                label: "Visão geral",
                key: "1",
                children: (
                  <TabOverview
                    client={client}
                    isLoadingClient={isLoadingClient}
                    isLoadingEquipment={isLoadingEquipment}
                    equipment={equipment}
                    setOpportunity={setOpportunity}
                    opportunity={opportunity}
                  />
                ),
              },
              {
                label: "Especificações",
                key: "2",
                children: (
                  <TabOpportunityDetail
                    opportunity={opportunity}
                    isLoadingEquipment={isLoadingEquipment}
                    summary={opportunity.summary}
                  />
                ),
              },
              {
                label: "Ordens de Serviços",
                key: "3",
                children: (
                  <TabDocumentOpportunity
                    client={client}
                    isLoadingEquipment={isLoadingEquipment}
                    equipment={equipment}
                    setOpportunity={setOpportunity}
                    opportunity={opportunity}
                  />
                ),
              },
              {
                label: "Técnicos próximos",
                key: "4",
                children: <TabNearTechnician opportunity={opportunity} />,
              },
              {
                label: "Notificações próximas",
                key: "5",
                children: <TabNearOpportunities opportunity={opportunity} />,
              },
              {
                label: "Linha do tempo",
                key: "6",
                children: <TabTimeline opportunity={opportunity} />,
              },
              {
                label: "Anexos",
                key: "7", //se mudar aqui ,deve mudar no tabKey do componente TabAttachment
                children: (
                  <TabAttachment
                    tabName={tabName}
                    tabKey={"7"} //deve mudar aqui
                    opportunity={opportunity}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
