import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Select as SelectAnt } from "antd";
import Empty from "./Empty";
import Avatar from "./Avatar";
import { BuildFieldErrorsDict } from "../../util/Utils";
import api from "../../services/api";
import { input_border, neutral_5 } from "../../Cores";

const StyledCloseOutline = styled(CloseOutlined)`
  color: white;
`;
const StyledDownOutlined = styled(DownOutlined)`
  color: white;
  transition: 0.1s;
`;

const { Option } = SelectAnt;
const StyledSelect = styled(SelectAnt)`
  &.ant-select-disabled span.ant-select-selection-item {
    color: ${input_border};
  }

  & .ant-select-arrow .ant-select-suffi {
    color: white;
  }
  svg {
    color: ${neutral_5};
  }
`;

export default function Select({
  url,
  label_key = "name",
  noPagination,
  ...props
}) {
  const [options, setOptions] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await api.get(url);
      setOptions(
        response.data.results.map((item) => {
          return {
            value: item.id,
            label: item[`${label_key}`],
          };
        })
      );
    } catch (e) {
      BuildFieldErrorsDict(e, null);
    }
  };

  useEffect(() => {
    if (url) fetchOptions();
  }, []);

  return (
    <StyledSelect
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Selecione"
      notFoundContent={<Empty />}
      removeIcon={<StyledCloseOutline />}
      clearIcon={<StyledCloseOutline />}
      {...props}
    >
      {url &&
        options.map((option) => {
          return (
            <Option
              key={"option" + option.value}
              value={option.value}
              label={option.label}
            >
              {option.label}
            </Option>
          );
        })}
    </StyledSelect>
  );
}
