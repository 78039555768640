import React, { useState } from "react";
import styled from "styled-components";
import EquipmentTable from "../../Equipment/EquipmentTable";
import { Col, Row } from "antd";
import { TableSearch } from "../../../components/Base/UPTable";
import { get_filter_params } from "../../../util/TableStorageService";
import FilterIcon from "../../../components/Base/FilterIcon";
import { buildFormDataToFilter } from "../../../util/Utils";
import { INITIAL_EQUIPMENT_FORM_FILTERS } from "../../../util/EquipmentUtils";
import FilterEquipmentOffCanvas from "../../../components/equipment/FilterEquipmentOffCanvas";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabClientEquipment({ clientId, ...props }) {
  const [search, setSearch] = useState(get_filter_params("search"));
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(1);
  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter", INITIAL_EQUIPMENT_FORM_FILTERS)
  );
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(_initialFormFilter)
  );

  return (
    <TabContainer>
      <Row>
        <Col flex={24}>
          <TableSearch
            size={"large"}
            allowClear
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquise por n° série, chassi ou marca"}
          />
        </Col>

        <Col
          xs={2}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>
        <EquipmentTable
          search={search}
          setFilterCount={setFilterCount}
          clientId={clientId}
          formFilter={formFilter}
        />
        <FilterEquipmentOffCanvas
          open={openFilter}
          initialFormFilter={_initialFormFilter}
          formFilter={formFilter}
          setOpen={setOpenFilter}
          setFormFilter={setFormFilter}
        />
      </Row>
    </TabContainer>
  );
}
