import React from 'react';
import Avatar from "./Base/Avatar";
import styled from "styled-components";
import {text_color} from "../Cores";
import useSystemBreakpoint from "../hooks/useSystemBreakpoint";

const Container = styled.div`
  display: flex;
  gap: 8px;
  min-width: 36px;
  align-items: center;
  max-width: 350px;
  cursor: ${({hasClick}) => hasClick ? 'pointer' : 'default'}
  //width: min-content;
`
const NameContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${text_color};
`
const StyledName = styled.span`
  font-size: 14px;
  text-overflow: ellipsis;
  max-width: 150px;
  min-width: 50px;
  overflow: hidden;
  //background: red;
  display: inline-block;
`

export default function AvatarProfile({
                                          src,
                                          id,
                                          noActionClick,
                                          hiddenName = false,
                                          onClick,
                                          iconSize = 32,
                                          name,
                                          extra = null,
                                          ...props
                                      }) {

    const {isDesktop} = useSystemBreakpoint()
    return (
        <Container {...props}>
            <Container onClick={onClick} hasClick={!!onClick}>
                <Avatar
                    id={id}
                    name={name}
                    size={iconSize}
                    src={src}/>
                <NameContainer>
                    {(isDesktop && !hiddenName) &&
                        <StyledName>{name ? name : '-'}</StyledName>}
                    {!noActionClick && extra}
                </NameContainer>
            </Container>
            {noActionClick && extra}
        </Container>
    )
}