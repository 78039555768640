import React from 'react';
import { primary_color} from "../Cores";
import styled from 'styled-components'
import RemixIcon from "./Base/RemixIcon";

const RenewPMP = styled.div`
  color: ${primary_color};
`

export default function ({text = 'Sim'}) {
    return (
        <RenewPMP>
            <RemixIcon remixIconName={"ri-refresh-line"}/> {text}
        </RenewPMP>
    )
}