import React from 'react';
import styled from 'styled-components';
import {
    border_dark_color,
    progress, progress_bg, progress_border,
    success_bg,
    success_color
} from "../../Cores";

const Container = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;  
  white-space: nowrap;
  color: ${({color}) => color}; 
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;

  
 
`

const colors = [
    {
        type: 'success',
        borderColor: success_color,
        color: success_color,
        backgroundColor: success_bg
    },
    {
        type: 'inactive',
        borderColor: border_dark_color ,
        color: 'white',
        backgroundColor: '#222425'
    },
    {
        type: 'progress',
        borderColor: progress_border ,
        color: progress,
        backgroundColor: progress_bg
    },
]

export default function Badge({children, type = 'success', ...props}) {

    return (
        <Container {...props} {...colors.find((color) => type === color.type)}>
            {children}
        </Container>)
}