import React, { useState } from "react";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import { TableSearch } from "../../components/Base/UPTable";
import { get_filter_params } from "../../util/TableStorageService";
import OilTable from "../../components/Oil/tables/OilTable";
import SendMultipleAnalysisModal from "../../components/Oil/tables/SendMultipleAnalysisModal";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabOilHistory({ equipment, ...props }) {
  const [search, setSearch] = useState(get_filter_params("search"));
  const [selectedAnalysis, setSelectedAnalysis] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSendSelectedAnalysis = () => {
    setIsOpen(true);
  };

  return (
    <TabContainer>
      <Row align="middle" gutter={[8, 16]}>
        <Col xs={20} md={20}>
          <TableSearch
            size={"large"}
            allowClear
            defaultValue={search}
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquise por cod."}
          />
        </Col>
        <Col xs={4} md={4}>
          <Button
            onClick={handleSendSelectedAnalysis}
            type="primary"
            style={{ width: "100%" }}
            disabled={selectedAnalysis.length === 0}
          >
            Enviar análises
          </Button>
        </Col>
      </Row>

      <OilTable
        search={search}
        equipment={equipment}
        setSelectedAnalysis={setSelectedAnalysis}
      />
      <SendMultipleAnalysisModal
        clientID={equipment.client.id}
        open={isOpen}
        setClose={() => setIsOpen(false)}
        analysisIds={selectedAnalysis.map((analysis) => analysis.id)}
      />
    </TabContainer>
  );
}
