import { createGlobalStyle } from "styled-components";
import {
  actionbar_bg,
  active_order_table,
  clear_icon,
  Conditional,
  content_bg,
  gray_2,
  menu_bg,
  neutral_1,
  neutral_4,
  neutral_5,
  primary_1,
  primary_color,
  table_header,
} from "./Cores";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //font-size: 1rem;
    color: inherit;
  }

  html {
    scrollbar-color: #555 ${content_bg};
    scrollbar-width: thin;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5 h6 {
    font-weight: normal;
  }

  img {
    display: block;
    //max-width: 100%;
    width: 100%;

  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto' !important;
    //font-family: "Fira Code Retina" !important;

  }

  .ant-notification-notice-close {
    right: 10px;
  }

  .ant-modal-header, .ant-modal-footer {
    background: ${neutral_1};
    border: none;
  }

  .ant-modal-confirm-title, .ant-modal-title {
    color: white;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: white;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: black;
  }

  .ant-radio-button-wrapper-disabled {
    color: white;
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    border-color: ${primary_color}
  }

  .ant-radio-button-wrapper-disabled:first-child:hover {
    color: black;
  }

  .ant-radio-button-wrapper-disabled:last-child:hover {
    color: white;
  }

  .ant-table-thead th.ant-table-column-sort {
    background: ${active_order_table};
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #ffce3b;
    color: black;
  }

  .ant-input-clear-icon .anticon-close-circle svg {
    color: ${clear_icon};
  }

  .ant-input-clear-icon .anticon-close-circle:hover svg {
    color: #ffce3b;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${content_bg};

  }

  ::-webkit-scrollbar-corner {
    background: ${content_bg} !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${neutral_5};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-tabs-nav-operations {
    border: 1px solid #565D62;
  }

  .ant-pagination-item-ellipsis {
    color: white !important;
  }


  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${clear_icon};
  }

  .ant-menu-inline-collapsed-tooltip a, .ant-menu-inline-collapsed-tooltip a:hover {
    color: black;
  }

  .ant-pagination-item a {
    font-family: 'Roboto';
  }

  .ant-input-textarea-show-count::after {
    color: ${clear_icon};
  }

  .ant-upload-list-item-done .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: ${clear_icon};
  }

  .ant-upload-list-item-card-actions .anticon {
    color: ${clear_icon};
  }

  .ant-upload-list-item-card-actions:hover .anticon {
    color: red;
  }


  .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover, .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
    background: ${table_header} !important;
  }

  .ant-picker-panel-container {
    background: ${Conditional};

  }

  .ant-picker-panel {
    border: none;
  }


  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: ${primary_1};
    color: black;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: black;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start:before,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start:hover:before,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end:hover:before,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end:before {
    background: ${primary_1};
  }

  .ant-picker-header-view {
    color: white;
  }

  .ant-picker-clear:hover {
    path {
      fill: ${primary_color};
    }
  }

  .ant-picker-clear {
    background: ${neutral_1};
  }

  .ant-picker-header {
    border: none;
    background: ${neutral_1};

    button {
      color: ${neutral_5};
    }
  }

  .goog-tooltip {
    color: black;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
    color: ${gray_2};
  }
  

.ant-modal-wrap {
    z-index: 1001;
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: block;
}
`;

export default GlobalStyle;
