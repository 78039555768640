import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { primary_color } from "../../Cores";

const StyledClick = styled.span`
  color: ${primary_color};
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
`;
export default function ArrowLink({ label, href, ...props }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (props.to) {
      const { pathname, state, search } = props.to;

      if (state) {
        return navigate(pathname + search, { state });
      }

      return navigate(props.to);
    }
  };

  return href ? (
    <StyledClick onClick={() => navigate(href)}>
      <span>{label}</span>
      <ArrowRightOutlined />
    </StyledClick>
  ) : (
    <NavLink {...props} onClick={handleClick}>
      {label} <ArrowRightOutlined />
    </NavLink>
  );
}
