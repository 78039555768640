import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {CloseOutlined} from "@ant-design/icons";
import {Form, Modal} from "antd";
import RadioButton from "../../components/Base/Forms/RadioButton";


const Container = styled.div`
  height: 374px;
  overflow: hidden;

`
const ContentContainer = styled.div`
  //height: 204px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

`


const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`


export default function FilterOsModal({isModalOpen, valuesFilter, setClose, setValues}) {


    const onClose = () => {
        setClose(false);
    }

    function handleRadioButton({target}) {
        setValues((values) => ({...values, [target.name]: target.value}))
    }

    return (
        <Modal open={isModalOpen} width={'350px'}
               bodyStyle={{padding: 0}}
               footer={null} closable={false} onCancel={onClose}>
            <StyledCloseOutlined onClick={onClose}/>
            <ContentContainer>

                {/*<Form.Item>*/}
                <RadioButton name={'order'} label={'Ordenação'}
                             defaultValue={valuesFilter.order}
                             onChange={handleRadioButton}
                             options={[
                                 {value: false, label: 'Mais recente'},
                                 {value: true, label: 'Mais antiga'},
                             ]}/>
                {/*</Form.Item>*/}
                {/*<Form.Item>*/}
                <RadioButton name={'filter'} label={'Filtro'}
                               defaultValue={valuesFilter.filter}
                             onChange={handleRadioButton} options={[
                    {value: 365, label: 'Último ano: Exibir as OS do equipamento geradas nos últimos 365 dias'},
                    {value: 730, label: 'Últimos 02 anos: Exibir as OS do equipamento geradas nos últimos 730 dias'},
                    {value: -1, label: 'Histórico completo: Exibir todas as OS retornadas pela API;'},
                ]}/>
                {/*</Form.Item>*/}
            </ContentContainer>

        </Modal>

    );
}

