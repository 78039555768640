import {Space} from "antd";
import React, {useState} from "react";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import FilterCentersOffCanvas from "../FilterCentersOffCanvas";
import {useUserContext} from "../../context/UserContext";
import BadgeCount from "./BadgeCount";


export default function FilterCenterActionBar() {


    const {isDesktop} = useSystemBreakpoint();
    const {userCentersFilter} = useUserContext()
    const [open, setOpen] = useState(false)

    return (<div style={{cursor: 'pointer'}}>
            <BadgeCount onClick={() => {
                setOpen(true);
            }}
                        count={userCentersFilter.length}
            >
                <Space style={{marginRight: 16}}>
                    <i className="ri-map-2-line"
                       style={{color: "white", fontSize: 24,}}/>
                    {isDesktop && 'Centros selecionados'}
                </Space>

            </BadgeCount>
            <FilterCentersOffCanvas open={open} setOpen={setOpen}/>
        </div>
    )

}