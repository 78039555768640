import useForm from "../../../hooks/useForm";
import {Checkbox as CheckboxAnt, Checkbox, Space} from 'antd';
import InputGroup from "./InputGroup";
import styled from 'styled-components'
import {input_border} from "../../../Cores";


const StyledCheckbox = styled(CheckboxAnt)`
  ${({align}) => align? `align-items: ${align};`:'' }

  & span.ant-checkbox-inner {
    border-color: ${input_border};
  }

`


export default ({
                    setData,
                    label,
                    error,
    align,
                    name,
                    onChange,
                    direction = 'vertical',
                    options,
                    renderContent = null,
                    ...props
                }) => {
    const {onChangeCheckedMult} = useForm()


    const onChangeInternal = (values) => {
        onChangeCheckedMult(name, values, setData)
        onChange?.(values);
    };

    return (
        <InputGroup label={label} error={error}>

            <div>

                <Checkbox.Group onChange={onChangeInternal} {...props}>
                    <Space direction={direction} >
                        {options.map(({label, ...options}, index) => (

                            <StyledCheckbox align={align}
                                key={`${name}-${label}-${index}`}
                                name={name} {...options}>
                                {renderContent ? renderContent({
                                    label,
                                    ...options
                                }, index) : (`${label}`)}
                            </StyledCheckbox>
                        ))}

                    </Space>
                </Checkbox.Group>

            </div>


        </InputGroup>

    )
}