import { Row, Col, Form, Select as AntSelect } from "antd";
import Select from "../../Base/Select";
import PageTitle from "../../Base/PageTitle";
import FormInfo from "./FormInfo";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import InputNumber from "../../Base/Forms/InputNumber";

export default function AlertFormContainer({ updateMode }) {
  const [telemterySystemOptions, setTelemetrySystemOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await api.get("telemetry_sistems?page_size=1000");
      setTelemetrySystemOptions(
        response.data.results
          .filter((item) => item.id === 5)
          .map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
      );
    };

    fetchOptions();
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <PageTitle value={"Variáveis da regra"} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <FormInfo
            label={
              "Verifique todas as informações a respeito da regra. Regras não podem ser apagadas após serem criadas, mas podem ser desativadas."
            }
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={"Sistema"}
            name={"telemetry_system"}
            rules={[
              {
                required: true,
                message: "Sistema é obrigatório",
              },
            ]}
          >
            <AntSelect
              disabled={updateMode}
              placeholder="Selecione o sistema"
              options={telemterySystemOptions}
            />
          </Form.Item>
          <Form.Item
            label={"SPN"}
            name={"spn_kalmar"}
            rules={[
              {
                required: true,
                message: "SPN é obrigatório",
              },
            ]}
          >
            <InputNumber placeholder="Digite o SPN" />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={"FMI"}
            name={"fmi_kalmar"}
            rules={[
              {
                required: true,
                message: "FMI é obrigatório",
              },
            ]}
          >
            <InputNumber placeholder="Digite o FMI" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <PageTitle value={"Frequência"} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <FormInfo
            label={
              "Selecione qual o tipo de variável de tempo (dias, horas) e o intervalo de tempo em que a frequência deverá atuar. Por exemplo: caso a variável seja dias, o intervalo 3, e a quantidade de ocorrências 5, isso significa que só será criada uma notificação se, dentro de 3 dias, aquela regra tenha uma ocorrência de 5 vezes."
            }
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={"Tipo da frequência"}
            name={"frequency_type"}
            rules={[
              {
                required: true,
                message: "Selecione o tipo da frequência",
              },
            ]}
          >
            <Select
              url={"frequency_type?page_size=1000"}
              placeholder="Selecione o tipo da frequência"
            />
          </Form.Item>
          <Form.Item
            label="Quantidade de ocorrências"
            name="recurrence"
            rules={[
              {
                required: true,
                message: "Quantidade de ocorrências é obrigatório",
              },

              {
                pattern: /^[1-9]+[0-9]*$/,
                message: "Informe um número maior que zero",
              },
            ]}
          >
            <InputNumber placeholder="Digite a quantidade de ocorrências mínimas" />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label="Intervalo de tempo"
            name="interval"
            rules={[
              {
                required: true,
                message: "Intervalo de tempo é obrigatório",
              },
              {
                pattern: /^[1-9]+[0-9]*$/,
                message: "Informe um número maior que zero",
              },
            ]}
          >
            <InputNumber placeholder="Digite o intervalo de tempo em números" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
