import React, {useEffect, useState} from 'react';
import {
    Button,


} from "antd";

import OffCanvas from "../Base/OffCanvas";
import styled from "styled-components";
import { text_color} from "../../Cores";


import {Tabs} from "../Base/Tabs";
import TabMentionOpportunityOffCanvas from "./TabMentionOpportunityOffCanvas";
import TabNewOpportunityOffCanvas from "./TabNewOpportunityOffCanvas";

import BadgeTabsCount from "../Base/BadgeTabsCount";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import useOffCanvas from "../../hooks/useOffCanvas";


const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`


const NEW_OPPORTUNITY_TAB = '1';
const MENTION_OPPORTUNITY_TAB = '2';

export default function NotificationOffCanvas({
                                                  open,
                                                  setOpen,
                                                  setAmoutNotification
                                              }) {


    const {fetchData} = useDefaultFetch()
    const {onClose} = useOffCanvas({open, setOpen})
    const {postData, isLoading: loadingReadNotification} = useDefaultFetch()
    const [currentTab, setCurrentTab] = useState(NEW_OPPORTUNITY_TAB)

    const [amountNewOpportunity, setAmountNewOpportunity] = useState(0)
    const [amountMentionsOpportunity, setAmountMentionsOpportunity] = useState(0)
    const [mentionOpportunitysNotification, setMentionOpportunitysNotification] = useState([]);
    const [newOpportunitysNotification, setNewOpportunitysNotification] = useState([]);

    useEffect(() => {
        if (open) {
            async function fetch() {
                const new_opp = await fetchData(`notification/new_opportunities?page=1&page_size=1&read=false`)
                setAmountNewOpportunity(new_opp.count)
                const mentions_opp = await fetchData(`notification/mentions?page=1&page_size=1&read=false`)
                setAmountMentionsOpportunity(mentions_opp.count)
            }

            fetch()
        }
    }, [open])

    function decrementAmoutNotification (){
        setAmoutNotification((amount) => amount - 1)
    }
    function decrementAmountTab (setAmount){
        setAmount((amount) => amount - 1)
    }
    async function onSubmit() {
        await postData(`notification/read_notifications`)
        setAmoutNotification(0)
        setAmountMentionsOpportunity(0)
        setAmountNewOpportunity(0)
        setNewOpportunitysNotification((newOpps) => {
            return newOpps.map((item) => {
                return {...item, read:true}
            })
        })

        setMentionOpportunitysNotification((mentionOpps) => {
            return mentionOpps.map((item) => {
                return {...item, read:true}
            })
        })
    }

    return (
        <OffCanvas
            destroyOnClose
            showBorderHeader={false}
            title={
                <Title level={4}>Notificações</Title>

            }
            onClose={onClose}
            open={open} width={'394px'} footer={
            <>

                <Button type="primary" onClick={onSubmit}
                        loading={loadingReadNotification}>Marcar todas como
                    lida</Button>
            </>

        }>
            <Tabs
                destroyInactiveTabPane={true}
                isOffCanvasParent
                activeKey={currentTab}

                onChange={setCurrentTab}
                items={[
                    {
                        label: <BadgeTabsCount
                            isActive={currentTab === NEW_OPPORTUNITY_TAB}
                            count={amountNewOpportunity}>Novas
                            notificações</BadgeTabsCount>,
                        key: NEW_OPPORTUNITY_TAB,
                        children: <TabNewOpportunityOffCanvas
                            decrementAmoutNotification={decrementAmoutNotification}
                            decrementAmountTab={() => decrementAmountTab(setAmountNewOpportunity)}
                            setNewOpportunitysNotification={setNewOpportunitysNotification}
                            newOpportunitysNotification={newOpportunitysNotification}/>
                    },
                    {
                        label: <BadgeTabsCount
                            isActive={currentTab === MENTION_OPPORTUNITY_TAB}
                            count={amountMentionsOpportunity}>Acompanhamentos</BadgeTabsCount>,
                        key: MENTION_OPPORTUNITY_TAB,
                        children: <TabMentionOpportunityOffCanvas
                             decrementAmoutNotification={decrementAmoutNotification}
                            decrementAmountTab={() => decrementAmountTab(setAmountMentionsOpportunity)}
                            setMentionOpportunitysNotification={setMentionOpportunitysNotification}
                            mentionOpportunitysNotification={mentionOpportunitysNotification}/>
                    },
                ]}/>

        </OffCanvas>
    )

}

