import React from 'react';
import styled from "styled-components";
import {switch_bg} from "../../Cores";
import {Switch as SwitchAnt} from "antd";

export const StyledSwitch = styled(SwitchAnt)`
  :not(.ant-switch-checked) {
    background-color: ${switch_bg};
  }
`


export default function Switch({...props}){
    return <StyledSwitch size={"small"} {...props}/>
}