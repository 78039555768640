import React from 'react'
import styled from "styled-components";
import {primary_color, text_color} from "../../Cores";
import {NavLink} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";


const HeaderContainer = styled.div`
  width: 100%;
  height: 148px;
  position: relative;


  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: ${({hasImage}) => hasImage ? 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(1, 1, 1, 1) 10%, rgba(52, 52, 52, 0.8) 50%)' : 'black'};
    position: absolute;
    top: 0;
    z-index: 1;
  }

`
const Image = styled.img`
  width: 100%;
  height: 148px;
  object-fit: cover;

`


const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  z-index: 2;

  & > * {
    flex-shrink: 0;
  }
`

const HeaderContent = styled.div`
  padding: 64px 16px 16px;

  display: flex;
  flex-direction: column;
`


const Ellipse = `
 white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
`

const Chassi = styled.span`

  font-size: 16px;
  color: ${primary_color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 197px;
  ${Ellipse};
  text-transform: uppercase;
`

const Name = styled(NavLink)`
  font-weight: 700;
  font-size: 20px;
  ${Ellipse};
  color: ${primary_color};
  text-transform: uppercase;
`
const Brand = styled.span`
  ${Ellipse};
  color: ${text_color};
  font-size: 12px;
`
const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`
export default function HeaderModalMap({
                                           image,
                                           title,
                                           subtitle1,
                                           subtitle2,
                                           onClose
                                       }) {

    function valueOrEmpty(value){
        if(value) return value
        return '-'
    }
    return (
        <HeaderContainer
            hasImage={!!image}>
            <Image
                src={image}/>
            <Header>
                <HeaderContent>
                    <Chassi title={valueOrEmpty(subtitle1)}>{valueOrEmpty(subtitle1)}</Chassi>
                    <Name title={valueOrEmpty(title)}>{valueOrEmpty(title)}</Name>
                    <Brand>{valueOrEmpty(subtitle2)}</Brand>
                </HeaderContent>

            </Header>
            <StyledCloseOutlined onClick={onClose}/>
        </HeaderContainer>
    )
}