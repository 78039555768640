import React, { useEffect, useState } from "react";

import { Button, Col, Form, Input, Row } from "antd";
import PageTitle from "../../../components/Base/PageTitle";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import Loading from "../../../components/Base/Loading";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import { Notification } from "../../../notification";
import InputNumber from "../../../components/Base/Forms/InputNumber";
import styled from "styled-components";
import { useParameters } from "../../../context/ParametersContext";

const SessionTitle = styled(PageTitle)`
  font-size: 14px;
  font-weight: 500;
`;

export default function Settings() {
  const [form] = Form.useForm();

  const { updateParameters } = useParameters();
  const { fetchData, isLoading } = useDefaultFetch();
  const [rules, setRules] = useState([]);
  const [systemParameters, setSystemParameters] = useState({});
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [hasChange, setHasChange] = useState({});

  useEffect(() => {
    async function fetch() {
      const data = await fetchData(`rule_type`);
      if (data) {
        setRules(data.results);
      }

      const systemParametersData = await fetchData(`parameters`);
      if (systemParametersData) {
        const systemParamsObject = systemParametersData.reduce(
          (acc, item) => ({ ...acc, [item.slug]: item.value }),
          {}
        );
        setSystemParameters(systemParamsObject);
      }
    }

    fetch();
  }, []);

  async function handleSubmit() {
    try {
      setIsLoadingSubmit(true);
      for (const id of Object.keys(hasChange)) {
        await api.patch(`rule_type/${id}`, { days_to_cancel: hasChange[id] });
      }
      setHasChange({});
      Notification("success", "Configurações atualizada com sucesso");
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  function handleChange(values, all) {
    setHasChange({ ...hasChange, ...values });
  }
  function handleDisabledButton() {
    return !(Object.keys(hasChange).length > 0);
  }

  async function handleSystemParamsSubmit() {
    try {
      setIsLoadingSubmit(true);
      const updateData = Object.keys(systemParameters).map((key) => ({
        slug: key,
        value: systemParameters[key],
      }));
      await api.patch(`parameters/update_system_parameters`, updateData);
      Notification("success", "Configurações atualizada com sucesso");
      updateParameters();
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  function handleSystemParamsChange(values, all) {
    setSystemParameters({ ...systemParameters, ...values });
  }
  function handleSystemParamsDisabledButton() {
    const totalParameters = Object.keys(systemParameters).length;
    const parameterValues = Object.values(systemParameters);
    return totalParameters === parameterValues;
  }

  return (
    <>
      <Form form={form} layout={"vertical"} onValuesChange={handleChange}>
        <Row gutter={[16, 0]} style={{ marginBottom: "2rem" }}>
          <Col xs={24}>
            <PageTitle
              value={"Cancelamento automático de oportunidades por inatividade"}
            />
          </Col>
          {isLoading ? (
            <Col xs={24}>
              <Loading />
            </Col>
          ) : (
            <>
              {rules.length > 0 ? (
                rules.map(({ name, id, days_to_cancel }) => (
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={`Tempo para cancelamento de ${name.toLowerCase()} (em dias)`}
                      name={id}
                      initialValue={days_to_cancel}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber placeholder="Digite o valor" />
                    </Form.Item>
                  </Col>
                ))
              ) : (
                <></>
              )}
              <Col xs={24}>
                <Button
                  disabled={handleDisabledButton()}
                  loading={isLoadingSubmit}
                  onClick={handleSubmit}
                  type="primary"
                >
                  Salvar
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleSystemParamsChange}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24}>
            <PageTitle value={"Parâmetros do sistema"} />
          </Col>
          {isLoading ? (
            <Col xs={24}>
              <Loading />
            </Col>
          ) : (
            <>
              <Col xs={24} md={12}>
                <Form.Item
                  label={`Quantidade de dias para considerar horímetro do G4`}
                  name="days_to_consider_g4"
                  initialValue={systemParameters.days_to_consider_g4}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber placeholder="Digite o valor" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={`Dias máximos de idade do horímetro do G4`}
                  name="max_day_age_g4"
                  initialValue={systemParameters.max_day_age_g4}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber placeholder="Digite o valor" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <SessionTitle value={"Confiabilidade do horímetro"} />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Quantidade de dias para alta confiabilidade (Verde)"
                  name="green_hourmeter_max_age"
                  initialValue={systemParameters.green_hourmeter_max_age}
                  rules={[{ required: true }]}
                >
                  <InputNumber placeholder="Digite o valor em dias" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Quantidade de dias para média confiabilidade (Amarelo)"
                  name="yellow_hourmeter_max_age"
                  initialValue={systemParameters.yellow_hourmeter_max_age}
                  rules={[{ required: true }]}
                >
                  <InputNumber placeholder="Digite o valor em dias" />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Button
                  disabled={handleSystemParamsDisabledButton()}
                  loading={isLoadingSubmit}
                  onClick={handleSystemParamsSubmit}
                  type="primary"
                >
                  Salvar
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
}
