
import useForm from "../../../hooks/useForm";
import {InputNumber} from 'antd';
import InputGroup from "./InputGroup";
import {formaterOnlyNumbers} from "../../../util/Utils";



export default ({setData, label, error, ...props}) => {
    const {onChange} = useForm()

    return (

        <InputGroup label={label} error={error}>
            <InputNumber
                style={{width:'100%'}}
                controls={false}
                status={error && 'error'}
                formatter={(value) => formaterOnlyNumbers(value)}
                parser={(value) => value.replaceAll('.', '')}
                onChange={(e) => onChange(e, setData)} {...props}/>
        </InputGroup>

    )
}