import React from "react";
import { Col, Form, Row } from "antd";
import PageTitle from "../../Base/PageTitle";
import Select from "../../Base/Select";
import InputNumber from "../../Base/Forms/InputNumber";
import { formaterThousandSeparator } from "../../../util/Utils";
import FormInfo from "./FormInfo";
import Checkbox from "../../Base/Forms/CheckBoxSingle";

export default function ScheduledReviewFormContainer({ updateMode }) {
  const system_field = Form.useWatch("telemetry_system");

  return (
    <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
      <Col lg={24} md={24} sm={24} xs={24}>
        <PageTitle value={<> Variáveis da regra </>} />
      </Col>
      <Col lg={24} md={24} sm={24} xs={24}>
        <FormInfo
          label={
            "Verifique todas as informações a respeito da regra. Regras não podem ser apagadas após serem criadas, mas podem ser desativadas."
          }
        />
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label={"Sistema"}
          name={"telemetry_system"}
          rules={[
            {
              required: true,
              message: "Sistema é obrigatório",
            },
          ]}
        >
          <Select
            disabled={updateMode}
            placeholder="Selecione o sistema"
            url={"telemetry_sistems?page_size=1000"}
          />
        </Form.Item>

        {system_field === 3 && (
          <Form.Item valuePropName="checked" name="is_first_time_only">
            <Checkbox labelCheck={"Regra de primeira revisão"} />
          </Form.Item>
        )}
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label="Padrão do horímetro para revisão"
          name="hour"
          rules={[
            {
              required: true,
              message: "Horimetro é obrigatório",
            },
          ]}
        >
          <InputNumber
            formatter={(value) => formaterThousandSeparator(value)}
            placeholder="Digite o valor em horas"
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
