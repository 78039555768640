import React, {useEffect, useState} from 'react';
import RuleContainerInfo from "./RuleContainerInfo";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import OilDetailInfo from "../../../components/Oil/tables/OilDetailInfo";
import DividerPage from "../../../components/Base/DividerPage";
import Loading from "../../../components/Base/Loading";
import {Notification} from "../../../notification";

export default function OilAnalysisOpportunity({opportunity}) {

    const [oil, setOil] = useState({})
    const {fetchData, isLoading} = useDefaultFetch()
    // const [form] = Form.useForm();

    useEffect(() => {
        // if (oilId) {
        async function fetch() {
            const oilData = await fetchData(`oil_analysis/${opportunity.object_id}`)
            if (oilData) {
                setOil(oilData)
            }
        }

        if (opportunity.object_id) {
            fetch()
        }else{
            Notification('error', 'Não encontrado, contate o suporte!')
        }
        // }
    }, [])


    return (<>
        {isLoading ? <Loading/> : (
            <>
                <OilDetailInfo oil={oil}/>
                <DividerPage style={{marginTop: 16}}/>
                <RuleContainerInfo opportunity={opportunity}/></>)}
    </>)
}

