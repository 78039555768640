import React, { useEffect, useState } from "react";
import { useActionBar } from "../../../context/ActionBarContext";
import api from "../../../services/api";
import {
  BuildFieldErrorsDict,
  buildFormDataToFilter,
} from "../../../util/Utils";
import { Col, Row } from "antd";
import DividerPage from "../../../components/Base/DividerPage";
import ContainerWithoutLimit from "../../../components/ContainerWithoutLimit";
import Maps from "../../../components/Base/Maps/Maps";
import PageTitle from "../../../components/Base/PageTitle";
import StatisticCard from "../../../components/Base/StatisticCard";
import EquipmentModalMaps from "../../../components/Base/Maps/EquipmentModalMaps";
import FilterIcon from "../../../components/Base/FilterIcon";
import {
  MAP_MARKER_NEAR_TECHNICIAN
} from "../../../components/Base/Maps/MapMarkerFactory";
import useActionPermission from "../../../hooks/useActionPermission";
import CenterUptimeOffCanvas from "../../../components/equipment/CenterUptimeOffCanvas";
import FilterEquipmentDashboardOffCanvas from "../../../components/FilterEquipmentDashboardOffCanvas";
import { get_filter_params } from "../../../util/TableStorageService";

const WORKING_TECHNICIAN_STATUS = [
  "ATENDIMENTO",
  "EM DESLOCAMENTO",
  "AGUARDANDO PEÇA",
  "ALMOÇO",
  "ATENDIMENTO CLIENTE",
  "DESLOCAMENTO INTERMEDIÁRIO",
  "INTEGRAÇÃO/SEGURANÇA",
  "LANCHE",
  "PERNOITE",
  "TRÂNSITO",
  "PARADO",
  "DESLOCAMENTO_VOLTA",
  "DESLOCAMENTO_IDA",
];

export default function GeneralTabDashboard({}) {
  const { changeTitle, setHasBack } = useActionBar();

  const [equipments, setEquipments] = useState([]);
  const [currentEquipment, setCurrentEquipment] = useState({});
  const [isModalEquipmentOpen, setIsModalEquipmentOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(4);

  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter")
  );
  const { hasPermission } = useActionPermission({ permissions: [1, 2, 4, 5] });
  const [openCenter, setOpenCenter] = useState();
  const [statistics, setStatistics] = useState();
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [nearTechnicians, setNearTechnicians] = useState([]);
  const initialFormFilter = {};
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(initialFormFilter)
  );

  useEffect(() => {
    changeTitle("Dashboards");
    setHasBack(false);

    return () => {
      setHasBack(true);
    };
  }, [changeTitle, setHasBack]);

  function handleOpenOffCanvasCenter(equipment) {
    setIsModalEquipmentOpen(false);
    setOpenCenter(true);
    setCurrentEquipment(equipment);
  }

  function handleCloseOffCanvasCenter(equipment) {
    setIsModalEquipmentOpen(true);
    setOpenCenter(false);
  }

  useEffect(() => {
    fetchEquipments();
    fetchStatisticValues();
  }, [formFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchEquipments = async () => {
    try {
      setIsLoadingMap(true);
      const response = await api.post(
        `/manager_dashboard/load_map`,
        formFilter
      );
      setEquipments(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingMap(false);
    }
  };

  const getCoords = () => {
    return equipments.map((eq) => {
      return {
        id: eq.id,
        lat: eq.latitude,
        lng: eq.longitude,
        // type: MAP_MARKER_EQUIPMENT
      };
    });
  };

  const fetchStatisticValues = async () => {
    try {
      const response = await api.post(
        `/get_dashboard_general_statistics`,
        formFilter
      );
      setStatistics(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      // setIsLoading(false)
    }
  };

  const fetchData = async () => {
    setIsLoadingMap(true);
    const promises = [];
    promises.push(api.get(`/g4/technical/`));
    let data = [];
    await Promise.allSettled(promises)
      .then(
        ([
          technical,
          // , equipments, opportunities
        ]) => {
          if (technical.status === "fulfilled") {
            setNearTechnicians(technical.value.data);
            data = [
              ...technical.value.data
                .filter((item) => item.id && item.latitude && item.longitude)
                .map((item) => ({
                  ...item,
                  id: item.id,
                  lat: item.latitude,
                  lng: item.longitude,
                  type: MAP_MARKER_NEAR_TECHNICIAN,
                })),
            ];
          }
        }
      )
      .catch((err) => console.log("error", err));

    setIsLoadingMap(false);
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <DividerPage />
        </Col>

        <Col xs={22} md={23} style={{ marginTop: 16 }}>
          <PageTitle isInline value={"Mapa geral"} />
        </Col>
        <Col
          xs={2}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>
        <Col xs={24}>
          <ContainerWithoutLimit>
            <Maps
              height={"666px"}
              isLoading={isLoadingMap}
              defaultZoom={5}
              coords={getCoords()}
              onMarkerClick={(data) => {
                setCurrentEquipment(equipments.find((eq) => eq.id === data.id));
                setIsModalEquipmentOpen(true);
              }}
            />
          </ContainerWithoutLimit>
        </Col>

        {/*<TechnicianLegendMap/>*/}
        <Col xs={24}>
          <DividerPage style={{ marginTop: 16 }} />
        </Col>

        <PageTitle
          style={{ marginTop: 16 }}
          // value={'Estatísticas da frota (em relação ao mês anterior)'}/>
          value={"Estatísticas gerais"}
        />
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12} lg={6}>
              {/*<StatisticCard number={statistics?.all_equipments} percent={statistics?.all_equipments_percent}*/}
              {/*               name={'Equipamentos no Uptime Center'}/>*/}

              <StatisticCard
                number={statistics?.all_equipments}
                name={"Equipamentos (total)"}
              />
            </Col>
            <Col xs={24} md={12} lg={6}>
              {/*<StatisticCard number={statistics?.equipments_with_telemetry} percent={statistics?.equipments_with_telemetry_percent}*/}
              {/*               name={'Equipamentos com telemetria'}/>*/}

              <StatisticCard
                number={statistics?.all_opportunities}
                name={"Notificações (total)"}
              />
            </Col>
            <Col xs={24} md={12} lg={6}>
              {/*<StatisticCard number={statistics?.equipments_actived} percent={statistics?.equipments_actived_percent}*/}
              {/*               name={'Equipamentos ativos (enviando dados de telemetria)'}/>*/}

              <StatisticCard
                number={nearTechnicians.length}
                name={"Técnicos (total)"}
              />
            </Col>
            <Col xs={24} md={12} lg={6}>
              {/*<StatisticCard number={statistics?.equipments_actived} percent={statistics?.equipments_actived_percent}*/}
              {/*               name={'Equipamentos ativos (enviando dados de telemetria)'}/>*/}

              <StatisticCard
                number={
                  nearTechnicians.filter(
                    (item) =>
                      !!item.status &&
                      WORKING_TECHNICIAN_STATUS.includes(
                        item.status.toUpperCase()
                      )
                  ).length
                }
                name={"Técnicos (em atendimento)"}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <EquipmentModalMaps
        isModalOpen={isModalEquipmentOpen}
        id={currentEquipment.id}
        handleOpenOffCanvasCenter={handleOpenOffCanvasCenter}
        hasPermission={hasPermission}
        setClose={setIsModalEquipmentOpen}
      />

      {hasPermission && currentEquipment && (
        <CenterUptimeOffCanvas
          setObject={setCurrentEquipment}
          open={openCenter}
          setOpen={setOpenCenter}
          extraOnClose={handleCloseOffCanvasCenter}
          centerId={currentEquipment?.center?.id}
          url={`equipment/${currentEquipment.id}/change_center`}
        />
      )}

      <FilterEquipmentDashboardOffCanvas
        open={openFilter}
        setOpen={setOpenFilter}
        initialFormFilter={_initialFormFilter}
        formFilter={formFilter}
        setFormFilter={setFormFilter}
      />
    </>
  );
}
