import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import PageTitle from "../../components/Base/PageTitle";
import Select from "../../components/Base/Select";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import Maps from "../../components/Base/Maps/Maps";

import DividerPage from "../../components/Base/DividerPage";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import {
  MAP_MARKER_AVAILABLE_TECHNICIAN,
  MAP_MARKER_BUSY_TECHNICIAN,
  MAP_MARKER_NEAR_TECHNICIAN,
  MAP_MARKER_OPPOTUNITY,
  MAP_MARKER_RETURNED_TECHNICIAN,
  MAP_MARKER_STOPPED_TECHNICIAN,
} from "../../components/Base/Maps/MapMarkerFactory";

import NearTechnicianModalMaps from "../../components/Base/Maps/NearTechnicianModalMaps";
import NoLocation from "../../components/Base/NoLocation";
import FilterButtons from "../../components/Base/FilterButtons";
import Switch from "../../components/Base/Switch";
import Legend from "../../components/Base/Legend";
import TechnicianLegendMap from "../../components/TechnicianLegendMap";

const TabContainer = styled.div``;
const ColAmountTechnician = styled(Col)`
  display: flex;
  align-self: center;
  justify-content: end;
  white-space: nowrap;
  gap: 8px;
`;

export default function TabNearTechnician({ opportunity, ...props }) {
  const { fetchData, isLoading } = useDefaultFetch();
  const [currentNearTechnician, setCurrentNearTechnician] = useState({});
  const [nearTechnicians, setNearTechnicians] = useState([]);
  const [cloneNearTechnicians, setCloneNearTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState("all");
  const [techniciansList, setTechniciansList] = useState([]);
  const { isTablet, isDesktop } = useSystemBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onlyAvailable, setOnlyAvailable] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("all");
  const [filterOptions, setFilterOptions] = useState([]);

  function generatePageTitle() {
    const _amountTechnician = cloneNearTechnicians.length;
    let title;
    if (_amountTechnician === 0) {
      title = `Nenhum resultado encontrado`;
    } else {
      title = `${_amountTechnician} técnico próximo`;
      if (_amountTechnician > 1) {
        title = `${_amountTechnician} técnicos próximos`;
      }
    }
    return title;
  }

  useEffect(() => {
    async function fetch() {
      const data = await fetchData(
        `/g4/technical?latitude=${opportunity.position?.latitude}&longitude=${opportunity.position?.longitude}`
      );
      const validated_data = data.filter(
        (item) => item.id && item.latitude && item.longitude
      );
      validated_data.sort((a, b) => {
        if (a.distance < b.distance) {
          return -1;
        }
        if (a.distance > b.distance) {
          return 1;
        }
        return 0;
      });
      // console.log(validated_data)
      setNearTechnicians(
        validated_data.filter(
          (item) =>
            !!item.status &&
            item.status.toUpperCase() !== "indisponivel".toUpperCase()
        )
      );
    }

    fetch();
  }, []);

  useEffect(() => {
    let aux = [
      {
        label: "Todos",
        value: "all",
      },
    ];
    let all_specialty = [];
    nearTechnicians.forEach((tec) => {
      let list_specialty = tec.specialty.split(",");
      list_specialty.forEach((specialty) => {
        if (!all_specialty.includes(specialty.trim())) {
          all_specialty.push(specialty.trim());
        }
      });
    });
    all_specialty.forEach((specialty) => {
      aux.push({
        label: specialty,
        value: specialty,
      });
    });
    setFilterOptions(aux);
  }, [nearTechnicians]);

  useEffect(() => {
    mapLoading && setMapLoading(false);
  }, [mapLoading]);

  function getTechnicianStatusIcon(status = "default") {
    const icons = {
      DISPONIVEL: MAP_MARKER_AVAILABLE_TECHNICIAN,
      PARADO: MAP_MARKER_STOPPED_TECHNICIAN,
      DESLOCAMENTO_VOLTA: MAP_MARKER_RETURNED_TECHNICIAN,
      DESLOCAMENTO_IDA: MAP_MARKER_RETURNED_TECHNICIAN,
      ATENDIMENTO: MAP_MARKER_BUSY_TECHNICIAN,
      default: "default",

      //     if(status === 'ATENDIMENTO') return 'blue'
      //     if(status === 'PARADO') return 'red'
      //     if(status === 'DESLOCAMENTO_VOLTA') return 'pink'
      //     if(status === 'DESLOCAMENTO_IDA') return 'pink'
      //       return 'white'
      // } };
    };

    return icons[status];
  }

  const getCoords = () => {
    const coords = [
      {
        id: opportunity.id,
        lat: opportunity.position.latitude,
        lng: opportunity.position.longitude,
        type: MAP_MARKER_OPPOTUNITY,
      },
    ];

    //        fill: ${({status}) => {
    //     if(status === 'DISPONIVEL') return 'green'
    //     if(status === 'ATENDIMENTO') return 'blue'
    //     if(status === 'PARADO') return 'red'
    //     if(status === 'DESLOCAMENTO_VOLTA') return 'pink'
    //     if(status === 'DESLOCAMENTO_IDA') return 'pink'
    //       return 'white'
    // } };

    cloneNearTechnicians.forEach((op) => {
      coords.push({
        id: op.id,
        lat: op.latitude,
        lng: op.longitude,
        type: getTechnicianStatusIcon(op.status),
        // type: MAP_MARKER_NEAR_TECHNICIAN,
        // status: op.status
      });
    });

    return coords;
  };

  useEffect(() => {
    let technicians = nearTechnicians;
    if (onlyAvailable) {
      technicians = technicians.filter((item) => item.status === "DISPONIVEL");
    }
    if (filterValue !== "all") {
      technicians = technicians.filter((item) =>
        item.specialty.includes(filterValue)
      );
    }
    if (selectedTechnician !== "all") {
      technicians = technicians.filter(
        (item) => item.id === selectedTechnician
      );
    }
    setCloneNearTechnicians(technicians);
  }, [selectedTechnician, nearTechnicians, filterValue, onlyAvailable]);

  // useEffect(() => {
  //     setSelectedTechnician('all')
  // }, [filterValue]);
  useEffect(() => {
    setMapLoading(true);
  }, [selectedTechnician]);

  useEffect(() => {
    setSelectedTechnician("all");
  }, [onlyAvailable]);

  useEffect(() => {
    let technicians = nearTechnicians;
    if (onlyAvailable) {
      technicians = technicians.filter((item) => item.status === "DISPONIVEL");
    }
    let _techniciansList = [];
    technicians.forEach((tec) => {
      _techniciansList.push({ value: tec.id, label: tec.name });
    });
    _techniciansList.sort((first, next) => {
      if (first.label.toLowerCase() > next.label.toLowerCase()) return 1;
      else if (first.label.toLowerCase() < next.label.toLowerCase()) return -1;

      return 0;
    });
    _techniciansList = [
      {
        value: "all",
        label: "Todos",
      },
      ..._techniciansList,
    ];
    setSelectedTechnician("all");
    setTechniciansList(_techniciansList);
  }, [nearTechnicians, onlyAvailable]);

  function noHasLocation() {
    return !opportunity?.position?.latitude || !opportunity?.position?.latitude;
  }

  function getZoomByDistance() {
    let zoom = 10;
    try {
      let technical = nearTechnicians.filter(
        (item) => item.id === selectedTechnician
      )[0];
      let distance = technical.distance;
      if (distance <= 50) {
        zoom = 10;
      } else if (distance <= 100) {
        zoom = 9;
      } else if (distance <= 200) {
        zoom = 8;
      } else if (distance <= 300) {
        zoom = 7;
      } else if (distance <= 800) {
        zoom = 6;
      } else if (distance <= 1400) {
        zoom = 5;
      } else {
        zoom = 4;
      }
    } catch (e) {}
    return zoom;
  }

  return (
    <TabContainer>
      <DividerPage />
      <Row gutter={[8, 0]} style={{ marginTop: 16 }}>
        {noHasLocation() ? (
          <Col xs={24}>
            <NoLocation
              message={
                "O equipamento não reportou sua localização recentemente, então não conseguimos criar um mapa de técnicos para essa notificação."
              }
            />
          </Col>
        ) : (
          <>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
              lg={{ span: 10, order: 1 }}
            >
              <PageTitle
                style={{ marginBottom: 0 }}
                value={generatePageTitle()}
              />
            </Col>
            {isDesktop && (
              <ColAmountTechnician
                xs={{ span: 12, order: 1 }}
                md={{ span: 7, order: 1 }}
                lg={{ span: 6, order: 1 }}
              >
                <Switch
                  checked={onlyAvailable}
                  onChange={(checked) => setOnlyAvailable(checked)}
                />{" "}
                Exibir apenas disponíveis
              </ColAmountTechnician>
            )}
            <ColAmountTechnician
              xs={{ span: 12, order: 2 }}
              md={{ span: 17, order: 1 }}
              lg={{ span: 8, order: 1 }}
            >
              <i className="ri-user-line" />{" "}
              {isTablet && <span>Lista de Técnicos</span>}{" "}
              <Select
                style={{ width: "200px" }}
                showSearch
                onChange={(value) => setSelectedTechnician(value)}
                value={selectedTechnician}
                size={"small"}
                options={techniciansList}
              />
            </ColAmountTechnician>
            <Col xs={{ span: 24, order: 3 }} md={{ order: 3 }}>
              <FilterButtons
                style={{ marginTop: 16, marginBottom: 16 }}
                onChange={({ target }) => setFilterValue(target.value)}
                value={filterValue}
                options={filterOptions}
              />
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ order: 4 }}
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              <ContainerWithoutLimit>
                <Maps
                  fullContent
                  agroupMarkers={false}
                  isLoading={isLoading || mapLoading}
                  defaultZoom={getZoomByDistance(5)}
                  useZoomState={false}
                  coords={getCoords()}
                  isModalOpen={isModalOpen}
                  markerClicked={currentNearTechnician}
                  onMarkerClick={(data) => {
                    if (
                      data.type === MAP_MARKER_BUSY_TECHNICIAN ||
                      data.type === MAP_MARKER_RETURNED_TECHNICIAN ||
                      data.type === MAP_MARKER_STOPPED_TECHNICIAN ||
                      data.type === MAP_MARKER_AVAILABLE_TECHNICIAN
                    ) {
                      setCurrentNearTechnician(data);
                      setIsModalOpen(true);
                    }
                  }}
                />
              </ContainerWithoutLimit>
              <TechnicianLegendMap />
            </Col>
          </>
        )}
      </Row>
      <NearTechnicianModalMaps
        isModalOpen={isModalOpen}
        opportunity={opportunity}
        id={currentNearTechnician.id}
        setClose={setIsModalOpen}
      />
    </TabContainer>
  );
}
