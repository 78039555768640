import React from "react";
import { Outlet } from "react-router-dom";
import ActionBar from "../components/Base/ActionBar";
import Menu from "../components/Base/Menu";
import styled from "styled-components";
import { Layout, Tag } from "antd";
import { actionbar_bg, content_bg } from "../Cores";
import { useUserContext } from "../context/UserContext";
import { useActionBar } from "../context/ActionBarContext";
import useSystemBreakpoint from "../hooks/useSystemBreakpoint";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const CustomLayout = styled(Layout)`
  height: 100vh;

  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  width: 100%;
  background: ${actionbar_bg};
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  background: ${content_bg};
  margin: 0 auto 0;
  padding: 16px 24px 16px;
  height: min-content;
  ${({ isTablet, isPhone }) => {
    if (isTablet && !isPhone) {
      return `
            margin-left: 80px;
            width: calc(100% - 80px);
          `;
    }
  }};

  ${({ isPhone }) => {
    if (isPhone) {
      return `
            padding-left: 16px;
            padding-right: 16px;
          `;
    }
  }};
`;

export default function LayoutDefault() {
  const { user, refreshContent } = useUserContext();
  const { mainContentRef } = useActionBar();
  const { isPhone, isTablet } = useSystemBreakpoint();
  const screens = useBreakpoint();

  return (
    <>
      {/*Current break point:{' '}*/}
      {/*{Object.entries(screens)*/}
      {/*    .filter((screen) => !!screen[1])*/}
      {/*    .map((screen) => (*/}
      {/*        <Tag color="blue" key={screen[0]}>*/}
      {/*            {screen[0]}*/}
      {/*        </Tag>*/}
      {/*    ))}*/}
      {user && user.is_active && (
        <CustomLayout>
          <Menu isPhone={isPhone} float={!isTablet} data-menu />
          <Container ref={mainContentRef} data-main-container>
            <ActionBar isMenuFloat={!isTablet} data-actionbar />

            <MainContent isTablet={!isTablet} isPhone={isPhone}>
              {!refreshContent && <Outlet />}
            </MainContent>
          </Container>
        </CustomLayout>
      )}
    </>
  );
}
