import React from "react";
import { Form, Input, Select } from "antd";
import { useUserContext } from "../../context/UserContext";
import { useStaticDataContext } from "../../context/StaticDataContext";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../services/api";

const FREIGHT_OPTIONS = [
  {
    label: "Frete CIF",
    value: "CIF",
  },
  {
    label: "Frete FOB",
    value: "FOB",
  },
];

export default function InitialInformationForm({
  form,
  startingCenter,
  setQuotationData,
}) {
  const [paymentConditions, setPaymentConditions] = useState([]);
  const { user } = useUserContext();
  const { centers } = useStaticDataContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentConditionsResponse = await api.get(
          "/parts_quotation/get_payment_conditions"
        );
        setPaymentConditions(paymentConditionsResponse.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name="order_number"
        label="Nº Pedido do Cliente"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="seller"
        label="Vendedor Responsável"
        rules={[
          {
            required: true,
            message: "Vendedor Responsável é obrigatório",
          },
        ]}
        initialValue={user.id_sap}
      >
        <Input defaultValue={user.id_sap} />
      </Form.Item>
      <Form.Item
        name="center"
        label="Filial (Centro)"
        rules={[
          {
            required: true,
            message: "Filial é obrigatória",
          },
        ]}
        initialValue={startingCenter}
      >
        <Select
          showSearch
          allowClear
          options={centers.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          defaultValue={startingCenter}
        />
      </Form.Item>
      <Form.Item
        name="incoterm"
        label="Tipo de Frete"
        rules={[
          {
            required: true,
            message: "Tipo de Frete é obrigatório",
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          options={FREIGHT_OPTIONS.map(({ label, value }) => ({
            label,
            value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="payment_condition"
        label="Condição de Pagamento"
        rules={[
          {
            required: true,
            message: "Condição de Pagamento é obrigatória",
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          options={paymentConditions.map((item) => ({
            label: item.description,
            value: item.condition,
          }))}
          onChange={(_, option) => {
            setQuotationData((prev) => ({
              ...prev,
              paymentConditionLabel: option.label,
            }));
          }}
        />
      </Form.Item>
    </Form>
  );
}
