import React, {useEffect, useState} from 'react';

import styled from 'styled-components'

import {Col, Form, Row, Tooltip} from "antd";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import {useActionBar} from "../../../context/ActionBarContext";
import PageTitle from "../../../components/Base/PageTitle";
import StatusInfo from "../../../components/StatusInfo";


const Container = styled.div`
  width: 318px;
  margin: 0 auto;
  text-align: center;
`



export default function TabStatus() {
    const {changeTitle} = useActionBar()
    const [statusInfo, setStatusInfo] = useState([])
    const {fetchData, isLoading} = useDefaultFetch()
    useEffect(() => {
        async function fetch() {
            const response = await fetchData(`/integration`)


            const data = {}
            response.results.forEach((item) => {
                if (!Object.keys(data).includes(item.name)) {

                    data[item.name] = []
                }
                data[item.name].push({
                    "id": item.id,
                    "description": item.description,
                    "is_serving": item.is_serving
                })
            })
            setStatusInfo(data)
        }

        fetch()

    }, [])

    useEffect(() => {
        changeTitle('Informações do sistema')
    }, [changeTitle])

    return (
        <Container>

            <Row align={'center'}>


                {Object.keys(statusInfo).map((key) => (
                    <Col xs={24} style={{marginBottom: 8}}>
                        <StatusInfo item={{name: key, items: statusInfo[key]}}/>
                    </Col>
                ))}

            < /Row>
        </Container>
    )
}