import React, { useEffect, useState } from "react";
import Switch from "../Base/Switch";
import { Form, Input } from "antd";
import CheckBoxMult from "../Base/Forms/CheckBoxMult";
import Collapse from "../Collapse";
import styled from "styled-components";
import { content_bg, icon_color, primary_color } from "../../Cores";
import SelectSearch from "../Base/SelectSearch";

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 11px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;

const FilterSearch = styled(Input.Search)`
  margin: 0;
  width: 100%;

  button {
    background: ${content_bg};
    border-color: ${icon_color};

    :hover {
      background: ${content_bg};
    }

    :hover span {
      color: ${primary_color};
    }

    svg {
      transition: 0.5s;
    }

    span {
      color: ${icon_color};
    }
  }
`;

export default function SelectSearchOffCanvas({
  collapseTitle,
  placeholder,
  form,
  url,
  labelLookup = "name",
  defaultActiveKey = ["1"],
  itemName,
}) {
  const [search, setSearch] = useState("");
  const [markAll, setMarkAll] = useState(true);
  const field = Form.useWatch(itemName, form);

  async function checkMarkAll() {
    setMarkAll(!markAll);
  }

  function handleOnChange(value) {
    setMarkAll(!(value.length > 0));
  }

  useEffect(() => {
    if (markAll) {
      form.setFieldsValue({ [itemName]: [] });
    }
  }, [markAll]);

  return (
    // todo criar componente generico
    <Collapse
      title={collapseTitle.toUpperCase()}
      defaultActiveKey={defaultActiveKey}
    >
      <SwitchContainer>
        <Switch checked={markAll} onChange={checkMarkAll} />
        <span>Marcar/desmarcar todos</span>
      </SwitchContainer>
      <Container>
        <Form.Item noStyle name={itemName}>
          <SelectSearch
            placeholder={placeholder}
            clearAll={markAll}
            onChange={handleOnChange}
            url={url}
          />
        </Form.Item>
      </Container>
    </Collapse>
  );
}
