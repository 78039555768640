import React, { useRef } from "react";
import styled from "styled-components";
import { Input, Table } from "antd";
import {
  actionbar_bg,
  calendula_gold_2,
  content_bg,
  icon_color,
  primary_color,
} from "../../Cores";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";

import Empty from "./Empty";
import { EditOutlined } from "@ant-design/icons";
import { maskIntNumber } from "../../util/Utils";

const StyledEmpty = styled(Empty)`
  padding: 73px;
`;
const StyledTable = styled(Table)`
  margin-top: 16px;

  .ant-select-arrow {
    color: white;
  }

  .ant-select-open .ant-select-arrow {
    color: #656c70;
  }

  td {
    background: ${content_bg};
  }

  tr:hover td {
    cursor: ${({ hasClick }) => (hasClick ? "pointer" : "default")};
    background: #323535 !important;
  }

  .ant-pagination-item-link {
    background: transparent;
    border: none;
    color: white;
  }

  .ant-pagination-item-link:disabled {
    color: ${icon_color};
  }

  .ant-pagination-item-link:hover:not(:disabled) {
    color: ${primary_color};
  }

  .ant-table-row-selected td.ant-table-cell {
    background: ${calendula_gold_2};
  }
  //.ant-table-row td.ant-table-selection-column:first-child{
  //  //display: none;
  //  padding: 0;
  //  width: 0;
  //
  //}
  //
  //th.ant-table-cell.ant-table-selection-column{
  //  //display: none;
  //  //padding: 0px;
  //  width: 0;
  //  background: red;
  //}
`;

export const TableEditIcon = styled(EditOutlined)`
  padding: 8px;
  border-radius: 8px;

  :hover {
    background: ${actionbar_bg};
  }
`;

export const TableSearch = styled(Input.Search)`
  button {
    background: ${content_bg};
    border-color: ${icon_color};

    :hover {
      background: ${content_bg};
    }

    :hover span {
      color: ${primary_color};
    }

    svg {
      transition: 0.5s;
    }

    span {
      color: ${icon_color};
    }
  }
`;

export const TableLabel = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
`;

export default function UpTable({
  onRowClick,
  tableParams,
  noPagination = false,
  columns,
  noScroll = false,
  showQuickJumper = true,
  showSizeChanger = true,
  tableHeightDiff = 0, //Diminui em pixels do scrool da tabela
  ...props
}) {
  const { isPhone, isTablet } = useSystemBreakpoint();
  const tableRef = useRef();

  const getScrollWidth = () => {
    let distance_table_2_botton = 120;
    if (!isTablet && !isPhone) {
      distance_table_2_botton += 40;
    }
    let distance_table_2_top = tableRef?.current?.getBoundingClientRect().top;
    return window.innerHeight - distance_table_2_top - distance_table_2_botton;
  };

  const _get_columns = () => {
    return columns.map((column) => {
      if (tableParams.sorter && column.dataIndex === tableParams.sorter.field) {
        column["defaultSortOrder"] = tableParams.sorter.order;
      }
      return column;
    });
  };

  return (
    <StyledTable
      ref={tableRef}
      size={"small"}
      showSorterTooltip={false}
      hasClick={!!onRowClick}
      onRow={(record) => {
        return {
          // onClick: (e) => onRowClick && onRowClick(record)
          onDoubleClick: (e) => onRowClick && onRowClick(record),
        };
      }}
      pagination={
        noPagination
          ? false
          : {
              ...tableParams.pagination,
              // hideOnSinglePage:true,
              showQuickJumper: showQuickJumper,
              locale: {
                jump_to: "Ir para",
                page: "",
              },
              position: isPhone && ["bottomCenter"],
              showTotal: (total, range) =>
                `${maskIntNumber(total)} resultados encontrados`,
              // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              size: "small",
              showSizeChanger: showSizeChanger,
            }
      }
      locale={{
        sortTitle: "",
        emptyText: <StyledEmpty />,
      }}
      scroll={{ x: false, y: !noScroll && getScrollWidth() }}
      columns={_get_columns()}
      {...props}
    />
  );
}
