import {Button, Upload} from 'antd';
import {
    PlusOutlined, UploadOutlined, UserOutlined,

} from "@ant-design/icons";
import React, {useState} from "react";
import styled from "styled-components";
import {
    gray_10,
    gray_2,
    neutral_1,
    neutral_5,
    primary_color
} from "../../../Cores";
import useFile from "../../../hooks/useFile";
import Loading from "../Loading";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`
const StyledUploader = styled(Upload)`
  // display: block;
  //
  // & .ant-upload-text-icon svg {
  //   color: white;
  // }
  //
  // & .ant-upload.ant-upload-select-picture-card {
    //   background: ${gray_10};
  // }
  //
  // .anticon-delete svg {
  //   width: 50px;
  //   height: 50px;
  //   color: white;
  //   transform: translateY(-15px);
  // }
`


const Image = styled.img`
  object-fit: cover;
  border: 1px solid ${neutral_1};
  width: 128px;
  height: 128px;
  border-radius: 50%;
`
//todo falta default
const StyledIconUser = styled.i`

  border: 1px solid white;
  overflow: hidden;

  width: 128px;
  height: 128px;
  font-size: 128px;
  position: relative;

  :before {
    position: absolute;
    //top: 0;
    transform: translateY(-10%);
  }

  border-radius: 50%;
`


const TextButton = styled(Button)`
  color: ${primary_color};

  span {
    text-decoration: underline;
  }
`
export default ({
                    setFileList, defaultPreviewImage,
                    noLimitFileSize,
                    beforeUpload,
                    onRemove,
                    onChange,
                    maxCount = 1,
                    typesAcceptFile = ['jpg', 'jpeg', 'png', 'svg', 'tiff'],
                    ...props
                }) => {
    const [imageUrl, setImageUrl] = useState(defaultPreviewImage && defaultPreviewImage);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChange = (info) => {
        onChange && onChange()
        getBase64(info.file, (url) => {

            setImageUrl(url);
        });
    };

    function _beforeUpload(file, fileList) {
        if (beforeUpload) {
            beforeUpload(file, fileList)
        }
        setFileList([file]);

    }

    function handleRemove() {
        setFileList([])
        onChange && onChange()
        setImageUrl('')
    }


    const {
        max_size,
        accept,
        ...useFileProps
    } = useFile({
        typesAcceptFile: typesAcceptFile,
        beforeUpload: _beforeUpload,
        onRemove: onRemove,
        noLimitFileSize: noLimitFileSize,
        setFileList: setFileList,
        maxCount: maxCount
    })

    return (
        <Container>
            {imageUrl ?
                <Image
                    src={imageUrl}
                    // alt="avatar"

                /> :
                <StyledIconUser className="ri-user-fill"></StyledIconUser>}
            <StyledUploader
                accept={accept}
                showUploadList={false}
                // listType="picture-card"
                maxCount={maxCount}

                {...useFileProps}// Props do hook useFile
                {...props} onChange={handleChange}>


                <Button icon={<UploadOutlined/>}>
                    <span>
                            Enviar
                       </span>
                </Button>

            </StyledUploader>
            {imageUrl  && <TextButton type={"text"}
                        onClick={handleRemove}>Remover</TextButton>}

        </Container>

    )
}
