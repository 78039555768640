import React from "react";
import styled from "styled-components";
import { gray, text_color } from "../../Cores";
import { Tooltip } from "antd";

const Container = styled.div`
  min-width: 0px;
`;

const StyledLabel = styled.h2`
  color: ${gray};
  font-size: 12px;
  white-space: nowrap;
  text-transform: ${({ isUpper }) => (isUpper ? "uppercase" : "none")};
  margin: 0;
`;
const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const StyledValue = styled.p`
  color: ${text_color};
  min-height: 22px;

  font-size: ${({ labelSize }) => (labelSize ? labelSize : "14px")};
  margin: 0;

  ${({ isList }) => {
    if (isList) {
      return `
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      width: 100%;
    `;
    }
  }}

  ${({ noWrap }) => {
    if (noWrap) {
      return `

       display:block;
       // background: green;
         white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width:10px;
          max-width: 400px;
          width: 100%;
        
`;
    }
  }}
}
`;

export default function DisplayInfo({
  isList,
  isUpper = true,
  label,
  showZero = false,
  labelSize,
  noWrap = false,
  children,
  extraIcon,
  showTooltip = true,
  tooltipValue,
}) {
  const tooltipText = () => {
    if (!showTooltip) {
      return;
    }
    if (tooltipValue) {
      return tooltipValue;
    }

    return children;
  };

  return (
    <Container>
      <StyledLabel isUpper={isUpper}>{label}</StyledLabel>
      <ValueContainer>
        <Tooltip title={tooltipText()}>
          <StyledValue labelSize={labelSize} noWrap={noWrap} isList={isList}>
            {children ? children : children === 0 && showZero ? 0 : "-"}
          </StyledValue>
        </Tooltip>
        {extraIcon && extraIcon}
      </ValueContainer>
    </Container>
  );
}
