import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";
import {Select as SelectAnt} from 'antd';
import Empty from "./Empty";
import debounce from 'lodash/debounce';
import {BuildFieldErrorsDict} from "../../util/Utils";
import api from "../../services/api";
import {input_border} from "../../Cores";
import Loading from "./Loading";

const StyledCloseOutline = styled(CloseOutlined)`
  color: white;
`
const StyledDownOutlined = styled(DownOutlined)`
  color: white;
  transition: 0.1s;
    //transform: translateY(-50%) rotate(${({isActive}) => isActive ? '0deg' : '-90deg'}) !important;
`

const {Option} = SelectAnt;
const StyledSelect = styled(SelectAnt)`
  width: 100%;

  &.ant-select-disabled span.ant-select-selection-item {
    color: ${input_border};
  }

  & .ant-select-arrow .ant-select-suffi {
    color: white;
  }
`

const AMOUNT_CARACTER = 3

export default function SelectSearch({
                                         url,
                                         debounceTimeout = 800,
                                         label_key = 'name',
                                         noPagination,
                                         placeholder = 'Selecione',
                                         onChange,
                                         ...props
                                     }) {
    const [value, setValue] = useState('');

    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const fetchRef = useRef(0);



    const fetchOptions = useMemo(() => {
        const loadOptions = async (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            if (value.length >= AMOUNT_CARACTER) {
                try {
                    setIsLoading(true)
                    const response = await api.get(`${url}?search=${value}&page=1&page_size=10000`)
                    setOptions(response.data.results.map((item) => {
                        return {
                            value: item.id,
                            label: item[`${label_key}`], // picture: item.picture
                        }
                    }))

                } catch (e) {
                    BuildFieldErrorsDict(e, null)
                } finally {
                    setIsLoading(false)
                }
            }

            // fetchOptions(value).then((newOptions) => {
            //     if (fetchId !== fetchRef.current) {
            //         // for fetch callback order
            //         return;
            //     }
            //     setOptions(newOptions);
            //     setFetching(false);
            // });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout])
    //     ;
    //     return (
    //         <Select
    //             labelInValue
    //             filterOption={false}
    //             onSearch={debounceFetcher}
    //             notFoundContent={fetching ? <Spin size="small"/> : null}
    //             {...props}
    //             options={options}
    //         />
    //     );
    // }


    return (<StyledSelect
            filterOption={false}
            labelInValue
            mode="multiple"
            allowClear
            placeholder={placeholder}
            value={value}
            onChange={(newValue) => {
                console.log(newValue)
                onChange && onChange(newValue.map(({value}) => value))
                setValue(newValue);
            }}
            notFoundContent={isLoading ? <Loading noHeight/> : <Empty
                text={value.length <= AMOUNT_CARACTER ? `Digite pelo menos ${AMOUNT_CARACTER} caracteres.` : "Nenhum resultado encontrado"}/>}
            // options={options}
            onSearch={fetchOptions}
            // suffixIcon={<StyledDownOutlined/>}
            removeIcon={<StyledCloseOutline/>}
            clearIcon={<StyledCloseOutline/>}
            {...props}
        >

            {url && options.map((option) => {
                return <Option key={'option' + option.value}
                               value={option.value} label={option.label}>
                    {option.label}
                </Option>
            })}

        </StyledSelect>)
}