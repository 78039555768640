import React from 'react';
import {dateFormat} from "../../../util/dates";
import {GenericTelemetricTable} from "./GenericTelemetricTable";


export default function TelemetricTigercatTable({search,}) {


    const columns = [{
        title: 'N° Série/PID17',
        dataIndex: 'equipment_pin_17',
        // sorter: 'equipment__pin_17',
        key: 'equipment_pin_17',
        width: '140px',
    },

        {
            title: 'Sistema',
            dataIndex: 'telemetry_system',
            // sorter: 'equipment__pin_17',
            key: 'telemetry_system',
            width: '130px',
            render: (value, record) => (value.name),
        },
        {
            title: 'Cliente',
            dataIndex: 'client_name',
            // sorter: 'equipment__client__name',
            key: 'client_name',
            width: '185px',
            ellipsis: true,
        },
        {
            title: 'Criação',
            dataIndex: 'created_at',
            // sorter: 'created_at',
            key: 'created_at',
            width: '91px',
            ellipsis: true,
            render: (value, record) => (dateFormat(value, "DD/MM/YYYY")),
        },
        {
            title: 'SPN',
            dataIndex: 'spn',
            // sorter: 'pid',
            key: 'spn',
            width: '75px',
            ellipsis: true,
        },
        {
            title: 'FMI',
            dataIndex: 'fmi',
            // sorter: 'pid_type',
            key: 'fmi',
            width: '50px',
            ellipsis: true,
        },

        {
            title: 'Descrição',
            dataIndex: 'caution_description',
            // sorter: 'description',
            key: 'caution_description',
            width: '485px',
            ellipsis: true,
        }
    ];


    return (
        <GenericTelemetricTable search={search} url={'caution_code_data'}
                                columns={columns}/>

    )
}