import { Col, Divider } from "antd";
import InfoCard from "../../../components/Base/display/InfoCard";
import FingerScroll from "../../../components/Base/FingerScroll";
import PageTitle from "../../../components/Base/PageTitle";
import PageText from "../../../components/Base/display/PageText";

export default function KalmarAlertOpportunity({ opportunity, summary }) {
  return (
    <>
      <PageTitle value={"Dados do Alerta"} />
      <FingerScroll>
        <InfoCard
          width={212}
          data={String(
            opportunity.content_object.alert_metadata.key
          ).toUpperCase()}
          title={"ID do Alerta"}
        />
        <InfoCard
          width={212}
          data={opportunity.content_object.code}
          title={"Código do Alerta"}
        />
        <InfoCard
          width={212}
          data={String(opportunity.content_object.alert_metadata.fmi)}
          title={"FMI"}
        />
        <InfoCard
          width={212}
          data={String(opportunity.content_object.alert_metadata.spn)}
          title={"SPN"}
        />
        <InfoCard
          width={212}
          data={String(opportunity.content_object.criticality).toUpperCase()}
          title={"Criticalidade do Alerta"}
        />
        <InfoCard
          width={212}
          data={String(
            opportunity.content_object.alert_metadata.category
          ).toUpperCase()}
          title={"Categoria do Alerta"}
        />
      </FingerScroll>
      <Divider style={{ marginTop: 16 }} />
      <Col xs={24}>
        <PageTitle value={"Descrição"} style={{ marginTop: 15 }} />
        <PageText
          value={opportunity.content_object.alert_metadata.descriptionCustomer}
        />
        <PageTitle value={"Ação do Técnico"} style={{ marginTop: 15 }} />
        <PageText
          value={opportunity.content_object.alert_metadata.actionTechnican}
        />
      </Col>
    </>
  );
}
