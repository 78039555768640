import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { TableSearch } from "../../components/Base/UPTable";
import FilterButtons from "../../components/Base/FilterButtons";
import { Button, Col, Row } from "antd";
import FilterOpportunityOffCanvas from "../../components/Opportunity/tables/FilterOpportunityOffCanvas";
import OpportunityTable from "../../components/Opportunity/tables/OpportunityTable";
import FilterIcon from "../../components/Base/FilterIcon";
import { buildFormDataToFilter } from "../../util/Utils";
import { get_filter_params } from "../../util/TableStorageService";
import { INITIAL_OPPORTUNITY_FORM_FILTERS } from "../../util/OpportunityUtils";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import ImportServiceLeedsOffCanvas from "../../components/Opportunity/ImportServiceLeedsOffCanvas";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import api from "../../services/api";
import { Notification } from "../../notification";

export default function ListOpportunity({
  equipmentId = null,
  clientId = null,
  hasImportButton = true,
  removeFilter = false,
  initialFormFilter = INITIAL_OPPORTUNITY_FORM_FILTERS,
  placeholderSearch = "Pesquisa por código, nº série, cliente, centro.",
  hasExportButton = true,
}) {
  const { changeTitle, setHasBack } = useActionBar();
  const [search, setSearch] = useState(get_filter_params("search"));
  const [filterCount, setFilterCount] = useState(2);
  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter", initialFormFilter)
  );
  const [openUploandImport, setOpenUploadImport] = useState(false);
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(_initialFormFilter)
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [typeTable, setTypeTable] = useState(
    get_filter_params("typeTable", "all")
  );
  const [isExportingOppotunities, setIsExportingOppotunities] = useState(false);

  useEffect(() => {
    changeTitle("Consultar notificações");
    setHasBack(false);

    return () => {
      setHasBack(true);
    };
  }, [changeTitle, setHasBack]);

  const { fetchData, isLoading } = useDefaultFetch();

  const [options, setOptions] = useState([
    {
      label: "Todos",
      value: "all",
    },
  ]);
  useEffect(() => {
    async function fetch() {
      const data = await fetchData(`rule_type?page_size=1000`);
      if (data) {
        const rules_type = data.results.map(({ name }) => ({
          label: name,
          value: name,
        }));
        setOptions([
          {
            label: "Todos",
            value: "all",
          },
          ...rules_type,
        ]);
      }
    }

    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExportOpportunities = async () => {
    setIsExportingOppotunities(true);
    try {
      const response = await api.post("opportunity/export_xlsx", formFilter);

      Notification("success", response.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setIsExportingOppotunities(false);
    }
  };

  return (
    <>
      <Row gutter={[8, 16]} align="middle">
        {removeFilter ? (
          <Col flex={"auto"}>
            <TableSearch
              size={"large"}
              allowClear
              defaultValue={search}
              onSearch={(value, e) => setSearch(value)}
              onPressEnter={(e) => setSearch(e.target.value)}
              placeholder={placeholderSearch}
            />
          </Col>
        ) : (
          <>
            <Col flex={"auto"}>
              <TableSearch
                size={"large"}
                allowClear
                defaultValue={search}
                onSearch={(value, e) => setSearch(value)}
                onPressEnter={(e) => setSearch(e.target.value)}
                placeholder={placeholderSearch}
              />
            </Col>
            <Col
              xs={2}
              md={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <FilterIcon
                count={filterCount}
                onClick={() => setOpenFilter(true)}
              />
            </Col>
            {hasImportButton && (
              <Col xs={24} md={24} lg={7} xl={5}>
                <Button
                  onClick={() => setOpenUploadImport(true)}
                  type="primary"
                  block
                >
                  <PlusOutlined />
                  Importar Service Leeds
                </Button>
              </Col>
            )}
            {hasExportButton && (
              <Col xs={24} md={24} lg={7} xl={5}>
                <Button
                  onClick={() => handleExportOpportunities()}
                  type="primary"
                  block
                  loading={isExportingOppotunities}
                >
                  <DownloadOutlined />
                  Exportar notificações
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>

      <FilterButtons
        onChange={({ target }) => setTypeTable(target.value)}
        style={{ marginTop: 16 }}
        value={typeTable}
        isLoading={isLoading}
        options={options}
      />
      <OpportunityTable
        typeTable={typeTable}
        search={search}
        equipmentId={equipmentId}
        clientId={clientId}
        setFilterCount={setFilterCount}
        formFilter={formFilter}
      />
      <FilterOpportunityOffCanvas
        typeTable={typeTable}
        open={openFilter}
        initialFormFilter={_initialFormFilter}
        formFilter={formFilter}
        setOpen={setOpenFilter}
        setFormFilter={setFormFilter}
      />
      <ImportServiceLeedsOffCanvas
        open={openUploandImport}
        setOpen={setOpenUploadImport}
      />
    </>
  );
}
