import React from 'react';

import { primary_color} from "../../Cores";
import {Badge} from "antd";




export default function BadgeCount({ children, ...props}) {
    return (
        <Badge  size="large" color={primary_color}
                offset={[10, 8]}
               style={{
                   marginRight: 24,
                   color: 'black',
                   borderColor: 'transparent'
               }} {...props}>
            {children}
        </Badge>
    )
}