import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShareLocation from "../ShareLocation";
import Loading from "../Loading";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import HeaderModalMap from "../../ModalMap/HeaderModalMap";
import { ItemBodyModalMap } from "../../ModalMap/ItemBodyModalMap";
import { ModalMap } from "../../ModalMap/ModalMap";
import { dateFormat } from "../../../util/dates";

const Container = styled.div`
  height: 444px;
  overflow: hidden;
`;
const ContentContainer = styled.div`
  height: 326px;
  overflow-y: scroll;
`;

export default function NearTechnicianModalMaps({
  isModalOpen,
  opportunity,
  setClose,
  id,
}) {
  const { postData, isLoading } = useDefaultFetch();
  const [nearTechnician, setNearTechnician] = useState();

  const onClose = () => {
    setClose(false);
  };

  useEffect(() => {
    async function post() {
      let data = {};
      if (opportunity) {
        data = {
          latitude: opportunity.position.latitude,
          longitude: opportunity.position.longitude,
        };
      }
      setNearTechnician(
        await postData(`g4/technical/${id}/modal_information/`, data)
      );
    }

    id && post();
  }, [id]);

  if (!nearTechnician) return null;
  return (
    <ModalMap open={isModalOpen} onClose={onClose}>
      <MapCard
        onClose={onClose}
        isLoading={isLoading}
        nearTechnician={nearTechnician}
      />
    </ModalMap>
  );
}

function getTechStatus(status) {
  switch (status) {
    case "DESLOCAMENTO_IDA":
      return "Em deslocamento para atendimento";
    case "DESLOCAMENTO_VOLTA":
      return "Retornando de atendimento";
    default:
      return status;
  }
}

function MapCard({ onClose, isLoading, nearTechnician }) {
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <HeaderModalMap
            subtitle1={nearTechnician.specialty}
            subtitle2={`A ${nearTechnician.distance}Km do equipamento`}
            title={nearTechnician.name}
            onClose={onClose}
          />

          <ContentContainer>
            {/*<ItemBodyModalMap*/}
            {/*    label={'N° SÉRIE'}*/}
            {/*    icon={<i className="ri-barcode-box-line"/>}>*/}
            {/*    {nearTechnician.pin_17}*/}
            {/*</ItemBodyModalMap>*/}

            <ItemBodyModalMap
              label={"STATUS"}
              icon={<i className="ri-base-station-line" />}
            >
              {getTechStatus(nearTechnician.status)}
            </ItemBodyModalMap>
            <ItemBodyModalMap
              label={"LOCALIZAÇÃO"}
              icon={<i className="ri-map-pin-2-line" />}
              extraIcon={
                <ShareLocation
                  lat={nearTechnician.latitude}
                  long={nearTechnician.longitude}
                />
              }
            >
              {nearTechnician.reverse_geocode}
            </ItemBodyModalMap>
            <ItemBodyModalMap
              label={"CENTRO"}
              icon={<i className="ri-map-2-line" />}
            >
              {nearTechnician.center_name}
            </ItemBodyModalMap>
            <ItemBodyModalMap
              label={"DATA DO POSICIONAMENTO"}
              icon={<i className="ri-calendar-2-line" />}
            >
              {dateFormat(nearTechnician.positioning_date, "DD/MM/YYYY HH:mm")}
            </ItemBodyModalMap>
            {/*<ItemBodyModalMap label={'ATENDIMENTO EM ANDAMENTO'}*/}
            {/*                  icon={<i*/}
            {/*                      className="ri-briefcase-3-line"/>}>*/}
            {/*    .....*/}
            {/*</ItemBodyModalMap>*/}
            {/*    <ItemBodyModalMap>*/}
            {/*        <ArrowLink*/}
            {/*            href={`/opportunity/${nearTechnician.id}/detail?tab=1`}*/}
            {/*            label={'Visualizar notificação'}/></ItemBodyModalMap>*/}
          </ContentContainer>
        </>
      )}
    </Container>
  );
}
