import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import api from "../../services/api";
import { BuildFieldErrorsDict } from "../../util/Utils";
import useTable from "../../hooks/useTable";
import UpTable, { TableSearch } from "../../components/Base/UPTable";
import {
  get_filter_params,
  set_filter_params,
} from "../../util/TableStorageService";
import GenericTelemetryOffCanvas from "./GenericTelemetryOffCanvas";
import { dateFormat } from "../../util/dates";

export default function TableTelemetryRecords({ equipment }) {
  const [search, setSearch] = useState(get_filter_params("search"));
  const [open, setOpen] = useState(false);
  const [telemetricyRecord, setTelemetricyRecord] = useState();
  const columns = [
    {
      title: "Criado em.",
      dataIndex: "created_at",
      sorter: "created_at",
      key: "created_at",
      width: "110px",
      ellipsis: true,
      render: (value, record) => dateFormat(value, "DD/MM/YYYY HH:mm"),
    },
    {
      title: "PID",
      dataIndex: "pid",
      sorter: "pid",
      key: "pid",
      width: "60px",
      ellipsis: true,
    },
    {
      title: "Tipo PID",
      dataIndex: "pid_type",
      sorter: "pid_type",
      key: "pid_type",
      width: "90px",
      ellipsis: true,
    },
    {
      title: "MID",
      dataIndex: "mid",
      sorter: "mid",
      key: "mid",
      width: "60px",
      ellipsis: true,
    },
    {
      title: "FMI",
      dataIndex: "fmi",
      sorter: "fmi",
      key: "fmi",
      width: "60px",
      ellipsis: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      sorter: "description",
      key: "description",
      width: "391px",
      ellipsis: true,
    },
    // {falta
    //     title: 'Occ.',
    //     dataIndex: 'description',
    //     sorter: 'description',
    //     key: 'description',
    //     width: '391px',
    //     ellipsis: true,
    // },
  ];
  const fetchTelemetryData = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `telemetric_data?equipment__id=${equipment.id}&page_size=${
          pagination.pageSize
        }&page=${pagination.current}&search=${search}&ordering=${
          order ? order : ""
        }`
      );
      setTableData(response.data.results);
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const { tableData, isLoading, tableParams, resetTable, handleTableChange } =
    useTable({
      fetchData: fetchTelemetryData,
      search,
      useHandleRequestTable: false,
    });

  useEffect(() => {
    resetTable();
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <TableSearch
            size={"large"}
            allowClear
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquise por PID, MID , FMI ou descrição"}
          />
        </Col>
      </Row>
      <UpTable
        onChange={handleTableChange}
        loading={isLoading}
        showQuickJumper
        onRowClick={(record) => {
          setTelemetricyRecord(record);
          setOpen(true);
        }}
        tableParams={tableParams}
        columns={columns}
        dataSource={tableData}
      />

      <GenericTelemetryOffCanvas
        record={telemetricyRecord}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
