import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { stylesMap } from "./maps_styles";
import styled from "styled-components";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";

const Container = styled.div`
  width: 100%;
  position: relative;
  height: ${({ height }) => (height ? height : "0px")};
`;

export default function PMPTabMap({
  originRef = null,
  destinationRef = null,
  height = "491px",
  phoneHeightDiff = 100,
  fullContent = false,
}) {
  const [response, setResponse] = useState(null);
  const [directionsFormValue, setDirectionsFormValue] = useState({
    origin: "",
    destination: "",
    travelMode: window.google.maps.TravelMode.DRIVING,
  });
  const mapContainerRef = useRef();

  const { isPhone } = useSystemBreakpoint();

  const containerStyle = useMemo(() => {
    const getMapHeight = () => {
      if (!fullContent) {
        return height;
      }
      let distance_map_2_botton = 35;
      if (isPhone) {
        distance_map_2_botton += phoneHeightDiff;
      }
      let distance_map_2_top =
        mapContainerRef?.current?.getBoundingClientRect().top;
      return `${
        window.innerHeight - distance_map_2_top - distance_map_2_botton
      }px`;
    };

    return {
      height: getMapHeight(),
      width: "100%",
    };
  }, [fullContent, height, isPhone, phoneHeightDiff]);

  const center = useMemo(
    () => ({
      lat: -18.769275,
      lng: -50.833514,
    }),
    []
  );

  const createMapOptions = () => {
    return {
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
        style: window.google.maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: false,
      styles: stylesMap,
    };
  };

  const directionsCallback = useCallback((result, status) => {
    if (result !== null) {
      if (status === "OK") {
        setResponse(result);
      } else {
        console.log("response: ", result);
      }
    }
  }, []);

  useEffect(() => {
    if (
      originRef &&
      originRef.current !== "" &&
      destinationRef &&
      destinationRef.current !== ""
    ) {
      setDirectionsFormValue((currentValue) => ({
        ...currentValue,
        origin: originRef.current ?? "",
        destination: destinationRef.current ?? "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originRef?.current, destinationRef?.current]);

  const onMapClick = useCallback((e) => {
    console.log("onClick args: ", e);
  }, []);

  const directionsServiceOptions = useMemo(() => {
    return {
      destination: directionsFormValue.destination,
      origin: directionsFormValue.origin,
      travelMode: "DRIVING",
    };
  }, [directionsFormValue.origin, directionsFormValue.destination]);

  const directionsResult = useMemo(() => {
    return {
      directions: response,
    };
  }, [response]);

  return (
    <Container ref={mapContainerRef} height={containerStyle.height}>
      <GoogleMap
        id="direction-example"
        options={createMapOptions()}
        mapContainerStyle={containerStyle}
        zoom={4}
        center={center}
        onClick={onMapClick}
      >
        {directionsFormValue.destination !== "" &&
          directionsFormValue.origin !== "" && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />
          )}

        {directionsResult.directions && (
          <DirectionsRenderer options={directionsResult} />
        )}
      </GoogleMap>
    </Container>
  );
}
