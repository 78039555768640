import {notification} from 'antd'
import {text_color} from "./Cores";
import {CloseOutlined} from "@ant-design/icons";
import styled from 'styled-components'

const Description = styled.span`
  white-space: pre-line;
`

export const Notification = (type, description, title = '', icon = null) => {
    //success, error,info,warning
    notification[type](
        {
            message: title,
            description: <Description >{description}</Description>,
            closeIcon: <CloseOutlined style={{color: `${text_color}`}}/>,
            style: {color: `${text_color}`},
            // duration:99999
        }
    )
}