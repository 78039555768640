import React, {useEffect, useState} from 'react';
import {Form, Modal, Button} from "antd";
import styled from "styled-components";
import {text_color} from "../../Cores";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import OffCanvas from "../Base/OffCanvas";
import {Notification} from "../../notification";
import {BuildFieldErrorsDict} from "../../util/Utils";

import PageTitle from "../Base/PageTitle";
import ResponsibleCard, {ListResponsibleCard} from "../ResponsibleCard";
import api from "../../services/api";
import Loading from "../Base/Loading";
import Empty from "../Base/Empty";
import SelectUser from "../User/SelectUser";
import Select from "../Base/Select";
import FormInfo from "../Rules/RuleForm/FormInfo";
import {useStaticDataContext} from "../../context/StaticDataContext";


const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`


export default function CenterUptimeOffCanvas({
                                                  centerId,
                                                  open,
                                                  setOpen,
                                                  setObject,
                                                  message,
                                                  url,
                                                  extraOnClose,
                                                  title = 'Alterar centro do uptime'
                                              }) {

    const [form] = Form.useForm();
    const [hasChange, setHasChange] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {centers} = useStaticDataContext()

    useEffect(() => {
        form.setFieldsValue({
            'new_center_id': centerId
            // 'new_center_id': equipment.center?.id
        })
    }, [centerId, open])

    function onClose() {
        if (hasChange) {
            Modal.confirm({
                title: 'Deseja realmente sair?',
                content: 'Ao escolher voltar, você abandonará a edição e os dados serão perdidos.',
                icon: <ExclamationCircleOutlined/>,
                // content: 'Deseja realmente fechar?',
                okText: 'Sim',
                cancelText: 'Não',
                onOk: closeDrawer
            });
        } else {
            closeDrawer()
        }
    }

    function closeDrawer() {
        setOpen(false)
        setHasChange(false)
        form.resetFields()
        extraOnClose && extraOnClose()
    }


    const onSubmit = async () => {
        setIsLoading(true)
        let values;
        try {
            values = await form.validateFields()

        } catch (error) {
            setIsLoading(false)
            return;
        }

        try {
            const data = {
                ...values
            }
            const response = await api.post(url, data);
            setObject(response.data)

            Notification('success', 'Centro alterado com sucesso.')
            closeDrawer()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, 'Erros encontrados')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <OffCanvas
            title={<Title>{title}</Title>}
            onClose={onClose}
            open={open} width={'378px'}
            footer={<Button disabled={!hasChange} loading={isLoading}
                            onClick={onSubmit}
                            type="primary">Salvar</Button>}>

            <Form
                id={'responsableForm'}
                layout={'vertical'}
                form={form}
                onValuesChange={() => setHasChange(true)}
            >
                <Form.Item label={'Novo centro'} name={'new_center_id'}
                >
                    <Select showSearch options={centers.map(({id, name}) => ({
                        value: id,
                        label: name
                    }))}/>
                </Form.Item>
                {message && <FormInfo
                    label={message}/>}
            </Form>

        </OffCanvas>
    )

}