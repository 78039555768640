import { useEffect, useState } from "react";
import {
  get_filter_params,
  set_filter_params,
} from "../util/TableStorageService";

export default function useTable({
  fetchData,
  search = "",
  useHandleRequestTable = true,
  addLoad = true,
}) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [order, setOrder] = useState(get_filter_params("ordening"));
  const [firstRequest, setFirstRequest] = useState(true);

  useEffect(() => {
    let is_detail = window.location.pathname.includes("/detail");
    !is_detail &&
      addLoad &&
      window.history.replaceState(
        window.history.state,
        "",
        `${window.location.pathname}?load=1`
      );
  }, []);

  const [tableParams, setTableParams] = useState({
    sorter: get_filter_params("sorter", null),
    pagination: {
      current: get_filter_params("currentPage", 1),
      pageSize: get_filter_params("pageSize", 20),
    },
  });

  function resetTable() {
    handleTableChange({
      ...tableParams.pagination,
      current: 1,
    });
  }

  function handleRequestTable() {
    if (firstRequest) {
      handleTableChange(tableParams.pagination, null, tableParams.sorter);
      setFirstRequest(false);
    } else {
      resetTable();
    }
  }

  useEffect(() => {
    if (useHandleRequestTable) {
      handleRequestTable();
    }

    // eslint-disable-next-line
  }, [search]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let orderAux = "";
    let _order = order;
    if (sorter && sorter.order && Object.keys(sorter).length > 0) {
      orderAux = sorter.order === "ascend" ? "" : "-";
      orderAux += sorter.column.sorter;
      setOrder(orderAux);
      _order = orderAux;
    } else if (sorter && sorter.order === undefined) {
      setOrder("");
      _order = orderAux;
    }
    fetchData &&
      fetchData({
        setIsLoading,
        pagination,
        order: _order,
        setTableParams,
        setTableData,
        firstRequest,
      });
    set_filter_params("search", search);
    set_filter_params("sorter", sorter);
    set_filter_params("pageSize", pagination.pageSize);
    set_filter_params("currentPage", pagination.current);
    set_filter_params("ordening", _order);
  };

  return {
    isLoading,
    tableData,
    setTableData,
    tableParams,
    setTableParams,
    resetTable,
    handleTableChange,
    handleRequestTable,
  };
}
