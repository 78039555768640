import React, { useEffect, useState } from "react";
import { Radio, Spin } from "antd";
import styled from "styled-components";
import { menu_bg, primary_color } from "../../Cores";
import FingerScroll from "./FingerScroll";
import useDefaultFetch from "../../hooks/useDefaultFetch";

const StyledGroup = styled(Radio.Group)`
  display: flex;
  //flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  .ant-radio-button-wrapper:not(:first-child)::before {
    background: transparent;
  }
`;

const StyledButton = styled(Radio.Button)`
  background: ${menu_bg} !important;

  border: 1px solid transparent !important;
  border-radius: 100px !important;

  &.ant-radio-button-wrapper-checked {
    color: ${primary_color} !important;

    background: black !important;
    border: unset !important;
  }

  & span {
    white-space: nowrap;
  }
`;

export default function FilterButtons({
  options,
  isLoading = false,
  hideScroll = true,
  ...props
}) {
  return (
    <FingerScroll hideScroll={hideScroll}>
      <Spin spinning={isLoading}>
        <StyledGroup size="large" {...props}>
          {options.map(({ label, value }) => {
            return <StyledButton value={value}>{label}</StyledButton>;
          })}
        </StyledGroup>
      </Spin>
    </FingerScroll>
  );
}
