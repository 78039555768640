import React from 'react';
import {
    Col, Row,

} from "antd";
import DisplayInfo from "../../Base/DisplayInfo";
import PageTitle from "../../Base/PageTitle";
import {dateFormat} from "../../../util/dates";
import OilAlertCondition from "../OilAlertCondition";

export default function OilDetailInfo({oil}) {


    return (

        <Row gutter={[4, 24]}>

            <Col xs={24}>
                <PageTitle value={'Informações da amostra'}/>
            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'ID. da amostra'}>
                    {oil.id_integration} </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'DATA DA amostra'}>
                    {dateFormat(oil.collection_date)}

                </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'DATA DO RESULTADO'}>
                    {dateFormat(oil.result_date)}
                </DisplayInfo>

            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'PARTE ANALISADA'}>
                    {oil.analized_part}

                </DisplayInfo>

            </Col>

            <Col xs={12} md={8}>
                <DisplayInfo label={'Horímetro da análise'}>
                    {oil.hourmeter_analysis}
                </DisplayInfo>

            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'HORÍMETRO ATUAL'}>
                    {oil.equipment_current_hourmeter}
                </DisplayInfo>

            </Col>
            <Col xs={24} style={{marginTop: 16}}>
                <PageTitle value={'Informações da análise'}/>
            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'Condição'}>
                    <OilAlertCondition
                        value={oil.condition_name}/>
                </DisplayInfo>
            </Col>
            <Col xs={12} md={8}>
                <DisplayInfo label={'TROCA DE ÓLEO'}>
                    {oil.oil_changed ? 'Realizada' : 'Não realizada'}
                </DisplayInfo>
            </Col>
            <Col xs={24}>
                <DisplayInfo label={'Laudo'}>
                    {oil.report}
                </DisplayInfo>
            </Col>
            <Col xs={24}>
                <DisplayInfo label={'Comentários'}>
                    {oil.comment}
                </DisplayInfo>
            </Col>


        </Row>


    )

}

