import React from "react";
import {useLocation} from "react-router-dom";

export default function useQuery() {
    const location = useLocation();
    // const [urlSearchParams, setUrlSearchParams] = useState(new URLSearchParams(location.search))
    // useEffect(() => {
    //     setUrlSearchParams(new URLSearchParams(location.search))
    // }, [location])
    return new URLSearchParams(location.search)
}