import React from 'react'

import {
    DatePicker,
} from "antd";
import styled from 'styled-components'
import {neutral_1, neutral_5} from "../Cores";

const StyledRangePicker = styled(DatePicker.RangePicker)`
  color: red;
  background: ${neutral_1};
  
  svg{
    color: ${neutral_5};
  }
`

export default function RangeDate({date, format = 'DD/MM/YYYY', ...props}) {

    return (
        <StyledRangePicker
            placeholder={["Data inicial", "Data final"]}
            format={format}
            {...props}
        />
    )
}