import React from 'react';
import styled from 'styled-components';
import {
    border_dark_color, dust_red_6,
    progress, progress_bg, progress_border, red_10,
    success_bg,
    success_color, sunset_orange_1, sunset_orange_3, sunset_orange_6
} from "../../Cores";

const Container = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;



`

const colors = {

    'aprovado': {
        borderColor: success_color,
        color: success_color,
        backgroundColor: success_bg
    },
    'reprovado': {
        borderColor: dust_red_6,
        color: dust_red_6,
        backgroundColor: red_10
    },
    'atenção': {
        borderColor: sunset_orange_3,
        color: sunset_orange_6,
        backgroundColor: sunset_orange_1
    },
}

export default function OilAlertCondition({
                                              value='',
                                              ...props
                                          }) {

    return (
        <Container {...props} {...colors[value.toLowerCase()]}>
            {value}
        </Container>)
}