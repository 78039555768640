import React from 'react';
import styled from 'styled-components';
import {
    border_dark_color, calendula_gold_1,
    calendula_gold_3,
    calendula_gold_6,
    cyan_1,
    cyan_3,
    cyan_6, red_10, dust_red_3, dust_red_6,
    geek_blue_1,
    geek_blue_3,
    geek_blue_6,
    lime_1,
    lime_3,
    lime_6,
    mangeta_1,
    mangeta_3,
    mangeta_6,
    success_bg,
    success_color, sunset_orange_1, sunset_orange_3, sunset_orange_6
} from "../../Cores";

import {InfoCircleOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";

const Container = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;
  gap: 8px;
`

function getColor(type) {
    let result = null
    switch (type) {
        case 1:
            result = {
                borderColor: border_dark_color,
                color: 'white',
                backgroundColor: '#222425'
            }
            break;
        case 2:
            result = {
                borderColor: cyan_3,
                color: cyan_6,
                backgroundColor: cyan_1
            }
            break;
        case 3:
            result = {
                borderColor: geek_blue_3,
                color: geek_blue_6,
                backgroundColor: geek_blue_1
            }
            break;
        case 4:
            result = {
                borderColor: mangeta_3,
                color: mangeta_6,
                backgroundColor: mangeta_1
            }
            break;
        case 5:
            result = {
                borderColor: lime_3,
                color: lime_6,
                backgroundColor: lime_1
            }

            break;
        case 6:
            result = {
                borderColor: calendula_gold_3,
                color: calendula_gold_6,
                backgroundColor: calendula_gold_1
            }
            break;
        case 7:
            result = {
                borderColor: success_color,
                color: success_color,
                backgroundColor: success_bg
            }
            break;
        case 8:
            result = {
                borderColor: sunset_orange_3,
                color: sunset_orange_6,
                backgroundColor: sunset_orange_1
            }
            break;
        case 9:
            result = {
                borderColor: dust_red_3,
                color: dust_red_6,
                backgroundColor: red_10
            }
            break;
        default:
            result = {
                borderColor: cyan_3,
                color: cyan_6,
                backgroundColor: cyan_1
            }
            break;
    }
    return result
}


function getIcon(type) {
    switch (type) {

        case 9:
            return <InfoCircleOutlined/>

        default:
            return <></>

    }
}

export default function BadgeOportunityStatus({
                                                  children,
                                                  type,
                                                  message,
                                                  ...props
                                              }) {
    return (
        // <Container {...props} {...colors.find((color) => type === color.type)}>
        <Tooltip title={message}>
            <Container {...props} {...getColor(type)}>

                {children}{getIcon(type)}

            </Container>
        </Tooltip>
    )
}