import React from 'react';
import styled from "styled-components";
import {Tabs} from "../../components/Base/Tabs";
import GeneralTabDashboard from "./Tabs/GeneralTabDashboard";
import EquipmentTabDashboard from "./Tabs/EquipmentTabDashboard";
import OpportunityTabDashboard from "./Tabs/OpportunityTabDashboard";
import ConsultantsTabDashboard from "./Tabs/ConsultantsTabDashboard";
import useTab from "../../hooks/useTab";


const Container = styled.div`
  position: relative;
`


export default function DashboardManager() {
    const {setQueryTab, defaultTabId} = useTab({defaultTab: '1'})


    return (
        <Container>

            <Tabs
                destroyInactiveTabPane={true}
                activeKey={defaultTabId}
                onChange={setQueryTab}
                items={[
                    {
                        label: 'Mapa',
                        key: '1',
                        children: <GeneralTabDashboard/>
                    },
                    {
                        label: 'Equipamentos',
                        key: '2',
                        children: <EquipmentTabDashboard/>
                    },
                    {
                        label: 'Notificações',
                        key: '3',
                        children: <OpportunityTabDashboard/>
                    },
                    {
                        label: 'Consultores',
                        key: '4',
                        children: <ConsultantsTabDashboard/>
                    },
                ]}/>


        </Container>
    )
}