import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

import styled from "styled-components";

import moment from "moment-timezone";
import { text_color } from "../../Cores";
import { BuildFieldErrorsDict, buildFormDataToFilter } from "../../util/Utils";
import { set_filter_params } from "../../util/TableStorageService";
import OffCanvas from "../Base/OffCanvas";
import CheckboxOffCanvas from "../FiltersOffCanvas/CheckboxOffCanvas";
import RadioButton from "../Base/Forms/RadioButton";
import api from "../../services/api";
import Collapse from "../Collapse";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //flex
`;
const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const NEXT_REVIEW_FORM_NAME = "next_review_date__range";
const BRAND_FORM_NAME = "brand_equipment_id__in";

export default function FilterEquipmentOffCanvas({
  open,
  typeTable,
  setOpen,
  initialFormFilter,
  setFormFilter,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [form] = Form.useForm();

  function onReset() {
    form.resetFields();
    form.setFieldsValue(initformvalues());
  }

  function initformvalues() {
    let range_date = initialFormFilter[NEXT_REVIEW_FORM_NAME];

    let aux = initialFormFilter;
    // aux[BRAND_FORM_NAME] = brands.map((item) => item.id)

    if (range_date) {
      aux[NEXT_REVIEW_FORM_NAME] = [
        moment(initialFormFilter[NEXT_REVIEW_FORM_NAME][0]),
        moment(initialFormFilter[NEXT_REVIEW_FORM_NAME][1]),
      ];
    }
    return aux;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const responseBrand = await api.get("brand_equipment?page_size=1000");
        setBrands(responseBrand.data.results);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      }
    }

    async function fetchDataPost(url, setData) {
      try {
        const response = await api.post(url);
        setData(response.data.results);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchData();
    fetchDataPost("type_equipment/list_post?page_size=1000&page=1", setTypes);
    form.setFieldsValue(initformvalues());
  }, []);

  async function onSubmit() {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
      let formData = buildFormDataToFilter(values);
      set_filter_params("formFilter", values);
      setFormFilter(formData);
      setOpen(false);
      // Notification('success', 'Filtro atualizado com sucesso.')
    } catch (error) {
      console.log(error);
      // return;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <OffCanvas
      destroyOnClose
      title={<Title level={4}>Filtrar equipamentos</Title>}
      // onClose={onClose}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      width={"394px"}
      footer={
        <>
          <Button onClick={onReset}>Redefinir filtros</Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Filtrar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onValuesChange={() => {
          // onFormChange()
        }}
      >
        <CheckboxOffCanvas
          collapseTitle={"marca"}
          form={form}
          items={brands}
          initialValue={brands.map((item) => item.id)}
          hasSearch
          itemName={BRAND_FORM_NAME}
          placeholder={"Pesquise pela marca"}
          defaultActiveKey={["0"]}
        />

        <CheckboxOffCanvas
          collapseTitle={"Tipo"}
          form={form}
          items={types}
          defaultActiveKey={0}
          initialValue={types.map((item) => item.id)}
          hasSearch
          itemName={"type_equipment_id__in"}
          placeholder={"Pesquise pelo tipo"}
        />

        <Collapse title={"PMP"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="pmps__isnull">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Possui PMP" },
                  { value: false, label: "Não possui PMP" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"GARANTIA"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="warranty_date__isnull">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: false, label: "Possui Garantia" },
                  { value: true, label: "Não possui Garantia" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"STATUS DE TELEMETRIA"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="telemetry_status">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: "Online", label: "Online" },
                  { value: "Offline", label: "Offline" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        <Collapse title={"GRANDES CONTAS"} defaultActiveKey={["0"]}>
          <Container>
            <Form.Item noStyle name="big_accounts">
              <RadioButton
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              ></RadioButton>
            </Form.Item>
          </Container>
        </Collapse>
        {/*}*/}
      </Form>
    </OffCanvas>
  );
}
