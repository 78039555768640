import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import Select from "../../Base/Select";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import { Notification } from "../../../notification";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import api from "../../../services/api";

export default function SendMultipleAnalysisModal({
  clientID,
  open,
  setClose,
  analysisIds,
}) {
  const [client, setClient] = useState();
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [optionsContacts, setOptionsContacts] = useState();
  const { fetchData, isLoading } = useDefaultFetch();
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      const clientData = await fetchData(`sap/client/${clientID}/`);
      setClient(clientData);
      setOptionsContacts(
        clientData.contact_list.map((contact, index) => ({
          value: index + 1,
          label: contact.nome,
        }))
      );
    }

    open && clientID && fetch();
  }, [clientID, open]);

  const onClose = () => {
    setClose(false);
  };

  const handleContactChoice = (value) => {
    if (value) {
      const contact = client.contact_list.find(
        (contact, index) => index + 1 === value
      );
      form.setFieldsValue({
        name: contact.nome,
        email: contact.email,
      });
    } else {
      form.resetFields();
    }
  };

  async function onSubmit() {
    setIsLoadingSendEmail(true);
    try {
      const data = await form.validateFields();
      const requestData = {
        email: data.email,
        analysisIds,
      };
      const response = await api.post(
        `oil_analysis/send_many_analysis_mail`,
        requestData
      );

      Notification("success", "Email com sucesso.");
      onClose();
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingSendEmail(false);
    }
  }

  return (
    <Modal
      open={open}
      width={"360px"}
      bodyStyle={{ padding: 24, zIndex: 9999 }}
      title={"Enviar análise"}
      closeIcon={true}
      footer={
        <>
          <Button onClick={() => setClose(false)}>Cancelar</Button>
          <Button
            type="primary"
            onClick={onSubmit}
            loading={isLoadingSendEmail}
          >
            Enviar
          </Button>
        </>
      }
      closable={false}
      onCancel={onClose}
    >
      <Form
        layout={"vertical"}
        form={form}
        requiredMark={false}
        validateTrigger={"onSubmit"}
      >
        <Form.Item label="Contato de uso para envio" name="contacts">
          <Select
            placeholder="Selecione"
            allowClear
            onChange={handleContactChoice}
            options={optionsContacts}
          />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Nome é obrigatório",
            },
          ]}
        >
          <Input placeholder="Digite o nome" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email é obrigatório",
            },
          ]}
        >
          <Input placeholder="Digite o e-mail" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
