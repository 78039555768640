import React, {useState} from 'react'
import styled from "styled-components";
import ScrollContainer from 'react-indiana-drag-scroll';

const StyledScrollContainer = styled(ScrollContainer)`
  width: calc(100% + 48px);
  margin-right: -24px;
  margin-left: ${({breakLeft}) => breakLeft ? '-24': 0 }px;
  cursor: grabbing;
`
const Content = styled.div`
  display: flex;
  column-gap: ${({gap}) => gap}px;

  & > * {
    flex-shrink: 0;
  }
`


export default function FingerScroll({children, gap=24, breakLeft=true, hideScroll=false}) {
    const [hiddenScrool, setHiddenScroll] = useState(true);

    return (
        <div onMouseEnter={() => {setHiddenScroll(hideScroll)}}
             onMouseLeave={() => {setHiddenScroll(true)}}>
            <StyledScrollContainer breakLeft={breakLeft} hideScrollbars={hiddenScrool}>
                <Content gap={gap}>
                    {breakLeft && <div style={{opacity: 0, width: 0}}>w</div>}
                    {children}
                    <div style={{opacity: 0, width: 0, marginLeft: '-12px'}}>w</div>
                </Content>
            </StyledScrollContainer>
        </div>


    )
}