import styled from "styled-components";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import api from "../../services/api";
import { useEffect } from "react";
import { useState } from "react";
import { Notification } from "../../notification";
import StepComponet from "./StepComponent";
import StepManager from "./StepManager";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  overflow-y: auto;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`;

export default function CreatePartCotacaoModal({
  isOpen,
  setIsOpen,
  startingCenter,
  startingBrand,
  client_id,
  equipment_id,
  opportunity_id = null,
  setPartsQuotations,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [quotationData, setQuotationData] = useState({});
  const [partData, setPartData] = useState([
    {
      partNumber: "",
      quantity: "",
      manufacturer: startingBrand,
      center: startingCenter,
    },
  ]);
  const [partDetails, setPartDetails] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setQuotationData({});
    setPartData([
      {
        partNumber: "",
        quantity: "",
        manufacturer: startingBrand,
        center: startingCenter,
      },
    ]);
    setPartDetails([]);
    setSelectedParts([]);
    setCurrentStep(0);
    setIsOpen(false);
  };

  useEffect(() => {
    if (currentStep === 1) {
      const buttonDisabled =
        partData.some(
          (part) =>
            part.partNumber === "" ||
            part.quantity === "" ||
            part.manufacturer === "" ||
            part.center === ""
        ) || !partData.length;
      setIsButtonDisabled(buttonDisabled);
    } else if (currentStep === 2) {
      const buttonDisabled = selectedParts.some((part) => !part.material_id);
      setIsButtonDisabled(buttonDisabled);
    }
  }, [partData, currentStep, selectedParts]);

  const onSubmit = async () => {
    try {
      setIsButtonLoading(true);
      const response = await api.post("/parts_quotation/create_quotation", {
        ...quotationData,
      });

      if (response.data.error) {
        Notification("error", "Erro ao criar cotação", response.data.message);
        return;
      }

      setPartsQuotations((prev) => [...prev, response.data]);
      Notification("success", "Cotação criada com sucesso");
      onClose();
    } catch (error) {
      Notification(
        "error",
        "Erro ao criar cotação",
        error.response.data.message
      );
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleButtonClick = async () => {
    switch (currentStep) {
      case 0:
        let values;
        try {
          values = await form.validateFields();
          values.client_id = client_id;
          values.equipment_id = equipment_id;
          values.opportunity_id = opportunity_id;
          setQuotationData((prev) => ({
            ...prev,
            ...values,
          }));
          setCurrentStep((prev) => prev + 1);
        } catch (error) {
          return;
        }
        break;
      case 1:
        try {
          setIsButtonLoading(true);
          const response = await api.post(
            "/parts_quotation/get_parts_materials",
            {
              client_id,
              parts: partData,
            }
          );
          setPartDetails(response.data);
          setSelectedParts(Array(response.data.length).fill({}));
          setCurrentStep((prev) => prev + 1);
        } catch (e) {
          Notification("error", "Erro ao consultar peças no SAP");
        } finally {
          setIsButtonLoading(false);
        }
        break;
      case 2:
        setQuotationData((prev) => ({
          ...prev,
          parts: selectedParts,
        }));
        setCurrentStep((prev) => prev + 1);
        break;
      case 3:
        onSubmit();
        break;
      default:
        setCurrentStep(0);
    }
  };

  const getButtonText = () => {
    if (isButtonLoading) {
      return "Processando...";
    }
    return currentStep < 3 ? (
      "Avançar"
    ) : (
      <>
        <PlusOutlined style={{ marginRight: "4px" }} />
        Criar nova cotação
      </>
    );
  };

  return (
    <Modal
      open={isOpen}
      width={"900px"}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button onClick={onClose}>Cancelar</Button>,
        <Button
          type={"primary"}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
          loading={isButtonLoading}
        >
          {getButtonText()}
        </Button>,
      ]}
      closable={false}
      title="Criar nova cotação de peças"
    >
      <StyledCloseOutlined onClick={onClose} />
      <StepComponet currentStep={currentStep} />
      <ContentContainer>
        <StepManager
          currentStep={currentStep}
          startingCenter={startingCenter}
          startingBrand={startingBrand}
          form={form}
          quotationData={quotationData}
          setQuotationData={setQuotationData}
          partDetails={partDetails}
          partData={partData}
          setPartData={setPartData}
          setSelectedParts={setSelectedParts}
        />
      </ContentContainer>
    </Modal>
  );
}
