import React from "react";
import styled from "styled-components";
import PageTitle from "../../components/Base/PageTitle";
import HourMeterCard from "../../components/Base/HourMeterCard";
import LastDate from "../../components/Base/LastDate";
import { Col } from "antd";
import Line from "../../components/Base/Charts/Line";
import FingerScroll from "../../components/Base/FingerScroll";
import { maskIntNumber } from "../../util/Utils";
import { dateFormat } from "../../util/dates";
import LastHourmeterUpdate from "../../components/Base/LastHourmeterUpdate";

const TabContainer = styled.div`
  padding-top: 24px;
`;

const StyledLastHourmetterDate = styled(LastHourmeterUpdate)`
  margin-top: 8px;
`;

const StyledPageTitle = styled(PageTitle)`
  margin-top: 24px;
  margin-bottom: 8px;
`;

export default function TabHourMeter({
  equipment_id,
  summary,
  use_g4_hourmeter,
  g4_hourmeter,
}) {
  const getCurrentHourmeter = () => {
    if (use_g4_hourmeter) {
      return maskIntNumber(g4_hourmeter.hourmeter);
    }

    if (summary?.current_hour_meter) {
      return maskIntNumber(summary?.current_hour_meter);
    }
    return "-";
  };

  const getCurrentHourmeterDate = () => {
    if (use_g4_hourmeter) {
      return g4_hourmeter.hourmeter_update_date;
    }
    if (summary?.current_hour_meter) {
      return summary?.last_communication_hourmeter;
    }
  };

  return (
    <TabContainer>
      <PageTitle value={"Estatísticas do horímetro"} />
      <FingerScroll>
        <HourMeterCard
          date={getCurrentHourmeter()}
          title={"Horímetro atual"}
          label={"Total"}
        />
        <HourMeterCard
          date={
            summary?.average_seven_days
              ? `${summary?.average_seven_days}h`
              : "-"
          }
          title={"Últimos 7 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={
            summary?.average_fifteen_days
              ? `${summary?.average_fifteen_days}h`
              : "-"
          }
          title={"Últimos 15 dias"}
          label={"Média diária"}
        />
        <HourMeterCard
          date={summary?.average_overall ? `${summary?.average_overall}h` : "-"}
          title={`Geral (${
            summary?.tracked_since ? summary?.tracked_since : "-"
          })`}
          label={"Média diária"}
        />
      </FingerScroll>
      <StyledLastHourmetterDate
        dataOrigin={use_g4_hourmeter ? "G4" : "Equipamento"}
        updateDate={getCurrentHourmeterDate()}
      />
      <PageTitle value={"Dados da próxima revisão"} style={{ marginTop: 15 }} />
      <FingerScroll>
        <HourMeterCard
          width={370}
          date={
            summary?.search_next_review
              ? `${maskIntNumber(summary?.search_next_review)}`
              : "-"
          }
          title={"Horímetro da próxima manutenção"}
          label={""}
        />
        <HourMeterCard
          width={370}
          date={
            summary?.next_maintenance_days
              ? `${summary?.next_maintenance_days} dias`
              : "-"
          }
          title={"Média dos últimos 15 dias"}
          label={"Até a próxima revisão"}
        />
        <HourMeterCard
          width={370}
          date={
            summary?.next_maintenance_date
              ? `${dateFormat(summary?.next_maintenance_date)}`
              : "-"
          }
          title={"Data em que o horímetro será alcançado"}
          label={"Estímativa"}
        />
      </FingerScroll>

      {/*{summary?.search_next_review ? <>*/}
      {/*    <StyledPageTitle value={'Histórico de revisões'}/>*/}
      {/*    <ReviewInfoHour*/}
      {/*        icon={<WarningOutlined style={{color: 'yellow'}}/>}*/}
      {/*        msg={summary?.search_next_review + ' Horas - Próxima revisão'}/>*/}
      {/*    /!*<ReviewInfoHour icon={<i className="ri-emotion-unhappy-line" style={{color: 'red'}}/>} msg={'1500 Horas - Não realizada'}/>*!/*/}
      {/*    /!*<ReviewInfoHour icon={<i className="ri-timer-line" style={{color: 'purple'}}/>} msg={'1000 Horas - Atrasada'}/>*!/*/}
      {/*    /!*<ReviewInfoHour icon={<i className="ri-checkbox-circle-line" style={{color: 'green'}}/> } msg={'500 Horas - Realizada'}/>*!/*/}
      {/*</> : ''}*/}

      <Col xs={24} md={24} style={{ marginTop: 16 }}>
        <Line
          url={`/equipment/${equipment_id}/get_hourmeter_historys`}
          title={"Histórico do horimetro"}
          width="100%"
          height="404px"
          hasSelect
          hasPadding={false}
          defaultValue={"7"}
          optionsSelect={[
            { value: "7", label: "Últimos 7 dias" },
            { value: "15", label: "Últimos 15 dias" },
            { value: "30", label: " Último mês" },
            { value: "365", label: "Último ano" },
          ]}
        />
      </Col>
    </TabContainer>
  );
}
