import React from 'react'
import {Tooltip} from "antd/es";
import {QuestionCircleOutlined} from "@ant-design/icons";

export default function InformationTip({text}) {

    return (
        <Tooltip title={text}>
            <QuestionCircleOutlined style={{cursor: 'pointer'}}/>
        </Tooltip>
    )
}