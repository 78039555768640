import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Space } from "antd";
import {
  Brand,
  Chassi,
  EquipementInfo,
  Name,
} from "../../components/GenericStyles";
import DisplayInfo from "../../components/Base/DisplayInfo";
import HeaderDetail from "../../components/HeaderDetail";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import AvatarProfile from "../../components/AvatarProfile";
import IconButton from "../../components/IconButton";
import PageTitle from "../../components/Base/PageTitle";
import TextArea from "../../components/Base/Forms/TextArea";
import Select from "../../components/Base/Select";
import Upload from "../../components/Base/Forms/Upload";
import DividerPage from "../../components/Base/DividerPage";
import { actionbar_bg, content_bg, primary_color } from "../../Cores";
import ShareLocation from "../../components/Base/ShareLocation";
import ArrowLink from "../../components/Base/ArrowLink";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import ResponsableOffCanvas from "../../components/Opportunity/ResponsableOffCanvas";
import StatusOffCanvas from "../../components/Opportunity/StatusOffCanvas";
import CancelOpportunityOffCanvas from "../../components/Opportunity/CancelOpportunityOffCanvas";
import api from "../../services/api";
import {
  BuildFieldErrorsDict,
  formatCPFCNPJ,
  maskIntNumber,
} from "../../util/Utils";
import Loading from "../../components/Base/Loading";
import { dateFormat } from "../../util/dates";
import { Notification } from "../../notification";
import Comment from "../../components/Comment";
import Empty from "../../components/Base/Empty";
import BadgeOportunityStatus from "../../components/Base/BadgeOportunityStatus";
import MyProfileOffCanvas from "../../components/User/MyProfileOffCanvas";
import Pagealert from "../../components/Base/Pagealert";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useUserContext } from "../../context/UserContext";
import AlertPMP from "../../components/Opportunity/AlertPMP";
import AlertPriority from "../../components/Opportunity/AlertPriority";
import { NEW_ID, validateOpportunityType } from "../../util/OpportunityUtils";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import useActionPermission from "../../hooks/useActionPermission";
import CenterUptimeOffCanvas from "../../components/equipment/CenterUptimeOffCanvas";
import SystemConfiabilityAlert from "../../components/Opportunity/SystemConfiabilityAlert";
import FingerScroll from "../../components/Base/FingerScroll";
import OpportunityOilTable from "../../components/Oil/tables/OppotunityOilTable";
import PriorityOpportunityCard from "../../components/Base/PriorityOpportunityCard";
import OpportunityServiceLeedsTable from "../../components/Opportunity/tables/OpportunityServiceLeedsTable";
import RenewPMP from "../../components/RenewPMP";
import WarrantyDateAlert from "../../components/Opportunity/WarrantyDateAlert";
import Collapse from "../../components/Collapse";
import PMPObservation from "../../components/PMPObservation";
import AlertOS from "../../components/Opportunity/AlertOS";
import AlertHourmeter from "../../components/equipment/AlertHourmeter";
import SendMultipleAnalysisModal from "../../components/Oil/tables/SendMultipleAnalysisModal";
import BigAccountIcon from "../../components/Base/BigAccountIcon";

const StyledEditOutlined = styled(EditOutlined)`
  color: ${primary_color};
  cursor: pointer;
`;

const HeaderContent = styled.div`
  padding: 65px 16px 16px;

  display: flex;
  height: 100%;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    padding-top: 16px;
  }
`;

const Container = styled.div`
  background: ${content_bg};
  width: 100%;
  height: 100%;
  padding: 16px;
  ${({ noHorizontalPadding }) => {
    if (noHorizontalPadding) {
      return `padding: 16px 0px`;
    }
  }}
`;
const PreHeaderContainer = styled.div`
  background: black;
  padding: 25px 32px 0px;
`;

const InfoContainer = styled.div`
  background: ${actionbar_bg};
`;

const FINISHED_STATUS_ID = 7;
const CANCELED_STATUS_ID = 9;

export default function TabOverview({
  opportunity,
  setOpportunity,
  equipment,
  isLoadingEquipment,
  client,
  isLoadingClient,
}) {
  const { isDesktop } = useSystemBreakpoint();
  const [form] = Form.useForm();
  const { userCentersFilter } = useUserContext();
  const [openPerfilUser, setOpenPerfilUser] = useState(false);
  const [priorityOpportunities, setPriorityOpportunities] = useState([]);
  const [open, setOpen] = useState();
  const [openCancelCanvas, setOpenCancelCanvas] = useState(false);
  const [openResponsable, setOpenResponsable] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [coments, setComents] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestion] = useState(false);
  const [isLoadingComents, setIsLoadingComents] = useState(false);
  const [openTableOil, setOpenTableOil] = useState(false);
  const [openTableServiceLeeds, setOpenTableServiceLeeds] = useState();
  const [responsible, setResponsible] = useState();
  const [fileList, setFileList] = useState([]);
  const { fetchData } = useDefaultFetch();
  const { hasPermission } = useActionPermission({ permissions: [1, 2, 4, 5] });
  const [osList, setOsList] = useState([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState([]);
  const [isSendMassAnalysisModalOpen, setIsSendMassAnalysisModalOpen] =
    useState(false);

  const handleSendSelectedAnalysis = () => {
    setIsSendMassAnalysisModalOpen(true);
  };

  useEffect(() => {
    if (opportunity.id && equipment.id) {
      async function fetch() {
        const priorityOpportunityData = await fetchData(
          `equipment/${equipment.id}/get_active_opportunities?opportunity_id=${opportunity.id}`
        );
        if (priorityOpportunityData) {
          setPriorityOpportunities(priorityOpportunityData);
        }
      }

      fetch();
    }
  }, [opportunity, equipment]);

  useEffect(() => {
    async function fetchOsList() {
      try {
        if (!equipment.id) return;

        const response = await api.get(`equipment/${equipment.id}/get_os_list`);
        const activeOs = response.data.filter(
          (os) =>
            !["concluída", "cancelada", "finalizda"].includes(
              os.status.toLowerCase()
            )
        );
        setOsList(activeOs);
      } catch (error) {
        console.error(error);
      }
    }

    fetchOsList();
  }, [equipment.id]);

  useEffect(() => {
    if (opportunity.id) {
      setResponsible(opportunity.responsible);
      fetchComents();
    }
  }, [opportunity]);

  function opportunity_is_finished() {
    if (!opportunity.status) {
      return false;
    }
    return (
      opportunity.status.id === FINISHED_STATUS_ID ||
      opportunity.status.id === CANCELED_STATUS_ID
    );
  }

  function checkHasPermission() {
    return opportunity?.status?.id === NEW_ID && hasPermission;
  }

  const addNewQuestion = async () => {
    let formData = new FormData();
    setIsLoadingQuestion(true);
    let values;
    try {
      values = await form.validateFields();
      delete values["file_list"];
      fileList.forEach((file) => {
        formData.append("files[]", file);
      });
      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (value) {
          if (value.constructor.name === "Array") {
            value.forEach((value) => {
              formData.append(key, value);
            });
          } else {
            formData.append(key, value);
          }
        }
      });
    } catch (error) {
      Notification("error", "Erros encontrados");
      setIsLoadingQuestion(false);
      return;
    }

    try {
      await api.post(`opportunity/${opportunity.id}/add_question`, formData);
      Notification("success", "Comentário criado com sucesso.");
      form.resetFields();
      setFileList([]);
      fetchComents();
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, false);
    } finally {
      setIsLoadingQuestion(false);
    }
  };

  const openResponsableCanvas = () => {
    setOpenResponsable(true);
  };

  const fetchComents = async () => {
    try {
      setIsLoadingQuestion(true);
      const response = await api.get(`opportunity/${opportunity.id}/questions`);
      setComents(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoadingQuestion(false);
    }
  };

  const getHourmeterSource = () => {
    if (equipment.use_g4_hourmeter) {
      return (
        <>
          <span style={{ display: "block" }}>
            Dia:{" "}
            {dateFormat(equipment?.last_g4_hourmeter?.hourmeter_update_date)}
          </span>
          <span style={{ display: "block" }}>Origem: G4</span>
        </>
      );
    }

    return (
      <>
        <span style={{ display: "block" }}>
          Dia: {dateFormat(equipment?.summary?.last_communication_hourmeter)}
        </span>
        <span style={{ display: "block" }}>Origem: Telemetria</span>
      </>
    );
  };

  const getHourmeterValue = () => {
    if (equipment.use_g4_hourmeter) {
      return equipment?.last_g4_hourmeter.hourmeter;
    }

    return equipment.last_reporting_hourmeter;
  };

  return (
    <>
      {!userCentersFilter.includes(opportunity.center) && (
        <Pagealert
          icon={<InfoCircleOutlined />}
          message={`Essa notificação está no centro de ${opportunity.center_name}, que não está na sua lista de centros.`}
        />
      )}
      <ContainerWithoutLimit>
        <PreHeaderContainer>
          <Row justify={"end"}>
            <Space>
              <BadgeOportunityStatus
                type={opportunity?.status?.id}
                message={opportunity?.cancellation_reason}
              >
                {opportunity?.status?.name}
              </BadgeOportunityStatus>
              {responsible ? (
                <AvatarProfile
                  iconSize={24}
                  src={responsible?.responsible?.picture}
                  id={responsible?.responsible?.id}
                  noActionClick
                  onClick={() => setOpenPerfilUser(true)}
                  name={responsible?.responsible?.get_full_name}
                  extra={
                    !opportunity_is_finished() && (
                      <>
                        <IconButton
                          onClick={openResponsableCanvas}
                          icon={<i className="ri-arrow-left-right-line" />}
                        />{" "}
                      </>
                    )
                  }
                />
              ) : (
                !opportunity_is_finished() && (
                  <Button onClick={openResponsableCanvas}>
                    Adicionar responsável
                  </Button>
                )
              )}
            </Space>
          </Row>
        </PreHeaderContainer>
      </ContainerWithoutLimit>
      <HeaderDetail>
        <Row gutter={[64, 0]}>
          <Col xs={24} lg={8}>
            <HeaderContent>
              <Chassi>{opportunity.chassi_no}</Chassi>
              <Name>{opportunity.rule_name}</Name>
              <Brand>{opportunity.equipment_name}</Brand>
            </HeaderContent>
          </Col>

          <Col xs={24} lg={14}>
            <EquipementInfo>
              <Row gutter={[16, 16]}>
                <Col xs={8} md={6} lg={4}>
                  <DisplayInfo label={"COD. NOTIFICAÇÃO"}>
                    {opportunity.id}
                  </DisplayInfo>
                </Col>
                <Col xs={8} md={6} lg={4}>
                  <DisplayInfo label={"SEVERIDADE"} noWrap>
                    {validateOpportunityType(opportunity, "Análise de óleo") ||
                    validateOpportunityType(
                      opportunity,
                      "Revisão programada"
                    ) ? (
                      "-"
                    ) : (
                      <AlertPriority value={opportunity.severity} />
                    )}
                  </DisplayInfo>
                </Col>
                <Col xs={8} md={6} lg={5}>
                  <DisplayInfo label={"HORÍMETRO CRIAÇÃO"} noWrap>
                    <AlertHourmeter
                      value={opportunity.hourmeter_confiability_on_creation}
                    />
                    {maskIntNumber(opportunity.active_opportunity_hourmeter)}
                  </DisplayInfo>
                </Col>
                <Col xs={8} md={6} lg={5}>
                  <DisplayInfo
                    label={"HORÍMETRO ATUAL"}
                    noWrap
                    tooltipValue={getHourmeterSource()}
                  >
                    <AlertHourmeter value={equipment.hourmeter_confiability} />
                    {maskIntNumber(getHourmeterValue())}
                  </DisplayInfo>
                </Col>
                <Col xs={8} md={6} lg={6}>
                  <DisplayInfo
                    label={"CENTRO(OPORTUNIDADE)"}
                    extraIcon={
                      checkHasPermission() && (
                        <StyledEditOutlined onClick={() => setOpen(true)} />
                      )
                    }
                  >
                    {opportunity.center_name}
                  </DisplayInfo>
                </Col>
              </Row>
            </EquipementInfo>
          </Col>
        </Row>
      </HeaderDetail>
      <DividerPage />
      <InfoContainer>
        <Row gutter={[24, 0]} align={"stretch"}>
          <Col xs={24} lg={12}>
            <Container noHorizontalPadding>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <PageTitle value={"Informações do equipamento"} />
                </Col>
                {isLoadingEquipment ? (
                  <Col xs={24}>
                    {" "}
                    <Loading />
                  </Col>
                ) : (
                  <>
                    <Col xs={12}>
                      <DisplayInfo label={"Código (SAP)"}>
                        {equipment.id}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"N° Série/PIN17"}>
                        {equipment.pin_17}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo
                        showTooltip={false}
                        label={"CONFIABILIDADE DA TELEMETRIA"}
                      >
                        <SystemConfiabilityAlert
                          value={equipment.system_reliability}
                        />
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo
                        showTooltip={false}
                        label={"CONFIABILIDADE DO HORÍMETRO NA CRIAÇÃO"}
                      >
                        <AlertHourmeter
                          value={opportunity.hourmeter_confiability_on_creation}
                          hasText
                        />
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"MARCA"}>
                        {equipment?.brand_equipment?.name}
                      </DisplayInfo>
                    </Col>
                    <Col md={12} xs={24}>
                      <DisplayInfo showTooltip={false} label={"PMP"}>
                        <AlertPMP value={equipment.PMP} />
                        {opportunity.renew_PMP && (
                          <RenewPMP
                            text={
                              "PMP próximo ao vencimento. Recomendado renovar."
                            }
                          />
                        )}
                        {equipment.last_pmp && (
                          <PMPObservation
                            text={`Último contrato: ${equipment.last_pmp.contract_number} (${equipment.last_pmp.expired_at})`}
                          />
                        )}
                        {!equipment.last_pmp && !equipment.renew_PMP && (
                          <PMPObservation text={"Sem informações adicionais"} />
                        )}
                      </DisplayInfo>
                    </Col>

                    <Col xs={24} md={12}>
                      <DisplayInfo label={"OS Ativa"}>
                        <AlertOS value={osList.length && "Sim"} />
                      </DisplayInfo>
                    </Col>
                    <Col xs={24} md={12}>
                      <DisplayInfo
                        showTooltip={false}
                        label={"Garantia (Final)"}
                      >
                        <WarrantyDateAlert
                          date={equipment.warranty_date}
                          brandEquipmentName={equipment.brand_equipment.name}
                          hourmeter={equipment.last_reporting_hourmeter}
                        />
                      </DisplayInfo>
                    </Col>
                    <Col xs={24}>
                      <DisplayInfo
                        showTooltip={false}
                        label={"Última localização"}
                        extraIcon={
                          <ShareLocation
                            lat={equipment.last_equipment_position?.latitude}
                            long={equipment.last_equipment_position?.longitude}
                          />
                        }
                      >
                        {equipment.last_equipment_position?.reverse_geocoding}
                      </DisplayInfo>
                    </Col>
                    <Col xs={24}>
                      <ArrowLink
                        label={"Acessar perfil do equipamentos"}
                        to={{
                          pathname: `/equipment/${equipment.id}/detail`,
                          search: `?tab=1`,
                          state: {
                            from: `opportunity/${opportunity.id}/detail?tab=1`,
                          },
                        }}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </Col>
          {!isDesktop && <DividerPage />}
          <Col xs={24} lg={12}>
            <Container noHorizontalPadding={!isDesktop}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <PageTitle value={"Informações do cliente"} />
                </Col>
                {isLoadingClient ? (
                  <Col xs={24}>
                    <Loading />
                  </Col>
                ) : (
                  <>
                    <Col xs={12}>
                      <DisplayInfo label={"CÓDIGO (SAP)"}>
                        {client.id}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"CLIENTE"}>
                        {equipment.client.is_big_account ? <BigAccountIcon /> : ""}{" "}
                        {client.name}
                      </DisplayInfo>
                    </Col>

                    <Col xs={12}>
                      <DisplayInfo label={"CPF/CNPJ"}>
                        {formatCPFCNPJ(client.cnpj_cpf)}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"CIDADE / ESTADO"}>
                        {client.city} / {client.state}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"BLOQUEIO FINANCEIRO"}>
                        {" "}
                        {client.financial_block ? "Sim" : "Não"}
                      </DisplayInfo>
                    </Col>
                    <Col xs={12}>
                      <DisplayInfo label={"Saldo do Limite"} showZero>
                        R$ {maskIntNumber(client.limit_value)}
                      </DisplayInfo>
                    </Col>
                    <Col xs={24}>
                      {client.id && (
                        <ArrowLink
                          label={"Acessar perfil do cliente"}
                          to={{
                            pathname: `/client/${client.id}/detail`,
                            search: `?tab=1`,
                            state: {
                              from: `opportunity/${opportunity.id}/detail?tab=1`,
                            },
                          }}
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
        <DividerPage />
        <Row gutter={[24, 0]} align={"stretch"}>
          <Col xs={24}>
            <Container noHorizontalPadding>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <PageTitle value={"Informações adicionais"} />
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"DATA DE CRIAÇÃO"}>
                    {dateFormat(opportunity.created_at)}
                  </DisplayInfo>
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"DATA DE ENCERRAMENTO"}>
                    {dateFormat(opportunity.closing_date)}
                  </DisplayInfo>
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"VALOR BRUTO (OS)"}>
                    {opportunity.status?.id === FINISHED_STATUS_ID &&
                      maskIntNumber(opportunity.gross_value)}
                  </DisplayInfo>
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"Formato de entrada"}>
                    {opportunity.telemetry_system?.name}
                  </DisplayInfo>
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"TEMPO DECORRIDO"}>
                    {opportunity.get_elapsed_time}
                  </DisplayInfo>
                </Col>
                <Col xs={12} lg={8}>
                  <DisplayInfo label={"DATA DA ULTIMA ATUALIZAÇÃO"}>
                    {dateFormat(opportunity.updated_at)}
                  </DisplayInfo>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <DividerPage />
        <Row>
          <Col xs={24}>
            <Container noHorizontalPadding>
              <Collapse
                title={
                  <PageTitle
                    style={{ marginTop: 16 }}
                    value={"Outras notificações desse equipamento"}
                  />
                }
                defaultActiveKey={priorityOpportunities.length > 0 && "1"}
              >
                {priorityOpportunities.length === 0 ? (
                  <Empty />
                ) : (
                  <FingerScroll gap={8} breakLeft={false}>
                    {priorityOpportunities.map((item) => {
                      return <PriorityOpportunityCard opportunity={item} />;
                    })}
                  </FingerScroll>
                )}
              </Collapse>
            </Container>
          </Col>
        </Row>
        <DividerPage />
        {opportunity.type_opportunity === "Revisão programada" && (
          <Row>
            <Col xs={24}>
              <Container noHorizontalPadding>
                <Collapse
                  title={
                    <PageTitle
                      style={{ marginTop: 16 }}
                      value={"Sugestões do fabricante para troca de peças"}
                    />
                  }
                  activeKey={openTableServiceLeeds}
                  onChange={(value) => setOpenTableServiceLeeds(value[0])}
                >
                  {opportunity.id && (
                    <OpportunityServiceLeedsTable
                      noPagination
                      onFirstRequest={(data) =>
                        setOpenTableServiceLeeds(data.length > 0 && "1")
                      }
                      opportunity={opportunity}
                    />
                  )}
                </Collapse>
              </Container>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <Container noHorizontalPadding>
              <Collapse
                title={
                  <PageTitle
                    style={{ marginTop: 16 }}
                    value={"Histórico recente de análises"}
                  />
                }
                activeKey={openTableOil}
                onChange={(value) => setOpenTableOil(value[0])}
              >
                {opportunity.id && (
                  <OpportunityOilTable
                    noPagination
                    onFirstRequest={(data) =>
                      setOpenTableOil(data.length > 0 && "1")
                    }
                    oppotunity={opportunity}
                    setSelectedAnalysis={setSelectedAnalysis}
                  />
                )}
                <Col xs={4} style={{ marginLeft: "auto", marginTop: "1rem" }}>
                  <Button
                    onClick={handleSendSelectedAnalysis}
                    type="primary"
                    style={{ width: "100%" }}
                    disabled={selectedAnalysis.length === 0}
                  >
                    Enviar análises
                  </Button>
                </Col>
                <SendMultipleAnalysisModal
                  clientID={client.id}
                  open={isSendMassAnalysisModalOpen}
                  setClose={() => setIsSendMassAnalysisModalOpen(false)}
                  analysisIds={selectedAnalysis.map((analysis) => analysis.id)}
                />
              </Collapse>
            </Container>
          </Col>
        </Row>
      </InfoContainer>
      <DividerPage />
      {/*Visao geral*/}
      <Row>
        <Col xs={24}>
          <Container noHorizontalPadding>
            <Collapse title={<PageTitle value={"Adicionar Comentários"} />}>
              <Col xs={24} md={24}>
                <Form layout={"vertical"} form={form}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Este campo é obrigatório",
                      },
                    ]}
                  >
                    <TextArea
                      showCount
                      maxLength={2000}
                      placeholder={"Adicione um comentário..."}
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Usuários a serem notificados"}
                    name="notified_users"
                  >
                    <Select
                      style={{ width: "100%" }}
                      mode="multiple"
                      label_key={"get_full_name"}
                      url={"user?is_active=true&page_size=200"}
                      placeholder="Selecione os usuários que deverão ser notificados"
                    />
                  </Form.Item>

                  <Form.Item name={"file_list"} label={"Adicionar Anexos"}>
                    <Upload setFileList={setFileList} />
                  </Form.Item>
                </Form>
                {/*)}*/}
              </Col>
              <Col xs={24} md={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 12,
                  }}
                >
                  <Button
                    onClick={addNewQuestion}
                    type="primary"
                    loading={isLoadingQuestions}
                  >
                    Adicionar Comentário
                  </Button>
                </div>
              </Col>
            </Collapse>
          </Container>
        </Col>
      </Row>
      <Row>
        <Container noHorizontalPadding>
          <Collapse
            title={<PageTitle value={"Todos os comentários"} />}
            defaultActiveKey={coments.length > 0 && "1"}
          >
            {isLoadingComents ? (
              <Loading />
            ) : (
              <>
                {coments.length === 0 ? (
                  <Empty style={{ height: 216 }} />
                ) : (
                  coments.map((comment) => <Comment comment={comment} />)
                )}
              </>
            )}
          </Collapse>
        </Container>
      </Row>

      {!opportunity_is_finished() && (
        <>
          <DividerPage />
          <Row style={{ marginTop: 24 }} gutter={16}>
            <Col>
              <Button
                type={"link"}
                // loading={isLoading}
                onClick={() => setOpenStatus(true)}
                style={{ padding: 0 }}
              >
                Alterar status
              </Button>
            </Col>
            <Col>
              <Button
                danger
                type={"link"}
                // loading={isLoading}
                onClick={() => setOpenCancelCanvas(true)}
                style={{ padding: 0 }}
              >
                Cancelar notificação
              </Button>
            </Col>
          </Row>
        </>
      )}
      {responsible && (
        <MyProfileOffCanvas
          noEditable
          user={responsible?.responsible}
          setOpen={setOpenPerfilUser}
          open={openPerfilUser}
        />
      )}
      {!opportunity_is_finished() && (
        <>
          <CancelOpportunityOffCanvas
            opportunity={opportunity}
            setOpportunity={setOpportunity}
            open={openCancelCanvas}
            setOpen={setOpenCancelCanvas}
          />
          <ResponsableOffCanvas
            opportunity={opportunity}
            setResponsible={setResponsible}
            open={openResponsable}
            responsible={responsible}
            setOpen={setOpenResponsable}
          />
          <StatusOffCanvas
            opportunity={opportunity}
            setOpportunity={setOpportunity}
            open={openStatus}
            setOpen={setOpenStatus}
          />
        </>
      )}
      {checkHasPermission() && (
        <CenterUptimeOffCanvas
          setObject={setOpportunity}
          open={open}
          setOpen={setOpen}
          centerId={opportunity.center}
          url={`opportunity/${opportunity.id}/change_center`}
        />
      )}
    </>
  );
}
