import React from 'react'
import Avatar from "./Base/Avatar";
import styled from "styled-components";
import {border_gray, collapse_header_text_color} from "../Cores";

const Container = styled.div`
  display: flex;
  gap: 8px;

  & > .ant-avatar.ant-avatar-circle {
    flex-shrink: 0;
  }
`

const Name = styled.div`
  font-size: 14px;
`

const Date = styled.div`
  font-size: 12px;
  color: ${collapse_header_text_color};
`

export const ListTimelineCard = styled.div`
  display: flex;
  flex-direction: column;


  & > * {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${border_gray};
  }

  & > *:first-child {
    padding-top: 16px;
  }

  & > *:last-child {
    padding-top: 16px;
    //padding-bottom: 16px;
    border-bottom: unset;
  }
`

export default function TimelineCard({timeline}) {

    return (
        <div>
            <Container>
                {timeline.owner && <Avatar size={24}
                                           id={timeline.owner.id}
                                           name={timeline.owner.get_full_name}
                                           src={timeline.owner.picture}
                                           style={{marginRight: 3}}/>}
                <Name>{timeline.description}</Name>
            </Container>

            <Date>{timeline.display_date}</Date>


        </div>
    )
}