import { gray } from "../../../Cores";
import RemixIcon from "../../Base/RemixIcon";
import React, { useEffect, useState } from "react";
import {
  ERROR_CODE_ID,
  OIL_ANALYSIS,
  SCHEDULED_REVIEW_ID,
  ALERT_ID,
} from "../rule_consts";
import { Col, Form, Row } from "antd";
import PageTitle from "../../Base/PageTitle";
import Select from "../../Base/Select";
import {
  BuildFieldErrorsDict,
} from "../../../util/Utils";
import DividerPage from "../../Base/DividerPage";
import GroupButtons from "../../Base/GroupButtons";
import TextArea from "../../Base/Forms/TextArea";
import Upload from "../../Base/Forms/Upload";
import Checkbox from "../../Base/Forms/CheckBoxSingle";
import Input from "../../Base/Forms/Input";
import api from "../../../services/api";
import ScheduledReviewFormContainer from "./ScheduledReviewFormContainer";
import FormInfo from "./FormInfo";
import ErrorCodeFormContainer from "./ErrorCodeFormContainer";
import OilAnalysisFormContainer from "./OilAnalysisFormContainer";
import AlertFormContainer from "./AlertFormContainer";

function NoRuleContainer() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: 206,
        color: gray,
      }}
    >
      <RemixIcon remixIconName={"ri-loader-fill"} style={{ fontSize: 40 }} />
      <span>Selecione o tipo da regra para continuar</span>
    </div>
  );
}

export default function RuleForm({
  form,
  setFileList,
  updateMode,
  defaultFileList = [],

  setHasChange,
  showContainers,
  setShowContainers,
  ...props
}) {
  const [priorities, setPriorities] = useState([]);
  const [ruleType, setRuleType] = useState(null);
  const [name, setName] = useState("");

  const fetchPriorities = async () => {
    try {
      const response = await api.get(`/rule_priority?page_size=1000&page=1`);
      setPriorities(
        response.data.results.map((priority) => {
          return { value: priority.id, label: priority.name };
        })
      );
    } catch (error) {
      BuildFieldErrorsDict(error, null, "Erro ao buscar prioridades da regra");
    }
  };

  useEffect(() => {
    fetchPriorities();
    if (updateMode) {
      setRuleType(form.getFieldValue("rule_type"));
      setName(form.getFieldValue("name"));
      form.setFieldValue(
        "notified_users_ids",
        form.getFieldValue("notified_users")
      );
    } else {
      form.setFieldsValue({ rule_type: ruleType, priority: 1 });
    }
  }, []);

  useEffect(() => {
    if (!updateMode) {
      form.resetFields();
      form.setFieldsValue({
        rule_type: ruleType,
        name: name,
        priority: 1,
      });
    }

    if (ruleType && name.length > 0) {
      setShowContainers(true);
    }
  }, [ruleType]);

  useEffect(() => {
    if (ruleType && name.length > 0) {
      setShowContainers(true);
    } else {
      setShowContainers(false);
    }
  }, [name]);

  function getRuleTypeFormContainers() {
    const formBlocks = {
      [SCHEDULED_REVIEW_ID]: (
        <ScheduledReviewFormContainer updateMode={updateMode} />
      ),
      [OIL_ANALYSIS]: <OilAnalysisFormContainer updateMode={updateMode} />,
      [ERROR_CODE_ID]: (
        <ErrorCodeFormContainer form={form} updateMode={updateMode} />
      ),
      [ALERT_ID]: <AlertFormContainer updateMode={updateMode} />,
    };
    return (
      <>
        {formBlocks[ruleType]}

        <DividerPage />
        <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
          <Col xs={24}>
            <PageTitle value={<>Detalhes da regra </>} />
          </Col>
          <Col xs={24}>
            <Form.Item label={"Severidade da notificação"} name={"priority"}>
              <GroupButtons
                options={priorities}
                disabled={ruleType === OIL_ANALYSIS}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={"Comentário técnico"} name={"tech_comment"}>
              <TextArea
                placeholder={
                  "Caso necessário, adicione uma observação para a regra.\n"
                }
                showCount
                maxLength={100}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name={"file_list"} label={"Adicionar Anexos"}>
              <Upload
                defaultFileList={defaultFileList}
                setFileList={setFileList}
              />
            </Form.Item>
          </Col>
        </Row>
        <DividerPage />
        <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <PageTitle value={<> Notificações</>} />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <FormInfo
              label={
                "Selecione as pessoas que deverão ser notificadas quando surgir uma notificação a partir dessa regra"
              }
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  valuePropName="checked"
                  name="notified_manager"
                  label={"Grupos de usuários"}
                >
                  <Checkbox
                    disabled
                    labelCheck={"Gestores (do centro do equipamento)"}
                  />
                </Form.Item>
                <Form.Item valuePropName="checked" name="notified_consultant">
                  <Checkbox
                    disabled
                    labelCheck={"Consultores (do centro do equipamento)"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={"Usuários a serem notificados"}
              name={"notified_users_ids"}
            >
              <Select
                disabled
                url={"user?is_active=true&page_size=10000"}
                label_key={"get_full_name"}
                mode="multiple"
                placeholder="Busque pelo nome do usuário"
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Form
        id={"createForm"}
        layout={"vertical"}
        form={form}
        onValuesChange={() => setHasChange(true)}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <PageTitle value={<> Informações gerais</>} />
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={"Tipo da regra"}
              name={"rule_type"}
              rules={[
                {
                  required: true,
                  message: "Selecione o tipo da regra",
                },
              ]}
            >
              <Select
                disabled={updateMode}
                url={"rule_type?page_size=10000"}
                onChange={(value) => setRuleType(value)}
                placeholder="Selecione o tipo da regra"
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Nome da regra"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Nome é obrigatório",
                },
                {
                  max: 100,
                  message: "Tamanho máximo de 100 caracteres",
                },
              ]}
            >
              <Input
                placeholder="Digite o nome da regra"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <DividerPage />
        {showContainers ? getRuleTypeFormContainers() : <NoRuleContainer />}
      </Form>
    </>
  );
}
