import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Col, Row} from "antd";
import PageTitle from "../../Base/PageTitle";
import {Transfer} from "../TransferComponents";
import {BuildFieldErrorsDict} from "../../../util/Utils";
import DividerPage from "../../Base/DividerPage";
import {useNavigate} from "react-router-dom";
import api from "../../../services/api";
import {Notification} from "../../../notification";
import {usePrompt} from "../../../hooks/useConfirmeUnsaveChanges";
import Loading from "../../Base/Loading";


const TabContainer = styled.div`
  padding-top: 16px;
`

const ContainerButtons = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-end;
`

export default function TabRuleEquipments({rule}) {
    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedTypes, setSelectedTypes] = useState([])
    const [selectedModels, setSelectedModels] = useState([])
    const [typeParams, setTypeParams] = useState({})
    const [modelParams, setModelParams] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInitialState, setIsLoadingInitialState] = useState(true)
    const navigate = useNavigate()
    const [hasChange, setHasChange] = useState(false)
    const [firstRequestDone, setFirstRequestDone] = useState(false)
    const [initialState, setInitialState] = useState({})

    usePrompt('Se você sair agora, perderá as alterações não salvas. Deseja realmente sair?', hasChange)

    useEffect(() => {
        const fechAssociateds = async () => {
            try {
                setIsLoadingInitialState(true)
                const response = await api.get(`/rule/${rule.id}/get_associated`)
                setInitialState(response.data)
            } catch (error) {
                BuildFieldErrorsDict(error, null, 'Erro ao consultar dados')
            } finally {
                setIsLoadingInitialState(false)
                setFirstRequestDone(true)
            }
        }
        fechAssociateds()
    }, []);

    useEffect(() => {
        hasChange === false && selectedBrands.length > 0 && setHasChange(true)
    }, [selectedBrands]);

    useEffect(() => {
        firstRequestDone && setTypeParams({'brands__id__in': selectedBrands})
    }, [selectedBrands]);

    useEffect(() => {
        firstRequestDone && setModelParams({'type_equipment__id__in': selectedTypes})
    }, [selectedTypes]);
    const onSubmit = async () => {
        try {
            setIsLoading(true)
            setHasChange(false)
            const data = {
                selected_models_ids: selectedModels
            }
            const response = await api.post(`/rule/${rule.id}/apply_to_models`, data)
            Notification('success', `Regra adicionada a ${response.data.equipments_count} equipamentos com sucesso.`)
            navigate(`/rule`)
        } catch (error) {
            BuildFieldErrorsDict(error, null)
            setHasChange(true)
        } finally {
            setIsLoading(false)
        }
    }

    const getbrandParams = () => {
        switch (rule.telemetry_system?.name) {
            case 'CareTrack':
                return {'name__icontains': 'volvo'}
            case 'RemoteLog':
                return {'name__icontains': 'tigercat'}
            default:
                return {}
        }
    }
    return (
        <>
            <DividerPage/>
            <TabContainer>
                <PageTitle value={'Equipamentos aplicáveis'}/>
                {isLoadingInitialState ? <Loading/> : <Row gutter={[8, 0]}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Transfer placeHolder={'Pesquise uma marca aqui'}
                                  url={'brand_equipment'}
                                  title={'Marca'}
                                  setSelecteds={setSelectedBrands}
                                  needParams={false}
                                  params={getbrandParams()}
                                  initalSelecteds={initialState?.brands}
                                  onClick={() => {
                                      setTypeParams({'brands__id__in': selectedBrands})
                                  }}>
                        </Transfer>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Transfer placeHolder={'Pesquise um tipo aqui'}
                                  title={'Tipo'}
                                  params={typeParams}
                                  setSelecteds={setSelectedTypes}
                                  initalSelecteds={initialState?.types}
                                  url={'type_equipment'}
                                  onClick={() => {
                                      setModelParams({'type_equipment__id__in': selectedTypes})
                                  }}>
                        </Transfer>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Transfer placeHolder={'Pesquise um modelo'}
                                  url={'model_equipment'}
                                  params={modelParams}
                                  setSelecteds={setSelectedModels}
                                  initalSelecteds={initialState?.models}
                                  title={'Modelo'}>
                        </Transfer>
                    </Col>
                </Row>}
                <ContainerButtons>
                    <Button type="primary"
                            disabled={selectedModels.length === 0}
                            onClick={() => onSubmit()}
                            loading={isLoading}>Salvar Equipamentos</Button>
                </ContainerButtons>
            </TabContainer>
        </>
    )
}
