import styled from "styled-components";
import useForm from "../../../hooks/useForm";
import { DatePicker } from "antd";
import InputGroup from "./InputGroup";
import { neutral_5 } from "../../../Cores";

const StyledDatePicker = styled(DatePicker)`
  display: flex;

  svg {
    color: ${neutral_5};
  }
`;

export default ({ setData, label, error, name, ...props }) => {
  const { onChangeDate } = useForm();

  return (
    <InputGroup label={label} error={error}>
      <StyledDatePicker
        format={"DD/MM/yyyy"}
        status={error && "error"}
        onChange={(date, value) => onChangeDate(name, value, setData)}
        name={name}
        {...props}
      />
    </InputGroup>
  );
};
