import React from 'react'
import DisplayInfo from "../Base/DisplayInfo";
import styled from "styled-components";
import {border_dark_color2} from "../../Cores";


const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid ${border_dark_color2};
`
const ExtraIcon = styled.div`
  margin-left: auto;
`

export function ItemBodyModalMap({label, icon, extraIcon, children, showTooltip=true}) {
    return (
        <ItemContainer>
            {icon}
            <DisplayInfo showTooltip={showTooltip} label={label}>
                {children}
            </DisplayInfo>
            <ExtraIcon>
                {extraIcon}
            </ExtraIcon>

        </ItemContainer>
    )
}