import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { Button, Form, Modal } from "antd";
import api from "../../services/api";
import { BuildFieldErrorsDict, buildFormDataToFilter } from "../../util/Utils";
import { Col, Row } from "antd";
import PageTitle from "../../components/Base/PageTitle";
import DividerPage from "../../components/Base/DividerPage";
import { collapse_header_text_color } from "../../Cores";
import { Notification } from "../../notification";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import RuleForm from "../../components/Rules/RuleForm/RuleForm";
import { usePrompt } from "../../hooks/useConfirmeUnsaveChanges";
import FormInfo from "../../components/Rules/RuleForm/FormInfo";

const ContainerInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${collapse_header_text_color};
  padding-bottom: 16px;
`;
const ContainerButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export default function RuleCreate() {
  const { changeTitle, setHasBack } = useActionBar();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showContainers, setShowContainers] = useState(false);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const [hasChange, setHasChange] = useState(false);
  usePrompt(
    "Se você sair agora, perderá as alterações não salvas. Deseja realmente sair?",
    hasChange
  );

  useEffect(() => {
    changeTitle("Criar regra");
    setHasBack(true);
  }, [changeTitle, setHasBack]);

  function onCancel() {
    if (hasChange) {
      Modal.confirm({
        title: "Deseja realmente sair?",
        content:
          "Ao escolher voltar, você abandonará a criação de regra e perderá os dados.",
        icon: <ExclamationCircleOutlined />,
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          navigate("/rule");
        },
      });
    } else {
      navigate("/rule");
    }
  }

  const onSubmit = async () => {
    let formData;
    setIsLoading(true);
    setHasChange(false);
    let values;
    try {
      values = await form.validateFields();
      delete values["file_list"];
      formData = buildFormDataToFilter(values);
      fileList.forEach((file) => {
        formData.append("files[]", file);
      });
    } catch (error) {
      Notification("error", "Erros encontrados");
      setIsLoading(false);
      setHasChange(true);
      return;
    }
    try {
      const response = await api.post(`/rule`, formData);

      Notification("success", "Regra cadastrada com sucesso.");

      Modal.confirm({
        title: "Deseja adicionar equipamentos agora?",
        icon: <ExclamationCircleOutlined />,
        okText: "Sim",
        cancelText: "Não",
        onOk: () => {
          navigate(`/rule/${response.data.id}/detail?tab=2`);
        },
        onCancel: () => {
          navigate(`/rule`);
        },
      });
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, "Erros encontrados");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <RuleForm
        form={form}
        setFileList={setFileList}
        setHasChange={setHasChange}
        showContainers={showContainers}
        setShowContainers={setShowContainers}
      />
      {showContainers && (
        <>
          <DividerPage />
          <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <PageTitle value={<> Finalizar </>} />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <FormInfo
                label={
                  "Verifique todas as informações a respeito da regra. Regras não podem ser apagadas após serem criadas, mas podem ser desativadas."
                }
              />

              <ContainerButtons>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="secondary"
                >
                  Cancelar
                </Button>
                <Button loading={isLoading} onClick={onSubmit} type="primary">
                  Cadastrar
                </Button>
              </ContainerButtons>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
