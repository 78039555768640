import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMenu } from "../../context/MenuContext";
import { ReactComponent as ExcavatorIcon } from "../../assets/icons/Excavator.svg";
import { Layout, Menu as MenuAntd } from "antd";
import { ReactComponent as TracbelMiniIcon } from "../../assets/icons/Logo_Principal_mini.svg";

import { UserOutlined, RocketOutlined } from "@ant-design/icons";
import { menu_bg, primary_color, table_header } from "../../Cores";
import { NavLink, useLocation } from "react-router-dom";
import RemixIcon from "./RemixIcon";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import { useUserContext } from "../../context/UserContext";
import { useActionBar } from "../../context/ActionBarContext";
import MenuButton from "../MenuButton";

const MenuHeader = styled.div`
  padding: 20px 16px;
  height: 72px;
  display: flex;
  justify-content: ${({ hiddenMenu }) =>
    hiddenMenu ? "center" : "space-between"};
  align-items: center;
`;

const ExcavatorIconStyled = styled(ExcavatorIcon)`
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
    color 0.3s;

  .ant-menu-item-selected &,
  li:hover & {
    stroke: rgb(253, 186, 18);
  }
`;

const StyledMenuAntd = styled(MenuAntd)`
  border: none;
  color: white;

  & > li {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  & > li.ant-menu-submenu a.active {
    color: ${primary_color};
  }

  //  .ant-menu-submenu-open {
  //   color:${primary_color};
  // }

  .ant-menu-submenu-selected .ant-menu-submenu-arrow {
    color: ${primary_color};
  }

  .ant-menu-sub.ant-menu-inline {
    background: ${table_header};
  }

  & > li.ant-menu-item-selected {
    border-right: 3px solid ${primary_color};
  }
`;

const StyledMenu = styled(Layout.Sider)`
  z-index: 100;
  flex-shrink: 0;
  height: 100%;
  border: none;
  background-color: ${menu_bg};

  ${({ float }) => {
    if (float) {
      return `
            position: fixed;
          `;
    }
  }}

  ${({ float, hiddenMenu }) => {
    if (float && !hiddenMenu) {
      return `
            box-shadow: 0px 0px 0px 100vw rgb(0 0 0 / 50%) !important;
          `;
    }
  }}

  ${({ isPhone }) => {
    if (isPhone) {
      return `
      visibility: hidden;
      opacity: 0;
 
          `;
    }
  }}

  ${({ isPhone, hiddenMenu }) => {
    if (isPhone && !hiddenMenu) {
      return `
            visibility: visible;
            opacity: 1;
           background-color: ${menu_bg};
          `;
    }
  }}
`;

const menuItems = [
  {
    key: "",
    icon: <RemixIcon remixIconName={"ri-home-line"} />,
    label: <NavLink to={"/"}>Inicio</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: true,
  },
  {
    key: "dashboard",
    icon: <RemixIcon remixIconName={"ri-dashboard-fill"} />,
    // children: [
    //     {//todo falta resolver problema do ativo no menu
    //         key: 'dash1',
    //         // icon: <RemixIcon remixIconName={'ri-home-line'}/>,
    //         label: <NavLink to={'dashboard/equipment?tab=1'}>Equipamentos</NavLink>,
    //         permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    //     },
    //     // {
    //     //     key: 'dash2',
    //     //     // icon: <RemixIcon remixIconName={'ri-home-line'}/>,
    //     //     label: <NavLink to={'dashboard/client?tab=1'}>Clientes</NavLink>,
    //     //     permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    //     // },
    //     // {
    //     //     key: 'dash3',
    //     //     // icon: <RemixIcon remixIconName={'ri-home-line'}/>,
    //     //     label: <NavLink to={'dashboard/consultant?tab=1'}>Consultores</NavLink>,
    //     //     permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    //     // },
    // ],
    label: (
      <NavLink to={"dashboard/equipment?tab=1"}>Dashboard (Gestor) </NavLink>
    ),
    permitions: [1, 2, 4],
    hidden: false,
  },
  {
    key: "client",
    icon: <UserOutlined />,
    label: <NavLink to={"client"}>Clientes</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
  // {
  //     key: 'machine',
  //     icon: <RocketOutlined/>,
  //     label: <NavLink to={'machine'}>Maquinários</NavLink>,
  //     permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  //
  // },
  {
    key: "opportunity",
    icon: <RocketOutlined />,
    label: <NavLink to={"opportunity"}>Notificações</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
  {
    key: "rule",
    icon: <RemixIcon remixIconName={"ri-list-settings-line"} />,
    label: <NavLink to={"rule"}>Regras de notificações</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
  {
    key: "telemetric_data",
    icon: <RemixIcon remixIconName={"ri-list-check-2"} />,
    label: <NavLink to={"telemetric_data"}>Registros de telemetria</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
  {
    key: "equipment",
    icon: <ExcavatorIconStyled />,
    label: <NavLink to={"equipment"}>Equipamentos</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
  {
    key: "user",
    icon: <UserOutlined />,
    label: <NavLink to={"user"}>Usuários</NavLink>,
    permitions: [1],
    hidden: false,
  },
  {
    key: "settings",
    icon: <RemixIcon remixIconName={"ri-stack-fill"} />,
    label: <NavLink to={"settings"}>Configurações do sistema</NavLink>,
    permitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    hidden: false,
  },
];

const pathsWithOutMenu = ["sobre"];

export default function Menu({ ...props }) {
  const { hiddenMenu, handleCloseMenuOutsideMenu } = useMenu();
  const { user } = useUserContext();
  const { hiddenActionBar } = useActionBar();
  const menuRef = useRef();

  const { isPhone } = useSystemBreakpoint();
  const [defaultMenuItem, setDefaultMenuItem] = useState("");
  const location = useLocation();

  useEffect(() => {
    let currentPath = location.pathname.split("/")[1];
    if (!pathsWithOutMenu.includes(currentPath)) {
      setDefaultMenuItem(currentPath);
    }
  }, [location.pathname]);

  useEffect(() => {
    handleCloseMenuOutsideMenu(menuRef);
    // eslint-disable-next-line
  }, [hiddenMenu]);

  function buildMenu() {
    return menuItems.filter((item) => {
      return (
        user.profiles.some((profile) => item.permitions.includes(profile)) &&
        !item.hidden
      );
    });
  }

  return (
    <StyledMenu
      ref={menuRef}
      collapsedWidth={isPhone ? 50 : 80}
      width={256}
      trigger={null}
      isPhone={isPhone}
      collapsible
      collapsed={hiddenMenu}
      // hiddenActionBar={hiddenActionBar}
      hiddenMenu={hiddenMenu}
      {...props}
    >
      <MenuHeader hiddenMenu={hiddenMenu}>
        {!hiddenMenu && <TracbelMiniIcon />}
        <MenuButton />
      </MenuHeader>
      <StyledMenuAntd
        items={buildMenu()}
        mode={"inline"}
        selectedKeys={[defaultMenuItem]}
      />
    </StyledMenu>
  );
}
