import React from 'react';
import {Empty as EmptyAnt} from "antd";
import styled from "styled-components";
import {ReactComponent as EmptyIcon} from "../../assets/icons/empty_icon.svg";
import {gray} from "../../Cores";


const StyledEmptyDescription = styled.span`
  color: ${gray};

`

const StyledEmptyIcon = styled(EmptyIcon)`

  width: 64px;
  height: 40px;
  //transform: translateY(35px);
`


const StyledEmpty = styled(EmptyAnt)`

  display: flex;
  flex-direction: column;
  justify-content: center;
`


export default function Empty({
                                  text = "Nenhum resultado encontrado",
                                  ...props
                              }) {
    return (
        <StyledEmpty imageStyle={{
            height: 40,
        }} image={<StyledEmptyIcon/>}
                     description={<StyledEmptyDescription>{text}</StyledEmptyDescription>}

                     {...props}/>
    )
}