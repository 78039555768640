import React from "react";
import PageTitle from "../PageTitle";
import Select from "../Select";
import { Col, Form, Row, Space } from "antd";
import InformationTip from "../../InformationTip";

export default function HeaderCharts({
  title,
  form,
  filterRender,
  selectName,
  information = "",
  optionsSelect,
  defaultValue,
  onValuesChange,
  ...props
}) {
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} md={12}>
        <Space align={"start"} size={4}>
          <PageTitle value={title} />
          {information && <InformationTip text={information} />}
        </Space>
      </Col>
      <Col xs={24} md={12}>
        <Form form={form} onValuesChange={onValuesChange}>
          {filterRender ? (
            filterRender
          ) : (
            <Row justify={"end"}>
              <Col xs={24} md={8}>
                <Form.Item name={selectName} initialValue={defaultValue}>
                  <Select
                    style={{ width: "100%" }}
                    size={"small"}
                    options={optionsSelect}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  );
}
