import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageTitle from "../../../components/Base/PageTitle";
import { Col, Row } from "antd";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import Maps from "../../../components/Base/Maps/Maps";
import ContainerWithoutLimit from "../../../components/ContainerWithoutLimit";
import {
  MAP_MARKER_EQUIPMENT_GRAY,
  MAP_MARKER_EQUIPMENT_YELLOW,
} from "../../../components/Base/Maps/MapMarkerFactory";
import EquipmentModalMaps from "../../../components/Base/Maps/EquipmentModalMaps";
import CenterUptimeOffCanvas from "../../../components/equipment/CenterUptimeOffCanvas";
import useActionPermission from "../../../hooks/useActionPermission";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabClientEquipmentsMap({ clientId, ...props }) {
  const [equipments, setEquipments] = useState([]);
  const [currentEquipment, setCurrentEquipment] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fetchData, isLoading } = useDefaultFetch();

  const { hasPermission } = useActionPermission({ permissions: [1, 2, 4, 5] });
  const [openCenter, setOpenCenter] = useState();
  useEffect(() => {
    async function fetch() {
      const data = await fetchData(`/client/${clientId}/equipments_map`);
      setEquipments(data);
    }

    fetch();
  }, []);

  function generatePageTitle() {
    const _amountEquipments = equipments.length;
    let title;
    if (_amountEquipments === 0) {
      title = `Nenhum resultado encontrado`;
    } else {
      title = `${_amountEquipments} equipamento encontrado`;
      if (_amountEquipments > 1) {
        title = `${_amountEquipments} equipamentos encontrados`;
      }
    }
    return title;
  }

  const getCoords = () => {
    return equipments.map(({ has_active_opportunity, position }) => {
      return {
        id: position.id,
        lat: position.latitude,
        lng: position.longitude,
        type: has_active_opportunity
          ? MAP_MARKER_EQUIPMENT_YELLOW
          : MAP_MARKER_EQUIPMENT_GRAY,
      };
    });
  };

  function handleCloseOffCanvasCenter(equipment) {
    setIsModalOpen(true);
    setOpenCenter(false);
  }

  function handleOpenOffCanvasCenter(equipment) {
    setIsModalOpen(false);
    setOpenCenter(true);
    setCurrentEquipment(equipment);
  }

  return (
    <TabContainer>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 7, order: 1 }}
          lg={{ span: 12, order: 1 }}
        >
          <PageTitle style={{ marginBottom: 20 }} value={generatePageTitle()} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ order: 3 }}
          style={{ marginBottom: 16 }}
        >
          <ContainerWithoutLimit>
            <Maps
              height={"640px"}
              isLoading={isLoading}
              defaultZoom={5}
              coords={getCoords()}
              onMarkerClick={(data) => {
                // console.log(data)
                setCurrentEquipment(
                  equipments.find(({ position: eq }) => eq.id === data.id)
                    ?.position
                );
                setIsModalOpen(true);
              }}
            />
          </ContainerWithoutLimit>
        </Col>
      </Row>
      <EquipmentModalMaps
        isModalOpen={isModalOpen}
        id={currentEquipment.id}
        hasPermission={hasPermission}
        handleOpenOffCanvasCenter={handleOpenOffCanvasCenter}
        setClose={setIsModalOpen}
      />

      {hasPermission && currentEquipment && (
        <CenterUptimeOffCanvas
          setObject={setCurrentEquipment}
          open={openCenter}
          setOpen={setOpenCenter}
          extraOnClose={handleCloseOffCanvasCenter}
          centerId={currentEquipment?.center?.id}
          url={`equipment/${currentEquipment.id}/change_center`}
        />
      )}
    </TabContainer>
  );
}
