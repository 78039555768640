import React from "react";
import styled from "styled-components";
import useSystemBreakpoint from "../hooks/useSystemBreakpoint";
import ContainerWithoutLimit from "./ContainerWithoutLimit";

const Container = styled.div`
  //margin-top: -16px;
  height: 148px;
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0.9;

    background: ${({ hasImage }) =>
      hasImage
        ? "linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(1, 1, 1, 1) 10%, rgba(52, 52, 52, 0.8) 50%)"
        : "black"};
    position: absolute;

    top: 0;
    z-index: 1;
  }

  @media screen and (max-width: 992px) {
    height: 208px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HeaderContent = styled.div`
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 0;
  left: 0;
  z-index: 2;
  padding-right: 16px;
`;

export default function HeaderDetail({ children, hasImage, src }) {
  const { isPhone, isDesktop } = useSystemBreakpoint();

  return (
    <ContainerWithoutLimit>
      <Container isPhone={isPhone} hasImage={!!src && isDesktop}>
        <Image src={isDesktop ? src : ""} />
        <HeaderContent>{children}</HeaderContent>
      </Container>
    </ContainerWithoutLimit>
  );
}
