import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import PageTitle from "../../components/Base/PageTitle";
import Select from "../../components/Base/Select";
import FilterButtons from "../../components/Base/FilterButtons";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";

import DividerPage from "../../components/Base/DividerPage";
import useDefaultFetch from "../../hooks/useDefaultFetch";
import {
  MAP_MARKER_NEAR_OPPOTUNITY,
  MAP_MARKER_NEAR_OPPOTUNITY_SAME_CLIENT,
  MAP_MARKER_OPPOTUNITY,
} from "../../components/Base/Maps/MapMarkerFactory";
import Maps from "../../components/Base/Maps/Maps";
import NearOpportunityModalMaps from "../../components/Base/Maps/NearOpportunityModalMaps";
import NoLocation from "../../components/Base/NoLocation";

const TabContainer = styled.div``;
const ColAmountOpportunities = styled(Col)`
  display: flex;
  align-self: center;
  justify-content: end;
  gap: 8px;
`;

const filterOptions = [
  {
    label: "Todos",
    value: "all",
  },
  {
    label: "Revisão programada",
    value: "Revisão programada",
  },
  {
    label: "Código de erro",
    value: "Código de erro",
  },
  {
    label: "Análise de óleo",
    value: "Análise de óleo",
  },
];

export default function TabNearOpportunities({ opportunity }) {
  const { postData, isLoading } = useDefaultFetch();
  const [nearOpportunities, setNearOpportunities] = useState([]);
  const [currentNearOpportunity, setCurrentNearOpportunity] = useState({});
  const [radius, setRadius] = useState(50);
  const { isTablet, isPhone } = useSystemBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("all");

  function getZoomByRadius() {
    let zoom = 0;
    switch (radius) {
      case 100:
        zoom = 9;
        break;
      case 150:
        zoom = 8;
        break;
      case 200:
        zoom = 8;
        break;
      default:
        zoom = 10;
        break;
    }
    return zoom;
  }

  function generatePageTitle() {
    const amountOpportunities = nearOpportunities.length;
    let title;
    if (amountOpportunities === 0) {
      title = `Nenhum resultado encontrado`;
    } else {
      title = `${amountOpportunities} notificação próxima`;
      if (amountOpportunities > 1) {
        title = `${amountOpportunities} notificações próximos`;
      }
    }
    return title;
  }

  useEffect(() => {
    async function post() {
      const data = await postData(
        `opportunity/${opportunity.id}/near_opportunities`,
        {
          radius: radius,
          opportunity_type: filterValue,
        }
      );

      data && setNearOpportunities(data);
    }

    post();
  }, [radius, filterValue]);

  const getCoords = () => {
    const coords = [
      {
        id: opportunity.id,
        lat: opportunity.position.latitude,
        lng: opportunity.position.longitude,
        type: MAP_MARKER_OPPOTUNITY,
      },
    ];
    nearOpportunities.forEach((op) => {
      coords.push({
        id: op.id,
        lat: op.latitude,
        lng: op.longitude,
        type:
          opportunity.client_id !== op.client_id
            ? MAP_MARKER_NEAR_OPPOTUNITY
            : MAP_MARKER_NEAR_OPPOTUNITY_SAME_CLIENT,
      });
    });
    return coords;
  };

  function noHasLocation() {
    return !opportunity?.position?.latitude || !opportunity?.position?.latitude;
  }

  return (
    <TabContainer>
      <DividerPage />
      <Row style={{ marginTop: 16 }}>
        {noHasLocation() ? (
          <Col xs={24}>
            <NoLocation
              message={
                "O equipamento não reportou sua localização recentemente, então não conseguimos criar um mapa de notificações próximas."
              }
            />
          </Col>
        ) : (
          <>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
              lg={{ span: 12, order: 1 }}
            >
              <PageTitle
                style={{ marginBottom: 0 }}
                value={generatePageTitle()}
              />
            </Col>
            <ColAmountOpportunities
              xs={{ span: 12, order: 2 }}
              md={{ span: 17, order: 1 }}
              lg={{ span: 12, order: 1 }}
            >
              <i className="ri-road-map-line" />{" "}
              {isTablet && <span>Distância</span>}
              <Select
                onChange={(value) => setRadius(value)}
                style={{ width: "200px" }}
                size={"small"}
                defaultValue={radius}
                options={[
                  { value: 50, label: "50Km" },
                  { value: 100, label: "100Km" },
                  { value: 150, label: "150Km" },
                  { value: 200, label: "200Km" },
                ]}
              />
            </ColAmountOpportunities>
            <Col xs={{ span: 24, order: 2 }} md={{ order: 2 }}>
              <FilterButtons
                style={{ marginTop: 16, marginBottom: 16 }}
                onChange={({ target }) => setFilterValue(target.value)}
                value={filterValue}
                options={filterOptions}
              />
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ order: 3 }}
              style={{ marginBottom: 16 }}
            >
              <ContainerWithoutLimit>
                <Maps
                  fullContent
                  isLoading={isLoading}
                  agroupMarkers={false}
                  defaultZoom={getZoomByRadius()}
                  useZoomState={false}
                  coords={getCoords()}
                  isModalOpen={isModalOpen}
                  // circleRadius={radius}
                  markerClicked={currentNearOpportunity}
                  onMarkerClick={(data) => {
                    if (
                      data.type === MAP_MARKER_NEAR_OPPOTUNITY ||
                      MAP_MARKER_NEAR_OPPOTUNITY_SAME_CLIENT
                    ) {
                      setCurrentNearOpportunity(data);
                      setIsModalOpen(true);
                    }
                  }}
                />
              </ContainerWithoutLimit>
            </Col>
          </>
        )}
      </Row>
      <NearOpportunityModalMaps
        isModalOpen={isModalOpen}
        opportunity={opportunity}
        id={currentNearOpportunity.id}
        setClose={setIsModalOpen}
      />
    </TabContainer>
  );
}
