import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const ActionBarContext = createContext();

export function ActionBarProvider({ children }) {
  const [titleActionBar, setTitleActionBar] = useState("");
  const [hasBack, setHasBack] = useState(true);
  const mainContentRef = useRef();
  const lastScrollPosition = useRef();
  const [hiddenActionBar, setHiddenActionBar] = useState(false);

  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.addEventListener("scroll", (event) => {
        if (event.target.children.length > 0) {
          let currentScrollPosition =
            event.target.children[1].getBoundingClientRect().top;

          if (currentScrollPosition < 0) {
            setHiddenActionBar(
              lastScrollPosition.current > currentScrollPosition
            );
            lastScrollPosition.current = currentScrollPosition;
          } else {
            setHiddenActionBar(false);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [mainContentRef.current]);

  function changeTitle(title) {
    setTitleActionBar(title);
  }

  return (
    <ActionBarContext.Provider
      value={{
        hasBack,
        titleActionBar,
        setHasBack,
        mainContentRef,
        changeTitle,
        hiddenActionBar,
      }}
    >
      {children}
    </ActionBarContext.Provider>
  );
}

export function useActionBar() {
  return useContext(ActionBarContext);
}
