import { Button, Col, InputNumber, Row, Select } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { gray_2, red_6 } from "../../Cores";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import Empty from "../Base/Empty";
import Dragger from "antd/lib/upload/Dragger";
import { useStaticDataContext } from "../../context/StaticDataContext";
import api from "../../services/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDragger = styled(Dragger)`
  background: rgba(34, 36, 37, 1) !important;
  border-color: rgba(86, 93, 98, 1);
  display: block;
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: white;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;

export default function LaborSelection({
  laborData,
  setLaborData,
  startingCenter,
  startingBrand,
}) {
  const [laborOptions, setLaborOptions] = useState([]);
  const [laborRowQuantity, setLaborRowQuantity] = useState(1);
  const { centers, brands } = useStaticDataContext();

  useEffect(() => {
    const fetchLaborOptions = async () => {
      const response = await api.get("/quotation_labor_price");
      const laborOptionsData = response.data;

      const newLaborOptions = laborOptionsData.reduce((acc, labor) => {
        acc[labor.brand_equipment] = [
          ...(acc[labor.brand_equipment] || []),
          {
            id_sap: labor.id_sap,
            description: `${labor.id_sap} - ${labor.description}`,
            price: labor.price,
          },
        ];

        return acc;
      }, {});

      setLaborOptions(newLaborOptions);
    };

    fetchLaborOptions();
  }, []);

  const handleButtonClick = () => {
    setLaborRowQuantity(laborRowQuantity + 1);
    setLaborData([
      ...laborData,
      {
        laborId: undefined,
        laborDescription: undefined,
        quantity: 1,
        manufacturer: startingBrand.id,
        center: startingCenter,
        price: 0,
      },
    ]);
  };

  const handleRowRemoval = (index) => {
    const newRowList = [...laborData];
    newRowList.splice(index, 1);
    setLaborData(newRowList);
  };

  const handleChange = (index, key, value) => {
    const newRowList = [...laborData];
    newRowList[index][key] = value;

    const laborId =
      key === "laborDescription" ? value : newRowList[index].laborId;

    const manufacturer = newRowList[index].manufacturer;
    const selectedLabor = laborOptions[manufacturer].find(
      ({ id_sap }) => id_sap === laborId
    );

    if (key === "manufacturer") {
      newRowList[index].laborId = undefined;
      newRowList[index].laborDescription = undefined;
      newRowList[index].price = 0;
    } else if (key === "laborDescription") {
      newRowList[index].laborId = selectedLabor.id_sap;
      newRowList[index].price = selectedLabor.price;
      newRowList[index]["laborDescriptionText"] = selectedLabor.description;
    }

    setLaborData(newRowList);
  };

  const formatPrice = (price) => {
    const number = parseFloat(price) || 0;
    return `R$ ${number.toFixed(2).replace(".", ",")}`;
  };

  return (
    <Container>
      <Row style={{ marginTop: "16px" }}>
        <Col span={8}>Serviço</Col>
        <Col span={4}>Quantidade (Horas)</Col>
        <Col span={4}>Fabricante</Col>
        <Col span={3}>Centro</Col>
        <Col span={3}>Preço (R$)</Col>
      </Row>
      {laborData.map((labor, index) => {
        const currentLabelOptions = laborOptions[labor.manufacturer] || [];

        return (
          <Row key={index}>
            <Col span={8}>
              <Select
                style={{ width: "90%" }}
                placeholder={"Serviço"}
                value={labor.laborDescription}
                onChange={(value) =>
                  handleChange(index, "laborDescription", value)
                }
                notFoundContent={<Empty />}
                suffixIcon={<StyledDownOutlined />}
                options={currentLabelOptions.map(({ id_sap, description }) => ({
                  label: description,
                  value: id_sap,
                }))}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                style={{ width: "80%" }}
                placeholder={"Quantidade"}
                value={labor.quantity}
                onChange={(value) => handleChange(index, "quantity", value)}
                min={1}
              />
            </Col>
            <Col span={4}>
              <Select
                style={{ width: "80%" }}
                placeholder={"Fabricante"}
                value={labor.manufacturer}
                onChange={(value) => handleChange(index, "manufacturer", value)}
                notFoundContent={<Empty />}
                suffixIcon={<StyledDownOutlined />}
                options={brands.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Col>
            <Col span={3}>
              <Select
                style={{ width: "80%" }}
                placeholder={"Centro"}
                value={labor.center}
                onChange={(value) => handleChange(index, "center", value)}
                notFoundContent={<Empty />}
                suffixIcon={<StyledDownOutlined />}
                options={centers
                  .sort((a, b) => a.id - b.id)
                  .map(({ id }) => ({
                    label: id,
                    value: id,
                  }))}
              />
            </Col>
            <Col span={3}>
              <InputNumber
                style={{ width: "80%" }}
                placeholder={"Preço"}
                value={labor.price}
                formatter={(value) => formatPrice(value)}
                disabled
              />
            </Col>
            <Col span={1}>
              <CloseOutlined
                style={{ color: `${red_6}`, marginTop: "9px" }}
                onClick={() => handleRowRemoval(index)}
              />
            </Col>
          </Row>
        );
      })}
      <Row>
        <Button type={"primary"} onClick={handleButtonClick}>
          Adicionar item
        </Button>
      </Row>
    </Container>
  );
}
