import React from "react";
import { Collapse as CollapseAnt } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { collapse_header_text_color } from "../Cores";
import styled from "styled-components";

const StyledDownOutlined = styled(DownOutlined)`
  color: white;
  transition: 0.1s;
  transform: translateY(-50%)
    rotate(${({ isActive }) => (isActive ? "0deg" : "-90deg")}) !important;
`;
const StyledCollapseAnt = styled(CollapseAnt)`
  border: unset;
  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
  & .ant-collapse-content {
    border: unset;
  }
  & > .ant-collapse-item {
    border: unset;
    //border-bottom: unset;
  }
`;

export default function Collapse({ title, children, ...props }) {
  return (
    <StyledCollapseAnt
      expandIconPosition={"end"}
      expandIcon={({ isActive }) => <StyledDownOutlined isActive={isActive} />}
      {...props}
    >
      <StyledCollapseAnt.Panel
        forceRender
        header={
          <span style={{ color: collapse_header_text_color }}>{title}</span>
        }
        key="1"
      >
        {children}
      </StyledCollapseAnt.Panel>
    </StyledCollapseAnt>
  );
}
