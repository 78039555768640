import React from 'react'
import { Badge as BadgeAnt } from "antd";
import {dateFormat} from "../util/dates";
export default function DateAlert({date}) {
    function calculateAlert() {
        const now = new Date()
        const alertDate = new Date(date)
        const diffDate = now.getTime() - alertDate.getTime()
        const mili_in_one_day = 86400000
        const days = diffDate/mili_in_one_day
        if(days > 0){
            return 'red'
        }
        if(days >= -2) {
            return 'yellow'
        }
        return 'green'
    }

    return (
        <BadgeAnt status={calculateAlert()} text={dateFormat(date)}/>
    )
}