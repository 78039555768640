import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import RadioButton from "../../../components/Base/Forms/RadioButton";
import api from "../../../services/api";
import { Notification } from "../../../notification";
import Empty from "../../../components/Base/Empty";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 300px;
  padding: 24px;
  gap: 16px;

  overflow-y: auto;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  top: 13px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
`;

export default function ChooseRevisionTypeModal({
  isModalOpen,
  setOpen,
  setRevisions,
  revisionInput,
  setRevisionInput,
  model,
  brand,
  setRevisionsIds,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    id: "",
    label: "",
  });
  const onClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(`/equipment/get_pmp_materials`, {
        model,
        brand,
        hours: revisionInput,
      });
      const options = data.map(({ value, label }) => ({
        value: `${value} - ${label}`,
        label: `${value} - ${label}`,
      }));
      setOptions(options);
    } catch (e) {
      const {
        response: {
          data: { data },
        },
      } = e;
      Notification("error", data);
    }
    setIsLoading(false);
  };

  const onSubmit = async () => {
    setRevisions((revisions) => [
      ...revisions,
      {
        hours: revisionInput,
        value: selectedValue.value,
      },
    ]);
    setRevisionsIds((revisionsIds) => [
      ...revisionsIds,
      selectedValue.value.split(" - ")[0],
    ]);
    onClose();
    setIsLoading(false);
    setRevisionInput(0);
  };

  useEffect(() => {
    if (isModalOpen) fetchData();
  }, [isModalOpen]);

  return (
    <Modal
      open={isModalOpen}
      width={"600px"}
      bodyStyle={{ padding: 0 }}
      title={"Escolha a revisão desejada"}
      footer={[
        <Button onClick={onClose}>Cancelar</Button>,
        <Button
          loading={isLoading}
          type={"primary"}
          onClick={onSubmit}
          disabled={!options.length}
        >
          Adicionar revisão
        </Button>,
      ]}
      closable={false}
      okText={"Adicionar revisão"}
    >
      <StyledCloseOutlined onClick={onClose} />
      {!options.length ? (
        <div style={{ margin: "16px" }}>
          <Empty />
        </div>
      ) : (
        <ContentContainer>
          <RadioButton
            options={options}
            onChange={({ target }) => {
              setSelectedValue({ value: target.value });
            }}
            setData={setRevisions}
          />
        </ContentContainer>
      )}
    </Modal>
  );
}
