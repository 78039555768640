import React, {useEffect, useState} from 'react';

import styled from "styled-components";
import {Chart} from "react-google-charts";
import {chart_color_gray, content_bg, primary_color} from "../../../Cores";
import PageTitle from "../PageTitle";
import api from "../../../services/api";
import {BuildFieldErrorsDict, formdataToJSON} from "../../../util/Utils";
import Select from "../Select";
import Loading from "../Loading";
import {NumberFormatInteger} from "./utils";
import {dateFormat} from "../../../util/dates";
import {Col, Form, Row} from "antd";
import {useForm} from "antd/es/form/Form";
import HeaderCharts from "./HeaderCharts";


const StyledChart = styled(Chart)`
  color: white;

  rect:first-child {

    fill: ${content_bg};
  }

  line {
    stroke: #292C2E;
  }

  text {
    fill: white;
  }

  g > g > text:last-child {
    fill: ${primary_color};
  }

`
const options = {
    colors: [primary_color, chart_color_gray],
    fill: 'red',
    legend: {position: 'none'}
};

export default function Line({
                                 url,
                                 selectName = 'days',
                                 hasSelect,
                                 defaultValue,
                                 optionsSelect,
                                 title,
                                 body,
                                 information = '',
                                 filter,
                                 format = "DD/MM",
                                 filterRender,
                                 ...props
                             }) {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData()

    }, [body, filter])


    const fetchData = async () => {
        try {
            setIsLoading(true)
            let response = await api.post(`${url}`,
                {
                    ...body,
                     ...formdataToJSON(filter),
                    ...form.getFieldsValue()
                }
            );
            if (response.data.length === 1) {
                response.data.push([0, 0])
            } else {
                response.data.map((value, index) => {
                    if (index !== 0) {
                        value[0] = dateFormat(value[0], format)
                    }

                    return value
                })
            }
            setData(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Row>
            <Col xs={24}>
                <HeaderCharts title={title} form={form}
                              optionsSelect={optionsSelect}
                              selectName={selectName}
                              information={information}
                              defaultValue={defaultValue}
                              filterRender={filterRender}
                              onValuesChange={fetchData}/>

            </Col>

            <Col xs={24}>


                {isLoading ? <Loading noHeight/> :
                    <StyledChart
                        formatters={[NumberFormatInteger]}
                        {...props}
                        options={options}
                        chartType="Line"
                        data={data}
                    />
                }
            </Col>
        </Row>

    )
}