import React, {useEffect, useState} from 'react';
import {useActionBar} from "../../context/ActionBarContext";
import {TableLabel, TableSearch} from "../../components/Base/UPTable";
import FilterButtons from "../../components/Base/FilterButtons";
import {Button, Col, Row} from "antd";
import {get_filter_params} from "../../util/TableStorageService";
import RuleTable from "../../components/Rules/tables/RulesTable";
import Switch from "../../components/Base/Switch";
import {PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import useActionPermission from "../../hooks/useActionPermission";
import useDefaultFetch from "../../hooks/useDefaultFetch";

const filterOptions = [
    {
        label: 'Todos',
        value: 'all'
    },
    {
        label: 'Revisão programada',
        value: 'Revisão programada'
    },
    {
        label: 'Código de erro',
        value: 'Código de erro'
    }, {
        label: 'Análise de óleo',
        value: 'Análise de óleo'
    },

]

export default function ListRules({placeholderSearch = 'Pesquise por regras'}) {


    const {changeTitle, setHasBack} = useActionBar()
    const {isDesktop} = useSystemBreakpoint()
    const [search, setSearch] = useState(get_filter_params('search'))
    const {hasPermission} = useActionPermission({permissions: [1, 2, 3]})


    const [typeTable, setTypeTable] = useState(get_filter_params('typeTable', 'all'))
    const [isActive, setIsActive] = useState(get_filter_params('isActive', true))
    let navigate = useNavigate();

    const {fetchData, isLoading} = useDefaultFetch()

    const [options, setOptions] = useState([{
        label: 'Todos',
        value: 'all'
    }])
    useEffect(() => {

        async function fetch() {
            const data = await fetchData(`rule_type?page_size=1000`)
            if (data) {
                const rules_type = data.results.map(({name}) => ({
                    label: name,
                    value: name
                }))
                setOptions([{
                    label: 'Todos',
                    value: 'all'
                }, , ...rules_type])
            }
        }

        fetch()

    }, [])

    useEffect(() => {
        changeTitle('Consultar Regras')
        setHasBack(false)

        return () => {
            setHasBack(true)
        }
    }, [changeTitle, setHasBack])


    return (
        <>
            <Row gutter={[24, 16]} align={'middle'}>
                <Col xs={24} lg={{span: 15, order: 1}}>
                    <TableSearch size={'large'}
                                 allowClear
                                 defaultValue={search}
                                 onSearch={(value, e) => setSearch(value)}
                                 onPressEnter={(e) => setSearch(e.target.value)}
                                 placeholder={placeholderSearch}/>
                </Col>
                {isDesktop &&
                    <Col xs={{span: 24, order: 3}} md={{span: 12, order: 2}}
                         lg={{span: 4, order: 2}}>
                        <Row gutter={4}
                             wrap={false}
                        >
                            <Col><Switch checked={isActive}
                                         onChange={(checked) => setIsActive(checked)}/></Col>
                            <Col> <TableLabel>Exibir apenas regras
                                ativas</TableLabel></Col>
                        </Row>
                    </Col>}
                {hasPermission && <Col style={{textAlign: 'end'}}
                                       xs={{span: 24, order: 1}}
                                       lg={{span: 5, order: 3}}>
                    <Button onClick={() => navigate(`/rule/create`)}
                            type="primary">
                        <PlusOutlined/>
                        Nova regra
                    </Button>
                </Col>}
                {/*<Col xs={2} md={1} style={{*/}
                {/*    display: "flex",*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center'*/}
                {/*}}>*/}
                {/*    <FilterIcon count={filterCount}*/}
                {/*                onClick={() => setOpenFilter(true)}/>*/}
                {/*</Col>*/}
            </Row>

            <FilterButtons
                onChange={({target}) => setTypeTable(target.value)}
                style={{marginTop: 16}}
                value={typeTable}
                isLoading={isLoading}
                options={options}/>
            <RuleTable typeTable={typeTable}
                       search={search}
                       isActive={isActive}
                // formFilter={formFilter}
            />
            {/*<FilterOpportunityOffCanvas typeTable={typeTable} open={openFilter}*/}
            {/*                            initialFormFilter={initialFormFilter}*/}
            {/*                            formFilter={formFilter}*/}
            {/*                            setOpen={setOpenFilter}*/}
            {/*                            setFormFilter={setFormFilter}/>*/}
        </>
    )
}