import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { Tabs } from "../../components/Base/Tabs";
import api from "../../services/api";
import { BuildFieldErrorsDict } from "../../util/Utils";
import { useLocation, useParams } from "react-router-dom";
import DividerPage from "../../components/Base/DividerPage";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";

import useTab from "../../hooks/useTab";
import Loading from "../../components/Base/Loading";
import TabClientDetail from "./Tabs/TabClientDetail";
import TabClientEquipmentsMap from "./Tabs/TabClientEquipmentsMap";
import TabClientOpportunities from "./Tabs/TabClientOpportunities";
import TabClientEquipment from "./Tabs/TabClientEquipment";
import { Col, Row } from "antd";
import { Name } from "../../components/GenericStyles";
import StatisticCard from "../../components/Base/StatisticCard";
import styled from "styled-components";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import FingerScroll from "../../components/Base/FingerScroll";
import TabClientSettings from "./Tabs/TabClientSettings";

const HeaderContainer = styled.div`
  background: black;
`;

export default function ClientDetail() {
  const { changeTitle, setHasBack } = useActionBar();
  const [previousLocation, setPreviousLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setPreviousLocation(location.state ? location.state.from : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setQueryTab, defaultTabId } = useTab({ defaultTab: "1" });
  const [client, setClient] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const clientID = params.id;
  const { isPhone } = useSystemBreakpoint();
  useEffect(() => {
    changeTitle("Visualizar cliente");

    setHasBack(previousLocation ? previousLocation : "client?load=1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTitle, setHasBack]);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`client/${clientID}`);
        setClient(response.data);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClient();
  }, [clientID]);

  function generateCardsHeader(equipment, open_opportunity, all_oportunity) {
    const equipment_label = "Equipamentos";
    const open_oportunity_label = "Notificações abertas";
    const all_oportunity_label = "Total de notificações";
    return (
      <>
        {isPhone ? (
          <FingerScroll breakLeft={false}>
            <Row gutter={[16, 16]}>
              <Col xs={8}>
                <StatisticCard
                  minWidth={118}
                  number={equipment}
                  name={equipment_label}
                />
              </Col>
              <Col xs={8}>
                <StatisticCard
                  minWidth={118}
                  number={open_opportunity}
                  name={open_oportunity_label}
                />
              </Col>
              <Col xs={8}>
                <StatisticCard
                  minWidth={118}
                  number={123}
                  name={all_oportunity_label}
                />
              </Col>
            </Row>
          </FingerScroll>
        ) : (
          <Row gutter={[16, 16]}>
            <Col xs={8}>
              <StatisticCard
                minWidth={118}
                number={equipment}
                name={equipment_label}
              />
            </Col>
            <Col xs={8}>
              <StatisticCard
                minWidth={118}
                number={open_opportunity}
                name={open_oportunity_label}
              />
            </Col>
            <Col xs={8}>
              <StatisticCard
                minWidth={118}
                number={all_oportunity}
                name={all_oportunity_label}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginTop: -16 }}>
            <ContainerWithoutLimit>
              <HeaderContainer>
                <Row gutter={[64, 16]} style={{ padding: 16 }}>
                  <Col xs={24} md={11} style={{ marginTop: 30 }}>
                    <Name style={{ whiteSpace: "pre-wrap" }}>
                      {client.name}
                    </Name>
                  </Col>
                  <Col xs={24} md={13}>
                    {generateCardsHeader(
                      client.equipments_count,
                      client.active_opportunities,
                      client.total_opportunities
                    )}
                  </Col>
                </Row>
              </HeaderContainer>
            </ContainerWithoutLimit>
          </div>
          <DividerPage />
          <Tabs
            destroyInactiveTabPane={true}
            activeKey={defaultTabId}
            onChange={setQueryTab}
            items={[
              {
                label: "Detalhes do cliente",
                key: "1",
                children: (
                  <TabClientDetail
                    clientId={clientID}
                    priority_opportunities={client.priority_opportunities}
                  />
                ),
              },
              {
                label: "Equipamentos",
                key: "2",
                children: <TabClientEquipment clientId={clientID} />,
              },
              {
                label: "Notificações",
                key: "3",
                children: <TabClientOpportunities clientId={clientID} />,
              },
              {
                label: "Mapa de equipamentos",
                key: "4",
                children: <TabClientEquipmentsMap clientId={clientID} />,
              },
              {
                label: "Configurações",
                key: "5",
                children: (
                  <TabClientSettings setClient={setClient} client={client} />
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
