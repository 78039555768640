import React from 'react';
import {Col, Row} from "antd";
import Legend from "./Base/Legend";
import {blue_8, green_8, purple_7, red_4} from "../Cores";


export default function TechnicianLegendMap({}) {
    return (
        <Row gutter={[16, 16]} style={{marginTop: 19}}>
            <Col><Legend value={'Disponível'} color={green_8}/></Col>
            <Col><Legend value={'Atendimento'} color={blue_8}/></Col>
            <Col><Legend value={'Retornando de atendimento'}
                         color={purple_7}/></Col>
            <Col><Legend value={'Parado'} color={red_4}/></Col>
        </Row>

    )
}