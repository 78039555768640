import React from 'react';
import styled from 'styled-components';
import {
    border_dark_color, calendula_gold_1,
    calendula_gold_3,
    calendula_gold_6,
    cyan_1,
    cyan_3,
    cyan_6, red_10, dust_red_3, dust_red_6,
    geek_blue_1,
    geek_blue_3,
    geek_blue_6,
    lime_1,
    lime_3,
    lime_6,
    mangeta_1,
    mangeta_3,
    mangeta_6,
    success_bg,
    success_color, sunset_orange_1, sunset_orange_3, sunset_orange_6
} from "../../Cores";

const Container = styled.span`
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  border-style: solid;
  border-width: 2px;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;



`

function getColor(type) {
    let result = null
    switch (type) {
        case true:
            result = {
                borderColor: success_color,
                color: success_color,
                backgroundColor: success_bg
            }
            break;
        case false:
            result = {
                borderColor: border_dark_color,
                color: 'white',
                backgroundColor: '#222425'
            }
            break;
        default:
            result = {
                borderColor: border_dark_color,
                color: 'white',
                backgroundColor: '#222425'
            }
            break;
    }
    return result
}

export default function BadgeRuleStatus({children, type, ...props}) {
    return (
        // <Container {...props} {...colors.find((color) => type === color.type)}>
        <Container {...props} {...getColor(type)}>
            {children}
        </Container>)
}