import axios from 'axios';
import {
    deleteTokens,
    getAccessToken,
    getBrands,
    getCenters,
    getRefreshToken,
    isAuthenticated,
    saveTokens
} from "../util/Auth";
import {BASE_ENDPOINT} from "../settings";
const LOGIN_EXPIRED = 'Login Expired'
axios.defaults.baseURL = BASE_ENDPOINT

axios.interceptors.request.use((config) => {
    if (isAuthenticated()) {
        config.headers.common['Authorization'] = `Bearer ${getAccessToken()}`
        config.headers['Centers'] = getCenters()
        config.headers['Brands'] = getBrands()
    }
    return config;
}, (error) => {
    return Promise.reject(error);
})

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (error.config && error.response && error.response.status === 403 && (error.response.data.errors['code'] === 'token_not_valid')) {
            const refresh_token = getRefreshToken()
            if (refresh_token) {
                try {
                    let response = await axios.post('auth/token/refresh/', {'refresh': refresh_token})
                    saveTokens({
                        'access': response.data.access,
                        'refresh': response.data.refresh
                    });
                    error.config.headers.Authorization = `Bearer ${response.data['access']}`
                    return axios.request(error.config);
                } catch (err) {
                    return _logout_and_redirect()
                }
            } else {
                _logout_and_redirect()
            }
        }
        return Promise.reject(error);
    },
);

function _logout_and_redirect() {
    deleteTokens()
    window.location.href = "/login";
    return Promise.reject({
        'message': LOGIN_EXPIRED,
        'error': LOGIN_EXPIRED,
        'detail': LOGIN_EXPIRED,
        "response": {
            'message': LOGIN_EXPIRED,
            'error': LOGIN_EXPIRED,
            'detail': LOGIN_EXPIRED,
            'data': {
                'message': LOGIN_EXPIRED,
                'error': LOGIN_EXPIRED,
                'detail': LOGIN_EXPIRED,
            }
        }
    })
}

export default axios;