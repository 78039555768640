import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import useFile from "../../../hooks/useFile";

const Label = styled.span`
  font-size: 16px;
  color: white;
`;
const StyledUploader = styled(Upload.Dragger)`
  background: rgba(34, 36, 37, 1) !important;
  border-color: rgba(86, 93, 98, 1);
  display: block;
`;

const DragAndDropUpload = ({
  noLimitFileSize,
  beforeUpload,
  onRemove,
  maxCount = 1,
  typesAcceptFile = [],
  ...props
}) => {
  const { max_size, ...useFileProps } = useFile({
    typesAcceptFile: typesAcceptFile,
    beforeUpload: beforeUpload,
    onRemove: onRemove,
    noLimitFileSize: noLimitFileSize,
  });

  return (
    <>
      <StyledUploader
        maxCount={maxCount}
        {...useFileProps} // Props do hook useFile
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <Label>Clique para adicionar ou arraste aqui</Label>
      </StyledUploader>
    </>
  );
};

export default DragAndDropUpload;
