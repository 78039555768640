import { Col, Row } from "antd";
import DisplayInfo from "../Base/DisplayInfo";
import Badge from "../Base/Badge";
import LastLogin from "../Base/LastLogin";
import React from "react";

export default function ProfileDisplay({ user }) {
  if (!user) return null;
  return (
    <>
      <Row gutter={[4, 24]}>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"E-MAIL"}>{user.username}</DisplayInfo>
        </Col>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"Usuário do SAP"}>{user.user_sap}</DisplayInfo>
        </Col>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"ID do SAP"}>{user.id_sap}</DisplayInfo>
        </Col>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"NOME COMPLETO"}>
            {user.get_full_name}
          </DisplayInfo>
        </Col>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"CELULAR PROFISSIONAL"}>{user.phone}</DisplayInfo>
        </Col>
        <Col xs={24} sm={12}>
          <DisplayInfo label={"SITUAÇÃO"}>
            <Badge type={user.is_active ? "success" : "inactive"}>
              {user.is_active ? "Ativo" : "Inativo"}
            </Badge>
          </DisplayInfo>
        </Col>
        <Col xs={24}>
          <DisplayInfo label={"Perfis de acesso"} isList>
            {user.profiles_display.map((value, index) => (
              <Badge key={`${index}_${value}`} type={"inactive"}>
                {value}
              </Badge>
            ))}
          </DisplayInfo>
        </Col>
        <Col xs={24}>
          <DisplayInfo label={"Centros"} isList>
            {user.centers_display.map((value, index) => (
              <Badge key={`${index}_${value}`} type={"inactive"}>
                {value}
              </Badge>
            ))}
          </DisplayInfo>
        </Col>
        <Col xs={24}>
          <DisplayInfo label={"Marcas"} isList>
            {user.brands_display.map((value, index) => (
              <Badge key={`${index}_${value}`} type={"inactive"}>
                {value}
              </Badge>
            ))}
          </DisplayInfo>
        </Col>
        <Col xs={24}>
          <LastLogin datetime={user.last_login} />
        </Col>
      </Row>
    </>
  );
}
