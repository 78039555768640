import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import DividerPage from "../../../components/Base/DividerPage";
import PageTitle from "../../../components/Base/PageTitle";
import StatisticCard from "../../../components/Base/StatisticCard";
import SelectUser from "../../../components/User/SelectUser";
import Bar from "../../../components/Base/Charts/Bar";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import { useUserContext } from "../../../context/UserContext";
import Loading from "../../../components/Base/Loading";
import { blue_6, mangeta_6, purple_5 } from "../../../Cores";

export const data = [
  ["Revisão", "Cod. erro", "An. óleo"],
  ["2014", 1000, 400],
  ["2015", 1170, 460],
  ["2016", 660, 1120],
  ["2017", 1030, 540],
];

const GRAF_OPTIONS = [
  {
    value: "0",
    label: "Último mês",
  },
  {
    value: "2",
    label: "Últimos 3 meses",
  },
  {
    value: "5",
    label: "Últimos 6 meses",
  },
  {
    value: "11",
    label: "Último ano ",
  },
];

export default function ConsultantsTabDashboard({}) {
  const [isStatisticsLoading, setStatisticsIsLoading] = useState();
  const { user } = useUserContext();
  const [statistics, setStatistics] = useState({});
  const [responsible, setResponsible] = useState(user.id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatisticsIsLoading(true);
        const response = await api.post(`get_consultant_statistics`, {
          responsibles: [responsible],
        });
        setStatistics(response.data);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setStatisticsIsLoading(false);
      }
    };

    fetchData();
  }, [responsible]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <DividerPage />
        </Col>
        <Col xs={24}>
          <Row style={{ marginTop: 16 }}>
            <Col xs={24} md={18}>
              <PageTitle isInline value={"Estatísticas do consultor"} />
            </Col>
            <Col
              xs={24}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SelectUser
                showSearch
                defaultValue={user.id}
                onChange={setResponsible}
                label_key={"get_full_name"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: 8 }}>
          <Row gutter={[24, 24]}>
            {isStatisticsLoading ? (
              <Col xs={24}>
                <Loading />
              </Col>
            ) : (
              <>
                <Col xs={24} md={12}>
                  <StatisticCard
                    number={statistics?.opportunities_responsible}
                    name={
                      "Notificações criadas no mês corrente na qual é responsável"
                    }
                  />
                </Col>
                <Col xs={24} md={12}>
                  <StatisticCard
                    number={statistics?.finished_opportunities}
                    name={"Notificações resolvidas no mês corrente"}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          {/*<Row gutter={[24, 0]} align={'stretch'}>*/}
          {/*    <Col xs={24} >*/}
          <Bar
            url={"/get_chart_opportunity_by_type"}
            options={{
              colors: [blue_6, purple_5, mangeta_6],
            }}
            body={{
              responsibles: [responsible],
            }}
            selectName={"months"}
            defaultValue={"2"}
            title={"Notificações por tipo"}
            height="400px"
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} md={24} style={{ marginTop: 16 }}>
          <Bar
            url={`get_chart_opportunity_revenue`}
            format={"MM/YYYY"}
            title={"Receita total gerada"}
            width="100%"
            height="404px"
            hasSelect
            body={{
              responsibles: [responsible],
            }}
            selectName={"months"}
            // hasPadding={false}
            defaultValue={"2"}
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
      </Row>
    </>
  );
}
