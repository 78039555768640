import React from "react";
import { Badge as BadgeAnt } from "antd";
import { useParameters } from "../../context/ParametersContext";

export default function AlertHourmeter({ value = "ERROR", hasText = false }) {
  const { params } = useParameters();

  const valueReturnMappings = {
    OK: {
      color: "green",
      text: `Verde (Até ${params.green_hourmeter_max_age} dias)`,
    },
    WARNING: {
      color: "yellow",
      text: `Amarelo (Entre ${params.green_hourmeter_max_age} a ${params.yellow_hourmeter_max_age} dias)`,
    },
    ERROR: {
      color: "red",
      text: `Vermelho (Maior que ${params.yellow_hourmeter_max_age} dias)`,
    },
  };

  return (
    <BadgeAnt
      status={valueReturnMappings[value]?.color}
      style={{ marginRight: "0.5rem" }}
      text={hasText && valueReturnMappings[value]?.text}
    />
  );
}
