import React from 'react';
import styled from "styled-components";
import {text_color} from "../../../Cores";

const Title = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${text_color};
  margin: 0 0 8px 0;
  display: ${({isInline}) => isInline ? 'inline-block' :'block'};
`



export default function PageText({value, isInline=false, ...props}) {
    return (
        <Title {...props} isInline={isInline}>
            {value}
        </Title>
    )
}