import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./style.css";
import { GOOGLE_MAPS_KEY } from "../../../settings";
// https://github.com/leighhalliday/google-maps-clustering
import styled from "styled-components";
import { stylesMap } from "./maps_styles";
import Loading from "../Loading";
import { MapMarkerFactory } from "./MapMarkerFactory";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import { primary_color } from "../../../Cores";

const Container = styled.div`
  width: 100%;
  position: relative;
  height: ${({ height }) => (height ? height : "0px")};
`;

export default function Maps({
  isLoading,
  isModalOpen,
  markerClicked,
  onMarkerClick,
  useZoomState = true,
  fullContent = false,
  agroupMarkers = true,
  height = "491px",
  center = {
    lat: -18.769275,
    lng: -50.833514,
  },
  coords = [],
  defaultZoom = 11,
  circleRadius = null,
  firstMarkerCenter = true,
  phoneHeightDiff = 100,
}) {
  const { isPhone } = useSystemBreakpoint();
  const getMapHeight = () => {
    if (!fullContent) {
      return height;
    }
    let distance_map_2_botton = 35;
    if (isPhone) {
      distance_map_2_botton += phoneHeightDiff;
    }
    let distance_map_2_top =
      mapContainerRef?.current?.getBoundingClientRect().top;
    return `${
      window.innerHeight - distance_map_2_top - distance_map_2_botton
    }px`;
  };
  const defaultProps = {
    center: center,
    zoom: defaultZoom,
  };

  // get map bounds
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(defaultZoom);
  const [centerState, setCenterState] = useState(center);

  useEffect(() => {
    !useZoomState && setZoom(defaultZoom);
  }, [defaultZoom]);

  const points = coords.map((coord) => ({
    // type: "Feature",
    object: coord,
    properties: {
      cluster: false,
      id: coord.id,
      // category: coord.category
    },
    geometry: {
      type: "Point",
      coordinates: [coord.lng, coord.lat],
    },
  }));
  const mapRef = useRef();
  const mapContainerRef = useRef();

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: false,
      styles: stylesMap,
    };
  }

  function getMapCenter() {
    if (firstMarkerCenter && coords.length > 0) {
      return { lat: Number(coords[0].lat), lng: Number(coords[0].lng) };
    } else {
      return centerState;
    }
  }

  function pointMarkers() {
    let markers;
    if (agroupMarkers) {
      markers = clusters;
    } else {
      markers = points;
    }
    return markers.map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount } =
        cluster.properties;
      if (isCluster) {
        return (
          <MapMarkerFactory
            isCluster
            key={`cluster-${cluster.id}`}
            lat={latitude}
            lng={longitude}
            // onClick={(e) => onMarkerClick && onMarkerClick(cluster.properties.id)}
          >
            <span>{pointCount > 99 ? `99+` : pointCount}</span>
          </MapMarkerFactory>
        );
      }
      return (
        <MapMarkerFactory
          isModalOpen={isModalOpen}
          markerClicked={cluster.object.id === markerClicked?.id}
          object={cluster.object}
          key={`${cluster.object ? cluster.object.type : "default"}-${
            cluster.properties.id
          }`}
          lat={latitude}
          lng={longitude}
          onClick={(e) => onMarkerClick && onMarkerClick(cluster.object)}
        />
      );
    });
  }

  return (
    // Important! Always set the container height explicitly
    <Container ref={mapContainerRef} height={getMapHeight()}>
      {isLoading ? (
        <Loading />
      ) : (
        <GoogleMapReact
          options={createMapOptions}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
          defaultCenter={defaultProps.center}
          center={getMapCenter()}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map;
            circleRadius &&
              new maps.Circle({
                strokeColor: `${primary_color}`,
                strokeOpacity: 0.35,
                strokeWeight: 1.5,
                fillColor: `${primary_color}`,
                fillOpacity: 0.15,
                map,
                center: getMapCenter(),
                radius: circleRadius * 1000, //raio em metros
              });
          }}
          onChange={({ zoom, bounds, center }) => {
            setZoom(zoom);
            setCenterState(center);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
        >
          {pointMarkers()}
        </GoogleMapReact>
      )}
    </Container>
  );
}
