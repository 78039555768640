import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { text_color } from "../../../Cores";
import OffCanvas from "../../Base/OffCanvas";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import Loading from "../../Base/Loading";
import SendAnalytisModal from "./SendAnalytisModal";
import OilDetailInfo from "./OilDetailInfo";
import { BuildFieldErrorsDict } from "../../../util/Utils";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

export default function OilDetailOffCanvas({ open, setOpen, oilId }) {
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [oil, setOil] = useState({});
  const downlaodButton = useRef();
  const { fetchData, isLoading } = useDefaultFetch();
  const [url, setUrl] = useState("false");
  useEffect(() => {
    if (open && oilId) {
      async function fetch() {
        const oilData = await fetchData(`oil_analysis/${oilId}`);
        setOil(oilData);
      }

      fetch();
    }
  }, [open, oilId]);

  function onClose() {
    closeDrawer();
  }

  function closeDrawer() {
    setOpen(false);
  }

  async function onSubmit() {
    try {
      setIsLoadingPdf(true);
      downlaodButton.current.href = oil.pdf_url;
      downlaodButton.current.click();
      // setUrl();
    } catch (e) {
      BuildFieldErrorsDict(null, null, "PDF não encontrado");
    } finally {
      setIsLoadingPdf(false);
    }
  }

  function onHandleOpenModal() {
    setOpenModal(true);
    // form.setFieldsValue({'centers': user.centers})
  }

  return (
    <>
      <OffCanvas
        destroyOnClose
        title={<Title level={4}>Selecionar centros</Title>}
        onClose={onClose}
        open={open}
        footer={
          <>
            <Button disabled={!oil.pdf_url} onClick={onHandleOpenModal}>
              Enviar ao cliente
            </Button>
            <Button
              disabled={!oil.pdf_url}
              type="primary"
              onClick={onSubmit}
              loading={isLoadingPdf}
            >
              Baixar análise
            </Button>
          </>
        }
      >
        {isLoading ? <Loading /> : <OilDetailInfo oil={oil} />}
        <a
          href={url}
          target={"_blank"}
          download
          ref={downlaodButton}
          rel="noreferrer"
        />
      </OffCanvas>
      <SendAnalytisModal
        clientID={oil.client_id}
        open={openModal}
        id={oil.id}
        setClose={setOpenModal}
      />
    </>
  );
}
