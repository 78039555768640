import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import UpTable, { TableSearch } from "../../components/Base/UPTable";
import { Col, Row } from "antd";
import { get_filter_params } from "../../util/TableStorageService";
import useTable from "../../hooks/useTable";
import api from "../../services/api";
import { BuildFieldErrorsDict, buildFormDataToFilter } from "../../util/Utils";
import { useNavigate } from "react-router-dom";
import BigAccountIcon from "../../components/Base/BigAccountIcon";
import FilterIcon from "../../components/Base/FilterIcon";
import FilterClientOffCanvas from "../../components/Client/FilterClientOffCanvas";
import { INITIAL_CLIENT_FORM_FILTERS } from "../../util/ClientUtils";

export default function ListClient() {
  const { changeTitle, setHasBack } = useActionBar();
  const [search, setSearch] = useState(get_filter_params("search"));
  const [filterCount, setFilterCount] = useState(2);
  const [initialFormFilter] = useState(
    get_filter_params("formFilter", INITIAL_CLIENT_FORM_FILTERS)
  );
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(initialFormFilter)
  );
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();

  const fetchClients = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      let data = formFilter;

      const response = await api.post(
        `client/list_post?page_size=${pagination.pageSize}&page=${
          pagination.current
        }&search=${search}&ordering=${order ? order : ""}`,
        data
      );

      setTableData(response.data.results);

      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    tableData,
    isLoading,
    tableParams,
    resetTable,
    handleTableChange,
    handleRequestTable,
  } = useTable({
    fetchData: fetchClients,
    search,
    useHandleRequestTable: false,
  });

  useEffect(() => {
    if (formFilter) {
      const size = new Set(formFilter.keys()).size;
      setFilterCount(size);

      handleRequestTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, formFilter]);

  const columns = [
    {
      title: "Cliente",
      dataIndex: "name",
      sorter: "name",
      key: "name",
      width: "300px",
      render: (text, record) => (
        <>
          {record.is_big_account ? <BigAccountIcon /> : ""}
          {text}
        </>
      ),
    },
    {
      title: "Qtd Equipamentos",
      dataIndex: "equipments_count",
      key: "equipments_count",
      sorter: "equipments_count",
      width: "160px",
      ellipsis: true,
    },
    {
      title: "Código(SAP)",
      dataIndex: "id",
      key: "id",
      sorter: "id",
      width: "150px",
      ellipsis: true,
    },

    {
      title: "Cidade",
      dataIndex: "city",
      key: "city",
      sorter: "city",
      width: "250px",
      ellipsis: true,
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      sorter: "state",
      width: "80px",
      ellipsis: true,
    },
    {
      title: "Centro",
      dataIndex: "center_name",
      key: "center_name",
      sorter: "center__name",
      width: "250px",
      ellipsis: true,
    },
  ];
  useEffect(() => {
    changeTitle("Consultar clientes");
    setHasBack(false);

    return () => {
      setHasBack(true);
    };
  }, [changeTitle, setHasBack]);

  const handleClientDetail = (record) => {
    navigate(`${record.id}/detail?tab=1`);
  };

  return (
    <>
      <Row>
        <Col xs={22} md={22}>
          <TableSearch
            size={"large"}
            allowClear
            defaultValue={search}
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquise por razão social, código ou centro"}
          />
        </Col>
        <Col
          xs={2}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>
      </Row>

      <UpTable
        onChange={handleTableChange}
        onRowClick={handleClientDetail}
        loading={isLoading}
        tableParams={tableParams}
        columns={columns}
        dataSource={tableData}
      />

      <FilterClientOffCanvas
        isOpen={openFilter}
        onClose={() => setOpenFilter(false)}
        initialFormFilter={initialFormFilter}
        setFormFilter={setFormFilter}
        formFilter={formFilter}
      />
    </>
  );
}
