import React, {useEffect, useState} from 'react';
import {
    ReactComponent as LargeTracbelIcon
} from "../../../assets/icons/Logo_Principal.svg";
import styled from 'styled-components'
import {
    PhoneOutlined,
    MailOutlined,
    WarningOutlined,
    CopyOutlined
} from '@ant-design/icons';
import {Tooltip} from "antd";
import {gray, primary_color, text_color} from "../../../Cores";
import {useActionBar} from "../../../context/ActionBarContext";
import {APP_VERSION} from "../../../settings";
import {copyToClipBoard} from "../../../util/Utils";

const Container = styled.div`
  width: 318px;
  margin: 0 auto;
  text-align: center;
`

const StyledLargeTracbelIcon = styled(LargeTracbelIcon)`
  width: 264px;
  height: 56px;
`

const StyledCopyOutlined = styled(CopyOutlined)`
  color: ${primary_color};
`

const StyledTitle = styled.h2`
  color: ${gray};
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 24px;
`
const StyledLabel = styled.p`
  color: ${text_color};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

`

export default function TabAboutUs() {
    const {changeTitle} = useActionBar()

    useEffect(() => {
        changeTitle('Informações do sistema')
    }, [changeTitle])

    return (
        <Container>
            <StyledLargeTracbelIcon/>
            <div>
                <StyledTitle>
                    VERSÃO ATUAL
                </StyledTitle>
                <StyledLabel>
                    ver. {APP_VERSION}
                </StyledLabel>
            </div>
            <div>
                <StyledTitle>
                    CONTATOS PARA SUPORTE
                </StyledTitle>
                <StyledLabel>
                    <PhoneOutlined/>
                    (99) 99999-9999
                    <Tooltip trigger={['click', 'hover']} mouseEnterDelay={999}
                             placement="top"
                             title={'(99) 99999-9999(setar o valor certo) Copiado'}>
                        <StyledCopyOutlined
                            onClick={() => copyToClipBoard('numero')}/>
                    </Tooltip>
                </StyledLabel>
                <StyledLabel>
                    <MailOutlined/> ti@tracbel.com.br
                    <Tooltip trigger={['click', 'hover']} mouseEnterDelay={999}
                             placement="top"
                             title={'email(setar o valor certo) Copiado'}>
                        <StyledCopyOutlined
                            onClick={() => copyToClipBoard('email')}/>
                    </Tooltip>
                </StyledLabel>
            </div>
            <div>
                <StyledTitle>
                    ENCONTROU PROBLEMAS?
                </StyledTitle>
                <StyledLabel>
                    <WarningOutlined/> <span style={{color: primary_color}}>Informar um problema</span>
                </StyledLabel>
            </div>

        </Container>
    )
}