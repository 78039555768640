import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useQuery from "./useQuery";

export default function useTab({defaultTab = '1', removeTab = false, tabName = 'tab'}) {
    const querys = useQuery()
    const navigate = useNavigate()
    const [defaultTabId, setDefaultTabId] = useState();
    const [value, setValue] = useState();

    const location = useLocation();
    useEffect(() => {

        let detailTab = querys.get(tabName)

        if (detailTab) {
            setDefaultTabId(detailTab)
            setValue(detailTab)
        } else {
            setQueryTab(defaultTab)
        }

    }, [location])

    function setQueryTab(idTab) {
        querys.set(tabName, idTab)
        setValue(idTab)
        navigate({search: querys.toString()})
    }

    return {setQueryTab, defaultTabId, value, tabName}
}