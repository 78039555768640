import React from "react";
import styled from 'styled-components';

const Container = styled.span`

`

export default function RemixIcon({remixIconName, ...props}){
    return (
        <Container {...props} role="img" className={'anticon'}>
            <i className={remixIconName}/>
        </Container>)
}