import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ShareLocation from "../ShareLocation";
import {
    BuildFieldErrorsDict,
    formatCPFCNPJ,
    maskIntNumber
} from "../../../util/Utils";
import ArrowLink from "../ArrowLink";
import api from "../../../services/api";
import Loading from "../Loading";
import HeaderModalMap from "../../ModalMap/HeaderModalMap";
import {ItemBodyModalMap} from "../../ModalMap/ItemBodyModalMap";
import {ModalMap} from "../../ModalMap/ModalMap";
import {EditOutlined} from "@ant-design/icons";
import {primary_color} from "../../../Cores";

const Container = styled.div`
  height: 374px;
  overflow: hidden;

`
const ContentContainer = styled.div`
  height: 226px;

  overflow-y: scroll;

`

const StyledEditOutlined = styled(EditOutlined)`
  color: ${primary_color};
  cursor: pointer;
`

const FormItemModal = styled.div`
  padding: 16px;
`

export default function EquipmentModalMaps({isModalOpen, setClose, handleOpenOffCanvasCenter,id, hasPermission}) {
    const [equipment, setEquipment] = useState();
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        if (id) {
            fetchEquipment()
        }
    }, [id, isModalOpen])

    const onClose = () => {
        setClose(false);
    }

    const fetchEquipment = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`equipment/${id}`);

            setEquipment(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    if (!equipment) return null
    return (
        <ModalMap open={isModalOpen} onClose={onClose}>
            <MapCard onClose={onClose} isLoading={isLoading} hasPermission={hasPermission}
                     equipment={equipment} handleOpenOffCanvasCenter={handleOpenOffCanvasCenter}/>
        </ModalMap>

    );
}


function MapCard({onClose, isLoading, equipment, hasPermission, handleOpenOffCanvasCenter}) {

    return (
        <Container>
            {isLoading ? (<Loading/>) : (
                <>
                    <HeaderModalMap subtitle1={equipment.model_equipment?.name}
                                    subtitle2={equipment.brand_equipment?.name}
                                    title={equipment.denomination}
                                    onClose={onClose}
                                    image={equipment.type_equipment?.picture}/>

                    <ContentContainer>


                        {/*<DividerPage/>*/}
                        <ItemBodyModalMap label={'N° SÉRIE'} icon={<i
                            className="ri-barcode-box-line"/>}>
                            {equipment.pin_17}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'CHASSIS'} icon={<i
                            className="ri-barcode-box-line"/>}>
                            {equipment.chassis_no}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'LOCALIZAÇÃO'} icon={<i
                            className="ri-map-pin-2-line"/>} extraIcon={
                            <ShareLocation
                                lat={equipment.last_equipment_position?.latitude}
                                long={equipment.last_equipment_position?.longitude}/>}>
                            {equipment.last_equipment_position?.reverse_geocoding}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'CENTRO'}
                                          extraIcon={hasPermission &&
                                              <StyledEditOutlined
                                                  onClick={() => handleOpenOffCanvasCenter(equipment)}/>}
                                          icon={<i
                                              className="ri-map-2-line"/>}>
                            {equipment?.center?.name}
                        </ItemBodyModalMap>

                        <ItemBodyModalMap label={'HORÍMETRO'}
                                          icon={<i
                                              className="ri-timer-line"/>}>
                            {maskIntNumber(equipment?.last_reporting_hourmeter)}

                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'CLIENTE (SAP)'}
                                          icon={<i className="ri-user-line"/>}>
                            {equipment?.client?.name}
                        </ItemBodyModalMap>

                        <ItemBodyModalMap label={'CLIENTE (CARETRACK)'}
                                          icon={<i className="ri-user-line"/>}>
                            {equipment?.name}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap label={'CPF/CNPJ'} icon={<i
                            className="ri-community-line"/>}>
                            {formatCPFCNPJ(equipment?.client?.cnpj_cpf)}
                        </ItemBodyModalMap>
                        <ItemBodyModalMap>
                            <ArrowLink
                                to={`/equipment/${equipment?.id}/detail?tab=1`}
                                label={'Visualizar perfil do equipamento'}/></ItemBodyModalMap>
                    </ContentContainer>
                </>
            )}
        </Container>
    )
}
