import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Chart } from "react-google-charts";
import { chart_color_gray, content_bg, primary_color } from "../../../Cores";
import PageTitle from "../PageTitle";
import api from "../../../services/api";
import { BuildFieldErrorsDict, formdataToJSON } from "../../../util/Utils";
import Select from "../Select";
import Loading from "../Loading";
import { NumberFormatInteger } from "./utils";
import { Col, Form, Row } from "antd";
import HeaderCharts from "./HeaderCharts";
import { dateFormat } from "../../../util/dates";

const StyledChart = styled(Chart)`
  color: white;

  svg text:first-child {
    fill: white;
  }

  text[text-anchor="middle"] {
    fill: white;
    font-weight: bold;
  }

  g.google-visualization-tooltip text {
    font-weight: bold;
    fill: ${content_bg};
  }

  //background: red !important;
`;
const defaultOptions = {
  colors: [primary_color, chart_color_gray],
  fill: "red",
  backgroundColor: content_bg,
  chartArea: { left: 50, top: 10, width: "100%", height: "85%" },
  legend: { position: "bottom", maxLines: 1 },
};
export default function Bar({
  url,
  hasSelect,
  defaultValue,
  format = "MM/YYYY",
  information = "",
  selectName,
  body,
  optionsSelect,
  title,
  filter,
  filterRender,
  options = {},
  ...props
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, [body, filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await api.post(`${url}`, {
        ...body,
        ...formdataToJSON(filter),
        ...form.getFieldsValue(),
      });
      setData(
        response.data.map((value, index) => {
          // role: "annotation"
          if (index !== 0) {
            value[0] = dateFormat(value[0], format);
          }

          return value;
        })
      );
    } catch (error) {
      console.log(error);
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    // <Container >
    <Row>
      <Col xs={24}>
        <HeaderCharts
          title={title}
          form={form}
          optionsSelect={optionsSelect}
          selectName={selectName}
          information={information}
          defaultValue={defaultValue}
          filterRender={filterRender}
          onValuesChange={fetchData}
        />
      </Col>

      <Col xs={24}>
        {isLoading ? (
          <Loading noHeight />
        ) : (
          <StyledChart
            formatters={[NumberFormatInteger]}
            {...props}
            width="95%"
            options={{ ...defaultOptions, ...options }}
            // chartType="Bar"
            chartType="ColumnChart"
            data={data}
          />
        )}
      </Col>
    </Row>
  );
}
