import React, { useEffect, useState } from "react";
import UpTable from "../../Base/UPTable";
import useTable from "../../../hooks/useTable";
import api from "../../../services/api";
import { BuildFieldErrorsDict } from "../../../util/Utils";
import { useNavigate } from "react-router-dom";
import AvatarProfile from "../../AvatarProfile";
import { set_filter_params } from "../../../util/TableStorageService";
import BadgeRuleStatus from "../BadgeRuleStatus";
import { dateFormat } from "../../../util/dates";

const ListRuleColumnsType = {
  //Ao duplicar deve adicionar no columns
  all: [
    "id",
    "type",
    "name",
    "created_at",
    "telemetry_system",
    "is_active",
    "owner",
    "num_equipments",
    "priority_name",
  ],
  "Análise de óleo": [
    "id",
    "name",
    "created_at",
    "telemetry_system",
    "is_active",
    "owner",
    "num_equipments",
    "priority_name",
  ],
  "Revisão programada": [
    "id",
    "name",
    "created_at",
    "telemetry_system",
    "is_active",
    "owner",
    "num_equipments",
    "priority_name",
  ],
  "Código de erro": [
    "id",
    "name",
    "created_at",
    "telemetry_system",
    "is_active",
    "owner",
    "num_equipments",
    "priority_name",
  ],
};

export default function RuleTable({
  search,
  formFilter,
  typeTable,
  isActive,
  setFilterCount,
}) {
  let navigate = useNavigate();

  const fetchRule = async ({
    setIsLoading,
    pagination,
    order,
    setTableParams,
    setTableData,
  }) => {
    try {
      setIsLoading(true);
      const queryTypeTable =
        typeTable !== "all" ? `rule_type__name=${typeTable}&` : "";
      const queryIsActive = isActive ? `&is_active=${isActive}` : "";
      const response = await api.post(
        `rule/list_post?${queryTypeTable}page_size=${
          pagination.pageSize
        }&page=${pagination.current}&search=${search}&ordering=${
          order ? order : ""
        }${queryIsActive}`,
        formFilter
      );
      setTableData(response.data.results);
      setTableParams((tableParams) => {
        return {
          ...tableParams,
          pagination: {
            ...pagination,
            total: response.data.count,
          },
        };
      });
      set_filter_params("typeTable", typeTable);
      set_filter_params("isActive", isActive);
    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    tableData,
    isLoading,
    tableParams,
    resetTable,
    handleTableChange,
    handleRequestTable,
  } = useTable({ fetchData: fetchRule, search, useHandleRequestTable: false });

  const columns = [
    {
      title: "Cód.",
      dataIndex: "id",
      // sorter: 'id',
      key: "id",
      width: "80px",
      // fixed: 'left',
    },
    {
      title: "Tipo",
      dataIndex: "type",
      // sorter: 'type',
      // key: 'client_name',
      width: "145px",
      ellipsis: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      // sorter: 'name',
      // key: 'client_name',
      width: "360px",
      ellipsis: true,
    },
    {
      title: "Criação",
      dataIndex: "created_at",
      // sorter: 'created_at',
      key: "created_at",
      width: "91px",
      ellipsis: true,
      render: (value, record) =>
        // dateFormat(value, "DD/MM/YYYY HH:mm")
        dateFormat(value, "DD/MM/YYYY"),
    },
    {
      title: "Sistema",
      dataIndex: "telemetry_system",
      // sorter: 'created_at',
      key: "telemetry_system",
      width: "91px",
      render: (value, record) => <span>{value?.name}</span>,
    },
    {
      title: "N° Eqp.",
      dataIndex: "num_equipments",
      // sorter: 'num_equipments',
      // key: 'client_name',
      width: "80px",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "100px",
      render: (value, record) => (
        <BadgeRuleStatus type={value}>
          {value ? "Ativa" : "Inativa"}
        </BadgeRuleStatus>
      ),
    },
    {
      title: "MID",
      dataIndex: "mid",
      // sorter: 'mid',
      key: "mid",
      width: "80px",
      ellipsis: true,
    },
    {
      title: "PID",
      dataIndex: "pid",
      // sorter: 'pid',
      key: "pid",
      width: "80px",
      ellipsis: true,
    },
    {
      title: "FMI",
      dataIndex: "fmi",
      // sorter: 'fmi',
      key: "fmi",
      width: "80px",
      ellipsis: true,
    },
    // {
    //     title: 'Severidade',
    //     dataIndex: 'priority_name',
    //     // sorter: 'priority',
    //     key: 'priority_name',
    //     width: '90px',
    //     // ellipsis: true,
    //      render: (value, record) => (
    //         <BadgePriority type={value}/>
    //     ),
    // },
    {
      title: "Criado por",
      dataIndex: "owner",
      // sorter: 'responsible__name',
      key: "owner",
      width: "100px",
      render: (owner, record) => {
        return (
          <>
            {owner ? (
              <AvatarProfile
                iconSize={24}
                src={owner.picture}
                id={owner.id}
                hiddenName
                name={owner.get_full_name}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleRequestTable();
    // eslint-disable-next-line
  }, [search, formFilter, isActive, typeTable]);

  const handleRuleDetail = (record) => {
    navigate(`/rule/${record.id}/detail?tab=1`);
  };
  const getColumns = () => {
    let columnsDict = ListRuleColumnsType;
    return columns.filter((column) => {
      return columnsDict[typeTable].includes(column.dataIndex);
    });
  };
  if (!typeTable) return null;
  return (
    <UpTable
      onChange={handleTableChange}
      loading={isLoading}
      showQuickJumper
      tableHeightDiff={56}
      onRowClick={handleRuleDetail}
      tableParams={tableParams}
      columns={getColumns()}
      dataSource={tableData}
    />
  );
}
