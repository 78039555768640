import { UserProvider } from "./UserContext";
import { MenuProvider } from "./MenuContext";
import { ActionBarProvider } from "./ActionBarContext";
import { StaticDataProvider } from "./StaticDataContext";
import { ParametersProvider } from "./ParametersContext";
const providers = [
  UserProvider,
  MenuProvider,
  ActionBarProvider,
  StaticDataProvider,
  ParametersProvider,
];

export default function AppProvider(props) {
  const { children, ...rest } = props;
  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp {...rest}>{acc}</Comp>;
      }, children)}
    </>
  );
}
