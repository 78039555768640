import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";

import PageTitle from "../../components/Base/PageTitle";
import styled from "styled-components";
import { ReactComponent as LargeTracbelIcon } from "../../assets/icons/Logo_Principal.svg";
import DividerPage from "../../components/Base/DividerPage";
import { Col, Row } from "antd";
import StatisticCard from "../../components/Base/StatisticCard";
import api from "../../services/api";
import { BuildFieldErrorsDict } from "../../util/Utils";
import Loading from "../../components/Base/Loading";
import FingerScroll from "../../components/Base/FingerScroll";
import PriorityOpportunityCard from "../../components/Base/PriorityOpportunityCard";
import Maps from "../../components/Base/Maps/Maps";
import { MAP_MARKER_PRITORITY_OPPOTUNITY } from "../../components/Base/Maps/MapMarkerFactory";
import ContainerWithoutLimit from "../../components/ContainerWithoutLimit";
import moment from "moment-timezone";
import ListOpportunity from "../Opportunity/ListOpportunity";
import { INITIAL_OPPORTUNITY_FORM_FILTERS } from "../../util/OpportunityUtils";
import { useUserContext } from "../../context/UserContext";
import OpportunityModalMaps from "../../components/Base/Maps/OpportunityModalMaps";

const StyledLargeTracbelIcon = styled(LargeTracbelIcon)`
  margin: 32px auto;
  width: 100%;
  height: 25px;
`;

export default function Home() {
  const { changeTitle, setHasBack } = useActionBar();
  const [opportunityModal, setOpportunityModal] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priorityOpportunities, setPriorityOpportunities] = useState([]);
  const [newOpportunityAmount, setNewOpportunityAmount] = useState(0);
  const [progressOppportunityAmount, setProgressOpportunityAmount] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserContext();

  useEffect(() => {
    changeTitle("Inicio");
    setHasBack(false);

    return () => {
      setHasBack(true);
    };
  }, [changeTitle, setHasBack]);

  useEffect(() => {
    async function fetchAmounts() {
      try {
        setIsLoading(true);

        const priorityOpportunityResponse = await api.get(
          "user/priority_opportunities"
        );
        setPriorityOpportunities(priorityOpportunityResponse.data);

        const newOpportunityResponse = await api.post(
          "opportunity/list_post?page_size=1&page=1",
          {
            created_at__gte: moment().subtract(1, "days"),
          }
        );
        setNewOpportunityAmount(newOpportunityResponse.data.count);

        const progressOportunityResponse = await api.post(
          "opportunity/list_post?page_size=1&page=1",
          {
            timelines__description__contains:
              "Status da notificação alterado para",
            updated_at__gte: moment().subtract(1, "days"),
            closing_date__isnull: true,
          }
        );
        setProgressOpportunityAmount(progressOportunityResponse.data.count);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setIsLoading(false);
      }
    }

    fetchAmounts();
  }, []);

  const getCoords = () => {
    const coords = [...priorityOpportunities];
    return coords.map(({ position, id }) => {
      return {
        id: id,
        lat: Number(position.latitude),
        lng: Number(position.longitude),
        type: MAP_MARKER_PRITORITY_OPPOTUNITY,
      };
    });
  };

  return (
    <>
      <StyledLargeTracbelIcon />
      <p>
        Bem vindo ao Uptime Center, o sistema de gestão e inteligência da frota
        da Tracbel. Utilize o menu lateral para navegar entre as funcionalidades
        disponíveis ou o menu superior para acessar suas notificações, perfil,
        selecionar quais centros você deseja ver no sistema ou se desconectar.
      </p>
      <DividerPage />
      <PageTitle
        style={{ marginTop: 16 }}
        value={"Notificações prioritárias não iniciadas"}
      />
      <ContainerWithoutLimit style={{ marginBottom: 16 }}>
        <Maps
          height={"572px"}
          // fullContent
          isLoading={isLoading}
          agroupMarkers={false}
          // defaultZoom={getZoomByRadius()}
          useZoomState={false}
          coords={getCoords()}
          // isModalOpen={isModalOpen}
          // circleRadius={radius}
          // markerClicked={opportunityModal}
          onMarkerClick={(data) => {
            setOpportunityModal(data);
            setIsModalOpen(true);
          }}
        />
      </ContainerWithoutLimit>
      {priorityOpportunities.length > 0 && (
        <FingerScroll gap={8} breakLeft={false}>
          {priorityOpportunities.map((item) => {
            return <PriorityOpportunityCard opportunity={item} />;
          })}
        </FingerScroll>
      )}
      <DividerPage style={{ marginTop: 16, marginBottom: 16 }} />
      <PageTitle value={"Suas notificações"} />
      <ListOpportunity
        initialFormFilter={{
          ...INITIAL_OPPORTUNITY_FORM_FILTERS,
          opportunities_resposibles__responsible_id__in: [user.id],
          opportunities_resposibles__end_date__isnull: true,
        }}
        removeFilter={true}
      />
      <DividerPage />
      <PageTitle
        style={{ marginTop: 16 }}
        value={"Notificações do seu centro (nas últimas 24hrs)"}
      />
      <Row gutter={[24, 24]}>
        {isLoading ? (
          <Col xs={24}>
            <Loading noHeight />
          </Col>
        ) : (
          <>
            <Col xs={12}>
              <StatisticCard
                number={newOpportunityAmount}
                name={"Notificações novas"}
              />
            </Col>
            <Col xs={12}>
              <StatisticCard
                number={progressOppportunityAmount}
                name={"Notificações em andamento"}
              />
            </Col>
          </>
        )}
      </Row>
      <OpportunityModalMaps
        isModalOpen={isModalOpen}
        id={opportunityModal.id}
        setClose={setIsModalOpen}
      />
    </>
  );
}
