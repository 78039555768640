import React from 'react';
import {Drawer} from "antd";
import styled from "styled-components";
import {CloseOutlined} from '@ant-design/icons';
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {dark_border, text_color} from "../../Cores";


const StyledCloseOutline = styled(CloseOutlined)`
  color: ${text_color};
`
const Footer = styled.div`
  display: flex;
  justify-content: right;
  gap: 8px;
`

const style_header = {
    borderColor: dark_border,
    padding: '36px 32px',
    color: 'white'
};
const style_header_noborder = {
    borderColor: 'transparent',
    padding: '36px 32px',
    color: 'white'
};
const style_footer = {
    borderColor: dark_border,
    padding: '36px 32px',
    color: 'white'
};

export default function OffCanvas({
                                      title,
                                      showBorderHeader = true,
                                      onClose,
                                      open,
                                      placement = 'right',
                                      width,
                                      children, footer, ...props
                                  }) {
    const {isPhone, isTablet, isDesktop} = useSystemBreakpoint()

    function getWidth(){
        if(isPhone) return '100%'
        if(width) return width
        if(isDesktop) return 'calc(100% - 256px)'
        return 'calc(100% - 80px)'
    }

    return (
        <Drawer headerStyle={showBorderHeader? style_header: style_header_noborder}
                footerStyle={style_footer}
                bodyStyle={{'overflow-x': 'hidden','overflow-y': 'scroll'}}
                title={title} placement={placement}
                closable={false} onClose={onClose}
                open={open} width={getWidth()}
                extra={<StyledCloseOutline onClick={onClose}/>}
                footer={<Footer>{footer}</Footer>} {...props}>
            {children}
        </Drawer>
    )

}