import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import useSystemBreakpoint from "../hooks/useSystemBreakpoint";
import {useLocation} from "react-router-dom";

const MenuContext = createContext();

export function MenuProvider({children}) {

    const [hiddenMenu, setHiddenMenu] = useState(false)
    const {isDesktop} = useSystemBreakpoint();
    const location = useLocation();
    const onClickPageRef = useRef()
    useEffect(() => {
        setHiddenMenu(!isDesktop)
    }, [isDesktop])

    function handleCloseMenuOutsideMenu(containerRef) {
        //containerRef é o container que vai ser ignorado no onclick na tela
        if (!hiddenMenu && !isDesktop) {
            onClickPageRef.current = (event) => {


                if (!containerRef.current.contains(event.target)) {
                    event.stopPropagation()
                    setHiddenMenu(true)
                    document.removeEventListener('click', onClickPageRef.current, {capture: true})
                }
            }
            setTimeout(() => {
                document.addEventListener('click', onClickPageRef.current, {capture: true})
            }, 100)

        } else {
            document.removeEventListener('click', onClickPageRef.current, {capture: true})
        }
    }


    useEffect(() => {
        !isDesktop && setHiddenMenu(true)
    }, [location])

    const handleMenu = () => {
        setHiddenMenu(!hiddenMenu)
    }


    return (
        <MenuContext.Provider value={{
            hiddenMenu,
            handleMenu,
            handleCloseMenuOutsideMenu
        }}>
            {children}
        </MenuContext.Provider>
    )

};

export function useMenu() {
    return useContext(MenuContext);
}