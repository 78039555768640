import React from 'react'
import styled from 'styled-components'
import {Typography} from "antd";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import {menu_bg, primary_color} from "../../../Cores";
import DisplayInfo from "../DisplayInfo";


const Container = styled.div`
  padding: 16px;
  width: ${({width}) => width}px;
  background: ${menu_bg};
`

const Title = styled(Typography.Title)`
  color: ${primary_color} !important;
`

export default function InfoCard({title, width = 271, label, data}) {
    const {isDesktop} = useSystemBreakpoint();
    return (
        <Container width={isDesktop ? width: 192}>
            <Title level={3}>{data ? data : "-"}</Title>
            <DisplayInfo labelSize='12px' label={label}
                         isUpper={false}>{title}</DisplayInfo>
        </Container>
    )
}