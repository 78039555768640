import React from 'react';
import styled from "styled-components";
import {text_color} from "../../Cores";

const Title = styled.h4`
  font-size: 16px;
  font-weight: 500;
  color: ${text_color};
  margin-bottom: 8px;
  display: ${({isInline}) => isInline ? 'inline-block' :'block'};
`



export default function PageTitle({value,counter=0, isInline=false, ...props}) {
    return (
        <Title {...props} isInline={isInline}>
            {value}
        </Title>
    )
}