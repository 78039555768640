import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import {
    BuildFieldErrorsDict,
    buildFormDataToFilter,
} from "../../../util/Utils";
import { Col, Form, Row } from "antd";
import DividerPage from "../../../components/Base/DividerPage";
import PageTitle from "../../../components/Base/PageTitle";
import Loading from "../../../components/Base/Loading";
import StatisticCard from "../../../components/Base/StatisticCard";
import Bar from "../../../components/Base/Charts/Bar";
import { blue_6, mangeta_6, purple_5 } from "../../../Cores";
import Select from "../../../components/Base/Select";
import Donut from "../../../components/Base/Charts/Donut";
import useDefaultFetch from "../../../hooks/useDefaultFetch";
import { get_filter_params } from "../../../util/TableStorageService";
import FilterIcon from "../../../components/Base/FilterIcon";
import FilterEquipmentDashboardNotificationOffCanvas from "../../../components/FilterEquipmentDashboardNotificationOffCanvas";

const GRAF_OPTIONS = [
  {
    value: "0",
    label: "Último mês",
  },
  {
    value: "2",
    label: "Últimos 3 meses",
  },
  {
    value: "5",
    label: "Últimos 6 meses",
  },
  {
    value: "11",
    label: "Último ano ",
  },
];

export default function OpportunityTabDashboard({}) {
  const [isStatisticsLoading, setStatisticsIsLoading] = useState();
  const [statistics, setStatistics] = useState({});
  const [optionsType, setOptionsType] = useState([]);
  const { fetchData } = useDefaultFetch();

  const initialFormFilter = {};

  const [openFilter, setOpenFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(4);

  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter")
  );

  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(initialFormFilter)
  );

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        setStatisticsIsLoading(true);
        const response = await api.post(
          `get_opportunity_statistics`,
          formFilter
        );
        setStatistics(response.data);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setStatisticsIsLoading(false);
      }
    };

    fetchStatistics();
  }, [formFilter]);

  useEffect(() => {
    async function fetch() {
      const data = await fetchData(`rule_type?page_size=1000`);
      if (data) {
        setOptionsType(data.results);
      }
    }

    fetch();
  }, []);

  return (
    <>
      <Row>
        <Col xs={24}>
          <DividerPage />
        </Col>
        <Col xs={22} md={23} style={{ marginTop: 16 }}>
          <PageTitle isInline value={"Estatísticas da frota"} />
        </Col>
        <Col
          xs={2}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>
        <Col xs={24} style={{ marginTop: 8 }}>
          <Row gutter={[24, 24]}>
            {isStatisticsLoading ? (
              <Col xs={24}>
                <Loading />
              </Col>
            ) : (
              <>
                <Col xs={24} md={8}>
                  <StatisticCard
                    percent={statistics?.all_opportunities_percent}
                    number={statistics?.all_opportunities}
                    name={"Notificações criadas no sistema"}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <StatisticCard
                    percent={statistics?.finished_opportunities_percent}
                    number={statistics?.finished_opportunities}
                    name={"Notificações resolvidas no sistema"}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <StatisticCard
                    percent={statistics?.lost_opporunities_percent}
                    number={statistics?.lost_opporunities}
                    name={"Notificações finalizadas por inatividade"}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          {/*<Row gutter={[24, 0]} align={'stretch'}>*/}
          {/*    <Col xs={24} >*/}
          <Bar
            url={"/get_chart_opportunity_by_type"}
            options={{
              colors: [blue_6, purple_5, mangeta_6],
            }}
            filter={formFilter}
            selectName={"months"}
            defaultValue={"2"}
            title={"Notificações por tipo"}
            height="400px"
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} md={24} style={{ marginTop: 16 }}>
          <Bar
            url={`get_chart_opportunity_lost`}
            format={"MM/YYYY"}
            options={{
              colors: [purple_5],
            }}
            title={"Notificações perdidas"}
            width="100%"
            height="404px"
            filter={formFilter}
            filterRender={
              <Row gutter={[16, 16]} justify={"end"}>
                <Col xs={12} lg={8}>
                  <Form.Item name={"type"} initialValue={null}>
                    <Select
                      style={{ width: "100%" }}
                      size={"small"}
                      options={[
                        {
                          value: null,
                          label: "Todos os tipos",
                        },
                        ...optionsType.map((item) => ({
                          value: item.id,
                          label: item.name,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={8}>
                  <Form.Item name={"months"} initialValue={"2"}>
                    <Select
                      style={{ width: "100%" }}
                      size={"small"}
                      options={GRAF_OPTIONS}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
          />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} md={24} style={{ marginTop: 16 }}>
          <Bar
            url={`get_chart_opportunity_revenue`}
            format={"MM/YYYY"}
            title={"Receita total gerada"}
            width="100%"
            height="404px"
            hasSelect
            filter={formFilter}
            options={{
              colors: [purple_5],
            }}
            selectName={"months"}
            // hasPadding={false}
            defaultValue={"2"}
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} md={24} style={{ marginTop: 16 }}>
          <Donut
            // hasPadding={isDesktop}
            url={"get_chart_opportunity_cancellation_reason"}
            title={"Distribuição do cancelamento"}
            width="100%"
            height="400px"
            filter={formFilter}
            defaultValue={"2"}
            selectName={"months"}
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
      </Row>

      <FilterEquipmentDashboardNotificationOffCanvas
        open={openFilter}
        setOpen={setOpenFilter}
        initialFormFilter={_initialFormFilter}
        formFilter={formFilter}
        setFormFilter={setFormFilter}
      />
    </>
  );
}
