import {useEffect, useState} from "react";
import {isAuthenticated, saveTokens} from "../../util/Auth";
import api from "../../services/api";
import {useNavigate} from "react-router-dom";
import {
    FormContainer, LoginContainer, MainContainer, StyleButtonMicrosoft,
    StyledImage,
    StyledLogoMicrosoft, StyledPasswordInput, StyledText,
    StyledTitle,
    StyledTracbelLargeIcon,
    StyledTracbelMiniIcon
} from "./styled";
import {BuildFieldErrorsDict} from "../../util/Utils";
import {MICROSOFT_APP_CLIENT_ID, MICROSOFT_APP_TENANT_URL} from "../../settings";
import LoginImage from '../../assets/img/image_login.jpg'
import LoginImageTablet from '../../assets/img/image_login_tablet.jpg'
import {Button, Col, Divider, Form, Input, Row} from 'antd';
import MicrosoftLogin from "react-microsoft-login";
import {useUserContext} from "../../context/UserContext";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";


function Login() {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    let navigate = useNavigate();
    const {user, refreshUser} = useUserContext()

    const {isTablet, isPhone,isDesktop} = useSystemBreakpoint();


    const MicrosoftHandleLogin = async (err, data, msal) => {
        localStorage.clear()
        if (!err && data) {
            // const request_data = {
            //     'email': data['account']['userName'],
            //     'name': data['account']['name'],
            //     'token': data['idToken']['rawIdToken']
            // };
            setLoading(true)
            try {
                const response = await api.post('auth/microsoft/', data);
                saveTokens({
                    'access': response.data.access,
                    'refresh': response.data.refresh
                });
                refreshUser()
            } catch (error) {
                setLoading(false)
                BuildFieldErrorsDict(error, null, false)
            }
        }else{
             BuildFieldErrorsDict(null, null, 'Erro ao realizar login contate o administrador')
        }
    };

    useEffect(() => {
        isAuthenticated() && user && navigate('/')
    }, [user])

    async function doLogin(values) {
        try {
            setLoading(true)
            let data = {
                'username': values.username,
                password: values.password
            };
            const response = await api.post('auth/token/', data);
            saveTokens({
                'access': response.data.access,
                'refresh': response.data.refresh
            });
            refreshUser()
        } catch (error) {
            BuildFieldErrorsDict(error, null)
        } finally {
            setLoading(false)
        }
    }


    return (
        <>

            <MainContainer>
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        <LoginContainer data-login>


                            <FormContainer data-form isPhone={isPhone}>
                                <StyledTracbelLargeIcon/>
                                <StyledTitle level={4}>Uptime
                                    Center</StyledTitle>
                                <MicrosoftLogin
                                    debug={true}
                                    withUserData={true}
                                    forceRedirectStrategy={false}
                                    graphScopes={["user.read"]}
                                    clientId={MICROSOFT_APP_CLIENT_ID}
                                    tenantUrl={MICROSOFT_APP_TENANT_URL}
                                    useLocalStorageCache={true}
                                    authCallback={MicrosoftHandleLogin}
                                >
                                    <StyleButtonMicrosoft>
                                        <div>
                                            <StyledLogoMicrosoft/>
                                        </div>
                                        <span>Entrar com a conta da Microsoft</span>
                                    </StyleButtonMicrosoft>
                                </MicrosoftLogin>
                                <Divider><StyledText>Ou</StyledText></Divider>
                                <Form
                                    layout={'vertical'}
                                    form={form}
                                    requiredMark={false}
                                    validateTrigger={'onSubmit'}
                                    onFinish={doLogin}>
                                    <Form.Item label="E-mail" name="username"
                                               rules={[{
                                                   required: true,
                                                   message: 'Email é obrigatório'
                                               },
                                                   {
                                                       type: 'email',
                                                       message: 'Informe um e-mail válido'
                                                   }
                                               ]}>
                                        <Input placeholder="Seu e-mail"/>
                                    </Form.Item>
                                    <Form.Item label="Senha" name="password"
                                               rules={[{
                                                   required: true,
                                                   message: 'Senha é obrigatória'
                                               }]}>
                                        <StyledPasswordInput
                                            placeholder="Sua senha"/>
                                    </Form.Item>
                                    <Button loading={isLoading} type="primary"
                                            block
                                            htmlType={'submit'}>Acessar</Button>
                                </Form>
                            </FormContainer>
                            <StyledTracbelMiniIcon/>
                        </LoginContainer>
                    </Col>
                    {isTablet && (
                        <Col md={12} lg={16}>
                            <StyledImage src={isDesktop ? LoginImage : LoginImageTablet}/>
                        </Col>)}
                </Row>
            </MainContainer>
        </>
    );
}

export default Login;
