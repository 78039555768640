import { Button, Col, InputNumber, Row, Select } from "antd";
import styled from "styled-components";
import { useState } from "react";
import { gray_2, red_6 } from "../../Cores";
import { CloseOutlined, DownOutlined, InboxOutlined } from "@ant-design/icons";
import Empty from "../Base/Empty";
import * as XLSX from "xlsx";
import Dragger from "antd/lib/upload/Dragger";
import { useStaticDataContext } from "../../context/StaticDataContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDragger = styled(Dragger)`
  background: rgba(34, 36, 37, 1) !important;
  border-color: rgba(86, 93, 98, 1);
  display: block;
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: white;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;

export default function PartSelection({
  partData,
  setPartData,
  startingCenter,
  startingBrand,
}) {
  const [partRowQuantity, setPartRowQuantity] = useState(1);
  const { centers, brands } = useStaticDataContext();

  const handleButtonClick = () => {
    setPartRowQuantity(partRowQuantity + 1);
    setPartData([
      ...partData,
      {
        partNumber: "",
        quantity: "",
        manufacturer: startingBrand.id_sap,
        center: startingCenter,
      },
    ]);
  };

  const handleRowRemoval = (index) => {
    const newRowList = [...partData];
    newRowList.splice(index, 1);
    setPartData(newRowList);
  };

  const handleChange = (index, key, value) => {
    const newRowList = [...partData];
    newRowList[index][key] = value;
    setPartData(newRowList);
  };

  const handleFileRead = (file, fileList) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const centerIds = Object.values(centers).map(({ id }) => id);

      const addedParts = partData.filter(
        (value) =>
          value.partNumber &&
          value.quantity &&
          value.manufacturer &&
          value.center
      ).length
        ? partData.filter(
            (value) =>
              value.partNumber &&
              value.quantity &&
              value.manufacturer &&
              value.center
          ).length - 1
        : 0;

      const newPartData = jsonData.slice(1).map((row, index) => {
        const center = `${row[3]}`.padStart(4, "0");
        const manufacturer =
          brands.find(({ name }) => name === row[2].toUpperCase())?.id_sap ||
          "";

        return {
          partNumber: row[0],
          quantity: row[1],
          manufacturer,
          center: centerIds.includes(center) ? center : "",
          item: addedParts + index + 1,
        };
      });
      setPartData((prev) => [
        ...prev.filter(
          (value) =>
            value.partNumber &&
            value.quantity &&
            value.manufacturer &&
            value.center
        ),
        ...newPartData,
      ]);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Container>
      <Row style={{ marginBottom: "1.25rem" }}>
        <Col span={24}>
          <StyledDragger
            typesAcceptFile={["csv", "txt"]}
            noLimitFileSize
            customRequest={customRequest}
            showUploadList={false}
            beforeUpload={handleFileRead}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <label>Clique para adicionar ou arraste aqui</label>
          </StyledDragger>
          <Info>
            * Baixe o modelo{" "}
            <a
              href="https://satracbeluptimecenter.blob.core.windows.net/uptime-prd/static/ImportPecas.xlsx?se=2124-03-31T17%3A23%3A37Z&sp=r&sv=2021-08-06&sr=b&sig=wB1NE4sB7MhKcjmgylKBTr1Ex01pxYtfNyb7oGJhBVk%3D"
              target="_blank"
              rel="noreferrer"
            >
              clicando aqui
            </a>
            . Não adicione ou remova nenhuma coluna, mesmo que não vá preencher
            os campos relativos à ela.
          </Info>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={5}>Número da Peça</Col>
        <Col span={5}>Quantidade</Col>
        <Col span={5}>Fabricante</Col>
        <Col span={5}>Centro</Col>
      </Row>
      {partData.map((part, index) => {
        return (
          <Row key={index}>
            <Col span={5}>
              <InputNumber
                style={{ width: "80%" }}
                placeholder={"Número da Peça"}
                value={part.partNumber}
                onChange={(value) => handleChange(index, "partNumber", value)}
              />
            </Col>
            <Col span={5}>
              <InputNumber
                style={{ width: "80%" }}
                placeholder={"Quantidade"}
                value={part.quantity}
                onChange={(value) => handleChange(index, "quantity", value)}
              />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: "80%" }}
                placeholder={"Fabricante"}
                value={part.manufacturer}
                onChange={(value) => handleChange(index, "manufacturer", value)}
                notFoundContent={<Empty />}
                suffixIcon={<StyledDownOutlined />}
                options={brands.map(({ id_sap, name }) => ({
                  label: name,
                  value: id_sap,
                }))}
              />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: "80%" }}
                placeholder={"Centro"}
                value={part.center}
                onChange={(value) => handleChange(index, "center", value)}
                notFoundContent={<Empty />}
                suffixIcon={<StyledDownOutlined />}
                options={centers
                  .sort((a, b) => a.id - b.id)
                  .map(({ id }) => ({
                    label: id,
                    value: id,
                  }))}
              />
            </Col>
            <Col span={2}>
              <CloseOutlined
                style={{ color: `${red_6}`, marginTop: "9px" }}
                onClick={() => handleRowRemoval(index)}
              />
            </Col>
          </Row>
        );
      })}
      <Row>
        <Button type={"primary"} onClick={handleButtonClick}>
          Adicionar item
        </Button>
      </Row>
    </Container>
  );
}
