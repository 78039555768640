import React from "react";
import styled from "styled-components";
import ListOpportunity from "../Opportunity/ListOpportunity";

const TabContainer = styled.div`
  padding-top: 24px;
`;

export default function TabOpportunityEquipment({ equipment, ...props }) {
  return (
    <TabContainer>
      <ListOpportunity
        hasImportButton={false}
        equipmentId={equipment.id}
        placeholderSearch={"Pesquise na tabela"}
        hasExportButton={false}
      />
    </TabContainer>
  );
}
