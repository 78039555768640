import React from "react";
import { Form, Select } from "antd";
import { TYPE_OS } from "../../util/OpportunityUtils";
import TextArea from "antd/lib/input/TextArea";
import { cities } from "../../util/cities";

export default function OsCreationInitialInformationForm({ form, client }) {
  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name={"type"}
        label={"Tipo de OS"}
        rules={[
          {
            required: true,
            message: "Tipo de OS é obrigatório",
          },
        ]}
      >
        <Select options={TYPE_OS} placeholder={"Selecione o tipo da OS"} />
      </Form.Item>
      <Form.Item
        name={"description"}
        rules={[
          {
            required: true,
            message: "Descrição é obrigatório",
          },
        ]}
        label={"Descrição"}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={"city"}
        initialValue={client.city}
        rules={[
          {
            required: true,
            message: "Cidade é obrigatória",
          },
        ]}
        label={"Cidade"}
      >
        <Select
          showSearch
          allowClear
          options={cities.map((item) => ({ label: item, value: item }))}
        />
      </Form.Item>
    </Form>
  );
}
