import React from "react";
import {UserOutlined} from "@ant-design/icons";
import {Avatar as AvatarAntd, Tooltip} from "antd";
import styled from 'styled-components';
import {
    cyan_2,
    cyan_9,
    day_break_blue_2, day_break_blue_9,
    lime_2,
    lime_9,
    mangeta_2,
    mangeta_9,
    text_color,
    volcano_2,
    volcano_9
} from "../../Cores";

const StyledAvatarAntd = styled(AvatarAntd)`
  border: 1px solid ${text_color};
  //background: white;
`

let colors = [
    {
        color: lime_2,
        backgroundColor: lime_9,
    },
    {
        color: cyan_2,
        backgroundColor: cyan_9,
    }, {
        color: mangeta_2,
        backgroundColor: mangeta_9,
    }, {
        color: day_break_blue_9,
        backgroundColor: day_break_blue_2,
    },
    {
        color: volcano_2,
        backgroundColor: volcano_9,
    }
]

export default function Avatar({src, name, id, ...props}) {

    const _getInitials = () => {
        try {
            let initials = ''
            let name_split = name.split(' ')
            const maxNumber = 2
            name_split.forEach((aux, index) => {

                if (index < maxNumber) {
                    initials += `${aux[0]}${index < maxNumber - 1 ? '.' : ''}`
                }
            })
            return initials.toUpperCase()
        } catch (e) {
            return <UserOutlined/>
        }
    }

    const _getColor = () => {
        let style = {}
        let mod_id = id % 5
        switch (mod_id) {
            case 1:
                style = {
                    color: cyan_2,
                    backgroundColor: cyan_9,
                }
                break
            case 2:
                style = {
                    color: day_break_blue_9,
                    backgroundColor: day_break_blue_2,
                }
                break
            case 3:
                style = {
                    color: mangeta_2,
                    backgroundColor: mangeta_9,
                }
                break
            case 4:
                style = {
                    color: volcano_2,
                    backgroundColor: volcano_9,
                }
                break
            default:
                style = {
                    color: lime_2,
                    backgroundColor: lime_9,
                }
                break
        }
        return style
    }
    return (
        <Tooltip title={name}>
            {src ? <StyledAvatarAntd
                    // size={{xs: 24, sm: 32, md: 40, lg: 50, xl: 50, xxl: 50}}
                    src={src}
                    // style={{background: "in"}}
                    icon={<UserOutlined/>} {...props}/> :
                <StyledAvatarAntd
                    style={_getColor()} {...props}>{_getInitials()}</StyledAvatarAntd>}
        </Tooltip>
    )
}