import React, {useState, createContext, useEffect, useContext} from 'react';
import {deleteTokens, isAuthenticated} from "../util/Auth";
import {BuildFieldErrorsDict} from "../util/Utils";
import api from "../services/api";
import {useNavigate} from "react-router-dom";
import {DEBUG} from "../settings";

const UserContext = createContext();

function UserProvider({children}) {
    const [user, setUser] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [refreshContent, setRefreshContent] = useState(false);
    const [userCentersFilter, setUserCentersFilter] = useState(null);
    const [userBrandsFilter, setUserBrandsFilter] = useState(null);

    useEffect(() => {
        if (userCentersFilter !== null) {
            updateCentersUser()
            localStorage.setItem('centers', JSON.stringify(userCentersFilter))
            setRefreshContent(true)
        }
        if (userBrandsFilter !== null) {
            localStorage.setItem('brands', JSON.stringify(userBrandsFilter))
            setRefreshContent(true)
        }
    }, [userCentersFilter, userBrandsFilter])

    let navigate = useNavigate();

    const updateCentersUser = async () => {
        try {

            if (isAuthenticated()) {
                const data = {
                    centers_filter_config: JSON.stringify(userCentersFilter),

                }
                await api.patch(`user/my_user`, data);

            }
        } catch (error) {
            console.log(error)

        }
    }

    const get_user = async () => {
        try {
            if (isAuthenticated()) {
                const response = await api.get(`user/my_user`);

                if (response.data.is_active) {
                    setUser(response.data);
                    setUserCentersFilter(JSON.parse(response.data.centers_filter_config))
                    setUserBrandsFilter(JSON.parse(response.data.brands_filter_config))
                } else {
                    // eslint-disable-next-line no-throw-literal
                    BuildFieldErrorsDict(null, null, 'Usuário inativo')
                    throw 'Usuário inativo'
                }
                // etUserClient(response.data[0].user_cliente_permission);
                // return response.data[0]
            }
        } catch (error) {
            if (!DEBUG) {
                deleteTokens()
                // BuildFieldErrorsDict(error, null, 'E-mail inválido. Em caso de dificuldades, fale com o seu gestor Tracbel.')
                navigate('/login')
            }

        }
    }

    useEffect(() => {
        get_user()
    }, []);
    useEffect(() => {
        if (user) {
            localStorage.setItem('brands', user.brands_filter_config)
            setRefreshContent(true)
        }

    }, [user]);

    const refreshUser = () => {
        setRefresh(true)
    }
    useEffect(() => {
        if (refresh === true) {
            get_user()
            setRefresh(false)
        }
    }, [refresh]);

    useEffect(() => {
        if (refreshContent === true) {
            // get_user()
            setRefreshContent(false)
        }
    }, [refreshContent]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                setUserCentersFilter,
                userCentersFilter,
                refreshContent,
                setRefreshContent,
                refreshUser
            }}>
            {children}
        </UserContext.Provider>
    );
}

export {UserProvider};

export function useUserContext() {
    return useContext(UserContext);
}