import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "antd";
import styled from "styled-components";
import { text_color } from "../../Cores";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import OffCanvas from "../Base/OffCanvas";
import Select from "../Base/Select";
import { Notification } from "../../notification";
import api from "../../services/api";
import { BuildFieldErrorsDict } from "../../util/Utils";
import TextArea from "../Base/Forms/TextArea";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

export default function CancelOpportunityOffCanvas({
  open,
  opportunity,
  setOpen,
  setOpportunity,
}) {
  const [form] = Form.useForm();
  const [hasChange, setHasChange] = useState(false);
  const [motivationOptions, setMotivationOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsStatusChangeReason, setOptionsStatusChangeReason] = useState(
    []
  );
  const [backupStatusChangeReason, setBackupStatusChangeReason] = useState([]);
  const [optionsContactChannel, setOptionsContactChannel] = useState([]);
  const [backupContactChannel, setBackupContactChannel] = useState([]);

  function onClose() {
    if (hasChange) {
      Modal.confirm({
        title: "Deseja realmente sair?",
        content:
          "Ao escolher voltar, você abandonará o cancelamento e os dados serão perdidos.",
        icon: <ExclamationCircleOutlined />,
        // content: 'Deseja realmente fechar?',
        okText: "Sim",
        cancelText: "Não",
        onOk: closeDrawer,
      });
    } else {
      closeDrawer();
    }
  }

  useEffect(() => {
    async function fetchOptions() {
      try {
        const ID_MOTIVATION_CANCEL = 10;
        const response = await api.get(
          "opportunity_cancelation_reason?page_size=10000"
        );
        const statusChangeReasonResponse = await api.get(
          "opportunity_status_change_reason"
        );
        const contactChannelResponse = await api.get(
          "opportunity_contact_channel"
        );

        setBackupStatusChangeReason(statusChangeReasonResponse.data);
        setBackupContactChannel(contactChannelResponse.data);
        setMotivationOptions(
          response.data.results
            .filter(({ id }) => id !== ID_MOTIVATION_CANCEL)
            .map((item) => ({
              value: item.id,
              label: item.name,
            }))
        );
      } catch (error) {
        // setLoading(false)
        BuildFieldErrorsDict(error, null, false);
      }
    }

    fetchOptions();

    // form.setFieldsValue({status: opportunity.status.id})
  }, []);

  function closeDrawer() {
    setOpen(false);
    form.resetFields();
  }

  const onSubmit = async () => {
    setIsLoading(true);
    let values;
    try {
      values = await form.validateFields();
    } catch (error) {
      setIsLoading(false);
      return;
    }

    try {
      const data = {
        ...values,
      };
      const response = await api.post(
        `opportunity/${opportunity.id}/cancel`,
        data
      );
      Notification("success", "Notificação cancelada com sucesso.");
      closeDrawer();
      setOpportunity(response.data);
    } catch (error) {
      BuildFieldErrorsDict(error, form.setFields, "Erros encontrados");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setOptionsStatusChangeReason(
      backupStatusChangeReason
        .filter((item) => item.id !== opportunity?.status_change_reason?.id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    );
    setOptionsContactChannel(
      backupContactChannel
        .filter((item) => item.id !== opportunity?.contact_channel?.id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
    );
  }, [
    backupStatusChangeReason,
    opportunity.status_change_reason,
    backupContactChannel,
    opportunity.contact_channel,
  ]);

  return (
    <OffCanvas
      title={<Title>Cancelar a notificação</Title>}
      onClose={onClose}
      open={open}
      width={"378px"}
      footer={
        <Button
          // disabled={!hasChange}
          loading={isLoading}
          // type={'primary'}
          onClick={onSubmit}
          danger
        >
          Cancelar notificação
        </Button>
      }
    >
      <Form
        id={"responsableForm"}
        layout={"vertical"}
        initialValues={{ is_active: true }}
        form={form}
        onValuesChange={() => setHasChange(true)}
      >
        <Form.Item
          label={"Motivo do cancelamento"}
          name={"cancellation_reason"}
          rules={[
            {
              required: true,
              message: "Selecione um motivo de cancelamento",
            },
          ]}
        >
          <Select
            options={motivationOptions}
            placeholder="Selecione o motivo do cancelamento"
          />
        </Form.Item>
        <Form.Item label={"Descrição"} name={"cancellation_description"}>
          <TextArea
            showCount
            maxLength={2000}
            placeholder="Descreva o motivo aqui"
          />
        </Form.Item>
        <Form.Item
          label={"Usuários a serem notificados"}
          name={"notified_users"}
        >
          <Select
            url={"user?is_active=true&page_size=10000"}
            label_key={"get_full_name"}
            mode="multiple"
            placeholder="Busque pelo nome do usuário"
          />
        </Form.Item>
        <Form.Item label={"Motivo do Status"} name={"status_change_reason"}>
          <Select
            mode="multiple"
            placeholder="Busque pelo motivo"
            options={optionsStatusChangeReason}
          />
        </Form.Item>
        <Form.Item label={"Canal de comunicação"} name={"contact_channel"}>
          <Select
            placeholder="Busque pelo canal de comunicação"
            options={optionsContactChannel}
          />
        </Form.Item>
      </Form>
    </OffCanvas>
  );
}
