import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { gray } from "../../Cores";
import { dateFormat } from "../../util/dates";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & > *:first-child {
    //margin-top: -4px;
  }
`;
const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${gray};
`;
const StyledInfo = styled.span`
  color: ${gray};
  line-height: 22px;
  //line-height: 1px;
`;

export default function LastDate({ label, datetime, ...props }) {
  // if(!datetime) return null
  return (
    <>
      {datetime && (
        <Container {...props}>
          <StyledInfoCircleOutlined />
          <StyledInfo>
            {label} {dateFormat(datetime, "[às] HH[h]mm [de] DD/MM/YYYY  ")}
          </StyledInfo>
        </Container>
      )}
    </>
  );
}
